import { RequestStatus } from "@common/enums";
import { AddDashboardForm, IModalBaseProps } from "@common/index";
import { Dataset, Organization, User } from "@common/types";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addDashboard } from "@store/slices/Dashboards/thunks";
import {
  fetchOwnedDatasets,
  fetchSharedDatasets,
} from "@store/slices/Datasets/thunks";
import { createAutoCompleteOptionsFromArray } from "@utils/index";
import { Input, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AddDashboardModal: React.FC<IModalBaseProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const ownedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.ownedDatasets.status
  );

  const sharedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.sharedDatasets.data
  );

  const sharedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.sharedDatasets.status
  );

  useEffect(() => {
    if (currentUser && ownedDatasetsStatus === RequestStatus.IDLE) {
      dispatch(fetchOwnedDatasets(currentUser.id));
    }
  }, [currentUser, ownedDatasetsStatus]);

  useEffect(() => {
    if (currentUser && sharedDatasetsStatus === RequestStatus.IDLE) {
      dispatch(fetchSharedDatasets(currentUser.id));
    }
  }, [currentUser, sharedDatasetsStatus]);

  useEffect(() => {
    const allDatasetsOfOrganization: Dataset[] = filterDatasetsByOrganization([
      ...ownedDatasets,
      ...sharedDatasets,
    ]);

    setOptions(
      createAutoCompleteOptionsFromArray(
        allDatasetsOfOrganization,
        (dataset) => dataset.id,
        (dataset) => dataset.name
      )
    );
  }, [currentOrganization, ownedDatasets, sharedDatasets]);

  const filterDatasetsByOrganization = (datasets: Dataset[]) => {
    return datasets.filter((d) => d.tenant === currentOrganization.id);
  };

  const onOkHandler = () => {
    setIsOpen(false);
    form.submit();
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: AddDashboardForm) => {
    formData.dataset = JSON.parse(formData.dataset as string);

    const data: AddDashboardForm = {
      tenant: currentOrganization.id,
      name: formData.name,
      description: formData.description,
      uid: currentUser.id,
      dataset: formData.dataset,
      layouts: [],
      users: [],
    };

    dispatch(addDashboard(data))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.addDashboard", {
            dashboardName: res.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addDashboard", {
            dashboardName: data.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.addDashboardModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addDashboardForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label={t("common.description")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="dataset"
          label={t("modals.addDashboardModal.datasets")}
        >
          <Select
            showSearch
            allowClear
            placeholder={t("modals.addDashboardModal.datasets")}
          >
            {options.map((option) => (
              <Select.Option
                key={option.value}
                value={JSON.stringify(option.selectedOption)}
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddDashboardModal;
