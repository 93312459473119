import { AddPaymentCardForm, AppDate, IModalBaseProps, Organization } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addPaymentCard } from "@store/slices/Payments/thunks";
import { Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentCard } from "../../components";
import * as S from "./AddPaymentCardModal.style";

const AddPaymentCardModal: React.FC<IModalBaseProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const [formFields, setFormFields] = useState<{
    cardHolderName: string;
    cardNumber: number;
    expireDate: AppDate;
  }>();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: AddPaymentCardForm) => {
    formData.tenantId = currentOrganization.id;
    formData.expireMonth = (formData.expireDate.month() + 1).toString();
    formData.expireYear = formData.expireDate.year().toString();

    dispatch(addPaymentCard(formData))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.addPaymentCard"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addPaymentCard"),
        });
      });
  };

  const renderTempCard = () => {
    return (
      <PaymentCard
        showcase={true}
        cardNumber={formFields?.cardNumber || ""}
        cardHolderName={formFields?.cardHolderName || ""}
        expireDate={
          formFields?.expireDate ? formFields?.expireDate.format("MM/YY") : ""
        }
      />
    );
  };

  const monthFormat = "MM/YY";

  return (
    <Modal
      title={t("modals.addPaymentCardModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <S.CustomSpacer direction="vertical" size={"large"}>
        <Row justify="center">{renderTempCard()}</Row>

        <BaseForm
          onValuesChange={() => setFormFields(form.getFieldsValue())}
          onFinish={onSubmitHandler}
          form={form}
          layout="vertical"
          name="addPaymentMethodForm"
        >
          <BaseForm.Item
            name="cardHolderName"
            label={t("modals.addPaymentCardModal.cardHolderName")}
          >
            <Input
              placeholder={t("modals.addPaymentCardModal.cardHolderName")}
            />
          </BaseForm.Item>

          <Row gutter={[12, 12]}>
            <Col span={16}>
              <BaseForm.Item
                name="cardNumber"
                label={t("modals.addPaymentCardModal.cardNumber")}
              >
                <Input
                  placeholder={t("modals.addPaymentCardModal.cardNumber")}
                />
              </BaseForm.Item>
            </Col>

            <Col span={8}>
              <BaseForm.Item
                name="expireDate"
                label={t("modals.addPaymentCardModal.expireDate")}
              >
                <S.CustomDayjsDatePicker
                  picker="month"
                  clearIcon={false}
                  format={monthFormat}
                  placeholder={t("modals.addPaymentCardModal.expireDate")}
                />
              </BaseForm.Item>
            </Col>
          </Row>
        </BaseForm>
      </S.CustomSpacer>
    </Modal>
  );
};

export default AddPaymentCardModal;
