import { AppstoreOutlined } from "@ant-design/icons";
import { InsufficientLicenseCause, RequestStatus } from "@common/enums";
import { Dashboard, License, Organization, User } from "@common/types";
import { CarouselNavigationSection, H1, TabTitle } from "@components/common";
import { LicenseUpgradeModal } from "@components/common/LicenseUpgradeModal";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { AddDashboardModal } from "@pages/DashboardPages/modals";
import { APPLICATION_PATHS } from "@routes/ApplicationPaths";
import { setCurrentDashboard } from "@store/slices/DashboardContent/slice";
import {
  fetchOwnedDashboards,
  fetchSharedDashboards,
} from "@store/slices/Dashboards/thunks";
import { fetchLicenses } from "@store/slices/Licenses/thunks";
import { fetchLicenseOfOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import { fetchAllUsers } from "@store/slices/Users/thunks";
import { findOrganizationShareType } from "@utils/commonUtils";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DashboardMainPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const allUsersStatus: RequestStatus = useAppSelector(
    (state) => state.users.status
  );

  const licenses: License[] = useAppSelector((state) => state.licenses.data);

  const licensesStatus: RequestStatus = useAppSelector(
    (state) => state.licenses.status
  );

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.ownedDashboards.data
  );

  const sharedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.sharedDashboards.data
  );

  const ownedDashboardsStatus: RequestStatus = useAppSelector(
    (state) => state.dashboards.ownedDashboards.status
  );

  const sharedDashboardsStatus: RequestStatus = useAppSelector(
    (state) => state.dashboards.sharedDashboards.status
  );

  const [isAddDashboardModalOpen, setIsAddDashboardModalOpen] =
    useState<boolean>(false);

  const [isLicenseUpgradeModalOpen, setIsLicenseUpgradeModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (allUsersStatus === RequestStatus.IDLE) {
      dispatch(fetchAllUsers());
    }
  }, [allUsersStatus]);

  useEffect(() => {
    if (licensesStatus === RequestStatus.IDLE) {
      dispatch(fetchLicenses());
    }
  }, [licensesStatus]);

  useEffect(() => {
    if (currentOrganization.license === undefined) {
      dispatch(fetchLicenseOfOrganization(currentOrganization.id))
        .unwrap()
        .then((res) => {
          const modifiedOrganization: Organization = {
            ...currentOrganization,
            license: res,
          };
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(
              modifiedOrganization,
              currentUser.id
            ),
          });
        })
        .catch((err) => {});
    }
  }, [currentOrganization]);

  useEffect(() => {
    currentUser && dispatch(fetchOwnedDashboards(currentUser.id));
  }, [currentUser]);

  useEffect(() => {
    currentUser && dispatch(fetchSharedDashboards(currentUser.id));
  }, [currentUser]);

  const carouselKeyExtractor = useCallback(
    (dashboard: Dashboard) => dashboard.id,
    []
  );

  const carouselTitleExtractor = useCallback(
    (dashboard: Dashboard) => dashboard.name,
    []
  );

  const carouselDescriptionExtractor = useCallback(
    (dashboard: Dashboard) => dashboard.description,
    []
  );

  const filterDashboardByOrganization = (dashboards: Dashboard[]) => {
    return dashboards.filter((d) => d.tenant === currentOrganization.id);
  };

  const onCreateDashboardHandler = () => {
    if (currentOrganization.license) {
      const currentLicense = licenses.find(
        (license) => license.licenseType === currentOrganization.license?.type
      );

      if (currentLicense?.dashboardCount) {
        currentLicense.dashboardCount <=
        filterDashboardByOrganization(ownedDashboards).length
          ? setIsLicenseUpgradeModalOpen(true)
          : setIsAddDashboardModalOpen(true);
      }
    }
  };

  return (
    <React.Fragment>
      <TabTitle title={t("pages.dashboards.tabTitle")} />
      <H1>{t("pages.dashboards.pageTitle")}</H1>

      <LicenseUpgradeModal
        cause={InsufficientLicenseCause.DASHBOARD_COUNT}
        isOpen={isLicenseUpgradeModalOpen}
        setIsOpen={setIsLicenseUpgradeModalOpen}
      />

      <AddDashboardModal
        isOpen={isAddDashboardModalOpen}
        setIsOpen={setIsAddDashboardModalOpen}
      />

      <CarouselNavigationSection<Dashboard>
        isLoading={ownedDashboardsStatus === RequestStatus.PENDING}
        items={filterDashboardByOrganization(ownedDashboards)}
        title={t("pages.dashboards.yourDashboards")}
        icon={<AppstoreOutlined />}
        onCreate={onCreateDashboardHandler}
        onItemClick={(dashboard: Dashboard) => {
          dispatch(setCurrentDashboard(dashboard));
          navigate(`${APPLICATION_PATHS.DASHBOARD_PATH}/${dashboard.id}`);
        }}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
        descriptionExtractor={carouselDescriptionExtractor}
      />

      <CarouselNavigationSection<Dashboard>
        isLoading={sharedDashboardsStatus === RequestStatus.PENDING}
        icon={<AppstoreOutlined />}
        items={filterDashboardByOrganization(sharedDashboards)}
        title={t("common.sharedWithYou")}
        onItemClick={(dashboard: Dashboard) => {
          dispatch(setCurrentDashboard(dashboard));
          navigate(`${APPLICATION_PATHS.DASHBOARD_PATH}/${dashboard.id}`);
        }}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
        descriptionExtractor={carouselDescriptionExtractor}
      />
    </React.Fragment>
  );
};

export default DashboardMainPage;
