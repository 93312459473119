import {
  Dashboard,
  Dataset,
  License, LicenseType, Organization,
  ThemeType
} from "@common/index";
import { H3 } from "@components/common";
import { useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Dates } from "@services/index";
import { themeObject } from "@styles/themes/ThemeVariables";
import { Col, Divider, Progress, Row, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./CurrentLicenseInfoCard.style";

interface ICurrentLicenseInfoCardProps {
  license: License;
}

enum LicenseProgressType {
  DATASET = "DATASET",
  USER = "USER",
  DASHBOARD = "DASHBOARD",
}

const CurrentLicenseInfoCard: React.FC<ICurrentLicenseInfoCardProps> = ({
  license,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme: ThemeType = useAppSelector((state) => state.theme.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const ownedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.ownedDashboards.data
  );

  const filterDatasetByOrganization = (datasets: Dataset[]) => {
    return datasets.filter((d) => d.tenant === currentOrganization.id);
  };

  const filterDashboardByOrganization = (dashboards: Dashboard[]) => {
    return dashboards.filter((d) => d.tenant === currentOrganization.id);
  };

  const getProgressData = (progressType: LicenseProgressType) => {
    switch (progressType) {
      case LicenseProgressType.USER:
        return {
          title: t("common.users"),
          percent:
            ((currentOrganization.users.length + 1) / license.userCount) * 100,
          indicator: `${currentOrganization.users.length + 1}/${
            license.userCount
          } `,
        };
      case LicenseProgressType.DATASET:
        const datasetCount = filterDatasetByOrganization(ownedDatasets).length;

        return {
          title: t("pages.organizations.datasets"),
          percent: (datasetCount / license.datasetCount) * 100,
          indicator: `${datasetCount}/${license.datasetCount}`,
        };
      case LicenseProgressType.DASHBOARD:
        const dashboardCount =
          filterDashboardByOrganization(ownedDashboards).length;

        return {
          title: t("pages.organizations.dashboards"),
          percent: (dashboardCount / license!.dashboardCount) * 100,
          indicator: `${dashboardCount}/${license!.dashboardCount}`,
        };
    }
  };

  const renderProgressItem = (progressData: {
    title: string;
    percent: number;
    indicator: string;
  }) => {
    return (
      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Space direction="vertical" style={{ display: "flex" }}>
          <Row justify="center">
            <H3>{progressData.title}</H3>
          </Row>
          <Row justify="center">
            <Progress
              type="circle"
              percent={progressData.percent}
              status={progressData.percent >= 100 ? "exception" : "normal"}
              strokeColor={{
                "0%": themeObject[theme].mainLinearGradientColor1,
                "100%": themeObject[theme].mainLinearGradientColor2,
              }}
              format={() => progressData.indicator}
            />
          </Row>
        </Space>
      </Col>
    );
  };

  const renderValidity = () => {
    return (
      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
        <Row justify="center">
          <S.SectionHeaderSubtitle>
            {t("pages.organizations.validUntil")}
          </S.SectionHeaderSubtitle>
        </Row>
        <Row justify="center">
          <S.SectionHeader>
            {Dates.convertStringToDate(
              currentOrganization.license?.validTime || ""
            ).format("LL")}
          </S.SectionHeader>
        </Row>
      </Col>
    );
  };

  const onChangeLicenseHandler = () => {
    navigate(APPLICATION_PATHS.ORGANIZATION_PLAN_PATH);
  };

  const onMakePaymentHandler = () => {
    navigate(
      APPLICATION_PATHS.PAYMENTS_PATH.replace(
        ":organizationId",
        currentOrganization.id
      )
    );
  };

  return (
    <S.CardWrapper>
      <S.Card autoHeight={false} padding={[24, 20]}>
        <Row justify="space-between" align="middle">
          <Col>
            <Row justify="start">
              <S.SectionHeaderSubtitle>
                {t("pages.organizations.currentLicense")}
              </S.SectionHeaderSubtitle>
            </Row>
            <Row justify="start">
              <S.SectionHeader>{license?.licenseType.slice(8)}</S.SectionHeader>
            </Row>
          </Col>
          <Col>
            <S.CustomTextButton
              size="small"
              type="text"
              onClick={onMakePaymentHandler}
            >
              {t("pages.organizations.payments")}
            </S.CustomTextButton>
            <Divider type="vertical" />
            <S.CustomTextButton onClick={onChangeLicenseHandler}>
              {t("pages.organizations.changeLicense")}
            </S.CustomTextButton>
          </Col>
        </Row>

        {license !== undefined && (
          <S.ContentRow gutter={[12, 24]} justify="center" align={"middle"}>
            {renderProgressItem(getProgressData(LicenseProgressType.USER))}
            {renderProgressItem(getProgressData(LicenseProgressType.DATASET))}
            {renderProgressItem(getProgressData(LicenseProgressType.DASHBOARD))}
            {currentOrganization.license?.type !== LicenseType.LICENSE_FREE &&
              renderValidity()}
          </S.ContentRow>
        )}
      </S.Card>
    </S.CardWrapper>
  );
};

export default CurrentLicenseInfoCard;
