import { UserLoginForm } from "@common/index";
import { BaseForm } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { login } from "@store/slices/UserContent/thunks";
import { Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as AuthStyles from "../../AuthPages.style";
import * as S from "./LoginForm.style";
import { readDefaultDashboard } from "@app/services";
import {setDefaultDashboardLoaded} from "@store/slices/Dashboards/slice";

const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const defaultDashboardId = readDefaultDashboard();
  const authType = process.env.REACT_APP_AUTH;

  const ANTD_REQUIRE_RULE = {
    required: true,
    message: t("errors.requiredField"),
  };

  const ANTD_EMAIL_RULE = {
    required: true,
    type: "email",
    message: t("errors.invalidEmail"),
  };

  const onSubmitHandler = (form: UserLoginForm) => {
    dispatch(login(form))
      .unwrap()
      .then(() => {
        if (authType === "keycloak") {
          if (defaultDashboardId) {
            navigate(
              `${APPLICATION_PATHS.DASHBOARD_PATH}/${defaultDashboardId}`
            );
            dispatch(setDefaultDashboardLoaded(true));
          } else {
            navigate(APPLICATION_PATHS.DASHBOARD_PATH);
          }
        }
      })
      .catch((err) => {
        NotificationController.error({
          message: t("errors.invalidCredentials"),
        });
      });
  };

  return (
    <BaseForm onFinish={(values: UserLoginForm) => onSubmitHandler(values)}>
      <AuthStyles.FormItem
        label={t("pages.auth.email")}
        name="email"
        rules={[ANTD_REQUIRE_RULE, ANTD_EMAIL_RULE]}
      >
        <Input placeholder={t("pages.auth.email")} />
      </AuthStyles.FormItem>

      <AuthStyles.FormItem
        label={t("pages.auth.password")}
        name="password"
        rules={[ANTD_REQUIRE_RULE]}
      >
        <Input.Password placeholder={t("pages.auth.password")} />
      </AuthStyles.FormItem>

      <Link to={APPLICATION_PATHS.FORGOT_PASSWORD_PATH}>
        <S.ForgotPasswordText>
          {t("pages.auth.forgotPassword")}
        </S.ForgotPasswordText>
      </Link>

      <AuthStyles.SubmitButton type="primary" htmlType="submit">
        {t("pages.auth.login")}
      </AuthStyles.SubmitButton>

      <AuthStyles.FooterWrapper>
        <AuthStyles.Text>
          {t("pages.auth.noAccount")}{" "}
          <Link to={APPLICATION_PATHS.SIGN_UP_PATH}>
            <AuthStyles.LinkText>
              {t("pages.auth.createAccount")}
            </AuthStyles.LinkText>
          </Link>
        </AuthStyles.Text>
      </AuthStyles.FooterWrapper>
    </BaseForm>
  );
};

export default LoginForm;
