import { Button } from "antd";
import styled from "styled-components";

export const LoadingAndEmptyContainer = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BtnContainer = styled(Button)`
  color: var(--text-extra-light-color);
`;
