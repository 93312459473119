import { InputNumber, Space } from "antd";
import styled from "styled-components";

export const CustomSpacer = styled(Space)`
  width: 100%;
  display: flex;
`;

export const CustomInputNumber = styled(InputNumber)`
  width: 100%;
`;
