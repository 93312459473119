import { useAppSelector } from "@hooks/index";
import { AvatarUpload } from "@pages/ProfilePages/components";
import * as CommonStyle from "@styles/CommonStyles";
import React from "react";
import UserProfileNavigation from "../UserProfileNavigation/UserProfileNavigation";
import * as S from "./UserProfileCard.style";

const UserProfileCard: React.FC = () => {
  const currentUser = useAppSelector((state) => state.userContent.data);

  return (
    <S.Card padding={[15, 15]}>
      <CommonStyle.VerticalSpacer size="large">
        <S.Wrapper>
          <AvatarUpload />
        </S.Wrapper>
        <S.Wrapper>
          <S.FullName>{currentUser.name}</S.FullName>
        </S.Wrapper>
        <S.NavigationWrapper>
          <UserProfileNavigation />
        </S.NavigationWrapper>
      </CommonStyle.VerticalSpacer>
    </S.Card>
  );
};

export default UserProfileCard;
