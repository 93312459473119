import { auth } from "@api/index";
import { User as FirebaseUser, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";

const useFirebaseAuth = () => {
  /* 
    For first render it is must to define user as undefined.
    If undefined is thrown it is absolute that first render. 
    After first render null is thrown as user if user not logged in. If
    User logged in firebase user will be thrown.
  */
  const [user, setUser] = useState<FirebaseUser | undefined | null>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userComing) => {
      if (userComing) {
        setUser(userComing);
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return {
    user,
    isLoading,
  };
};

export default useFirebaseAuth;
