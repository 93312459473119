import {
  DatabaseOutlined,
  FileTextOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { RequestStatus } from "@common/index";
import {
  H1,
  Loading,
  NavigationCard,
  SectionHeader,
  TabTitle,
} from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import {
  AddDatasetFromTemplateModal,
  AddDatasetModal,
} from "@pages/DatasetPages/modals";
import { setCurrentTemplateset } from "@store/slices/TemplatesetContent/slice";
import {
  fetchTemplatesetKpis,
  fetchTemplatesetMetrics,
} from "@store/slices/TemplatesetContent/thunks";
import { fetchTemplatesets } from "@store/slices/Templatesets/thunks";
import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AINavigationCard } from "../components/AINavigationCard";
import { AddDatasetWithAIModal } from "../modals/AddDatasetWithAIModal";
import * as S from "./DatasetCreationPage.style";

const DatasetCreationPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isAddDatasetModalOpen, setIsAddDatasetModalOpen] =
    useState<boolean>(false);

  const [isAddDatasetWithAIModalOpen, setIsAddDatasetWithAIModalOpen] =
    useState<boolean>(false);

  const [
    isAddDatasetFromTemplateModalOpen,
    setIsAddDatasetFromTemplateModalOpen,
  ] = useState<boolean>(false);

  const templatesets = useAppSelector((state) => state.templatesets.data);
  const templatesetsStatus = useAppSelector(
    (state) => state.templatesets.status
  );

  useEffect(() => {
    if (templatesetsStatus === RequestStatus.IDLE) {
      dispatch(fetchTemplatesets());
    }
  }, [templatesetsStatus]);

  const datasetCreationNavigationCard = (
    <S.CardWrapper key={"createDataset"}>
      <NavigationCard
        icon={<DatabaseOutlined />}
        title={t("pages.datasets.createDataset")}
        description={t("pages.datasets.createFromStratch")}
        onClick={() => setIsAddDatasetModalOpen(true)}
      />
    </S.CardWrapper>
  );

  const datasetCreationWithAINavigationCard = (
    <S.CardWrapper key={"createDatasetWithAI"}>
      <AINavigationCard
        icon={<RobotOutlined />}
        title={t("pages.datasets.createDataset")}
        description={t("pages.datasets.createDatasetWithAI")}
        onClick={() => setIsAddDatasetWithAIModalOpen(true)}
      />
    </S.CardWrapper>
  );

  const generateDatasetCreationFromTemplateNavigationCards = () =>
    templatesets.map((templateset) => (
      <S.CardWrapper key={templateset.id}>
        <NavigationCard
          icon={<FileTextOutlined />}
          title={templateset.name}
          description={templateset.description}
          onClick={() => {
            dispatch(setCurrentTemplateset(templateset));
            dispatch(fetchTemplatesetKpis(templateset.id));
            dispatch(fetchTemplatesetMetrics(templateset.id));
            setIsAddDatasetFromTemplateModalOpen(true);
          }}
        />
      </S.CardWrapper>
    ));

  const renderNavigationCards = () => {
    return [
      datasetCreationWithAINavigationCard,
      datasetCreationNavigationCard,
      ...generateDatasetCreationFromTemplateNavigationCards(),
    ];
  };

  const renderLoading = () => {
    return (
      <S.LoadingContainer>
        <Loading size="7rem" />
      </S.LoadingContainer>
    );
  };

  return (
    <React.Fragment>
      <TabTitle title={t("pages.datasets.tabTitle")} />
      <SectionHeader
        customMargin="0 0 2rem 0"
        title={<H1>{t('pages.datasets.createDataset')}</H1>}
        backButtonVisible
      />

      <AddDatasetModal
        isOpen={isAddDatasetModalOpen}
        setIsOpen={setIsAddDatasetModalOpen}
      />

      <AddDatasetFromTemplateModal
        isOpen={isAddDatasetFromTemplateModalOpen}
        setIsOpen={setIsAddDatasetFromTemplateModalOpen}
      />

      <AddDatasetWithAIModal
        isOpen={isAddDatasetWithAIModalOpen}
        setIsOpen={setIsAddDatasetWithAIModalOpen}
      />

      {templatesetsStatus === RequestStatus.PENDING ? (
        renderLoading()
      ) : (
        <S.Container>
          {renderNavigationCards().map((card) => (
            <Col xs={24} md={12} lg={8} xl={6}>
              {card}
            </Col>
          ))}
        </S.Container>
      )}
    </React.Fragment>
  );
};

export default DatasetCreationPage;
