import React from "react";
import { useTranslation } from "react-i18next";
import { PersonalInfoForm } from "../PersonalInfoForm";
import { Card, withAnimation, withLoading } from "@components/common";

const PersonalInfoSection: React.FC = ({}) => {
    const { t } = useTranslation();

    return (
        <Card padding={[30, 30]}>
            <PersonalInfoForm />
        </Card>
    );
};

export default withLoading(withAnimation(PersonalInfoSection));
