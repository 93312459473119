import { axios } from "@api/index";
import { Kpi, Metric } from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const TEMPLATE_METRIC_ENDPOINT = `${BASE_PATH}/template/metric`;
const TEMPLATE_KPI_ENDPOINT = `${BASE_PATH}/template/kpi`;

export const fetchTemplatesetMetrics = createAsyncThunk(
  "templatesets/fetchTemplatesetMetrics",
  async (templateId: string) => {
    const response = await axios.get<Metric[]>(
      `${TEMPLATE_METRIC_ENDPOINT}/${templateId}`
    );
    return response.data;
  }
);

export const fetchTemplatesetKpis = createAsyncThunk(
  "templatesets/fetchTemplatesetKpis",
  async (templateId: string) => {
    const response = await axios.get<Kpi[]>(
      `${TEMPLATE_KPI_ENDPOINT}/${templateId}`
    );
    return response.data;
  }
);
