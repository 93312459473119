import { APPLICATION_PATHS } from "@routes/index";
import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface IProtectedRouteProps {
  isAllowed: boolean;
  fallbackPath?: string;
}

const ProtectedRoute = ({
  isAllowed,
  fallbackPath,
}: PropsWithChildren<IProtectedRouteProps>) => {
  return isAllowed ? (
    <Outlet />
  ) : fallbackPath ? (
    <Navigate to={fallbackPath} />
  ) : (
    <Navigate to={APPLICATION_PATHS.LOGIN_PATH} />
  );
};

export default ProtectedRoute;
