import { AddOrganizationForm, RequestStatus, User } from "@common/index";
import { BaseForm } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { setCurrentOrganization } from "@store/slices/OrganizationContent/slice";
import { addOrganization } from "@store/slices/Organizations/thunks";
import { logout } from "@store/slices/UserContent/thunks";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./NoOrganizationForm.style";

const NoOrganizationForm = () => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);
  const ownedOrganizationStatus = useAppSelector(
    (state) => state.organizations.ownedOrganizations.status
  );

  const onSubmitHandler = (form: AddOrganizationForm) => {
    form.uid = currentUser.id;
    form.users = [];

    dispatch(addOrganization(form))
      .unwrap()
      .then((res) => {
        dispatch(setCurrentOrganization(res));
        NotificationController.success({
          message: t("notifications.success.addOrganization", {
            organizationName: res.name,
          }),
        });
        navigate(APPLICATION_PATHS.DASHBOARD_PATH);
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addOrganization", {
            organizationName: form.name,
          }),
        });
      });
  };

  const onLogoutHandler = () => {
    dispatch(logout()).then((res) => navigate(APPLICATION_PATHS.LOGIN_PATH));
  };

  return (
    <BaseForm form={form} onFinish={onSubmitHandler}>
      <BaseForm.Item name="name">
        <Input placeholder={t("pages.noOrganization.organizationName")} />
      </BaseForm.Item>
      <Button
        loading={ownedOrganizationStatus === RequestStatus.PENDING}
        type="primary"
        htmlType="submit"
        block
      >
        {t("pages.noOrganization.createOrganization")}
      </Button>
      <S.FooterContainer>
        <S.LogoutText onClick={onLogoutHandler}>
          {t("pages.noOrganization.logout")}
        </S.LogoutText>
      </S.FooterContainer>
    </BaseForm>
  );
};

export default NoOrganizationForm;
