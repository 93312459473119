import { H1, H6 } from "@components/common";
import { Button } from "antd";
import styled from "styled-components";

export const SectionHeaderTitle = styled(H1)``;

export const SectionHeaderSubtitle = styled(H6)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-light-color);
  }
`;

export const BtnContainer = styled(Button)`
  color: var(--text-extra-light-color);
`;
