import { H6 } from "@app/components/common";
import { Button } from "antd";
import styled from "styled-components";

export const SectionHeaderSubtitle = styled(H6)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-light-color);
  }
`;

export const CustomButton = styled(Button)`
  color: var(--text-extra-light-color);
`;
