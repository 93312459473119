import { Col, Row } from "antd";
import React from "react";
import { ProfileDropdown } from "../components/Profile";
import { SettingsDropdown } from "../components/Settings";

interface ExpandedHeaderProps {}

const ExpandedHeader: React.FC<ExpandedHeaderProps> = () => {
  return (
    <Row justify="end" align="middle">
      <Row align="middle" gutter={[10, 10]}>
        <Col>
          <SettingsDropdown />
        </Col>
        <Row>
          <ProfileDropdown />
        </Row>
      </Row>
    </Row>
  );
};

export default ExpandedHeader;
