import {
  DatasetDefinitionItem as DatasetDefinitionItemType,
  UserRole,
} from "@common/enums";
import { IDropdownMenuItemOption, IUserWithRole } from "@common/interfaces";
import { Kpi, Metric } from "@common/types";
import { DropdownMenu } from "@components/common";
import {
  DeleteDatasetDefinitionItemModal,
  EditKpiModal,
  EditMetricModal,
} from "@pages/DatasetPages/modals";
import React, { useState } from "react";
import * as S from "./DatasetDefinitionItem.style";

interface IDatasetDefinitionItemProps {
  item?: Metric | Kpi;
  itemType?: DatasetDefinitionItemType;
  currentUserWithRole: IUserWithRole;
}

const DatasetDefinitionItem: React.FC<IDatasetDefinitionItemProps> = ({
  item,
  itemType,
  currentUserWithRole,
}) => {
  const DATASET_DEFINITION_ITEM_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "edit",
      title: "Edit",
      disabled: ![UserRole.ADMIN, UserRole.EDITOR].includes(
        currentUserWithRole.role
      ),
      onClick: () => setIsEditModalOpen(true),
    },
    {
      key: "delete",
      title: "Delete",
      disabled: ![UserRole.ADMIN].includes(currentUserWithRole.role),
      onClick: () => setIsDeleteModalOpen(true),
    },
  ];

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const renderEditModal = () => {
    switch (itemType) {
      case DatasetDefinitionItemType.METRIC:
        return (
          <EditMetricModal
            item={item as Metric}
            isOpen={isEditModalOpen}
            setIsOpen={setIsEditModalOpen}
          />
        );
      case DatasetDefinitionItemType.KPI:
        return (
          <EditKpiModal
            item={item as Kpi}
            isOpen={isEditModalOpen}
            setIsOpen={setIsEditModalOpen}
          />
        );
    }
  };

  const renderDeleteModal = () => {
    switch (itemType) {
      case DatasetDefinitionItemType.METRIC:
        return (
          <DeleteDatasetDefinitionItemModal
            item={item}
            itemType={DatasetDefinitionItemType.METRIC}
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
          />
        );
      case DatasetDefinitionItemType.KPI:
        return (
          <DeleteDatasetDefinitionItemModal
            item={item}
            itemType={DatasetDefinitionItemType.KPI}
            isOpen={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
          />
        );
    }
  };

  return (
    <React.Fragment>
      {renderEditModal()}
      {renderDeleteModal()}
      <S.Card padding={[15, 20]}>
        <S.HeaderRow>
          <S.HeaderText>{item?.name}</S.HeaderText>
          <DropdownMenu options={DATASET_DEFINITION_ITEM_OPTIONS} />
        </S.HeaderRow>
        <S.BodyText>{item?.description}</S.BodyText>
      </S.Card>
    </React.Fragment>
  );
};

export default DatasetDefinitionItem;
