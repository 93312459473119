import {
  ClockCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useAppDispatch } from "@app/hooks";
import { Dates } from "@app/services";
import {
  deleteMetricRecord,
  editMetricRecord,
} from "@app/store/slices/DatasetContent/thunks";
import {
  AppDate,
  DeleteMetricRecordForm,
  EditMetricRecordForm,
  Metric,
  MetricRecord,
} from "@common/index";
import { BaseForm, P1 } from "@components/common";
import { Col, Popconfirm, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./MetricRecordCard.style";

interface IMetricRecordCardProps {
  metric: Metric;
  record: MetricRecord;
}

const MetricRecordCard: React.FC<IMetricRecordCardProps> = ({
  metric,
  record,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const timeInputRef = useRef<any>(null);
  const valueInputRef = useRef<HTMLInputElement>(null);

  const [isEditModeOpen, setIsEditModeOpen] = useState<boolean>(false);

  useEffect(() => {
    form?.setFieldsValue({
      timestamp: Dates.convertStringToDate(record.timestamp),
      value: record.value,
    });
  }, [form, record]);

  const onTextClickHandler = (ref: React.RefObject<HTMLInputElement>) => {
    setIsEditModeOpen(true);

    setTimeout(() => {
      ref.current?.focus();
    }, 300);
  };

  const onCancelHandler = () => {
    setIsEditModeOpen(false);
  };

  const onSaveHandler = () => {
    setIsEditModeOpen(false);
    form.submit();
  };

  const onMetricRecordDeleteHandler = () => {
    const formData: DeleteMetricRecordForm = {
      metricId: metric.id,
      recordId: record.id,
      timestamp: record.timestamp,
    };

    dispatch(deleteMetricRecord(formData));
  };

  const onMetricRecordEditHandler = (formData: EditMetricRecordForm) => {
    formData.timestamp = (formData.timestamp as AppDate).toISOString();
    formData.metricId = metric.id;
    formData.recordId = record.id;

    dispatch(editMetricRecord(formData));
  };

  return (
    <React.Fragment>
      <S.Card padding={[15, 15]}>
        <BaseForm form={form} onFinish={onMetricRecordEditHandler}>
          <Row gutter={[15, 15]} justify="space-between" align="middle">
            <Col span={2}>
              <Row justify="center">
                <ClockCircleOutlined />
              </Row>
            </Col>

            <Col span={8}>
              {isEditModeOpen ? (
                <S.CustomBaseFormItem name="timestamp">
                  <S.CustomDatePicker ref={timeInputRef} />
                </S.CustomBaseFormItem>
              ) : (
                <P1 onClick={() => onTextClickHandler(timeInputRef)}>
                  {Dates.convertStringToDate(record.timestamp).format("HH:mm")}
                </P1>
              )}
            </Col>

            <Col span={10}>
              {isEditModeOpen ? (
                <S.CustomBaseFormItem name="value">
                  <S.ValueInput ref={valueInputRef} />
                </S.CustomBaseFormItem>
              ) : (
                <S.ValueText onClick={() => onTextClickHandler(valueInputRef)}>
                  {record.value}
                </S.ValueText>
              )}
            </Col>

            <Col span={4}>
              <Row justify="center">
                {isEditModeOpen && (
                  <S.CustomButton
                    type="text"
                    size="small"
                    icon={<SaveOutlined />}
                    onClick={onSaveHandler}
                  />
                )}

                {isEditModeOpen && (
                  <S.CustomButton
                    type="text"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={onCancelHandler}
                  />
                )}

                {!isEditModeOpen && (
                  <Popconfirm
                    onConfirm={onMetricRecordDeleteHandler}
                    title={t("pages.measurements.deleteRecord")}
                  >
                    <S.CustomButton
                      type="text"
                      size="small"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                )}
              </Row>
            </Col>
          </Row>
        </BaseForm>
      </S.Card>
    </React.Fragment>
  );
};

export default MetricRecordCard;
