import {
  AnalyticData,
  Dashboard,
  IDropdownMenuItemOption,
  IUserWithRole,
  UserRole,
  Visual,
} from "@common/index";
import { DropdownMenu, H3, SectionHeader } from "@components/common";
import { useAppSelector } from "@hooks/index";
import {
  DeleteVisualFromDashboardModal,
  EditVisualFromDashboardModal,
} from "@pages/DashboardPages/modals";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { VisualChart } from "../VisualChart";
import * as S from "./VisualCard.style";
import {visualRecordsInitialState, visualsInitialState} from "@store/slices/DashboardContent/slice";

interface IVisualCardProps {
  dashboardId: string;
  visualId: string;
  currentUserWithRole: IUserWithRole;
  setIsDraggable?: React.Dispatch<React.SetStateAction<boolean>>;
}

const VisualCard: React.FC<IVisualCardProps> = ({
  dashboardId,
  visualId,
  currentUserWithRole,
  setIsDraggable,
}) => {
  const { t } = useTranslation();

  const [
    isDeleteVisualFromDashboardModalOpen,
    setIsDeleteVisualFromDashboardModalOpen,
  ] = useState<boolean>(false);

  const [
    isEditVisualFromDashboardModalOpen,
    setIsEditVisualFromDashboardModalOpen,
  ] = useState<boolean>(false);

  const currentDashboard: Dashboard | null = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboardId]
          ? state.dashboardContent.dashboardRecords[dashboardId].selectedDashboard : null
  );

  const currentVisuals: Visual[] = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboardId]
          ? state.dashboardContent.dashboardRecords[dashboardId].visuals.data : visualsInitialState.data
  );

  const visualOfCard: Visual | undefined = currentVisuals?.find(
    (vis) => vis.id === visualId
  );

  const analyticDataOfCard: AnalyticData[] = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboardId]
          ? state.dashboardContent.dashboardRecords[dashboardId].visualRecords.data : visualRecordsInitialState.data
  );

  useEffect(() => {
    if (!setIsDraggable) {
      return;
    }
    if (
      isEditVisualFromDashboardModalOpen ||
      isDeleteVisualFromDashboardModalOpen
    ) {
      setIsDraggable(false);
    } else {
      setIsDraggable(true);
    }
  }, [
    isEditVisualFromDashboardModalOpen,
    isDeleteVisualFromDashboardModalOpen,
  ]);

  const VISUAL_CARD_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "editVisual",
      title: t("common.edit"),
      disabled: ![UserRole.ADMIN, UserRole.EDITOR].includes(
        currentUserWithRole.role
      ),
      onClick: () => setIsEditVisualFromDashboardModalOpen(true),
    },
    {
      key: "deleteVisual",
      title: t("common.delete"),
      disabled: ![UserRole.ADMIN, UserRole.EDITOR].includes(
        currentUserWithRole.role
      ),
      onClick: () => setIsDeleteVisualFromDashboardModalOpen(true),
    },
  ];

  return (
    <React.Fragment>
      {visualOfCard && currentDashboard && (
        <React.Fragment>
          <DeleteVisualFromDashboardModal
            visual={visualOfCard}
            dashboard={currentDashboard}
            isOpen={isDeleteVisualFromDashboardModalOpen}
            setIsOpen={setIsDeleteVisualFromDashboardModalOpen}
          />
          <EditVisualFromDashboardModal
            visual={visualOfCard}
            dashboard={currentDashboard}
            isOpen={isEditVisualFromDashboardModalOpen}
            setIsOpen={setIsEditVisualFromDashboardModalOpen}
          />
        </React.Fragment>
      )}

      <S.Card padding={[20, 20]}>
        <SectionHeader title={<H3>{visualOfCard?.name}</H3>}>
          <DropdownMenu options={VISUAL_CARD_OPTIONS} />
        </SectionHeader>

        {visualOfCard && (
          <VisualChart data={analyticDataOfCard} visual={visualOfCard} />
        )}
      </S.Card>
    </React.Fragment>
  );
};

export default VisualCard;
