import { ReloadOutlined } from "@ant-design/icons";
import {
  EditDashboardForm,
  IDropdownMenuItemOption,
  IUserWithRole,
  RequestStatus,
  UserRole,
} from "@common/index";
import {
  Dashboard,
  LayoutOption,
  User,
  UserOption,
  Visual,
} from "@common/types";
import {
  DropdownMenu,
  Loading,
  NoData,
  SectionHeader,
} from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector, useResponsive } from "@hooks/index";
import { VisualCard } from "@pages/DashboardPages/components";
import { ManageDashboardDrawer } from "@pages/DashboardPages/drawers";
import {
  AddVisualToDashboardModal,
  DeleteDashboardModal,
  EditDashboardModal,
} from "@pages/DashboardPages/modals";
import { APPLICATION_PATHS } from "@routes/index";
import {
  setCurrentDashboard,
  visualRecordsInitialState,
  visualsInitialState
} from "@store/slices/DashboardContent/slice";
import {
  editDashboard,
  fetchAllVisualRecords,
  fetchVisualsOfDashboard,
} from "@store/slices/DashboardContent/thunks";
import { reflectDashboardEdit } from "@store/slices/Dashboards/slice";
import {
  fetchOwnedDashboards,
  fetchSharedDashboards,
} from "@store/slices/Dashboards/thunks";
import { convertUserOptionArray, findDashboardShareType } from "@utils/index";
import { Button, Col, Row, Space } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { Layout as ReactGridLayoutType } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import "react-resizable/css/styles.css";
import { useParams } from "react-router-dom";
import * as S from "./DashboardDetailPage.style";
import {
  clearDefaultDashboard,
  persistDefaultDashboard,
  readDefaultDashboard,
} from "@app/services";

const DashboardDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isDesktop } = useResponsive();
  const { dashboardId } = useParams();

  const ROW_HEIGHT = 50;
  const COLUMN_COUNT = 12;
  const LAYOUT_MIN_WIDTH = 2;
  const LAYOUT_MIN_HEIGHT = 2;

  const REFRESH_OPTIONS: DefaultOptionType[] = [
    { label: t("pages.dashboards.1minuteRefresh"), value: 60, default: true },
    { label: t("pages.dashboards.5minuteRefresh"), value: 300 },
    { label: t("pages.dashboards.1hourRefresh"), value: 3600 },
    { label: t("pages.dashboards.24hourRefresh"), value: 86400 },
  ];

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const ownedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.ownedDashboards.data
  );

  const sharedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.sharedDashboards.data
  );

  const ownedDashboardsStatus: RequestStatus = useAppSelector(
    (state) => state.dashboards.ownedDashboards.status
  );

  const sharedDashboardsStatus: RequestStatus = useAppSelector(
    (state) => state.dashboards.sharedDashboards.status
  );

  const currentDashboardInStore: Dashboard | null = useAppSelector(
    (state) => state.dashboardContent.dashboardRecords[dashboardId!]
        ?
        state.dashboardContent.dashboardRecords[dashboardId!].selectedDashboard : null
  );

  const currentDashboardStatus: RequestStatus = useAppSelector(
    (state) => state.dashboardContent.dashboardRecords[dashboardId!]
        ? state.dashboardContent.dashboardRecords[dashboardId!].selectedDashboardStatus : RequestStatus.IDLE
  );

  const currentVisuals: Visual[] = useAppSelector(
    (state) => state.dashboardContent.dashboardRecords[dashboardId!]
        ? state.dashboardContent.dashboardRecords[dashboardId!].visuals.data : visualsInitialState.data
  );

  const currentVisualsStatus: RequestStatus = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboardId!]
          ? state.dashboardContent.dashboardRecords[dashboardId!].visuals.status : visualsInitialState.status
  );

  const currentVisualRecordsStatus: RequestStatus = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboardId!]
          ? state.dashboardContent.dashboardRecords[dashboardId!].visualRecords.status : visualRecordsInitialState.status
  );

  const [isDraggable, setIsDraggable] = useState<boolean>(true);

  const [selectedRefreshRate, setSelectedRefreshRate] = useState<number>(300);

  const [temporaryLayout, setTemporaryLayout] = useState<LayoutOption[]>([]);

  const [isDeleteDashboardModalOpen, setIsDeleteDashboardModalOpen] =
    useState<boolean>(false);

  const [isEditDashboardModalOpen, setIsEditDashboardModalOpen] =
    useState<boolean>(false);

  const [isManageDashboardDrawerOpen, setIsManageDashboardDrawerOpen] =
    useState<boolean>(false);

  const [isAddVisualModalOpen, setIsAddVisualModalOpen] =
    useState<boolean>(false);

  const [isDefaultDashboard, setIsDefaultDashboard] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser.id && ownedDashboardsStatus === RequestStatus.IDLE) {
      dispatch(fetchOwnedDashboards(currentUser.id));
    }
  }, [currentUser, ownedDashboardsStatus]);

  useEffect(() => {
    if (currentUser.id && sharedDashboardsStatus === RequestStatus.IDLE) {
      dispatch(fetchSharedDashboards(currentUser.id));
    }
  }, [currentUser, sharedDashboardsStatus]);

  useEffect(() => {
    if (
      currentDashboardInStore?.id &&
      currentVisualsStatus === RequestStatus.IDLE
    ) {
      dispatch(fetchVisualsOfDashboard(currentDashboardInStore.id));
    }
  }, [currentDashboardInStore, currentVisualsStatus]);

  useEffect(() => {
    if (
      currentVisuals.length > 0 &&
      currentVisualRecordsStatus === RequestStatus.IDLE
    ) {
      dispatch(
        fetchAllVisualRecords({ visuals: currentVisuals, predict: true })
      );
    }
  }, [currentVisuals, currentVisualRecordsStatus]);

  useEffect(() => {
    currentDashboardInStore &&
      setTemporaryLayout(currentDashboardInStore.layouts);
  }, [currentDashboardInStore?.layouts]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      dispatch(
        fetchAllVisualRecords({ visuals: currentVisuals, predict: true })
      );
    }, selectedRefreshRate * 1000);

    return () => clearInterval(refreshInterval);
  }, [currentVisuals, selectedRefreshRate]);

  useEffect(() => {
    const defaultDashboardId = readDefaultDashboard();
    setIsDefaultDashboard(dashboardId === defaultDashboardId);
  }, [dashboardId]);

  const getCurrentDashboardIfNotInStore = (): Dashboard | undefined => {
    let matchedDashboard = ownedDashboards.find((od) => od.id === dashboardId);

    matchedDashboard = matchedDashboard
      ? matchedDashboard
      : sharedDashboards.find((od) => od.id === dashboardId);
    if (matchedDashboard) {
      dispatch(setCurrentDashboard(matchedDashboard));
    }
    return matchedDashboard;
  };

  const getCurrentUserWithRole = (): IUserWithRole | undefined => {
    if (!currentDashboardInStore) {
      return undefined;
    }

    if ((currentDashboardInStore.user as User).id === currentUser.id) {
      return {
        user: currentUser,
        role: UserRole.ADMIN,
      };
    }

    const correspondingUserOption: UserOption | undefined =
      currentDashboardInStore.users.find(
        (userOpt) => (userOpt.user as User).id === currentUser.id
      );

    return correspondingUserOption
      ? {
          user: correspondingUserOption!.user as User,
          role: correspondingUserOption?.editable
            ? UserRole.EDITOR
            : UserRole.BASIC,
        }
      : undefined;
  };

  const currentUserWithRole: IUserWithRole | undefined =
    getCurrentUserWithRole();

  const currentDashboard: Dashboard | undefined = currentDashboardInStore
    ? currentDashboardInStore
    : getCurrentDashboardIfNotInStore();

  const onRefreshRateChangeHandler = (refreshInterval: unknown) => {
    if (typeof refreshInterval === "number") {
      setSelectedRefreshRate(refreshInterval);
    }
  };

  const onForceRefreshHandler = () => {
    dispatch(fetchAllVisualRecords({ visuals: currentVisuals, predict: true }));
  };

  const onLayoutChangeHandler = (e: ReactGridLayoutType[]) => {
    if (
      [RequestStatus.IDLE, RequestStatus.PENDING].includes(
        currentDashboardStatus
      )
    ) {
      return;
    }

    setTemporaryLayout(e);
  };

  const onSaveLayoutHandler = () => {
    if (!currentDashboard) {
      return;
    }

    const form: EditDashboardForm = {
      dashboardId: currentDashboard.id,
      name: currentDashboard.name,
      description: currentDashboard.description,
      datasetId: currentDashboard.dataset.id,
      userId: (currentDashboard.user as User).id,
      users: convertUserOptionArray(currentDashboard.users),
      layouts: temporaryLayout,
      tenantId: currentDashboard.tenant,
    };

    dispatch(editDashboard(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDashboardEdit({
            dashboard: res,
            dashboardType: findDashboardShareType(res, currentUser.id),
          })
        );

        NotificationController.success({
          message: t("notifications.success.saveLayout"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.saveLayout"),
        });
      });
  };

  const positionCreator = (visualId: string): LayoutOption => {
    const firstColumnItems: LayoutOption[] = temporaryLayout.filter(
      (layoutOpt) => layoutOpt.x === 0
    );

    const newYPosition = firstColumnItems.reduce(
      (accumulator, currentValue) => accumulator + currentValue.h,
      0
    );

    const newPosition: LayoutOption = {
      i: visualId,
      x: 0,
      y: newYPosition,
      w: 4,
      h: 4,
      minW: LAYOUT_MIN_WIDTH,
      minH: LAYOUT_MIN_HEIGHT,
    };

    return newPosition;
  };

  const renderConstantLayout = () => {
    return (
      <Row gutter={[15, 15]}>
        {temporaryLayout.map((item) => (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            {currentUserWithRole && currentDashboard && (
              <VisualCard
                dashboardId={currentDashboard?.id}
                visualId={item.i}
                currentUserWithRole={currentUserWithRole}
              />
            )}
          </Col>
        ))}
      </Row>
    );
  };

  const renderEditableLayout = () => {
    return (
      <S.ResponsiveGridLayoutContainer>
        <S.ResponsiveGridLayout
          isDraggable={isDraggable}
          cols={{
            lg: COLUMN_COUNT,
            md: COLUMN_COUNT,
            sm: COLUMN_COUNT,
            xs: COLUMN_COUNT,
            xxs: COLUMN_COUNT,
          }}
          onLayoutChange={onLayoutChangeHandler}
          rowHeight={ROW_HEIGHT}>
          {temporaryLayout.map((item) => (
            <div key={item.i} data-grid={item}>
              {currentUserWithRole && currentDashboard && (
                <VisualCard
                  dashboardId={currentDashboard.id}
                  visualId={item.i}
                  currentUserWithRole={currentUserWithRole}
                  setIsDraggable={setIsDraggable}
                />
              )}
            </div>
          ))}
        </S.ResponsiveGridLayout>
      </S.ResponsiveGridLayoutContainer>
    );
  };

  const renderDashboard = () => {
    const isDashboardLoading: boolean =
      currentVisualsStatus === RequestStatus.PENDING;

    if (isDashboardLoading) {
      return (
        <S.LoadingAndNoDataContainer>
          <Loading size="5rem" />
        </S.LoadingAndNoDataContainer>
      );
    } else if (currentVisuals.length === 0) {
      return (
        <S.LoadingAndNoDataContainer>
          <NoData />
        </S.LoadingAndNoDataContainer>
      );
    } else if (isDesktop) {
      return renderEditableLayout();
    } else {
      return renderConstantLayout();
    }
  };

  const renderRefreshTools = () => {
    return (
      <React.Fragment>
        <Space>
          <S.CustomSelect
            onChange={onRefreshRateChangeHandler}
            defaultValue={300}>
            {REFRESH_OPTIONS.map((opt) => (
              <S.CustomSelect.Option key={opt.value} value={opt.value}>
                {opt.label}
              </S.CustomSelect.Option>
            ))}
          </S.CustomSelect>

          <Button
            type="text"
            size="small"
            icon={<ReloadOutlined />}
            onClick={onForceRefreshHandler}
          />
        </Space>
      </React.Fragment>
    );
  };

  const renderVisualLoadingStatus = () => {
    if (
      currentVisualsStatus === RequestStatus.PENDING ||
      currentVisualRecordsStatus === RequestStatus.PENDING
    ) {
      return (
        <S.LoadingContainer>
          <Loading size="1.75rem" />
        </S.LoadingContainer>
      );
    }

    return null;
  };

  const DASHBOARD_DETAIL_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "addVisual",
      title: t("pages.dashboards.addVisual"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => setIsAddVisualModalOpen(true),
    },
    {
      key: "saveLayout",
      title: t("pages.dashboards.saveLayout"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => onSaveLayoutHandler(),
    },
    {
      key: "editDashboard",
      title: t("common.edit"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => setIsEditDashboardModalOpen(true),
    },
    {
      key: "manageDashboard",
      title: t("common.manage"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => setIsManageDashboardDrawerOpen(true),
    },
    {
      key: "deleteDashboard",
      title: t("common.delete"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => setIsDeleteDashboardModalOpen(true),
    },
    {
      key: "setDefault",
      title: isDefaultDashboard
        ? t("pages.dashboards.unsetDefault")
        : t("pages.dashboards.setDefault"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => {
        if (!dashboardId) {
          return;
        }
        if (dashboardId === readDefaultDashboard()) {
          clearDefaultDashboard();
          setIsDefaultDashboard(false);
        } else {
          persistDefaultDashboard(dashboardId);
          setIsDefaultDashboard(true);
        }
      },
    },
  ];

  return (
    <React.Fragment>
      {currentDashboard && (
        <React.Fragment>
          <AddVisualToDashboardModal
            positionCreator={positionCreator}
            dashboard={currentDashboard}
            isOpen={isAddVisualModalOpen}
            setIsOpen={setIsAddVisualModalOpen}
          />
          <DeleteDashboardModal
            dashboard={currentDashboard}
            isOpen={isDeleteDashboardModalOpen}
            setIsOpen={setIsDeleteDashboardModalOpen}
          />
          <EditDashboardModal
            dashboard={currentDashboard}
            isOpen={isEditDashboardModalOpen}
            setIsOpen={setIsEditDashboardModalOpen}
          />
          <ManageDashboardDrawer
            dashboard={currentDashboard}
            isOpen={isManageDashboardDrawerOpen}
            setIsOpen={setIsManageDashboardDrawerOpen}
          />
        </React.Fragment>
      )}

      <SectionHeader
        customMargin="0 0 3rem 0"
        title={
          <S.SectionHeaderTitle>{currentDashboard?.name}</S.SectionHeaderTitle>
        }
        subtitle={
          <S.SectionHeaderSubtitle>
            {currentDashboard?.description}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={APPLICATION_PATHS.DASHBOARD_PATH}
        backButtonVisible>
        <Space>
          {renderVisualLoadingStatus()}
          {renderRefreshTools()}
          <DropdownMenu options={DASHBOARD_DETAIL_OPTIONS} />
        </Space>
      </SectionHeader>
      {renderDashboard()}
    </React.Fragment>
  );
};

export default DashboardDetailPage;
