import { axios } from "@api/index";

import {
  AddUserToOrganizationForm,
  ChangeLicenseOfOrganizationForm,
  DeleteMultipleUsersFromOrganizationForm,
  DeleteUserFromOrganizationForm,
  EditOrganizationForm,
  EditUserFromOrganizationForm,
  Organization,
  OrganizationLicenseSpecification,
  User,
} from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertUserOptionArray } from "@utils/index";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const ORGANIZATION_ENDPOINT = `${BASE_PATH}/tenant`;
const LICENSE_ENDPOINT = `${BASE_PATH}/license`;

export const deleteOrganization = createAsyncThunk(
  "organizationContent/deleteOrganization",
  async (organizationId: string) => {
    const response = await axios.delete(
      `${ORGANIZATION_ENDPOINT}/${organizationId}`
    );
    return response.data;
  }
);

export const editOrganization = createAsyncThunk(
  "organizationContent/editOrganizaiton",
  async (data: EditOrganizationForm) => {
    const response = await axios.put<Organization>(
      `${ORGANIZATION_ENDPOINT}/${data.id}`,
      {
        name: data.name,
        uid: data.uid,
        users: data.users,
        disabled: data.disabled
      }
    );
    return response.data;
  }
);

export const fetchLicenseOfOrganization = createAsyncThunk(
  "organizations/fetchLicenseOfOrganization",
  async (tenantId: string) => {
    const response = await axios.get<OrganizationLicenseSpecification[]>(
      `${LICENSE_ENDPOINT}/${tenantId}`
    );

    return response.data[0];
  }
);

export const changeLicenseOfOrganization = createAsyncThunk(
  "organizations/changeLicenseOfOrganization",
  async (data: ChangeLicenseOfOrganizationForm) => {
    const response = await axios.put<OrganizationLicenseSpecification>(
      `${LICENSE_ENDPOINT}/${data.tenantId}`,
      {
        licenseType: data.licenseType,
        duration: data.duration,
      }
    );

    return response.data;
  }
);

export const addUserToOrganization = createAsyncThunk(
  "organizationContent/addUserToOrganization",
  async (data: AddUserToOrganizationForm) => {
    const addedUser = {
      uid: (data.user as User).id,
      editable: data.editable,
    };

    const editedUsers = convertUserOptionArray(data.organization.users);

    const editedOrganization = {
      ...data.organization,
      uid: (data.organization.user as User).id,
      users: [...editedUsers, addedUser],
    };

    const response = await axios.put(
      `${ORGANIZATION_ENDPOINT}/${data.organization.id}`,
      editedOrganization
    );
    return response.data;
  }
);

export const editUserFromOrganization = createAsyncThunk(
  "organizationContent/editUserFromOrganization",
  async (data: EditUserFromOrganizationForm) => {
    const editedUser = {
      uid: (data.user as User).id,
      editable: data.editable,
    };

    let editedUsers = convertUserOptionArray(data.organization.users);

    editedUsers = editedUsers.map((userOption) => {
      if (userOption.uid === editedUser.uid) {
        return editedUser;
      }
      return userOption;
    });

    const editedOrganization = {
      ...data.organization,
      uid: (data.organization.user as User).id,
      users: editedUsers,
    };

    const response = await axios.put(
      `${ORGANIZATION_ENDPOINT}/${data.organization.id}`,
      editedOrganization
    );

    return response.data;
  }
);

export const deleteUserFromOrganization = createAsyncThunk(
  "organizationContent/deleteUserFromOrganization",
  async (data: DeleteUserFromOrganizationForm) => {
    const editedUsers = convertUserOptionArray(data.organization.users);

    const editedOrganization = {
      ...data.organization,
      uid: (data.organization.user as User).id,
      users: editedUsers.filter((user) => user.uid !== data.user),
    };

    const response = await axios.put(
      `${ORGANIZATION_ENDPOINT}/${data.organization.id}`,
      editedOrganization
    );

    return response.data;
  }
);

export const deleteMultipleUsersFromOrganization = createAsyncThunk(
  "organizationContent/deleteUsersFromOrganization",
  async (data: DeleteMultipleUsersFromOrganizationForm) => {
    const editedUsers = convertUserOptionArray(data.organization.users);

    const editedOrganization = {
      uid: (data.organization.user as User).id,
      ...data.organization,
      users: editedUsers.filter((user) => !data.userIds.includes(user.uid)),
    };

    const response = await axios.put(
      `${ORGANIZATION_ENDPOINT}/${data.organization.id}`,
      editedOrganization
    );

    return response.data;
  }
);
