import { CountryDetail, LicenseType, Obligation, ObligationItem } from "@common/index";
import { H3, P2 } from "@components/common";
import { useAppSelector } from "@hooks/index";
import { Dates } from "@services/index";
import * as CommonStyles from "@styles/CommonStyles";
import { getCurrencySymbol, getLicenseTitle } from "@utils/index";
import { Col, Row, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./LicenseUsageDetailCard.style";

interface ILicenseUsageDetailCardProps {
  obligation: Obligation;
}

const LicenseUsageDetailCard: React.FC<ILicenseUsageDetailCardProps> = ({
  obligation,
}) => {
  const { t } = useTranslation();
  const userAgent: CountryDetail | null = useAppSelector(
    (state) => state.userAgent.local.data
  );

  const renderObligationItemsDetails = (obligationItems: ObligationItem[]) => {
    return (
      <CommonStyles.VerticalSpacer>
        <Row align="middle">
          <Col span={8}>
            <H3>{t('pages.organizations.licenseType')}</H3>
          </Col>
          <Col span={8}>
            <H3>{t('pages.organizations.daysUsed')}</H3>
          </Col>
          <Col span={8}>
            <H3>{t('pages.organizations.paymentAmount')}</H3>
          </Col>
        </Row>

        {obligationItems.map((obgIt) => {
          return (
            <Row align="middle">
              <Col span={8}>
                <P2>{getLicenseTitle(obgIt.type as LicenseType)}</P2>
              </Col>
              <Col span={8}>
                <Tooltip
                  placement="topLeft"
                  overlayStyle={{ whiteSpace: "pre-line" }}
                  title={`${t("common.startDate")}: ${Dates.convertStringToDate(
                    obgIt.startTime
                  ).format("LL")}
                  ${t("common.endDate")}: ${Dates.convertStringToDate(
                    obgIt.endTime
                  ).format("LL")}`}
                >
                  <P2>{obgIt.daysUsed}</P2>
                </Tooltip>
              </Col>
              <Col span={8}>
                <P2>
                  {getCurrencySymbol(userAgent)} {obgIt.fee.tr.toFixed(2)}
                </P2>
              </Col>
            </Row>
          );
        })}
      </CommonStyles.VerticalSpacer>
    );
  };

  return (
    <S.CardWrapper>
      <S.Card padding={[15, 15]}>
        {renderObligationItemsDetails(obligation.items)}
      </S.Card>
    </S.CardWrapper>
  );
};

export default LicenseUsageDetailCard;
