import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RequestStatus, ShareType } from "@common/enums";
import { ApiResponseState, Dashboard } from "@common/types";
import {
  addDashboard,
  fetchOwnedDashboards,
  fetchSharedDashboards,
} from "./thunks";

const ownedDashboardsInitialState: ApiResponseState<Dashboard[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const sharedDashboardsInitialState: ApiResponseState<Dashboard[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const initialState = {
  defaultDashboardLoaded: false,
  ownedDashboards: ownedDashboardsInitialState,
  sharedDashboards: sharedDashboardsInitialState,
};

export const dashboardSlice = createSlice({
  name: "dashboards",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    reflectDashboardDelete: (state, action) => {
      const { dashboard, dashboardType } = action.payload;
      const deletedDashboardId = dashboard.id;

      switch (dashboardType) {
        case ShareType.SHARED:
          state.sharedDashboards.data = state.sharedDashboards.data.filter(
            (sharedDashboard) => sharedDashboard.id !== deletedDashboardId
          );
          break;
        case ShareType.OWNED:
          state.ownedDashboards.data = state.ownedDashboards.data.filter(
            (ownedDashboard) => ownedDashboard.id !== deletedDashboardId
          );
          break;
        default:
          break;
      }
    },
    reflectDashboardEdit: (state, action) => {
      const { dashboard, dashboardType } = action.payload;
      const dashboardIndex = getDashboardIndex(state, dashboardType, dashboard);

      switch (dashboardType) {
        case ShareType.SHARED:
          if (dashboardIndex >= 0) {
            state.sharedDashboards.data[dashboardIndex] = dashboard;
          }
          break;
        case ShareType.OWNED:
          if (dashboardIndex >= 0) {
            state.ownedDashboards.data[dashboardIndex] = dashboard;
          }
          break;
        default:
          break;
      }
    },
    setDefaultDashboardLoaded: (state, action: PayloadAction<boolean>) => {
      state.defaultDashboardLoaded = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwnedDashboards.pending, (state) => {
        state.ownedDashboards.status = RequestStatus.PENDING;
      })
      .addCase(fetchOwnedDashboards.fulfilled, (state, action) => {
        state.ownedDashboards.status = RequestStatus.FULFILLED;
        state.ownedDashboards.data = action.payload;
      })
      .addCase(fetchOwnedDashboards.rejected, (state) => {
        state.ownedDashboards.status = RequestStatus.REJECTED;
      })
      .addCase(fetchSharedDashboards.pending, (state) => {
        state.sharedDashboards.status = RequestStatus.PENDING;
      })
      .addCase(fetchSharedDashboards.fulfilled, (state, action) => {
        state.sharedDashboards.status = RequestStatus.FULFILLED;
        state.sharedDashboards.data = action.payload;
      })
      .addCase(fetchSharedDashboards.rejected, (state) => {
        state.sharedDashboards.status = RequestStatus.REJECTED;
      })
      .addCase(addDashboard.pending, (state) => {
        state.ownedDashboards.status = RequestStatus.PENDING;
      })
      .addCase(addDashboard.fulfilled, (state, action) => {
        state.ownedDashboards.status = RequestStatus.FULFILLED;
        state.ownedDashboards.data.push(action.payload);
      })
      .addCase(addDashboard.rejected, (state) => {
        state.ownedDashboards.status = RequestStatus.REJECTED;
      });
  },
});

const getDashboardIndex = (
  state: typeof initialState,
  dashboardType: ShareType,
  dashboard: Dashboard
) => {
  let dashboardIndex = -1;

  switch (dashboardType) {
    case ShareType.SHARED:
      dashboardIndex = state.sharedDashboards.data.findIndex(
        (sharedDashboard) => sharedDashboard.id === dashboard.id
      );
      break;
    case ShareType.OWNED:
      dashboardIndex = state.ownedDashboards.data.findIndex(
        (ownedDashboard) => ownedDashboard.id === dashboard.id
      );
      break;
    default:
      break;
  }

  return dashboardIndex;
};

export const {
  reset: resetDashboards,
  reflectDashboardDelete,
  reflectDashboardEdit,
  setDefaultDashboardLoaded,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
