import React from "react";
import { Col, Row } from "antd";
import * as S from "./CollapsedHeader.style";
import { ProfileDropdown } from "../components/Profile";
import { SettingsDropdown } from "../components/Settings";

interface ICollapsedHeaderProps {
  toggleSider: () => void;
  isSiderCollapsed: boolean;
}

const CollapsedHeader: React.FC<ICollapsedHeaderProps> = ({
  isSiderCollapsed,
  toggleSider,
}) => {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <ProfileDropdown />
      </Col>

      <Col>
        <Row align="middle">
          <Col>
            <SettingsDropdown />
          </Col>
        </Row>
      </Col>

      <S.BurgerCol>
        <S.MobileBurger
          onClick={() => toggleSider()}
          isCross={!isSiderCollapsed}
        />
      </S.BurgerCol>
    </Row>
  );
};

export default CollapsedHeader;
