import { H3 } from "@components/common";
import { Space } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0rem 1.5rem;
  margin-bottom: 1rem;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomSpacer = styled(Space)`
  width: 100% !important;
  display: flex;
`;

export const Title = styled(H3)`
  &.ant-typography {
    color: var(--text-light-color);
  }
`;
