import { useResponsive } from "@hooks/index";
import React from "react";
import * as S from "./MainSider.style";
import { SiderHeader, SiderMenu } from "./components";

interface IMainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<IMainSiderProps> = ({
  isCollapsed,
  setCollapsed,
  ...props
}) => {
  const { mobileOnly } = useResponsive();
  const toggleSider = () => setCollapsed(!isCollapsed);

  return (
    <S.Sider
      trigger={null}
      collapsed={isCollapsed}
      collapsedWidth={!mobileOnly ? 80 : 0}
      collapsible={true}
      width={260}
      {...props}
    >
      <SiderHeader isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
      <S.SiderContent>
        <SiderMenu isCollapsed={isCollapsed} toggleSider={toggleSider} />
      </S.SiderContent>
    </S.Sider>
  );
};

export default MainSider;
