import styled from "styled-components";
import { Button, Checkbox, Input, Row } from "antd";
import { BaseForm } from "@components/common";
import { FONT_SIZE, FONT_WEIGHT, media } from "@common/constants";

export const LogoContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const AuthWrapper = styled.div`
  min-width: 280px;
`;

export const ActionsWrapper = styled.div`
  margin-top: 1rem;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  margin: 1.25rem 0;
`;

export const FormCheckbox = styled(Checkbox)`
  display: flex;
  padding-left: 0.125rem;
  margin-right: 0.5rem;

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  }
`;
export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;

  & .ant-form-item-control-input {
    min-height: 3.125rem;
  }

  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    color: var(--primary-color);
    font-size: ${FONT_SIZE.xs};
    line-height: 1.25rem;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }

  @media only screen and ${media.md} {
    width: 28rem;
  }
`;

export const FormInputPassword = styled(Input.Password)`
  color: var(--text-main-color);
  background: transparent;

  & input.ant-input {
    background: transparent;
  }
`;

export const FooterWrapper = styled.div`
  text-align: center;
`;

export const TextWrapper = styled.div`
  max-width: 200px;

  @media only screen and ${media.md} {
    max-width: 100% !important;
  }
`;

export const Text = styled.span`
  color: var(--text-main-color);
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.regular};
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
  color: var(--primary-color);
`;
