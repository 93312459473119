import { SearchOutlined } from "@ant-design/icons";
import {
  AITemplateForm,
  AddDatasetFromAITemplateForm, DatasetDefinitionItem, IModalBaseProps, RequestStatus
} from "@common/index";
import {
  Kpi,
  Metric,
  Organization,
  Templateset,
  User,
} from "@common/types";
import { BaseForm, Loading, Modal, P1, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { fetchLanguage } from "@services/index";
import { addDatasetFromAITemplate } from "@store/slices/Datasets/thunks";
import { fetchAITemplateset } from "@store/slices/Templatesets/thunks";
import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./AddDatasetWithAIModal.style";

const AddDatasetWithAIModal: React.FC<IModalBaseProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [AITemplateStatus, setAITemplateStatus] = useState<RequestStatus>(
    RequestStatus.IDLE
  );
  const [AITemplate, setAITemplate] = useState<Templateset | undefined>();

  const currentUser: User = useAppSelector((state) => state.userContent.data);
  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const renderLoading = () => {
    return (
      <S.LoadingContainer>
        <Loading size="2rem" />
      </S.LoadingContainer>
    );
  };

  const onOkHandler = () => {
    const form: AddDatasetFromAITemplateForm = {
      templateset: AITemplate!,
      userId: currentUser.id,
      tenantId: currentOrganization.id,
    };
    dispatch(addDatasetFromAITemplate(form))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.addDataset", {
            datasetName: form.templateset.name,
          }),
        });
        navigate(APPLICATION_PATHS.DATASET_CREATE_PATH);
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addDataset", {
            datasetName: form.templateset.name,
          }),
        });
      });
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSearchClickHandler = (values: AITemplateForm) => {
    const currentLanguage = fetchLanguage();

    const data: AITemplateForm = {
      prompt: values.prompt,
      language: currentLanguage ? currentLanguage : "en",
    };
    setAITemplateStatus(RequestStatus.PENDING);
    dispatch(fetchAITemplateset(data))
      .unwrap()
      .then((res) => {
        setAITemplate(res);
        setAITemplateStatus(RequestStatus.FULFILLED);
      })
      .catch((err) => {
        console.error(err);
        setAITemplateStatus(RequestStatus.REJECTED);
      });
  };

  const renderDefinition = (
    items: Metric[] | Kpi[] | undefined,
    type: DatasetDefinitionItem
  ) => {
    let title: string;
    switch (type) {
      case DatasetDefinitionItem.METRIC:
        title = t("common.metrics");
        break;
      case DatasetDefinitionItem.KPI:
        title = t("common.kpis");
        break;
    }

    return (
      <S.Container>
        <Text>{title}</Text>
        <S.Container>
          <ul>
            {items?.map((item) => (
              <li style={{ marginLeft: "14px" }}>
                <P1>{item.name}</P1>
              </li>
            ))}
          </ul>
        </S.Container>
      </S.Container>
    );
  };

  return (
    <Modal
      title={t("modals.addDatasetWithAIModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSearchClickHandler}
        form={form}
        layout="vertical"
        name="addDatasetForm"
      >
        <Row gutter={[12, 12]}>
          <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
            <BaseForm.Item name="prompt">
              <Input
                placeholder={t(
                  "modals.addDatasetWithAIModal.searchPlaceholder"
                )}
              />
            </BaseForm.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
            <BaseForm.Item>
              <Button
                block
                loading={RequestStatus.PENDING === AITemplateStatus}
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              />
            </BaseForm.Item>
          </Col>
        </Row>
        {RequestStatus.IDLE !== AITemplateStatus && (
          <React.Fragment>
            <Text>{t("pages.datasets.createFromAITemplate")}</Text>
            <Row wrap>
              <Col span={12}>
                {RequestStatus.PENDING === AITemplateStatus
                  ? renderLoading()
                  : renderDefinition(
                      AITemplate?.metrics,
                      DatasetDefinitionItem.METRIC
                    )}
              </Col>
              <Col span={12}>
                {RequestStatus.PENDING === AITemplateStatus
                  ? renderLoading()
                  : renderDefinition(
                      AITemplate?.kpis,
                      DatasetDefinitionItem.KPI
                    )}
              </Col>
            </Row>
          </React.Fragment>
        )}
      </BaseForm>
    </Modal>
  );
};

export default AddDatasetWithAIModal;
