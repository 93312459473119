import { CrownOutlined, HighlightOutlined } from "@ant-design/icons";
import { UserRole } from "@common/enums";
import { IDropdownMenuItemOption, IUserWithRole } from "@common/interfaces";
import { Dataset } from "@common/types";
import { DropdownMenu } from "@components/common";
import { useAppSelector } from "@hooks/index";
import {
  DeleteUserFromDatasetModal,
  EditUserFromDatasetModal,
} from "@pages/DatasetPages/modals";
import { Avatar, Col, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DatasetUserCard.style";

interface IDatasetUserCardProps {
  userWithRole: IUserWithRole;
}

const DatasetUserCard: React.FC<IDatasetUserCardProps> = ({ userWithRole }) => {
  const { t } = useTranslation();

  const [
    isDeleteUserFromDatasetModalOpen,
    setIsDeleteUserFromDatasetModalOpen,
  ] = useState<boolean>(false);

  const [isEditUserFromDatasetModalOpen, setIsEditUserFromDatasetModalOpen] =
    useState<boolean>(false);

  const currentDataset: Dataset | null = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  const DATASET_USER_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "edit",
      title: t("common.edit"),
      disabled: false,
      onClick: () => setIsEditUserFromDatasetModalOpen(true),
    },
    {
      key: "delete",
      title: t("common.delete"),
      disabled: false,
      onClick: () => setIsDeleteUserFromDatasetModalOpen(true),
    },
  ];

  const getRoleIcon = () => {
    if (userWithRole.role === UserRole.ADMIN) {
      return <CrownOutlined />;
    } else if (userWithRole.role === UserRole.EDITOR) {
      return <HighlightOutlined />;
    }
  };

  return (
    <React.Fragment>
      {currentDataset && (
        <React.Fragment>
          <EditUserFromDatasetModal
            userWithRole={userWithRole}
            dataset={currentDataset}
            isOpen={isEditUserFromDatasetModalOpen}
            setIsOpen={setIsEditUserFromDatasetModalOpen}
          />

          <DeleteUserFromDatasetModal
            user={userWithRole.user}
            dataset={currentDataset}
            isOpen={isDeleteUserFromDatasetModalOpen}
            setIsOpen={setIsDeleteUserFromDatasetModalOpen}
          />
        </React.Fragment>
      )}

      <S.Card padding={[15, 20]}>
        <Row align="middle">
          <S.AvatarWrapper>
            <Avatar />
          </S.AvatarWrapper>
          <Col flex="auto">
            <S.HeaderRow>
              <span>
                {userWithRole.user.name} {getRoleIcon()}
              </span>
              {userWithRole.role !== UserRole.ADMIN && (
                <DropdownMenu options={DATASET_USER_OPTIONS} />
              )}
            </S.HeaderRow>
            <S.BodyText>{userWithRole.user.email}</S.BodyText>
          </Col>
        </Row>
      </S.Card>
    </React.Fragment>
  );
};

export default DatasetUserCard;
