import { BORDER_RADIUS } from "@common/constants";
import { Button, Upload } from "antd";
import styled from "styled-components";

export const CustomUpload = styled(Upload)`
  .ant-upload {
    margin: 0;
  }

  &.ant-upload-picture-card-wrapper {
    width: fit-content;
  }
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
  cursor: pointer;
`;

export const ProfileImageContainer = styled.div`
  margin: 1rem 1rem 0 1rem;
  position: relative;
`;

export const EditButton = styled(Button).attrs({
    type: "primary",
    size: "small",
    shape: "circle",
})`
  position: absolute;
  right: 5px;
  bottom: 0;
`;

