import { Dataset, License } from "@common/index";
import { BaseForm, Card, H1 } from "@components/common";
import { Checkbox, Form, List } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DowngradeDatasetDeleteCard.style";

interface IDowngradeDatasetDeleteCardProps {
  title: string;
  datasetList: Dataset[];
  selectedLicense: License;
  datasetsToDelete: Dataset[];
  onDatasetsToDeleteChange: (datasetsToDelete: Dataset[]) => void;
}

const DowngradeDatasetDeleteCard: React.FC<
  IDowngradeDatasetDeleteCardProps
> = ({
  title,
  datasetList,
  selectedLicense,
  datasetsToDelete,
  onDatasetsToDeleteChange,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const isErrorText =
    datasetList.length - datasetsToDelete.length > selectedLicense.datasetCount;

  const handleCheckboxChange = (dataset: Dataset) => {
    const index = datasetsToDelete.findIndex(
      (selectedDataset) => selectedDataset.id === dataset.id
    );

    if (index === -1) {
      onDatasetsToDeleteChange([...datasetsToDelete, dataset]);
      setSelectedRows([...selectedRows, dataset.id]);
    } else {
      onDatasetsToDeleteChange(
        datasetsToDelete.filter(
          (selectedDatasets) => selectedDatasets.id !== dataset.id
        )
      );
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== dataset.id)
      );
    }
  };

  const renderItem = (dataset: Dataset) => {
    return (
      <S.UserListItem>
        <S.CheckboxWrapper>
          <Checkbox
            checked={
              datasetsToDelete.findIndex(
                (selectedDataset) => selectedDataset.id === dataset.id
              ) !== -1
            }
            onChange={() => handleCheckboxChange(dataset)}
          />
        </S.CheckboxWrapper>
        <List.Item.Meta
          title={
            <S.TitleWrapper>
              <S.StyledTitle>{dataset.name}</S.StyledTitle>
              <S.Description>{dataset.description}</S.Description>
            </S.TitleWrapper>
          }
        />
      </S.UserListItem>
    );
  };

  return (
    <Card padding={[30, 30]}>
      <S.HeaderRow>
        <H1>{title}</H1>
        <S.CustomText isErrorText={isErrorText}>
          {`${datasetList.length - datasetsToDelete.length} / ${
            selectedLicense.datasetCount
          }`}
        </S.CustomText>
      </S.HeaderRow>

      <S.InfoText style={{ display: "block" }}>
        {selectedRows.length}
        {t("pages.downgradeLicense.datasetsSelected")}
      </S.InfoText>

      <BaseForm form={form}>
        <List dataSource={datasetList} renderItem={renderItem} />
      </BaseForm>
    </Card>
  );
};

export default DowngradeDatasetDeleteCard;
