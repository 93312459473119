import { CrownOutlined, HighlightOutlined } from "@ant-design/icons";
import { EditUserFromDashboardModal } from "@pages/DashboardPages/modals/EditUserFromDashboardModal";
import { Dashboard, IDropdownMenuItemOption, IUserWithRole, UserRole } from "@common/index";
import { DropdownMenu } from "@components/common";
import { useAppSelector } from "@hooks/index";
import { DeleteUserFromDashboardModal } from "@pages/DashboardPages/modals";
import { Avatar, Col, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DashboardUserCard.style";

interface IDashboardUserCardProps {
  userWithRole: IUserWithRole;
  dashboardId: string;
}

const DashboardUserCard: React.FC<IDashboardUserCardProps> = ({
  userWithRole,
  dashboardId,
}) => {
  const { t } = useTranslation();

  const [
    isDeleteUserFromDashboardModalOpen,
    setIsDeleteUserFromDashboardModalOpen,
  ] = useState<boolean>(false);

  const [
    isEditUserFromDashboardModalOpen,
    setIsEditUserFromDashboardModalOpen,
  ] = useState<boolean>(false);

  const currentDashboard: Dashboard | null = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboardId]
          ? state.dashboardContent.dashboardRecords[dashboardId].selectedDashboard : null
  );

  const DASHBOARD_USER_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "editDashboardUser",
      title: t("common.edit"),
      disabled: false,
      onClick: () => setIsEditUserFromDashboardModalOpen(true),
    },
    {
      key: "deleteDashboardUser",
      title: t("common.delete"),
      disabled: false,
      onClick: () => setIsDeleteUserFromDashboardModalOpen(true),
    },
  ];

  const getRoleIcon = () => {
    if (userWithRole.role === UserRole.ADMIN) {
      return <CrownOutlined />;
    } else if (userWithRole.role === UserRole.EDITOR) {
      return <HighlightOutlined />;
    }
  };

  return (
    <React.Fragment>
      {currentDashboard && (
        <React.Fragment>
          <DeleteUserFromDashboardModal
            user={userWithRole.user}
            dashboard={currentDashboard}
            isOpen={isDeleteUserFromDashboardModalOpen}
            setIsOpen={setIsDeleteUserFromDashboardModalOpen}
          />

          <EditUserFromDashboardModal
            dashboard={currentDashboard}
            userWithRole={userWithRole}
            isOpen={isEditUserFromDashboardModalOpen}
            setIsOpen={setIsEditUserFromDashboardModalOpen}
          />
        </React.Fragment>
      )}

      <S.Card padding={[15, 20]}>
        <Row align="middle">
          <S.AvatarWrapper>
            <Avatar />
          </S.AvatarWrapper>
          <Col flex="auto">
            <S.HeaderRow>
              <span>
                {userWithRole.user.name} {getRoleIcon()}
              </span>
              {userWithRole.role !== UserRole.ADMIN && (
                <DropdownMenu options={DASHBOARD_USER_OPTIONS} />
              )}
            </S.HeaderRow>
            <S.BodyText>{userWithRole.user.email}</S.BodyText>
          </Col>
        </Row>
      </S.Card>
    </React.Fragment>
  );
};

export default DashboardUserCard;
