import { Dashboard, EditUserFromDashboardForm, IModalBaseProps, IUserWithRole, User, UserRole } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editUserFromDashboard } from "@store/slices/DashboardContent/thunks";
import { reflectDashboardEdit } from "@store/slices/Dashboards/slice";
import { findDashboardShareType } from "@utils/index";
import { Input, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IEditUserFromDashboardModalProps extends IModalBaseProps {
  userWithRole: IUserWithRole;
  dashboard: Dashboard;
}

const EditUserFromDashboardModal: React.FC<
  IEditUserFromDashboardModalProps
> = ({ userWithRole, dashboard, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  useEffect(() => {
    const formValues = {
      user: userWithRole.user.name,
      editable: [UserRole.ADMIN, UserRole.EDITOR].includes(userWithRole.role),
    };

    form?.setFieldsValue(formValues);
  }, [userWithRole]);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: EditUserFromDashboardForm) => {
    const form: EditUserFromDashboardForm = {
      user: userWithRole.user,
      dashboard: dashboard,
      editable: formData.editable,
    };

    dispatch(editUserFromDashboard(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDashboardEdit({
            dashboard: res,
            dashboardType: findDashboardShareType(res, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.editUserFromDashboard", {
            userName: userWithRole.user.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editUserFromDashboard", {
            userName: userWithRole.user.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.editUserFromDashboardModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <BaseForm
        form={form}
        onFinish={onSubmitHandler}
        layout="vertical"
        name="editUserFromDatasetForm"
      >
        <BaseForm.Item name="user" label={t("common.name")}>
          <Input disabled={true} />
        </BaseForm.Item>
        <BaseForm.Item name="editable" label={t("common.editable")}>
          <Select placeholder={t("common.editable")}>
            <Select.Option value={false}>{t("common.no")}</Select.Option>
            <Select.Option value={true}>{t("common.yes")}</Select.Option>
          </Select>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditUserFromDashboardModal;
