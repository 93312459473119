import React from 'react';
import { Form, FormInstance } from 'antd';

export const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface IEditableDataTableRowProps {
  index: number;
}

const EditableDataTableRow: React.FC<IEditableDataTableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableDataTableRow;
