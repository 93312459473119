import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppLogo, Loading, TabTitle } from "@components/common";
import { SignupForm } from "../components";
import * as AuthStyles from "../AuthPages.style";

const SignupPage: React.FC = () => {
  const { t } = useTranslation();
  const [isSignupLoading, setIsSignupLoading] = useState<boolean>(false);

  return (
    <AuthStyles.AuthWrapper>
      <TabTitle title={t("pages.auth.login")} />

      {isSignupLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <AuthStyles.LogoContainer>
            <AppLogo margin="0" height="100px" width="100px" />
          </AuthStyles.LogoContainer>
          <SignupForm
            loading={isSignupLoading}
            setLoading={setIsSignupLoading}
          />
        </React.Fragment>
      )}
    </AuthStyles.AuthWrapper>
  );
};

export default SignupPage;
