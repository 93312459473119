import { RequestStatus, ShareType } from "@common/enums";
import { ApiResponseState, Organization } from "@common/types";
import { createSlice } from "@reduxjs/toolkit";
import {
  addOrganization,
  fetchOwnedOrganizationsOfUser,
  fetchSharedOrganizationsOfUser,
} from "./thunks";

const organizationsInitialState: ApiResponseState<Organization[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const sharedOrganizationsInitialState: ApiResponseState<Organization[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const initialState = {
  ownedOrganizations: organizationsInitialState,
  sharedOrganizations: sharedOrganizationsInitialState,
};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    reset: () => initialState,
    reflectOrganizationDelete(state, action) {
      const { organization, organizationType } = action.payload;
      const deletedOrganizationId = organization.id;

      switch (organizationType) {
        case ShareType.SHARED:
          state.sharedOrganizations.data =
            state.sharedOrganizations.data.filter(
              (sharedOrganization) =>
                sharedOrganization.id !== deletedOrganizationId
            );
          break;
        case ShareType.OWNED:
          state.ownedOrganizations.data = state.ownedOrganizations.data.filter(
            (ownedOrganization) =>
              ownedOrganization.id !== deletedOrganizationId
          );
          break;
        default:
          break;
      }
    },
    reflectOrganizationEdit(state, action) {
      const { organization, organizationType } = action.payload;
      const organizationIndex = getOrganizationIndex(
        state,
        organizationType,
        organization
      );

      switch (organizationType) {
        case ShareType.SHARED:
          if (organizationIndex >= 0) {
            state.sharedOrganizations.data[organizationIndex] = organization;
          }
          break;
        case ShareType.OWNED:
          if (organizationIndex >= 0) {
            state.ownedOrganizations.data[organizationIndex] = organization;
          }
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwnedOrganizationsOfUser.pending, (state) => {
        state.ownedOrganizations.status = RequestStatus.PENDING;
      })
      .addCase(fetchOwnedOrganizationsOfUser.fulfilled, (state, action) => {
        state.ownedOrganizations.status = RequestStatus.FULFILLED;
        state.ownedOrganizations.data = action.payload;
      })
      .addCase(fetchOwnedOrganizationsOfUser.rejected, (state) => {
        state.ownedOrganizations.status = RequestStatus.REJECTED;
      })
      .addCase(fetchSharedOrganizationsOfUser.pending, (state) => {
        state.sharedOrganizations.status = RequestStatus.PENDING;
      })
      .addCase(fetchSharedOrganizationsOfUser.fulfilled, (state, action) => {
        state.sharedOrganizations.status = RequestStatus.FULFILLED;
        state.sharedOrganizations.data = action.payload;
      })
      .addCase(fetchSharedOrganizationsOfUser.rejected, (state) => {
        state.sharedOrganizations.status = RequestStatus.REJECTED;
      })
      .addCase(addOrganization.pending, (state) => {
        state.ownedOrganizations.status = RequestStatus.PENDING;
      })
      .addCase(addOrganization.fulfilled, (state, action) => {
        state.ownedOrganizations.status = RequestStatus.FULFILLED;
        state.ownedOrganizations.data.push(action.payload);
      })
      .addCase(addOrganization.rejected, (state) => {
        state.ownedOrganizations.status = RequestStatus.REJECTED;
      });
  },
});

const getOrganizationIndex = (
  state: typeof initialState,
  organizationType: ShareType,
  organization: Organization
) => {
  let organizationIndex = -1;

  switch (organizationType) {
    case ShareType.SHARED:
      organizationIndex = state.sharedOrganizations.data.findIndex(
        (sharedOrganization) => sharedOrganization.id === organization.id
      );
      break;
    case ShareType.OWNED:
      organizationIndex = state.ownedOrganizations.data.findIndex(
        (ownedOrganization) => ownedOrganization.id === organization.id
      );
      break;
    default:
      break;
  }

  return organizationIndex;
};

export const {
  reset: resetOrganizations,
  reflectOrganizationDelete,
  reflectOrganizationEdit,
} = organizationsSlice.actions;
export default organizationsSlice.reducer;
