import { DatabaseOutlined } from "@ant-design/icons";
import { Dataset, Organization, RequestStatus, User } from "@common/index";
import { CarouselNavigationSection, H1, TabTitle } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { setCurrentDataset } from "@store/slices/DatasetContent/slice";
import {
  fetchOwnedDatasets,
  fetchSharedDatasets,
} from "@store/slices/Datasets/thunks";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MeasurementMainPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentUserStatus: RequestStatus = useAppSelector(
    (state) => state.userContent.status
  );

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const sharedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.sharedDatasets.data
  );

  const ownedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.ownedDatasets.status
  );

  const sharedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.sharedDatasets.status
  );

  useEffect(() => {
    currentUser.id && dispatch(fetchOwnedDatasets(currentUser.id));
  }, [currentUser.id]);

  useEffect(() => {
    currentUser.id && dispatch(fetchSharedDatasets(currentUser.id));
  }, [currentUser.id]);

  const carouselKeyExtractor = useCallback(
    (dataset: Dataset) => dataset.id,
    []
  );

  const carouselTitleExtractor = useCallback(
    (dataset: Dataset) => dataset.name,
    []
  );

  const carouselDescriptionExtractor = useCallback(
    (dataset: Dataset) => dataset.description,
    []
  );

  const filterDatasetByOrganization = (datasets: Dataset[]) => {
    return datasets.filter((d) => d.tenant === currentOrganization.id);
  };

  const onItemClick = (dataset: Dataset) => {
    dispatch(setCurrentDataset(dataset));
    navigate(`${APPLICATION_PATHS.MEASUREMENT_PATH}/${dataset.id}`);
  };

  return (
    <React.Fragment>
      <TabTitle title={t("pages.measurements.tabTitle")} />
      <H1>{t("pages.measurements.pageTitle")}</H1>

      <CarouselNavigationSection<Dataset>
        isLoading={ownedDatasetsStatus === RequestStatus.PENDING}
        items={filterDatasetByOrganization(ownedDatasets)}
        title={t("pages.measurements.yourDatasets")}
        icon={<DatabaseOutlined />}
        onItemClick={onItemClick}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
        descriptionExtractor={carouselDescriptionExtractor}
      />

      <CarouselNavigationSection<Dataset>
        isLoading={sharedDatasetsStatus === RequestStatus.PENDING}
        items={filterDatasetByOrganization(sharedDatasets)}
        title={t("common.sharedWithYou")}
        icon={<DatabaseOutlined />}
        onItemClick={onItemClick}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
        descriptionExtractor={carouselDescriptionExtractor}
      />
    </React.Fragment>
  );
};

export default MeasurementMainPage;
