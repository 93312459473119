import { DatasetDefinitionItem, DeleteDefinitionForm, IModalBaseProps, Kpi, Metric } from "@common/index";
import { Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch } from "@hooks/index";
import { deleteKpi, deleteMetric } from "@store/slices/DatasetContent/thunks";
import React from "react";
import { useTranslation } from "react-i18next";

interface IDeleteDatasetDefinitionItemModalProps extends IModalBaseProps {
  item?: Metric | Kpi;
  itemType?: DatasetDefinitionItem;
}

const DeleteDatasetDefinitionItemModal: React.FC<
  IDeleteDatasetDefinitionItemModalProps
> = ({ item, itemType, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onOkHandler = () => {
    if (item) {
      const form: DeleteDefinitionForm = {
        datasetId: (item as any).datasetId,
        elementId: item?.id,
      };

      switch (itemType) {
        case DatasetDefinitionItem.METRIC:
          dispatch(deleteMetric(form))
            .unwrap()
            .then((res) => {
              NotificationController.success({
                message: t("notifications.success.deleteMetric", {
                  metricName: item.name,
                }),
              });
            })
            .catch((err) => {
              NotificationController.success({
                message: t("notifications.error.deleteMetric", {
                  metricName: item.name,
                }),
              });
            });

          break;
        case DatasetDefinitionItem.KPI:
          dispatch(deleteKpi(form))
            .unwrap()
            .then((res) => {
              NotificationController.success({
                message: t("notifications.success.deleteKpi", {
                  kpiName: item.name,
                }),
              });
            })
            .catch((err) => {
              NotificationController.error({
                message: t("notifications.error.deleteKpi", {
                  kpiName: item.name,
                }),
              });
            });
          break;
        default:
          break;
      }
    }
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const getTitle = (): string => {
    switch (itemType) {
      case DatasetDefinitionItem.METRIC:
        return t("modals.deleteDatasetDefinitionItemModal.metricTitle", {
          itemName: item?.name,
        });
      case DatasetDefinitionItem.KPI:
        return t("modals.deleteDatasetDefinitionItemModal.kpiTitle", {
          itemName: item?.name,
        });
      default:
        return "";
    }
  };

  return (
    <Modal
      open={isOpen}
      title={getTitle()}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteDatasetDefinitionItemModal;
