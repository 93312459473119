import { BillingProfile, EditBillingProfileForm, IModalBaseProps, Organization } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editBillingProfile } from "@store/slices/Payments/thunks";
import { Input } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IEditBillingProfileModalProps extends IModalBaseProps {
  billingProfile: BillingProfile;
}

const EditBillingProfileModal: React.FC<IEditBillingProfileModalProps> = ({
  billingProfile,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  useEffect(() => {
    const formValues = {
      title: billingProfile.title,
      taxId: billingProfile.taxId,
      taxAdmin: billingProfile.taxAdmin,
      address: billingProfile.address,
    };

    form?.setFieldsValue(formValues);
  }, [billingProfile, currentOrganization.id]);

  const onOkHandler = async () => {
    await form.validateFields();
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (values: EditBillingProfileForm) => {
    values.id = billingProfile.id;
    values.tenantId = billingProfile.tenantId;

    dispatch(editBillingProfile(values))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.editBillingProfile", {
            billingProfile: values.title,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editBillingProfile", {
            billingProfile: values.title,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.editBillingProfileModal.modalTitle")}
      open={isOpen}
      onOk={onOkHandler}
      getContainer={false}
      onCancel={onCancelHandler}
    >
      <BaseForm
        name="editBillingProfileForm"
        form={form}
        layout="vertical"
        onFinish={onSubmitHandler}
      >
        <BaseForm.Item
          name="title"
          label={t("modals.editBillingProfileModal.title")}
          rules={[
            {
              required: true,
              message: t("errors.requiredField"),
            },
            {
              min: 3,
              message: t("errors.titleRequired"),
            },
          ]}
        >
          <Input placeholder={t("modals.editBillingProfileModal.title")} />
        </BaseForm.Item>

        <BaseForm.Item
          name="taxId"
          label={t("modals.editBillingProfileModal.taxId")}
          rules={[
            {
              required: true,
              message: t("errors.requiredfield"),
            },
            {
              min: 8,
              max: 12,
              message: t("errors.taxIdRequired"),
            },
          ]}
        >
          <Input placeholder={t("modals.editBillingProfileModal.taxId")} />
        </BaseForm.Item>

        <BaseForm.Item
          name="taxAdmin"
          label={t("modals.editBillingProfileModal.taxAdmin")}
        >
          <Input placeholder={t("modals.editBillingProfileModal.taxAdmin")} />
        </BaseForm.Item>
        <BaseForm.Item
          name="address"
          label={t("modals.editBillingProfileModal.address")}
          rules={[
            {
              required: true,
              message: t("errors.requiredField"),
            },
            {
              min: 3,
              message: t("errors.addressRequired"),
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder={t("modals.editBillingProfileModal.address")}
          />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditBillingProfileModal;
