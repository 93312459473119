import axios from "axios";
import {
  readBearerToken,
  clearBearerToken,
  readKeycloakToken,
  clearKeycloakToken,
} from "@services/LocalStorageService/localStorage.service";

const bearerInterceptor = (config: any) => {
  const authMethod = process.env.REACT_APP_AUTH;

  if (authMethod === "keycloak") {
    const keycloakToken = readKeycloakToken();
    if (keycloakToken) {
      config.headers.Authorization = `Bearer ${keycloakToken}`;
    }
  } else if (authMethod === "firebase") {
    const firebaseToken = readBearerToken();
    if (firebaseToken) {
      const parsedToken = firebaseToken.includes('"')
        ? JSON.parse(firebaseToken)
        : firebaseToken;
      config.headers.Authorization = `Bearer ${parsedToken}`;
    }
  }

  return  config;
};

const unauthorizedInterceptor = (data: any) => {
  if (data?.response?.status === 403) {
    const authMethod = process.env.REACT_APP_AUTH;
    if (authMethod === 'keycloak') {
      clearKeycloakToken();
    } else if (authMethod === 'firebase') {
      clearBearerToken();
    }
    return Promise.reject(data);
  }
  return Promise.resolve(data);
};

const instance = axios.create({});

instance.interceptors.request.use(bearerInterceptor);
instance.interceptors.response.use(unauthorizedInterceptor);

export default instance;

export type { AxiosRequestConfig } from "axios";
