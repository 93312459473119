import { Organization } from "@common/index";
import { Loading, withAnimation, withLoading } from "@components/common";
import {
  AuthLayout,
  MainLayout,
  PaymentBillingLayout,
  ProfileLayout,
} from "@components/layout";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector, useFirebaseAuth } from "@hooks/index";
import {
  BillingDetailsSection,
  BillingProfilesSection,
  DashboardDetailPage,
  DashboardMainPage,
  DatasetCreationPage,
  DatasetDetailPage,
  DatasetMainPage,
  DowngradeLicensePage,
  ForgotPasswordPage,
  LoginPage,
  MeasurementDetailPage,
  MeasurementDetailPagev2,
  MeasurementMainPage,
  NoOrganizationPage,
  OrganizationDetailPage,
  OrganizationDisabledPage,
  OrganizationMainPage,
  OrganizationPlanPage,
  PaymentAreaSection,
  PaymentMethodsSection,
  PaymentsSection,
  PersonalInfoSection,
  SecuritySettingsSection,
  SignupPage,
} from "@pages/index";
import { APPLICATION_PATHS } from "@routes/index";
import {
  persistBearerToken,
  readDefaultDashboard,
  readKeycloakToken,
  readOrganization,
} from "@services/index";
import { setCurrentOrganization } from "@store/slices/OrganizationContent/slice";
import {
  fetchOwnedOrganizationsOfUser,
  fetchSharedOrganizationsOfUser,
} from "@store/slices/Organizations/thunks";
import { fetchUserLocationInfo } from "@store/slices/UserAgent/thunks";
import { fetchDatabaseUser, logout } from "@store/slices/UserContent/thunks";
import * as CommonStyles from "@styles/CommonStyles";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import useKeycloakAuth from "@app/hooks/useKeycloakAuth";
import {setDefaultDashboardLoaded} from "@store/slices/Dashboards/slice";

const AppRouter: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authType = process.env.REACT_APP_AUTH;
  const defaultDashboardLoaded = useAppSelector(
    (state) => state.dashboards.defaultDashboardLoaded
  );

  const { user: authUser, isLoading: isAuthUserLoading } = authType === "keycloak" ? useKeycloakAuth() : useFirebaseAuth();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const [routedNoOrganization, setRoutedNoOrganization] =
    useState<boolean>(false);

  /* Loading Statuses */
  const [isUserCredentialsLoading, setIsUserCredentialsLoading] =
    useState<boolean>(true);

  const [isOrganizationsOfUserLoading, setIsOrganizationsOfUserLoading] =
    useState<boolean>(true);

  /* Authentication Statuses */
  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);

  const [isOrganizationAuthenticated, setIsOrganizationAuthenticated] =
    useState<boolean>(false);



  const fetchLatestOrganizationAndAssignAsCurrent = (
    ownedOrgRes: Organization[],
    sharedOrgRes: Organization[]
  ) => {
    const storedOrganization: Organization | undefined = readOrganization();

    if (!(ownedOrgRes.length + sharedOrgRes.length > 0)) {
      return;
    }

    if (storedOrganization) {
      const storedOrgId: string = storedOrganization.id;
      const matchedOwnOrg = ownedOrgRes.find((org) => org.id === storedOrgId);
      const matchedSharedOrg = sharedOrgRes.find(
        (org) => org.id === storedOrgId
      );

      if (matchedOwnOrg) {
        dispatch(setCurrentOrganization(matchedOwnOrg));
      } else if (matchedSharedOrg) {
        dispatch(setCurrentOrganization(matchedSharedOrg));
      } else {
        dispatch(
          setCurrentOrganization(
            ownedOrgRes.length > 0 ? ownedOrgRes[0] : sharedOrgRes[0]
          )
        );
      }
    } else {
      dispatch(
        setCurrentOrganization(
          ownedOrgRes.length > 0 ? ownedOrgRes[0] : sharedOrgRes[0]
        )
      );
    }
  };

  const fetchOrganizationsOfUser = async (id: string) => {
    return await Promise.all([
      dispatch(fetchOwnedOrganizationsOfUser(id)).unwrap(),
      dispatch(fetchSharedOrganizationsOfUser(id)).unwrap(),
    ]);
  };

  const fetchDatabaseUserByEmail = async (email: string) => {
    return await dispatch(fetchDatabaseUser(email)).unwrap();
  };

  const fetchDatabaseUserAndOrganizations = async (email: string) => {
    await fetchDatabaseUserByEmail(email)
      .then(async (dbUser) => {
        await fetchOrganizationsOfUser(dbUser.id)
          .then((res) => {
            const ownedOrganizationCount: number = res[0].length;
            const sharedOrganizationCount: number = res[1].length;
            const doesUserHaveOrganization: boolean =
              ownedOrganizationCount + sharedOrganizationCount > 0;

            setRoutedNoOrganization(!doesUserHaveOrganization);
            fetchLatestOrganizationAndAssignAsCurrent(res[0], res[1]);
            setIsUserAuthenticated(true);
            setIsOrganizationAuthenticated(doesUserHaveOrganization);
          })
          .catch(() => {
            setIsUserAuthenticated(true);
          });
      })
      .catch(() => {
        setAuthenticationStates(false);
      })
      .finally(() => {
        setLoadingStates(false);
      });
  };

  const forceVerificationLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => {
        NotificationController.info({
          message: t("notifications.info.confirmEmail"),
        });
        navigate(APPLICATION_PATHS.LOGIN_PATH);
      })
      .finally(() => {
        setIsUserCredentialsLoading(false);
        setIsOrganizationsOfUserLoading(false);
        setIsUserAuthenticated(false);
        setIsOrganizationAuthenticated(false);
      });
  };

  const setLoadingStates = (state: boolean) => {
    setIsUserCredentialsLoading(state);
    setIsOrganizationsOfUserLoading(state);
  };

  const setAuthenticationStates = (state: boolean) => {
    setIsUserAuthenticated(state);
    setIsOrganizationAuthenticated(state);
  };

  const defaultDashboardId: string | null = readDefaultDashboard();

  useEffect(() => {
    if (authType === "keycloak") {
      if (authUser === null) {
        setLoadingStates(false);
        setAuthenticationStates(false);
      }
  
      if (authUser) {
        if ('email_verified' in authUser && !authUser.email_verified) {
          forceVerificationLogout();
        }
  
        const userAccessToken = readKeycloakToken();
  
        if (userAccessToken) {
          setLoadingStates(true);
          fetchDatabaseUserAndOrganizations(authUser.email!);
        }
      }
    } else {
      if (authUser === null) {
        setLoadingStates(false);
        setAuthenticationStates(false);
      }
  
      if (authUser && 'emailVerified' in authUser && authUser.emailVerified) {
        authUser.getIdToken(true).then((res) => {
          persistBearerToken(res);
          setLoadingStates(true);
          fetchDatabaseUserAndOrganizations(authUser.email!);
        });
      } else if (authUser && 'emailVerified' in authUser && !authUser.emailVerified) {
        forceVerificationLogout();
      }
    }
  }, [authUser]);


  useEffect(() => {
    const loading: boolean =
      isUserCredentialsLoading || isOrganizationsOfUserLoading;

    if (loading) {
      return;
    }
    
    const requestedPath = window.location.pathname;

    if (isUserAuthenticated && isOrganizationAuthenticated) {
      if (defaultDashboardId && !defaultDashboardLoaded) {
        navigate(`${APPLICATION_PATHS.DASHBOARD_PATH}/${defaultDashboardId}`);
        dispatch(setDefaultDashboardLoaded(true));
      }
      if (requestedPath === APPLICATION_PATHS.ROOT_PATH ||
          requestedPath === APPLICATION_PATHS.APPLICATION_ROOT_PATH ||
          requestedPath === APPLICATION_PATHS.LOGIN_PATH ||
          requestedPath === APPLICATION_PATHS.SIGN_UP_PATH
      ) {
        navigate(APPLICATION_PATHS.DASHBOARD_PATH);
      } else {
        navigate(requestedPath);
      }
    } else if (isUserAuthenticated && !isOrganizationAuthenticated) {
      navigate(APPLICATION_PATHS.NO_ORGANIZATION_PATH);
    }

  }, [
    isUserCredentialsLoading,
    isOrganizationsOfUserLoading,
    isUserAuthenticated,
    isOrganizationAuthenticated,
    currentOrganization,
  ]);
  
  useEffect(() => {
    if (currentOrganization) {
      setIsOrganizationAuthenticated(true);
    }
  }, [currentOrganization]);

  useEffect(() => {
    dispatch(fetchUserLocationInfo());
  }, []);

  const Login = withAnimation(withLoading(LoginPage));
  const Signup = withAnimation(withLoading(SignupPage));
  const ForgotPassword = withAnimation(withLoading(ForgotPasswordPage));
  const NoOrganization = withAnimation(withLoading(NoOrganizationPage));

  const DashboardMain = withAnimation(withLoading(DashboardMainPage));
  const DashboardDetail = withAnimation(withLoading(DashboardDetailPage));

  const DatasetMain = withAnimation(withLoading(DatasetMainPage));
  const DatasetDetail = withAnimation(withLoading(DatasetDetailPage));
  const DatasetCreation = withAnimation(withLoading(DatasetCreationPage));

  const MeasurementMain = withAnimation(withLoading(MeasurementMainPage));
  const MeasurementDetail = withAnimation(withLoading(MeasurementDetailPage));
  const MeasurementDetailv2 = withAnimation(
    withLoading(MeasurementDetailPagev2)
  );

  const OrganizationMain = withAnimation(withLoading(OrganizationMainPage));
  const OrganizationDisabled = withAnimation(
    withLoading(OrganizationDisabledPage)
  );
  const OrganizationDetail = withAnimation(withLoading(OrganizationDetailPage));
  const OrganizationPlan = withAnimation(withLoading(OrganizationPlanPage));
  const BillingProfiles = withAnimation(withLoading(BillingProfilesSection));
  const DowngradeLicense = withAnimation(withLoading(DowngradeLicensePage));

  const renderRoutes = useCallback(() => {
    const organizationExists = isUserAuthenticated && isOrganizationAuthenticated;
    const organizationNotDisabled = currentOrganization && !currentOrganization.disabled;
    const defaultDashboardRoute = defaultDashboardId ? `${APPLICATION_PATHS.DASHBOARD_PATH}/${defaultDashboardId}` : APPLICATION_PATHS.DASHBOARD_PATH;

    const protectedRoutes = (
        <Route element={<MainLayout />}>
          <Route index element={<Navigate to={defaultDashboardRoute} replace />} />
          <Route path={APPLICATION_PATHS.ORGANIZATION_DISABLED_PATH} element={<OrganizationDisabled />} />
          <Route element={<ProtectedRoute isAllowed={organizationNotDisabled} fallbackPath={APPLICATION_PATHS.ORGANIZATION_DISABLED_PATH} />}>
            <Route path={APPLICATION_PATHS.DASHBOARD_PATH} element={<DashboardMain />} />
            <Route path={APPLICATION_PATHS.DASHBOARD_DETAIL_PATH} element={<DashboardDetail />} />
            <Route path={APPLICATION_PATHS.DATASET_PATH} element={<DatasetMain />} />
            <Route path={APPLICATION_PATHS.DATASET_DETAIL_PATH} element={<DatasetDetail />} />
            <Route path={APPLICATION_PATHS.DATASET_CREATE_PATH} element={<DatasetCreation />} />
            <Route path={APPLICATION_PATHS.MEASUREMENT_PATH} element={<MeasurementMain />} />
            <Route path={APPLICATION_PATHS.MEASUREMENT_DETAIL_PATH} element={<MeasurementDetailv2 />} />
          </Route>
          <Route path={APPLICATION_PATHS.ORGANIZATION_PATH} element={<OrganizationMain />} />
          <Route path={APPLICATION_PATHS.ORGANIZATION_DETAIL_PATH} element={<OrganizationDetail />} />
          <Route path={APPLICATION_PATHS.ORGANIZATION_PLAN_PATH} element={<OrganizationPlan />} />
          <Route path={APPLICATION_PATHS.DOWNGRADE_LICENSE_PATH} element={<DowngradeLicense />} />
          <Route path={APPLICATION_PATHS.PAYMENT_BILLING_PATH} element={<PaymentBillingLayout />}>
            <Route path={APPLICATION_PATHS.PAYMENTS_PATH} element={<PaymentsSection />} />
            <Route path={APPLICATION_PATHS.PAYMENT_AREA_PATH} element={<PaymentAreaSection />} />
            <Route path={APPLICATION_PATHS.BILLING_DETAIL_PATH} element={<BillingDetailsSection />} />
            <Route path={APPLICATION_PATHS.PAYMENT_METHODS_PATH} element={<PaymentMethodsSection />} />
            <Route path={APPLICATION_PATHS.BILLING_PROFILES_PATH} element={<BillingProfiles />} />
          </Route>
          <Route path={APPLICATION_PATHS.PROFILE_PATH} element={<ProfileLayout />}>
            <Route path={APPLICATION_PATHS.PERSONAL_INFO_PATH} element={<PersonalInfoSection />} />
            <Route path={APPLICATION_PATHS.SECURITY_SETTINGS_PATH} element={<SecuritySettingsSection />} />
          </Route>
          <Route path={`${APPLICATION_PATHS.APPLICATION_ROOT_PATH}/*`} element={<Navigate to={APPLICATION_PATHS.DASHBOARD_PATH} replace />} />
        </Route>
    );

    return (
        <Routes>
          {/* Paths that are only accessible when organization exists and user is authenticated */}
          <Route path={APPLICATION_PATHS.APPLICATION_ROOT_PATH} element={<ProtectedRoute isAllowed={organizationExists} />}>
            {protectedRoutes}
          </Route>

          {/* Duplicated route for the same protected routes under different path */}
          <Route path={APPLICATION_PATHS.ROOT_PATH} element={<ProtectedRoute isAllowed={organizationExists} />}>
            {protectedRoutes}
          </Route>

          {/* Paths that are only accessible when user is authenticated and not organization authenticated*/}
          <Route element={<ProtectedRoute isAllowed={isUserAuthenticated && !isOrganizationAuthenticated} />}>
            <Route path={APPLICATION_PATHS.NO_ORGANIZATION_PATH} element={<NoOrganization />} />
          </Route>

          {/* Paths that are accessible to all */}
          <Route path={APPLICATION_PATHS.APPLICATION_AUTH_PATH} element={<AuthLayout />}>
            <Route path={APPLICATION_PATHS.LOGIN_PATH} element={<Login />} />
            <Route path={APPLICATION_PATHS.SIGN_UP_PATH} element={<Signup />} />
            <Route path={APPLICATION_PATHS.FORGOT_PASSWORD_PATH} element={<ForgotPassword />} />
          </Route>

          {/* Unknown Path */}
          <Route path="*" element={<Navigate to={APPLICATION_PATHS.LOGIN_PATH} replace />} />
        </Routes>
    );
  }, [isUserAuthenticated, isOrganizationAuthenticated, currentOrganization.disabled]);

  const renderLoading = () => {
    return (
      <CommonStyles.LoadingContainer width="100%" height="100vh">
        <Loading size="5rem" />
      </CommonStyles.LoadingContainer>
    );
  };

  return (
    <React.Fragment>
      {isAuthUserLoading ||
      isUserCredentialsLoading ||
      isOrganizationsOfUserLoading
        ? renderLoading()
        : renderRoutes()}
    </React.Fragment>
  );

};

export default AppRouter;
