import {
  Dashboard,
  EditVisualFromDashboardForm,
  IModalBaseProps,
  Kpi,
  Visual,
  VisualType,
} from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import {kpisInitialState, resetVisualRecords} from "@store/slices/DashboardContent/slice";
import { editVisualFromDashboard } from "@store/slices/DashboardContent/thunks";
import {Checkbox, Col, Input, InputNumber, Row, Select, Space, Switch, Typography} from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

interface IEditVisualFromDashboardModalProps extends IModalBaseProps {
  visual: Visual;
  dashboard: Dashboard;
}

const EditVisualFromDashboardModal: React.FC<
  IEditVisualFromDashboardModalProps
> = ({ isOpen, setIsOpen, visual, dashboard }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const dashboardConnectedDatasetKpis: Kpi[] = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboard.id]
          ? state.dashboardContent.dashboardRecords[dashboard.id].kpis.data : kpisInitialState.data
  );
  const thresholdEnabledWatcher = BaseForm.useWatch("thresholdEnabled", form);
  const VISUAL_TYPE_OPTIONS: DefaultOptionType[] = [
    {
      label: t("modals.editVisualFromDashboard.bar"),
      value: VisualType.BAR,
    },
    {
      label: t("modals.editVisualFromDashboard.line"),
      value: VisualType.LINE,
    },
    {
      label: t("modals.editVisualFromDashboard.value"),
      value: VisualType.VALUE,
    },
    {
      label: t("modals.editVisualFromDashboard.date"),
      value: VisualType.DATE,
    },
    {
      label: t("modals.editVisualFromDashboard.text"),
      value: VisualType.TEXT,
    },

    {
      label: t("modals.editVisualFromDashboard.heatmap"),
      value: VisualType.HEATMAP,
    },
  ];

  const VISUAL_KPI_OPTIPONS: DefaultOptionType[] = [
    ...dashboardConnectedDatasetKpis.map((kpi) => {
      return {
        label: kpi.name,
        value: kpi.id,
      };
    }),
  ];

  useEffect(() => {
    const formValues = {
      name: visual.name,
      type: visual.visualType,
      threshold: visual.params.threshold,
      predict: visual.params.predict,
      dataComparison: visual.params.dataComparison,
      value: visual.params.value,
      kpiId: visual.kpiId,
      historyDepth: visual.params.dataComparison ? visual.historyDepth / 2 : visual.historyDepth,
      thresholdEnabled: visual.params.thresholdEnabled,
      thresholdReversed: visual.params.thresholdReversed,
    };

    form?.setFieldsValue(formValues);
  }, [visual, dashboard]);

  const renderValueFormItem = () => {
    return (
      <BaseForm.Item
        name="value"
        label={t("modals.editVisualFromDashboard.value")}
      >
        <Input placeholder={t("modals.editVisualFromDashboard.value")} />
      </BaseForm.Item>
    );
  };

  const renderKpiFormItem = () => {
    return (
      <BaseForm.Item
        name="kpiId"
        label={t("modals.addVisualToDashboardModal.kpi")}
      >
        <Select
          disabled
          placeholder={t("modals.addVisualToDashboardModal.kpi")}
          options={VISUAL_KPI_OPTIPONS}
        />
      </BaseForm.Item>
    );
  };

  const renderThresholdFormItem = () => {
    return (
        <Row align={"bottom"} gutter={20}>
          <Col xs={22}>
            <BaseForm.Item
                name="threshold"
                label={t("modals.addVisualToDashboardModal.threshold")}
            >
              <Input
                  type="number"
                  style={{width: "100%"}}
                  disabled={!thresholdEnabledWatcher}
                  addonAfter={
                    <Space>
                      <Typography style={{fontSize: 10}}>Reversed</Typography>
                      <BaseForm.Item noStyle valuePropName={"checked"} name={"thresholdReversed"}>
                        <Switch disabled={!thresholdEnabledWatcher}></Switch>
                      </BaseForm.Item>
                    </Space>
                  }
              />
            </BaseForm.Item>
          </Col>
          <Col xs={2}><BaseForm.Item valuePropName={"checked"} name="thresholdEnabled">
            <Checkbox/>
          </BaseForm.Item>
          </Col>
        </Row>
    );
  };

  const renderPredictionFormItem = () => {
    return (
      <BaseForm.Item
        name="predict"
        label={t("modals.addVisualToDashboardModal.forecasting")}
      >
        <Select placeholder={t("modals.addVisualToDashboardModal.forecasting")}>
          <Select.Option value={true}>{t("common.yes")}</Select.Option>
          <Select.Option value={false}>{t("common.no")}</Select.Option>
        </Select>
      </BaseForm.Item>
    );
  };

  const renderDataComparisonFormItem = () => {
    return (
      <BaseForm.Item
        name="dataComparison"
        label={t("modals.addVisualToDashboardModal.dataComparison")}
      >
        <Select placeholder={t("modals.addVisualToDashboardModal.dataComparison")}>
          <Select.Option value={true}>{t("common.yes")}</Select.Option>
          <Select.Option value={false}>{t("common.no")}</Select.Option>
        </Select>
      </BaseForm.Item>
    );
  };

  const renderHistoryDepthFormItem = () => {
    return (
      <BaseForm.Item
        name="historyDepth"
        label={t("modals.addVisualToDashboardModal.history")}
      >
        <InputNumber
          style={{ width: "100%" }}
          min={0}
          placeholder={t("modals.addVisualToDashboardModal.history")}
        />
      </BaseForm.Item>
    );
  };

  const renderDependentFieldsByVisualType = (): React.ReactNode => {
    switch (visual.visualType) {
      case VisualType.BAR:
      case VisualType.LINE:
        return (
          <React.Fragment>
            {renderKpiFormItem()}
            {renderHistoryDepthFormItem()}
            {renderPredictionFormItem()}
             {renderThresholdFormItem()}
            {renderDataComparisonFormItem()}
          </React.Fragment>
        );
      case VisualType.HEATMAP:
        return (
          <React.Fragment>
            {renderKpiFormItem()}
            {renderHistoryDepthFormItem()}
            {/* {renderThresholdFormItem()} */}
          </React.Fragment>
        );
      case VisualType.VALUE:
        return (
          <React.Fragment>
            {renderKpiFormItem()}
            {renderHistoryDepthFormItem()}
          </React.Fragment>
        );
      case VisualType.TEXT:
        return <React.Fragment>{renderValueFormItem()}</React.Fragment>;
      case VisualType.DATE:
      default:
        return <React.Fragment />;
    }
  };

  const onOkHandler = () => {
    setIsOpen(false);
    form.submit();
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: EditVisualFromDashboardForm) => {
    let data: EditVisualFromDashboardForm = {
      dashboardId: dashboard.id,
      datasetId: dashboard.dataset.id,
      visualId: visual.id,
      kpiId: formData.kpiId,
      name: formData.name,
      type: formData.type,
      historyDepth: formData.historyDepth,
      params: {},
    };

    if (formData.thresholdEnabled !== undefined) {
      data.params["thresholdEnabled"] = formData.thresholdEnabled;
    }
    data.params["thresholdReversed"] = formData.thresholdReversed;

    if (formData.threshold) {
      data.params["threshold"] = formData.threshold;
    }

    if ("predict" in formData) {
      data.params["predict"] = formData.predict;
    }

    if ("dataComparison" in formData) {
      data.params["dataComparison"] = formData.dataComparison;
      if (data.historyDepth && formData.dataComparison == true) {
        data.historyDepth *= 2;
      }
    }

    if (formData.value) {
      data.params["value"] = formData.value;
    }

    dispatch(editVisualFromDashboard(data))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.editVisualFromDashboard"),
        });
        dispatch(resetVisualRecords(dashboard.id));
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editVisualFromDashboard"),
        });
      });
  };

  return (
    <Modal
      title={t("modals.editVisualFromDashboard.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <BaseForm
        form={form}
        onFinish={onSubmitHandler}
        layout="vertical"
        name="editVisualFromDashboardForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="type" label={t("common.name")}>
          <Select
            disabled
            placeholder={t("modals.addVisualToDashboardModal.type")}
            options={VISUAL_TYPE_OPTIONS}
          />
        </BaseForm.Item>
        {renderDependentFieldsByVisualType()}
      </BaseForm>
    </Modal>
  );
};

export default EditVisualFromDashboardModal;
