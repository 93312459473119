import {
  AddVisualToDashboardForm,
  Dashboard,
  EditDashboardForm,
  IModalBaseProps,
  Kpi,
  LayoutOption,
  RequestStatus,
  User,
  VisualType,
} from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import {kpisInitialState, resetVisualRecords} from "@store/slices/DashboardContent/slice";
import {
  addVisualToDashboard,
  editDashboard,
  fetchKpis,
} from "@store/slices/DashboardContent/thunks";
import { reflectDashboardEdit } from "@store/slices/Dashboards/slice";
import { convertUserOptionArray, findDashboardShareType } from "@utils/index";
import {Checkbox, Col, Input, InputNumber, Row, Select, Space, Switch, Typography} from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IAddVisualToDashboardModalProps extends IModalBaseProps {
  dashboard: Dashboard;
  positionCreator: (visualId: string) => LayoutOption;
}

const AddVisualToDashboardModal: React.FC<IAddVisualToDashboardModalProps> = ({
  dashboard,
  isOpen,
  setIsOpen,
  positionCreator,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const dashboardConnectedDatasetKpis: Kpi[] = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboard.id]
          ? state.dashboardContent.dashboardRecords[dashboard.id].kpis.data : kpisInitialState.data
  );

  const dashboardConnectedDatasetKpisStatus: RequestStatus = useAppSelector(
      (state) => state.dashboardContent.dashboardRecords[dashboard.id]
          ? state.dashboardContent.dashboardRecords[dashboard.id].kpis.status : kpisInitialState.status
  );
  const thresholdEnabledWatcher = BaseForm.useWatch("thresholdEnabled", form);
  useEffect(() => {
    if (
      dashboard.dataset &&
      dashboardConnectedDatasetKpisStatus === RequestStatus.IDLE
    ) {
      dispatch(fetchKpis(dashboard.dataset.id));
    }
  }, [dashboard, dashboardConnectedDatasetKpisStatus]);

  const [selectedVisualType, setSelectedVisualType] = useState<
    VisualType | undefined
  >();

  const VISUAL_TYPE_OPTIONS: DefaultOptionType[] = [
    {
      label: t("modals.addVisualToDashboardModal.bar"),
      value: VisualType.BAR,
    },
    {
      label: t("modals.addVisualToDashboardModal.line"),
      value: VisualType.LINE,
    },
    {
      label: t("modals.addVisualToDashboardModal.value"),
      value: VisualType.VALUE,
    },
    {
      label: t("modals.addVisualToDashboardModal.date"),
      value: VisualType.DATE,
    },
    {
      label: t("modals.addVisualToDashboardModal.text"),
      value: VisualType.TEXT,
    },

    {
      label: t("modals.addVisualToDashboardModal.heatmap"),
      value: VisualType.HEATMAP,
    },
  ];

  const VISUAL_KPI_OPTIPONS: DefaultOptionType[] = [
    ...dashboardConnectedDatasetKpis.map((kpi) => {
      return {
        label: kpi.name,
        value: kpi.id,
      };
    }),
  ];

  const renderValueFormItem = () => {
    return (
      <BaseForm.Item
        name="value"
        label={t("modals.addVisualToDashboardModal.value")}
      >
        <Input placeholder={t("modals.addVisualToDashboardModal.value")} />
      </BaseForm.Item>
    );
  };

  const renderKpiFormItem = () => {
    return (
      <BaseForm.Item
        name="kpiId"
        label={t("modals.addVisualToDashboardModal.kpi")}
      >
        <Select
          placeholder={t("modals.addVisualToDashboardModal.kpi")}
          options={VISUAL_KPI_OPTIPONS}
        />
      </BaseForm.Item>
    );
  };

  const renderThresholdFormItem = () => {
    return (
        <Row align={"bottom"} gutter={20}>
            <Col xs={22}>
                <BaseForm.Item
                    name="threshold"
                    label={t("modals.addVisualToDashboardModal.threshold")}
                >
                    <Input
                        type="number"
                        style={{width: "100%"}}
                        disabled={!thresholdEnabledWatcher}
                        addonAfter={
                            <Space>
                                <Typography style={{fontSize: 10}}>Reversed</Typography>
                                <BaseForm.Item noStyle valuePropName={"checked"} name={"thresholdReversed"} initialValue={false}>
                                    <Switch disabled={!thresholdEnabledWatcher}></Switch>
                                </BaseForm.Item>
                            </Space>
                        }
                    />
                </BaseForm.Item>
            </Col>
            <Col xs={2}><BaseForm.Item valuePropName={"checked"} name="thresholdEnabled">
                <Checkbox/>
            </BaseForm.Item>
            </Col>
        </Row>
    );
  };
  const renderPredictionFormItem = () => {
    return (
      <BaseForm.Item
        name="predict"
        label={t("modals.addVisualToDashboardModal.forecasting")}
      >
        <Select placeholder={t("modals.addVisualToDashboardModal.forecasting")}>
          <Select.Option value={true}>{t("common.yes")}</Select.Option>
          <Select.Option value={false}>{t("common.no")}</Select.Option>
        </Select>
      </BaseForm.Item>
    );
  };

  const renderDataComparisonFormItem = () => {
    return (
      <BaseForm.Item
        name="dataComparison"
        label={t("modals.addVisualToDashboardModal.dataComparison")}
      >
        <Select placeholder={t("modals.addVisualToDashboardModal.dataComparison")}>
          <Select.Option value={true}>{t("common.yes")}</Select.Option>
          <Select.Option value={false}>{t("common.no")}</Select.Option>
        </Select>
      </BaseForm.Item>
    );
  };

  const renderHistoryDepthFormItem = () => {
    return (
      <BaseForm.Item
        name="historyDepth"
        label={t("modals.addVisualToDashboardModal.history")}
      >
        <InputNumber
          style={{ width: "100%" }}
          min={0}
          placeholder={t("modals.addVisualToDashboardModal.history")}
        />
      </BaseForm.Item>
    );
  };

  const renderDependentFieldsByVisualType = (): React.ReactNode => {
    switch (selectedVisualType) {
      case VisualType.BAR:
      case VisualType.LINE:
        return (
          <React.Fragment>
            {renderKpiFormItem()}
            {renderHistoryDepthFormItem()}
            {renderPredictionFormItem()}
            {renderThresholdFormItem()}
            {renderDataComparisonFormItem()}
          </React.Fragment>
        );
      case VisualType.HEATMAP:
        return (
          <React.Fragment>
            {renderKpiFormItem()}
            {renderHistoryDepthFormItem()}
            {/* {renderThresholdFormItem()} */}
          </React.Fragment>
        );
      case VisualType.VALUE:
        return (
          <React.Fragment>
            {renderKpiFormItem()}
            {renderHistoryDepthFormItem()}
          </React.Fragment>
        );
      case VisualType.TEXT:
        return <React.Fragment>{renderValueFormItem()}</React.Fragment>;
      case VisualType.DATE:
      default:
        return <React.Fragment />;
    }
  };

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: AddVisualToDashboardForm) => {
    let data: AddVisualToDashboardForm = {
      dashboardId: dashboard.id,
      datasetId: dashboard.dataset.id,
      kpiId: formData.kpiId,
      name: formData.name,
      type: formData.type,
      historyDepth: formData.historyDepth,
      params: {},
    };

    if (formData.thresholdEnabled !== undefined) {
      data.params["thresholdEnabled"] = formData.thresholdEnabled;
    }
    data.params["thresholdReversed"] = formData.thresholdReversed;
    if (formData.threshold) {
      data.params["threshold"] = formData.threshold;
    }

    if ("predict" in formData) {
      data.params["predict"] = formData.predict;
    }

    if ("dataComparison" in formData) {
      data.params["dataComparison"] = formData.dataComparison;
      if (data.historyDepth && formData.dataComparison == true) {
        data.historyDepth *= 2;
      }
    }

    if (formData.value) {
      data.params["value"] = formData.value;
    }

    dispatch(addVisualToDashboard(data))
      .unwrap()
      .then((res) => {
        const dashboardData: EditDashboardForm = {
          dashboardId: dashboard.id,
          name: dashboard.name,
          description: dashboard.description,
          datasetId: dashboard.dataset.id,
          userId: (dashboard.user as User).id,
          users: convertUserOptionArray(dashboard.users),
          layouts: [...dashboard.layouts, positionCreator(res.id)],
          tenantId: dashboard.tenant,
        };

        dispatch(editDashboard(dashboardData))
          .unwrap()
          .then((res) => {
            dispatch(
              reflectDashboardEdit({
                dashboard: res,
                dashboardType: findDashboardShareType(
                  dashboard,
                  currentUser.id
                ),
              })
            );

            dispatch(resetVisualRecords(dashboard.id));
          })
          .catch((err) => {});

        NotificationController.success({
          message: t("notifications.success.addVisualToDashboard", {
            visualName: data.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addVisualToDashboard", {
            visualName: data.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.addVisualToDashboardModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addVisualToDashboardForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input placeholder={t("common.name")} />
        </BaseForm.Item>

        <BaseForm.Item
          name="type"
          label={t("modals.addVisualToDashboardModal.type")}
        >
          <Select
            placeholder={t("modals.addVisualToDashboardModal.type")}
            options={VISUAL_TYPE_OPTIONS}
            onChange={(value) => setSelectedVisualType(value)}
          />
        </BaseForm.Item>

        {renderDependentFieldsByVisualType()}
      </BaseForm>
    </Modal>
  );
};

export default AddVisualToDashboardModal;
