import { AddMetricForm, Dataset, IModalBaseProps, User } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addMetric } from "@store/slices/DatasetContent/thunks";
import { Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface IAddMetricModalProps extends IModalBaseProps {
  dataset: Dataset;
}

const AddMetricModal: React.FC<IAddMetricModalProps> = ({
  dataset,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: AddMetricForm) => {
    formData.datasetId = dataset.id;
    dispatch(addMetric(formData))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.addMetric", {
            metricName: formData.name,
          }),
        });
        form.resetFields();
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addMetric", {
            metricName: formData.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.addMetricModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addMetricForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label={t("common.description")}>
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddMetricModal;
