import { FONT_SIZE } from "@common/index";
import { Text } from "@components/common";
import { List, Row } from "antd";
import styled, { css } from "styled-components";

export const StyledTitle = styled.span`
  margin-right: 0.5rem;
  white-space: nowrap;
`;

export const UserListItem = styled(List.Item)`
  display: flex;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Description = styled.div`
  color: var(--text-light-color);
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

export const CutDownText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CustomText = styled(Text)<{ isErrorText: boolean }>`
  font-size: ${FONT_SIZE.xxl};
  ${(props) =>
    props.isErrorText
      ? css`
          color: var(--error-color);
        `
      : css`
          color: var(--success-color);
        `};
`;

export const InfoText = styled(Text)`
  &.ant-typography {
    color: var(--text-light-color);
    display: block;
    margin-bottom: 1rem;
  }
`;

export const HeaderRow = styled(Row)`
  margin: 0 0 1rem 0;
  justify-content: space-between;
  align-items: center;
`;
