import { AddBillingProfileForm, IModalBaseProps, Organization } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addBillingProfile } from "@store/slices/Payments/thunks";
import { Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const AddBillingProfileModal: React.FC<IModalBaseProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onOkHandler = async () => {
    await form.validateFields();
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const onSubmitHandler = (values: AddBillingProfileForm) => {
    values.tenantId = currentOrganization.id;
    dispatch(addBillingProfile(values))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.addBillingProfile", {
            billingProfile: values.title,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addBillingProfile", {
            billingProfile: values.title,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.addBillingProfileModal.modalTitle")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addBillingProfileForm"
      >
        <BaseForm.Item
          name="title"
          label={t("modals.addBillingProfileModal.title")}
          rules={[
            {
              required: true,
              message: t("errors.requiredField"),
            },
            {
              min: 3,
              message: t("errors.titleRequired"),
            },
          ]}
        >
          <Input placeholder={t("modals.addBillingProfileModal.title")} />
        </BaseForm.Item>

        <BaseForm.Item
          name="taxId"
          label={t("modals.addBillingProfileModal.taxId")}
          rules={[
            {
              required: true,
              message: t("errors.requiredField"),
            },
            {
              min: 8,
              max: 12,
              message: t("errors.taxIdRequired"),
            },
          ]}
        >
          <Input placeholder={t("modals.addBillingProfileModal.taxId")} />
        </BaseForm.Item>

        <BaseForm.Item
          name="taxAdmin"
          label={t("modals.addBillingProfileModal.taxAdmin")}
        >
          <Input placeholder={t("modals.addBillingProfileModal.taxAdmin")} />
        </BaseForm.Item>

        <BaseForm.Item
          name="address"
          label={t("modals.addBillingProfileModal.address")}
          rules={[
            {
              required: true,
              message: t("errors.requiredField"),
            },
            {
              min: 3,
              message: t("errors.addressRequired"),
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder={t("modals.addBillingProfileModal.address")}
          />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddBillingProfileModal;
