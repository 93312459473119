import { RequestStatus } from "@common/enums";
import { Organization } from "@common/types";
import {
  Card,
  H2,
  Loading,
  SectionHeader,
  withAnimation,
  withLoading,
} from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import {
  fetchObligations,
  fetchSettlements,
} from "@store/slices/Payments/thunks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PaymentsTable } from "../";
import * as S from "./PaymentSection.style";

const PaymentsSection = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const obligationsStatus: RequestStatus = useAppSelector(
    (state) => state.payments.obligations.status
  );

  const settlementsStatus: RequestStatus = useAppSelector(
    (state) => state.payments.settlements.status
  );

  useEffect(() => {
    currentOrganization && dispatch(fetchObligations(currentOrganization.id));
  }, [currentOrganization]);

  useEffect(() => {
    currentOrganization && dispatch(fetchSettlements(currentOrganization.id));
  }, [currentOrganization]);

  const renderPaymentsTable = (): React.ReactNode => {
    const loading: boolean =
      obligationsStatus === RequestStatus.PENDING ||
      settlementsStatus === RequestStatus.PENDING;

    return loading ? (
      <S.LoadingContainer>
        <Loading size="4rem" />
      </S.LoadingContainer>
    ) : (
      <PaymentsTable />
    );
  };

  return (
    <React.Fragment>
      <Card padding={[25, 25]}>
        <SectionHeader
          title={<H2>{t("pages.organizations.payments")}</H2>}
          customMargin="0 0 2rem 0"
        />

        {renderPaymentsTable()}
      </Card>
    </React.Fragment>
  );
};

export default withAnimation(withLoading(PaymentsSection));
