import { CreditCardOutlined, EyeOutlined } from "@ant-design/icons";
import {
  CountryDetail, Currency, LicenseType, Obligation,
  ObligationItem,
  Organization, PaymentStatus, Settlement
} from "@common/index";
import { H3, P1 } from "@components/common";
import { useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Dates } from "@services/index";
import { getCurrencySymbol, getLicenseTitle } from "@utils/index";
import { Button, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./PaymentsTable.style";

const EMPTY_TABLE_CELL = "-";

const PaymentsTable: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userAgent: CountryDetail | null = useAppSelector(
    (state) => state.userAgent.local.data
  );

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const obligations: Obligation[] = useAppSelector(
    (state) => state.payments.obligations.data
  );

  const settlements: Settlement[] = useAppSelector(
    (state) => state.payments.settlements.data
  );

  const isSettlement = (obj: Obligation | Settlement): boolean => {
    if ("obligation" in obj) {
      return true;
    }
    return false;
  };

  const onPayClickHandler = (organizationId: string, obligationId: string) => {
    const payNavigationPath = APPLICATION_PATHS.PAYMENT_AREA_PATH.replace(
      ":organizationId",
      organizationId
    ).replace(":obligationId", obligationId);

    navigate(payNavigationPath);
  };

  const onDetailClickHandler = (
    organizationId: string,
    settlementId: string
  ) => {
    const detailNavigationPath = APPLICATION_PATHS.BILLING_DETAIL_PATH.replace(
      ":organizationId",
      organizationId
    ).replace(":settlementId", settlementId);

    navigate(detailNavigationPath);
  };

  const renderLicenseTypesFromObligationItems = (
    obligationItems: ObligationItem[]
  ) => {
    const licenseTypes: LicenseType[] = obligationItems.map(
      (obl) => obl.type as LicenseType
    );

    if (licenseTypes.length === 1) {
      return <H3>{getLicenseTitle(licenseTypes[0])}</H3>;
    } else if (licenseTypes.length > 1) {
      return (
        <Row align="middle" justify="space-between">
          <H3>{getLicenseTitle(licenseTypes[0])}</H3>
          <S.LicenseTypeTag color="success">
            +{licenseTypes.length - 1}
          </S.LicenseTypeTag>
        </Row>
      );
    } else {
      return EMPTY_TABLE_CELL;
    }
  };

  const renderTotalAmount = (obligationItems: ObligationItem[]) => {
    const totalAmount = obligationItems.reduce((acc, curr) => {
      let currentValueInLocalCurrency = acc;

      switch (userAgent?.currencyCode) {
        case Currency.TRY:
          return (currentValueInLocalCurrency += curr.fee.tr);
        case Currency.EUR:
          return (currentValueInLocalCurrency += curr.fee.eu);
        case Currency.USD:
        default:
          return (currentValueInLocalCurrency += curr.fee.us);
      }
    }, 0);

    return (
      <P1>
        {getCurrencySymbol(userAgent)} {totalAmount.toFixed(2)}
      </P1>
    );
  };

  const columns: ColumnsType<Obligation | Settlement> = [
    {
      title: t("pages.organizations.licenseTypes"),
      width: "20%",
      render(value) {
        return isSettlement(value)
          ? renderLicenseTypesFromObligationItems(value.obligation.items)
          : renderLicenseTypesFromObligationItems(value.items);
      },
    },

    {
      title: t("pages.organizations.obligationStartDate"),
      width: "22%",
      render(value) {
        return isSettlement(value) ? (
          <P1>
            {Dates.convertStringToDate(value.obligation.datetime).format("LL")}
          </P1>
        ) : (
          <P1>{Dates.convertStringToDate(value.datetime).format("LL")}</P1>
        );
      },
    },

    {
      title: t("pages.organizations.paymentDate"),
      width: "22%",
      render(value) {
        return isSettlement(value) ? (
          <P1>{Dates.convertStringToDate(value.paymentDate).format("LL")}</P1>
        ) : (
          <P1>{EMPTY_TABLE_CELL}</P1>
        );
      },
    },

    {
      title: t("pages.organizations.paymentAmount"),
      width: "16%",
      render(value) {
        return renderTotalAmount(
          isSettlement(value) ? value.obligation.items : value.items
        );
      },
    },

    {
      title: t("pages.organizations.paymentStatus"),
      width: "10%",
      render(value) {
        return (
          <S.PaymentTag
            $paymentStatus={
              isSettlement(value) ? PaymentStatus.PAID : PaymentStatus.UNPAID
            }
          >
            {isSettlement(value)
              ? t("pages.organizations.paid")
              : t("pages.organizations.unpaid")}
          </S.PaymentTag>
        );
      },
    },

    {
      title: t("common.actions"),
      width: "10%",
      render(value) {
        return isSettlement(value) ? (
          <Button
            block
            type="primary"
            size="small"
            icon={<EyeOutlined />}
            onClick={() =>
              onDetailClickHandler(currentOrganization.id, value.id)
            }
          >
            {t("pages.organizations.inspect")}
          </Button>
        ) : (
          <Button
            block
            type="primary"
            size="small"
            icon={<CreditCardOutlined />}
            onClick={() => onPayClickHandler(currentOrganization.id, value.id)}
          >
            {t("pages.organizations.pay")}
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={[...obligations, ...settlements]}
      bordered
      pagination={false}
      scroll={{ x: 600 }}
    />
  );
};

export default PaymentsTable;
