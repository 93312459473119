import { AddUserToOrganizationForm, IModalBaseProps, Organization, User } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addUserToOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import {
  createAutoCompleteOptionsFromArray,
  findOrganizationShareType,
} from "@utils/index";
import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IAddUserToOrganizationModalProps extends IModalBaseProps {
  organization: Organization;
}

const AddUserToOrganizationModal: React.FC<
  IAddUserToOrganizationModalProps
> = ({ organization, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>([]);

  const dispatch = useAppDispatch();

  const allUsers: User[] = useAppSelector((state) => state.users.data);
  const currentUser: User = useAppSelector((state) => state.userContent.data);
  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const initialFormValues = {
    user: undefined,
    editable: false,
  };

  useEffect(() => {
    const options = createAutoCompleteOptionsFromArray(
      allUsers
        .filter((user) => user.id !== currentUser.id)
        .filter(
          (user) =>
            !currentOrganization.users.some(
              (orgUser) => (orgUser.user as User).id === user.id
            )
        ),
      (user) => user.id,
      (user) => user.name
    );

    setUserOptions(options);
  }, [allUsers, currentUser, currentOrganization.users]);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const onSubmitHandler = (formData: AddUserToOrganizationForm) => {
    formData.user = JSON.parse(formData.user as string);

    const addUserform: AddUserToOrganizationForm = {
      ...formData,
      organization: currentOrganization,
    };

    const isUserAlreadyInOrganization = currentOrganization.users.some(
      (userOpt) => (userOpt.user as User).id === (formData.user as User).id
    );

    if (isUserAlreadyInOrganization) {
      NotificationController.error({
        message: t("notifications.error.userAlreadyInOrganization"),
      });
      return;
    }

    form.resetFields();
    dispatch(addUserToOrganization(addUserform))
      .unwrap()
      .then(async (res) => {
        dispatch(
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(res, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.addUserToOrganization", {
            userName: (formData.user as User).name,
            organizationName: addUserform.organization.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addUserToOrganization", {
            userName: (formData.user as User).name,
            organizationName: addUserform.organization.name,
          }),
        });
      });
  };

  const onSearchHandler = (searchText: string) => {
    if (!searchText) {
      const options = createAutoCompleteOptionsFromArray(
        allUsers
          .filter((user) => user.id !== currentUser.id)
          .filter(
            (user) =>
              !currentOrganization.users.some(
                (orgUser) => (orgUser.user as User).id === user.id
              )
          ),
        (user) => user.id,
        (user) => user.name
      );
      setUserOptions(options);
      return;
    }

    const filteredUsers = allUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(searchText.toLowerCase()) &&
        user.id !== currentUser.id &&
        !currentOrganization.users.some(
          (orgUser) => (orgUser.user as User).id === user.id
        )
    );

    const options = createAutoCompleteOptionsFromArray(
      filteredUsers,
      (user) => user.id,
      (user) => user.name
    );

    setUserOptions(options);
  };

  return (
    <Modal
      title={t("modals.addUserToOrganizationModal.title", {
        organizationName: organization.name,
      })}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <BaseForm
        initialValues={initialFormValues}
        onFinish={(values: AddUserToOrganizationForm) =>
          onSubmitHandler(values)
        }
        form={form}
        layout="vertical"
        name="addUserToOrganizationForm"
      >
        <BaseForm.Item name="user" label={t("common.name")}>
          <Select
            showSearch
            onSearch={onSearchHandler}
            allowClear
            placeholder={t("common.user")}
          >
            {userOptions.map((option) => (
              <Select.Option
                key={option.value}
                value={JSON.stringify(option.selectedOption)}
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </BaseForm.Item>
        <BaseForm.Item name="editable" label={t("common.editable")}>
          <Select placeholder={t("common.editable")}>
            <Select.Option value={false}>{t("common.no")}</Select.Option>
            <Select.Option value={true}>{t("common.yes")}</Select.Option>
          </Select>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddUserToOrganizationModal;
