import { axios } from "@api/index";
import { AddDatasetForm, AddDatasetFromAITemplateForm, AddDatasetFromTemplateForm, Dataset } from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const DATASET_ENDPOINT = `${BASE_PATH}/dataset`;
const SHARED_DATASET_ENDPOINT = `${BASE_PATH}/shared/dataset`;
const DATASET_FROM_TEMPLATE_ENDPOINT = `${BASE_PATH}/datasetFromTemplate`;
const DATASET_FROM_AI_TEMPLATE_ENDPOINT = `${BASE_PATH}/templateFromBody`

export const fetchOwnedDatasets = createAsyncThunk(
  "datasets/fetchOwnedDatasets",
  async (userId: string) => {
    const response = await axios.get<Dataset[]>(
      `${DATASET_ENDPOINT}/${userId}`
    );

    return response.data;
  }
);

export const fetchSharedDatasets = createAsyncThunk(
  "datasets/fetchSharedDatasets",
  async (userId: string) => {
    const response = await axios.get<Dataset[]>(
      `${SHARED_DATASET_ENDPOINT}/${userId}`
    );
    return response.data;
  }
);

export const addDataset = createAsyncThunk(
  "datasets/addDataset",
  async (data: AddDatasetForm) => {
    const { userId, name, description, tenantId, users } = data;
    const response = await axios.post<Dataset>(
      `${DATASET_ENDPOINT}/${userId}`,
      { name, description, tenant: tenantId, users }
    );
    return response.data;
  }
);

export const addDatasetFromTemplate = createAsyncThunk(
  "datasets/addDatasetFromTemplate",
  async (data: AddDatasetFromTemplateForm) => {
    const { userId, templatesetId, tenantId } = data;
    const response = await axios.post<Dataset>(
      `${DATASET_FROM_TEMPLATE_ENDPOINT}/${userId}/${tenantId}/${templatesetId}`
    );
    return response.data;
  }
);

export const addDatasetFromAITemplate = createAsyncThunk(
  "datasets/addDatasetFromAITemplate",
  async (data: AddDatasetFromAITemplateForm) => {
    const { userId, templateset, tenantId } = data;
    const response = await axios.post<Dataset>(
      `${DATASET_FROM_AI_TEMPLATE_ENDPOINT}/${userId}/${tenantId}`, templateset
    );
    return response.data;
  }
);

