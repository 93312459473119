export enum RequestStatus {
  IDLE = "IDLE",
  PENDING = "PENDING",
  FULFILLED = "FULFILLED",
  REJECTED = "REJECTED",
}

export enum DatasetDefinitionItem {
  METRIC = "METRIC",
  KPI = "KPI",
}

export enum ShareType {
  OWNED = "OWNED",
  SHARED = "SHARED",
  NONE = "NONE",
}

export enum LicenseType {
  LICENSE_FREE = "LICENSE_FREE",
  LICENSE_STARTER = "LICENSE_STARTER",
  LICENSE_PRO = "LICENSE_PRO",
  LICENSE_ENTERPRISE = "LICENSE_ENTERPRISE",
}

export enum UserRole {
  ADMIN = "ADMIN",
  EDITOR = "EDITOR",
  BASIC = "BASIC",
}

export enum InsufficientLicenseCause {
  DATASET_COUNT = "DATASET_COUNT",
  DASHBOARD_COUNT = "DASHBOARD_COUNT",
  USER_COUNT = "USER _COUNT",
}

export enum TimeOption {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum TimeDirection {
  PREVIOUS = "PREVIOUS",
  NEXT = "NEXT",
}

export enum EditableTableActions {
  NONE = "NONE",
  EDIT = "EDIT",
  DELETE = "DELETE",
  SAVE = "SAVE",
}

export enum VisualType {
  BAR = "bar",
  LINE = "line",
  VALUE = "value",
  DATE = "date",
  TEXT = "text",
  HEATMAP = "heatmap",
}

export enum Currency {
  TRY = "TRY",
  EUR = "EUR",
  USD = "USD",
}

export enum PaymentStatus {
  PAID = "PAID",
  UNPAID = "UNPAID",
}

export enum PaymentStorageMethod {
  SAVED_METHOD = "SAVED_METHOD",
  NEW_METHOD = "NEW_METHOD",
}

export enum FileType {
  EXCEL = "Excel",
  CSV = "CSV",
  JSON = "JSON",
}
