import { DRAWER_SIZES, Dataset, IDrawerBaseProps, IUserWithRole, User, UserOption, UserRole } from "@common/index";
import { H2, SectionHeader } from "@components/common";
import { useAppSelector, useResponsive } from "@hooks/index";
import { AddUserToDatasetModal } from "@pages/DatasetPages/modals";
import * as CommonStyles from "@styles/CommonStyles";
import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DatasetUserCard } from "../../components";

interface IManageDatasetDrawerProps extends IDrawerBaseProps {
  dataset: Dataset;
}

const ManageDatasetDrawer: React.FC<IManageDatasetDrawerProps> = ({
  dataset,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useResponsive();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const [isAddUserToDatasetModalOpen, setIsAddUserToDatasetModalOpen] =
    useState<boolean>(false);

  const getDatasetOwner = (): IUserWithRole => {
    const ownerWithRole: IUserWithRole = {
      user: dataset.user as User,
      role: UserRole.ADMIN,
    };

    return ownerWithRole;
  };

  const getCurrentUserIfNotOwner = (): IUserWithRole | undefined => {
    if (currentUser.id !== (dataset.user as User).id) {
      const currentUserOptions: UserOption = dataset.users.find(
        (userOpt) => userOpt
      ) as UserOption;

      const currentUserWithRole = {
        user: currentUser,
        role: currentUserOptions.editable ? UserRole.EDITOR : UserRole.BASIC,
      };

      return currentUserWithRole;
    }

    return undefined;
  };

  const getDatasetMembers = (): IUserWithRole[] => {
    const datasetMembersWithRole: IUserWithRole[] | undefined =
      dataset?.users.map((userOpt) => {
        const userWithRole: IUserWithRole = {
          user: userOpt.user as User,
          role: userOpt.editable ? UserRole.EDITOR : UserRole.BASIC,
        };

        return userWithRole;
      });

    return datasetMembersWithRole.filter(
      (userWithRole) => userWithRole.user.id !== currentUser.id
    );
  };

  const getAllUsersWithRole = (): IUserWithRole[] => {
    const currentUserIfNotOwner: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    return currentUserIfNotOwner
      ? [getDatasetOwner(), currentUserIfNotOwner, ...getDatasetMembers()]
      : [getDatasetOwner(), ...getDatasetMembers()];
  };

  const getCurrentUserWithRole = () => {
    const currentUserWithRole: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    if (currentUserWithRole) {
      return currentUserWithRole;
    }

    return getDatasetOwner();
  };

  const renderDrawerButtons = () => {
    const currentUserIfNotOwner: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    const currentUserWithRole: IUserWithRole = currentUserIfNotOwner
      ? currentUserIfNotOwner
      : getDatasetOwner();

    if ([UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)) {
      return (
        <Space>
          <Button
            size="small"
            type="primary"
            onClick={() => setIsAddUserToDatasetModalOpen(true)}
          >
            {t("pages.datasets.addUser")}
          </Button>
        </Space>
      );
    }
  };

  return (
    <React.Fragment>
      <AddUserToDatasetModal
        user={currentUser}
        dataset={dataset}
        isOpen={isAddUserToDatasetModalOpen}
        setIsOpen={setIsAddUserToDatasetModalOpen}
      />

      <Drawer
        open={isOpen}
        title={dataset.name}
        width={isTablet ? DRAWER_SIZES.large : DRAWER_SIZES.small}
        placement="right"
        extra={renderDrawerButtons()}
        onClose={() => setIsOpen(false)}
      >
        <SectionHeader title={<H2>{t("common.users")}</H2>} />
        <CommonStyles.VerticalSpacer>
          {getAllUsersWithRole().map((userWithRole) => (
            <DatasetUserCard userWithRole={userWithRole} />
          ))}
        </CommonStyles.VerticalSpacer>
      </Drawer>
    </React.Fragment>
  );
};

export default ManageDatasetDrawer;
