import { LanguageType, UserSignupForm } from "@common/index";
import { BaseForm } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { signup } from "@store/slices/UserContent/thunks";
import { Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as AuthStyles from "../../AuthPages.style";

interface ISignupFormProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignupForm: React.FC<ISignupFormProps> = ({ loading, setLoading }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const [isSignupLoading, setIsSignupLoading] = useState<boolean>(false);

  const ANTD_REQUIRE_RULE = {
    required: true,
    message: t("errors.requiredField"),
  };

  const ANTD_EMAIL_RULE = {
    required: true,
    type: "email",
    message: t("errors.invalidEmail"),
  };

  const onSubmitHandler = (formData: UserSignupForm) => {
    setLoading(true);
    dispatch(signup(formData))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.signup"),
        });
        navigate(APPLICATION_PATHS.LOGIN_PATH);
        setLoading(false);
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.signup"),
        });
        setLoading(false);
      });
  };

  const getTermsAndPoliciesText = () => {
    const currentLanguage: LanguageType = i18n.language as LanguageType;

    switch (currentLanguage) {
      case "en":
        return (
          <AuthStyles.Text>
            {t("pages.auth.agreement.0")}
            <a
              href={APPLICATION_PATHS.SUBSCRIPTION_AGREEMENT_PATH}
              target="_blank"
            >
              {t("pages.auth.agreement.1")}
            </a>

            {t("pages.auth.agreement.2")}

            <a href={APPLICATION_PATHS.PRIVACY_POLICY_PATH} target="_blank">
              {t("pages.auth.agreement.3")}
            </a>
          </AuthStyles.Text>
        );
      case "tr":
        return (
          <AuthStyles.Text>
            <a
              href={APPLICATION_PATHS.SUBSCRIPTION_AGREEMENT_PATH}
              target="_blank"
            >
              {t("pages.auth.agreement.0")}
            </a>
            {t("pages.auth.agreement.1")}{" "}
            <a href={APPLICATION_PATHS.PRIVACY_POLICY_PATH} target="_blank">
              {t("pages.auth.agreement.2")}
            </a>
            {t("pages.auth.agreement.3")}
          </AuthStyles.Text>
        );
      default:
        return "";
    }
  };

  return (
    <BaseForm onFinish={(values) => onSubmitHandler(values)}>
      <AuthStyles.FormItem
        label={t("pages.auth.fullName")}
        name="fullName"
        rules={[ANTD_REQUIRE_RULE]}
      >
        <Input placeholder={t("pages.auth.fullName")} />
      </AuthStyles.FormItem>

      <AuthStyles.FormItem
        label={t("pages.auth.email")}
        name="email"
        rules={[ANTD_REQUIRE_RULE, ANTD_EMAIL_RULE]}
      >
        <Input placeholder={t("pages.auth.email")} />
      </AuthStyles.FormItem>

      <AuthStyles.FormItem
        label={t("pages.auth.password")}
        name="password"
        rules={[ANTD_REQUIRE_RULE]}
      >
        <AuthStyles.FormInputPassword placeholder={t("pages.auth.password")} />
      </AuthStyles.FormItem>

      <AuthStyles.FormItem
        label={t("pages.auth.passwordConfirm")}
        name="passwordConfirm"
        rules={[
          ANTD_REQUIRE_RULE,
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("errors.confirmPassword")));
            },
          }),
        ]}
      >
        <AuthStyles.FormInputPassword
          placeholder={t("pages.auth.passwordConfirm")}
        />
      </AuthStyles.FormItem>

      <AuthStyles.ActionsWrapper>
        <AuthStyles.FormItem
          name="termOfUse"
          valuePropName="checked"
          noStyle
          rules={[
            ANTD_REQUIRE_RULE,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("errors.termsOfUse")));
              },
            }),
          ]}
        >
          <Row wrap>
            <Col span={2}>
              <AuthStyles.FormCheckbox />
            </Col>
            <Col span={22}>
              <AuthStyles.TextWrapper style={{ maxWidth: "200px" }}>
                {getTermsAndPoliciesText()}
              </AuthStyles.TextWrapper>
            </Col>
          </Row>
        </AuthStyles.FormItem>
      </AuthStyles.ActionsWrapper>

      <AuthStyles.SubmitButton type="primary" htmlType="submit">
        {t("pages.auth.signup")}
      </AuthStyles.SubmitButton>

      <AuthStyles.FooterWrapper>
        <AuthStyles.Text>
          {t("pages.auth.alreadyHaveAccount")}
          <Link to={APPLICATION_PATHS.LOGIN_PATH}>
            {" "}
            <AuthStyles.LinkText>
              {t("pages.auth.loginHere")}
            </AuthStyles.LinkText>
          </Link>
        </AuthStyles.Text>
      </AuthStyles.FooterWrapper>
    </BaseForm>
  );
};

export default SignupForm;
