import { media } from "@common/constants";
import { Row } from "antd";
import styled from "styled-components";

export const Container = styled(Row)`
  margin-top: 1.2rem;

  @media only screen and ${media.md} {
    margin-top: 2rem;
  }
`;

export const CardWrapper = styled.div`
  padding: 0.625rem;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
