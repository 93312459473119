import {
  HomeOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { IDropdownMenuItemOption } from "@common/interfaces";
import { BillingProfile } from "@common/types";
import { DropdownMenu } from "@components/common";
import { Row } from "antd";
import React, { useState } from "react";
import {
  DeleteBillingProfileModal,
  EditBillingProfileModal,
} from "../../modals";
import * as S from "./BillingProfileCard.style";
import { useTranslation } from "react-i18next";

interface IBillingProfileCardProps {
  billingProfile: BillingProfile;
}

const BillingProfileCard: React.FC<IBillingProfileCardProps> = ({
  billingProfile,
}) => {
  const { t } = useTranslation();

  const [isEditBillingProfileModalOpen, setIsEditBillingProfileModalOpen] =
    useState<boolean>(false);

  const [isDeleteBillingProfileModalOpen, setIsDeleteBillingProfileModalOpen] =
    useState<boolean>(false);

  const BILLING_PROFILE_ITEM_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "editBillingProfile",
      title: t("common.edit"),
      disabled: false,
      onClick: () => setIsEditBillingProfileModalOpen(true),
    },
    {
      key: "deleteBillingProfile",
      title: t("common.delete"),
      disabled: false,
      onClick: () => setIsDeleteBillingProfileModalOpen(true),
    },
  ];

  return (
    <React.Fragment>
      <DeleteBillingProfileModal
        billingProfile={billingProfile}
        isOpen={isDeleteBillingProfileModalOpen}
        setIsOpen={setIsDeleteBillingProfileModalOpen}
      />

      <EditBillingProfileModal
        billingProfile={billingProfile}
        isOpen={isEditBillingProfileModalOpen}
        setIsOpen={setIsEditBillingProfileModalOpen}
      />

      <S.Card padding={[15, 20]}>
        <S.HeaderRow>
          <S.HeaderText>{billingProfile.title}</S.HeaderText>
          <DropdownMenu options={BILLING_PROFILE_ITEM_OPTIONS} />
        </S.HeaderRow>
        <Row align={"middle"}>
          <S.IconWrapper>
            <TagOutlined />
          </S.IconWrapper>
          <S.InfoText>{billingProfile.taxId}</S.InfoText>
        </Row>
        <Row align={"middle"}>
          <S.IconWrapper>
            <UserOutlined />
          </S.IconWrapper>
          <S.InfoText>{billingProfile.taxAdmin}</S.InfoText>
        </Row>
        <Row align={"middle"}>
          <S.IconWrapper>
            <HomeOutlined />
          </S.IconWrapper>
          <S.InfoText>{billingProfile.address}</S.InfoText>
        </Row>
      </S.Card>
    </React.Fragment>
  );
};

export default BillingProfileCard;
