import { Row } from "antd";
import styled from "styled-components";

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 2rem 0;
`;
