import {
  clearBearerToken,
  clearDefaultDashboard,
  clearKeycloakToken,
  clearOrganization,
  persistBearerToken,
  persistKeycloakToken,
  readKeycloakToken,
} from "@services/index";

import {
  EmailAuthProvider,
  auth,
  axios,
  createUserWithEmailAndPassword,
  getKeycloakAdminToken,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  signInWithEmailAndPassword,
  signOut,
  storage,
  updatePassword,
} from "@api/index";
import {
  ChangePasswordForm,
  EditUserInfoForm,
  UploadAvatarForm,
  User,
  UserLoginForm,
  UserSignupForm,
} from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { resetDashboardContent } from "../DashboardContent/slice";
import { resetDashboards } from "../Dashboards/slice";
import { resetDatasetContent } from "../DatasetContent/slice";
import { resetDatasets } from "../Datasets/slice";
import { resetLicenses } from "../Licenses/slice";
import { resetOrganizationContent } from "../OrganizationContent/slice";
import { resetOrganizations } from "../Organizations/slice";
import { resetTemplasetContent } from "../TemplatesetContent/slice";
import { resetTemplatesets } from "../Templatesets/slice";
import { resetUsers } from "../Users/slice";
import { resetUserContent } from "./slice";
import { decodeToken } from "react-jwt";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const USER_ENDPOINT = `${BASE_PATH}/user`;
const AVATAR_STORAGE = "avatar";
const authType = process.env.REACT_APP_AUTH;

export const signup = createAsyncThunk(
  "userContent/signup",
  async (data: UserSignupForm, { rejectWithValue }) => {
    if (authType === "keycloak") {
      const keycloakAdminUserEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users`;
      const userTokenEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`;
      try {
        const adminToken = await getKeycloakAdminToken();

        const keycloakResponse = await axios.post(
          keycloakAdminUserEndpoint,
          {
            email: data.email,
            username: data.fullName,
            enabled: true,
            firstName: data.fullName.split(" ")[0],
            lastName: data.fullName.split(" ").pop(),
            credentials: [
              { type: "password", value: data.password, temporary: false },
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const tokenResponse = await axios.post(
          userTokenEndpoint,
          new URLSearchParams({
            grant_type: "password",
            client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "",
            client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET ?? "",
            username: data.email,
            password: data.password,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const userAccessToken = tokenResponse.data.access_token;
        const decodedToken = decodeToken(userAccessToken);
        const userId = (decodedToken as any)?.sub;
        const keycloakAdminVerifyEmailEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${userId}/send-verify-email`;

        persistKeycloakToken(userAccessToken);

        const databaseResponse = await axios.post(`${USER_ENDPOINT}`, {
          name: data.fullName,
          email: data.email,
          photoUrl: null,
        });

        clearKeycloakToken();

        const keycloakVerifyEmailResponse = await axios.put(
          keycloakAdminVerifyEmailEndpoint,
          null,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        return databaseResponse.data;
      } catch (error) {
        rejectWithValue(error);
      }
    } else {
      try {
        const firebaseResponse = await createUserWithEmailAndPassword(
          auth,
          data.email,
          data.password
        );

        const userToken = await firebaseResponse.user.getIdToken();
        persistBearerToken(userToken);

        const databaseResponse = await axios.post(`${USER_ENDPOINT}`, {
          name: data.fullName,
          email: data.email,
          photoUrl: null,
        });

        sendEmailVerification(firebaseResponse.user);

        clearBearerToken();
        return databaseResponse.data;
      } catch (err) {
        rejectWithValue(err);
      }
    }
  }
);

export const login = createAsyncThunk(
  "userContent/login",
  async (data: UserLoginForm) => {
    const { email, password } = data;

    if (authType === "keycloak") {
      const userTokenEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`;
      try {
        const tokenResponse = await axios.post(
          userTokenEndpoint,
          new URLSearchParams({
            grant_type: "password",
            client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? "",
            client_secret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET ?? "",
            username: email,
            password: password,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        persistKeycloakToken(tokenResponse.data.access_token);

        return tokenResponse.data.access_token;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          throw new Error("Invalid credentials");
        } else {
          throw error;
        }
      }
    } else {
      const response = await signInWithEmailAndPassword(auth, email, password);
      return JSON.parse(JSON.stringify(response));
    }
  }
);

export const editUserInfo = createAsyncThunk(
  "userContent/editUserInfo",
  async (data: EditUserInfoForm) => {
    const response = await axios.put(`${USER_ENDPOINT}/${data.user.id}`, {
      name: data.name,
      email: data.email,
      photoUrl: data.user.photoUrl,
    });

    return response.data;
  }
);

export const changePassword = createAsyncThunk(
  "userContent/changePassword",
  async (data: ChangePasswordForm, { rejectWithValue }) => {
    if (authType === "keycloak") {
      try {
        const userAccessToken = readKeycloakToken();
        if (userAccessToken) {
          const adminToken = await getKeycloakAdminToken();
          const decodedToken = decodeToken(userAccessToken);
          const userId = (decodedToken as any)?.sub;
          const keycloakResetPasswordEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${userId}/reset-password`;

          clearKeycloakToken();

          const keycloakResponse = await axios.put(
            keycloakResetPasswordEndpoint,
            {
              type: "password",
              value: data.newPassword,
              temporary: false,
            },
            {
              headers: {
                Authorization: `Bearer ${adminToken}`,
                "Content-Type": "application/json",
              },
            }
          );
          persistKeycloakToken(userAccessToken);
        }
      } catch (error) {
        rejectWithValue(error);
      }
    } else {
      if (!auth.currentUser) {
        return rejectWithValue("User not found");
      }

      const { email, password, newPassword } = data;
      const credentials = EmailAuthProvider.credential(email, password);

      await reauthenticateWithCredential(auth.currentUser, credentials).then(
        (res) => {
          if (!auth.currentUser) {
            return rejectWithValue("User not found");
          }

          updatePassword(auth.currentUser, newPassword);
        }
      );
    }
  }
);

export const logout = createAsyncThunk(
  "userContent/logout",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(resetUsers());
    thunkAPI.dispatch(resetUserContent());
    thunkAPI.dispatch(resetLicenses());
    thunkAPI.dispatch(resetDashboards());
    thunkAPI.dispatch(resetDashboardContent());
    thunkAPI.dispatch(resetDatasets());
    thunkAPI.dispatch(resetDatasetContent());
    thunkAPI.dispatch(resetOrganizations());
    thunkAPI.dispatch(resetOrganizationContent());
    thunkAPI.dispatch(resetTemplatesets());
    thunkAPI.dispatch(resetTemplasetContent());
    clearBearerToken();
    clearOrganization();
    clearDefaultDashboard();

    if (authType === "keycloak") {
      try {
        const userAccessToken = readKeycloakToken();
        if (userAccessToken) {
          const adminToken = await getKeycloakAdminToken();
          const decodedToken = decodeToken(userAccessToken);
          const userId = (decodedToken as any)?.sub;
          const keycloakLogoutEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${userId}/logout`;
          clearKeycloakToken();
          const keycloakResponse = await axios.post(
            keycloakLogoutEndpoint,
            null,
            {
              headers: {
                Authorization: `Bearer ${adminToken}`,
                "Content-Type": "application/json",
              },
            }
          );
        }
      } catch (error) {}
      return;
    } else {
      return await signOut(auth);
    }
  }
);

export const fetchDatabaseUser = createAsyncThunk(
  "userContent/fetchDatabaseUser",
  async (email: string) => {
    const databaseResponse = await axios.get<User[]>(
      `${USER_ENDPOINT}?email=${email}`
    );
    return databaseResponse.data[0];
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "userContent/sendPasswordResetEmail",
  async (email: string, { rejectWithValue }) => {
    if (authType === "keycloak") {
      clearKeycloakToken();
      try {
        const keycloakAdminGetUsersEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users`;
        const adminToken = await getKeycloakAdminToken();

        const keycloakResponse = await axios.get(
          `${keycloakAdminGetUsersEndpoint}?email=${encodeURIComponent(email)}`,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (keycloakResponse.data[0].id) {
          const userId = keycloakResponse.data[0].id;
          const keycloakResetPasswordEmailEndpoint = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${userId}/execute-actions-email`;
          const payload = {
            actions: ["UPDATE_PASSWORD"],
          };

          const keycloakResetEmailResponse = await axios.put(
            keycloakResetPasswordEmailEndpoint,
            payload,
            {
              headers: {
                Authorization: `Bearer ${adminToken}`,
                "Content-Type": "application/json",
              },
            }
          );
          return;
        } else {
          throw new Error("Invalid email");
        }
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      return await sendPasswordResetEmailFirebase(auth, email);
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  "userContent/uploadAvatar",
  async (data: UploadAvatarForm) => {
    const { file, content, user } = data;

    const metadata = {
      cacheControl: "public,max-age=300",
      contentType: file.type,
    };

    const imageRef = ref(storage, `${AVATAR_STORAGE}/${(user as User).id}`);
    const res = await uploadBytes(imageRef, content, metadata);

    const downloadUrl = await getDownloadURL(imageRef);

    const response = await axios.put(`${USER_ENDPOINT}/${data.user.id}`, {
      name: data.user.name,
      email: data.user.email,
      photoUrl: downloadUrl,
    });

    return response.data;
  }
);
