import { Dashboard, Dataset, EditDashboardForm, IModalBaseProps, Organization, User } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editDashboard } from "@store/slices/DashboardContent/thunks";
import { reflectDashboardEdit } from "@store/slices/Dashboards/slice";
import {
  convertUserOptionArray,
  findDashboardShareType,
} from "@utils/index";
import { Input } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IEditDashboardModalProps extends IModalBaseProps {
  dashboard: Dashboard;
}

const EditDashboardModal: React.FC<IEditDashboardModalProps> = ({
  dashboard,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: EditDashboardForm) => {
    const data: EditDashboardForm = {
      dashboardId: dashboard.id,
      name: formData.name,
      description: formData.description,
      datasetId: dashboard.dataset.id,
      userId: (dashboard.user as User).id,
      users: convertUserOptionArray(dashboard.users),
      layouts: dashboard.layouts,
      tenantId: currentOrganization.id,
    };

    dispatch(editDashboard(data))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDashboardEdit({
            dashboard: res,
            dashboardType: findDashboardShareType(res, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.editDashboard"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editDashboard"),
        });
      });
  };

  useEffect(() => {
    const formValues = {
      name: dashboard.name,
      description: dashboard.description,
      dataset: (dashboard.dataset as Dataset).name,
    };

    form?.setFieldsValue(formValues);
  }, [dashboard]);

  return (
    <Modal
      title={t("modals.editDashboardModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        form={form}
        onFinish={onSubmitHandler}
        layout="vertical"
        name="editDashboardForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label={t("common.description")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item
          name="dataset"
          label={t("modals.editDashboardModal.dataset")}
        >
          <Input disabled={true} />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditDashboardModal;
