import {
  ApiRangeFilter,
  AppDate,
  FileType,
  Metric,
  MetricRecord,
  MetricRecordMap,
} from "@app/common";
import { IModalBaseProps } from "@app/common/interfaces";
import { BaseForm, H4, Modal } from "@app/components/common";
import {
  fetchMetricRecordsOfDatasetInRange,
  getDataFile,
} from "@app/store/slices/DatasetContent/thunks";
import CSVIcon from "@assets/icons/CSV.png";
import ExcelIcon from "@assets/icons/Excel.png";
import JSONIcon from "@assets/icons/JSON.png";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { DatePicker, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ExportDataModal.style";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

const ExportDataModal: React.FC<IModalBaseProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const currentUser = useAppSelector((state) => state.userContent.data);

  const currentMetrics: Metric[] = useAppSelector(
    (state) => state.datasetContent.metrics.data
  );

  const [selectedFileType, setSelectedFileType] = useState<FileType>(
    FileType.EXCEL
  );

  const handleStartDateChange = (date: Dayjs | null) => {
    setStartDate(date);
    if (endDate && (date === null || date.isAfter(endDate))) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    setEndDate(date);
    if (startDate && (date === null || date.isBefore(startDate))) {
      setStartDate(null);
    }
  };

  const disabledStartDate = (currentDate: Dayjs | null) => {
    if (!currentDate || !endDate) {
      return false;
    }
    return currentDate.isAfter(endDate);
  };

  const disabledEndDate = (currentDate: Dayjs | null) => {
    if (!currentDate || !startDate) {
      return false;
    }
    return currentDate.isBefore(startDate);
  };

  const getMetricRecordMapWithNames = async (
    rangeFilter: ApiRangeFilter,
    currentMetricIds: string[]
  ) => {
    const result: MetricRecordMap = await dispatch(
      fetchMetricRecordsOfDatasetInRange({
        metricIds: currentMetricIds,
        filter: rangeFilter,
      })
    ).unwrap();

    const metricRecordMapWithNames: { [metricName: string]: MetricRecord[] } =
      {};

    for (const metric of currentMetrics) {
      const metricId = metric.id;
      const metricData = result[metricId];
      if (metricData) {
        metricRecordMapWithNames[metric.name] = metricData;
      }
    }

    return metricRecordMapWithNames;
  };

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const onFinishHandler = async (data: {
    startDate: AppDate;
    endDate: AppDate;
    fileType: FileType;
  }) => {
    data.fileType = selectedFileType;

    const rangeFilter: ApiRangeFilter = {
      filter: {
        time: {
          begin: data.startDate.toISOString(),
          end: data.endDate.toISOString(),
        },
      },
    };

    const currentMetricIds: string[] = currentMetrics.map(
      (metric) => metric.id
    );

    const metricRecordMapWithNames = await getMetricRecordMapWithNames(
      rangeFilter,
      currentMetricIds
    );

    await dispatch(
      getDataFile({
        metricRecordMap: metricRecordMapWithNames,
        fileType: selectedFileType,
        user: currentUser,
      })
    )
      .unwrap()
      .then((res) => {
        const firebaseDownloadLink = res;
        window.open(firebaseDownloadLink, "_blank");
        setIsOpen(false);
      })
      .catch((error) => {});
  };

  const renderOptionCard = (fileType: FileType, icon: string) => {
    return (
      <S.OptionCard
        padding={[20, 20]}
        onClick={() => onFileTypeClickHandler(fileType)}
        selected={fileType === selectedFileType}
      >
        <S.OptionIcon src={icon} />
        <Row justify={"center"}>
          <H4>{fileType}</H4>
        </Row>
      </S.OptionCard>
    );
  };

  const onFileTypeClickHandler = (fileType: FileType) => {
    setSelectedFileType(fileType);
  };

  return (
    <React.Fragment>
      <Modal
        title={t("modals.exportDataModal.title")}
        open={isOpen}
        onOk={onOkHandler}
        okText={t("common.download")}
        onCancel={onCancelHandler}
        okButtonProps={{ disabled: !startDate || !endDate }}
      >
        <BaseForm form={form} onFinish={onFinishHandler}>
          <BaseForm.Item name="startDate" label={t("common.startDate")}>
            <S.CustomDayjsDatePicker
              style={{ width: "100%" }}
              showTime
              format="YYYY-MM-DD HH:mm"
              onChange={handleStartDateChange}
              disabledDate={disabledStartDate}
            />
          </BaseForm.Item>

          <BaseForm.Item name="endDate" label={t("common.endDate")}>
            <S.CustomDayjsDatePicker
              showTime
              format="YYYY-MM-DD HH:mm"
              onChange={handleEndDateChange}
              disabledDate={disabledEndDate}
            />
          </BaseForm.Item>

          <BaseForm.Item name="fileType">
            <S.Container
              justify="space-evenly"
              align="middle"
              gutter={[12, 12]}
            >
              {renderOptionCard(FileType.EXCEL, ExcelIcon)}
              {renderOptionCard(FileType.CSV, CSVIcon)}
              {renderOptionCard(FileType.JSON, JSONIcon)}
            </S.Container>
          </BaseForm.Item>
        </BaseForm>
      </Modal>
    </React.Fragment>
  );
};

export default ExportDataModal;
