import { Dates } from "@app/services";
import {
  addMetricRecord,
  editMetricRecord,
  fetchMetricRecordByMetricIdAndTimestamp,
} from "@app/store/slices/DatasetContent/thunks";
import {
  AddMetricRecordForm,
  ApiRangeFilter,
  Dataset,
  EditMetricRecordForm,
  IModalBaseProps,
  Metric,
} from "@common/index";
import { Modal, Text } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { List } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface IImportDataConfirmModalProps extends IModalBaseProps {
  matchingMetrics: string[];
  metricsFromFile: Map<any, any> | null;
}

const ImportDataConfirmModal: React.FC<IImportDataConfirmModalProps> = ({
  isOpen,
  setIsOpen,
  matchingMetrics,
  metricsFromFile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentDataset: Dataset | null = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  const currentMetrics: Metric[] = useAppSelector(
    (state) => state.datasetContent.metrics.data
  );

  const createFoundMetricsMap = (
    matchingMetrics: string[],
    metricsFromFile: Map<string | undefined, string[] | undefined[]> | null
  ): Map<string | undefined, string[] | undefined[]> => {
    const newMap: Map<string | undefined, string[] | undefined[]> = new Map();
    if (metricsFromFile) {
      metricsFromFile.forEach((value, key) => {
        if (
          key === undefined ||
          matchingMetrics.includes(key) ||
          key === "Date and Time"
        ) {
          newMap.set(key, value);
        }
      });
    }
    return newMap;
  };

  const findMetricRecord = async (metricId: string, timestamp: string) => {
    const rangeFilter: ApiRangeFilter = {
      filter: {
        time: {
          begin: timestamp,
          end: Dates.convertStringToDate(timestamp)
            .add(1, "second")
            .toISOString(),
        },
      },
    };

    const result = await dispatch(
      fetchMetricRecordByMetricIdAndTimestamp({
        metricId: metricId,
        filter: rangeFilter,
      })
    );

    return result.payload;
  };

  const onOkHandler = async () => {
    const matchedMetricsDataMap = createFoundMetricsMap(
      matchingMetrics,
      metricsFromFile
    );

    for (const [key, values] of matchedMetricsDataMap) {
      const metric = currentMetrics.find((metric) => metric.name === key);

      if (metric) {
        for (let index = 0; index < values.length; index++) {
          const value = values[index];
          const dateTime = matchedMetricsDataMap.get("Date and Time")?.[
            index
          ] as string;

          if (isNaN(Number(value)) || value === "") {
            continue;
          }

          const existingRecord = await findMetricRecord(
            metric.id,
            Dates.convertStringToDate(
              dateTime,
              Dates.getTimeZone()
            ).toISOString()
          );

          const recordData = {
            metricId: metric.id,
            timestamp: Dates.convertStringToDate(
              dateTime,
              Dates.getTimeZone()
            ).toISOString(),
            value: parseFloat(value as string),
          };

          if (existingRecord) {
            const editFormData: EditMetricRecordForm = {
              recordId: existingRecord.id,
              ...recordData,
            };
            dispatch(editMetricRecord(editFormData));
          } else {
            const formData: AddMetricRecordForm = recordData;
            dispatch(addMetricRecord(formData));
          }
        }
      }
    }

    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={t("modals.importDataMappingModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
      style={{ width: "100%" }}>
      <List
        size="small"
        bordered
        dataSource={matchingMetrics}
        renderItem={(item) => <List.Item>{item}</List.Item>}
        style={{ marginBottom: "20px" }}
      />
      <Text>
        {t("modals.importDataMappingModal.text", {
          datasetName: currentDataset?.name,
        })}
      </Text>
    </Modal>
  );
};

export default ImportDataConfirmModal;
