import { axios } from "@api/index";
import { AITemplateForm, Templateset } from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const TEMPLATESET_ENDPOINT = `${BASE_PATH}/templateset`;
const AI_TEMPLATESET_ENDPOINT = `${BASE_PATH}/template/openai`;

export const fetchTemplatesets = createAsyncThunk(
  "templatesets/fetchTemplatesets",
  async () => {
    const response = await axios.get<Templateset[]>(`${TEMPLATESET_ENDPOINT}`);
    return response.data;
  }
);

export const fetchAITemplateset = createAsyncThunk(
  "templatesets/fetchAITemplateset",
  async (data: AITemplateForm) => {
    const response = await axios.post<Templateset>(
      `${AI_TEMPLATESET_ENDPOINT}?language=${data.language}`,
      { prompt: data.prompt, }
    );
    return response.data;
  }
);
