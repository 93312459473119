import {
  AnalyticData,
  ApiResponseState,
  Dashboard,
  Kpi, RequestStatus, Visual
} from "@common/index";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  deleteVisualFromDashboard,
  editDashboard,
  editVisualFromDashboard,
  fetchAllVisualRecords,
} from "@store/slices/DashboardContent/thunks";
import {
  addUserToDashboard,
  addVisualToDashboard,
  deleteUserFromDashboard,
  editUserFromDashboard,
  fetchKpis,
  fetchVisualsOfDashboard,
} from "./thunks";

export const kpisInitialState: ApiResponseState<Kpi[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

export const visualsInitialState: ApiResponseState<Visual[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

export const visualRecordsInitialState: ApiResponseState<AnalyticData[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

type DashboardContent = {
  selectedDashboardId: string | null;
  dashboardRecords: Record<string, {
    selectedDashboard: Dashboard | null;
    selectedDashboardStatus: RequestStatus;
    kpis: ApiResponseState<Kpi[]>;
    visuals: ApiResponseState<Visual[]>;
    visualRecords: ApiResponseState<AnalyticData[]>;
  }>
}
const initialState: DashboardContent = {selectedDashboardId: null, dashboardRecords: {}};

export const dashboardContentSlice = createSlice({
  name: "dashboardContent",
  initialState,
  reducers: {
    setCurrentDashboard: (state, action: PayloadAction<Dashboard>) => {
        state.selectedDashboardId = action.payload.id;
        if (state.dashboardRecords[action.payload.id]) {
          state.dashboardRecords[action.payload.id].selectedDashboard = action.payload;
        } else {
            state.dashboardRecords[action.payload.id] = {
                selectedDashboard: action.payload,
                selectedDashboardStatus: RequestStatus.FULFILLED,
                kpis: kpisInitialState,
                visuals: visualsInitialState,
                visualRecords: visualRecordsInitialState,
            };
        }

    },
    reset: () => initialState,
    resetVisuals: (state, action) => {
      if (state.dashboardRecords[action.payload]) {
        state.dashboardRecords[action.payload].visuals = visualsInitialState;
      }
    },
    resetVisualRecords: (state, action) => {
      if (state.dashboardRecords[action.payload]) {
        state.dashboardRecords[action.payload].visualRecords = visualRecordsInitialState;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.PENDING;
        }
      })
      .addCase(editDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].selectedDashboard = action.payload;
        }
      })
      .addCase(editDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.REJECTED;
        }
      })
      .addCase(addUserToDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.PENDING;
        }
      })
      .addCase(addUserToDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].selectedDashboard = action.payload;
        }
      })
      .addCase(addUserToDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.REJECTED;
        }
      })
      .addCase(deleteUserFromDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.PENDING;
        }
      })
      .addCase(deleteUserFromDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].selectedDashboard = action.payload;
        }
      })
      .addCase(deleteUserFromDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.REJECTED;
        }
      })
      .addCase(editUserFromDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.PENDING;
        }
      })
      .addCase(editUserFromDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].selectedDashboard = action.payload;
        }
      })
      .addCase(editUserFromDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].selectedDashboardStatus = RequestStatus.REJECTED;
        }
      })
      .addCase(fetchKpis.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].kpis.status = RequestStatus.PENDING;
        }
      })
      .addCase(fetchKpis.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].kpis.status = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].kpis.data = action.payload;
        }
      })
      .addCase(fetchKpis.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].kpis.status = RequestStatus.REJECTED;
        }
      })
      .addCase(addVisualToDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.PENDING;
        }
      })
      .addCase(addVisualToDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].visuals.data.push(action.payload);
        }
      })
      .addCase(addVisualToDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.REJECTED;
        }
      })
      .addCase(deleteVisualFromDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.PENDING;
        }
      })
      .addCase(editVisualFromDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.PENDING;
        }
      })
      .addCase(editVisualFromDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].visuals.data = state.dashboardRecords[state.selectedDashboardId].visuals.data.map((visual) => {
            if (visual.id === action.payload.id) {
              return action.payload;
            }
            return visual;
          });
        }
      })
      .addCase(editVisualFromDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.REJECTED;
        }
      })
      .addCase(deleteVisualFromDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].visuals.data = state.dashboardRecords[state.selectedDashboardId].visuals.data.filter(
            (visual) => visual.id !== action.payload
          );
        }
      })
      .addCase(deleteVisualFromDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.REJECTED;
        }
      })
      .addCase(fetchVisualsOfDashboard.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.PENDING;
        }
      })
      .addCase(fetchVisualsOfDashboard.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].visuals.data = action.payload;
        }

      })
      .addCase(fetchVisualsOfDashboard.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visuals.status = RequestStatus.REJECTED;
        }
      })
      .addCase(fetchAllVisualRecords.pending, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
            state.dashboardRecords[state.selectedDashboardId].visualRecords.status = RequestStatus.PENDING;
        }
      })
      .addCase(fetchAllVisualRecords.fulfilled, (state, action) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
          state.dashboardRecords[state.selectedDashboardId].visualRecords.status = RequestStatus.FULFILLED;
          state.dashboardRecords[state.selectedDashboardId].visualRecords.data = action.payload;
        }
      })
      .addCase(fetchAllVisualRecords.rejected, (state) => {
        if (state.selectedDashboardId && state.dashboardRecords[state.selectedDashboardId]){
            state.dashboardRecords[state.selectedDashboardId].visualRecords.status = RequestStatus.REJECTED;
        }
      });
  },
});

export default dashboardContentSlice.reducer;
export const {
  setCurrentDashboard,
  resetVisuals,
  resetVisualRecords,
  reset: resetDashboardContent,
} = dashboardContentSlice.actions;
