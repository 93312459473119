import {
  CreditCardOutlined,
  DollarOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { INavigationItem } from "@common/interfaces";

const PaymentNavigationData: INavigationItem[] = [
  {
    title: "pages.organizations.payments",
    key: "payments",
    url: "payments",
    icon: <DollarOutlined />,
    color: "success",
  },
  {
    title: "pages.organizations.paymentMethods",
    key: "payment-methods",
    url: "payment-methods",
    icon: <CreditCardOutlined />,
    color: "warning",
  },
  {
    title: "pages.organizations.billingProfiles",
    key: "billing-profiles",
    url: "billing-profiles",
    icon: <FileDoneOutlined />,
    color: "primary",
  },
];

export default PaymentNavigationData;
