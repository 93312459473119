import { Dashboard, DeleteUserFromDashboardForm, IModalBaseProps, User } from "@common/index";
import { Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { deleteUserFromDashboard } from "@store/slices/DashboardContent/thunks";
import { reflectDashboardEdit } from "@store/slices/Dashboards/slice";
import { findDashboardShareType } from "@utils/index";
import React from "react";
import { useTranslation } from "react-i18next";

interface IDeleteUserFromDashboardModalProps extends IModalBaseProps {
  user: User;
  dashboard: Dashboard;
}

const DeleteUserFromDashboardModal: React.FC<
  IDeleteUserFromDashboardModalProps
> = ({ user, dashboard, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    setIsOpen(false);

    const form: DeleteUserFromDashboardForm = {
      dashboard: dashboard,
      userId: user.id,
    };

    dispatch(deleteUserFromDashboard(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDashboardEdit({
            dashboard: res,
            dashboardType: findDashboardShareType(res, currentUser.id),
          })
        );

        NotificationController.success({
          message: t("notifications.success.deleteUserFromDashboard", {
            userName: user.name,
            dashboardName: dashboard.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.deleteUserFromDashboard", {
            userName: user.name,
            dashboardName: dashboard.name,
          }),
        });
      });
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteUserFromOrganizationModal.title", {
        userName: user.name,
        dashboardName: dashboard.name,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteUserFromDashboardModal;
