import React from "react";
import { useTranslation } from "react-i18next";
import { DropdownCollapse } from "../../../Header.style";
import { LanguagePicker } from "../../LanguagePicker";
import { ThemePicker } from "../../ThemePicker";
import * as S from "./SettingsOverlay.style";

const SettingsOverlay: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.SettingsOverlayMenu mode="inline" selectable={false}>
      <DropdownCollapse bordered={false} expandIconPosition="right" ghost>
        <DropdownCollapse.Panel
          header={t("header.settings.changeLanguage")}
          key="languagePicker"
        >
          <LanguagePicker />
        </DropdownCollapse.Panel>
        <DropdownCollapse.Panel
          header={t("header.settings.changeTheme")}
          key="themePicker"
        >
          <ThemePicker />
        </DropdownCollapse.Panel>
      </DropdownCollapse>
    </S.SettingsOverlayMenu>
  );
};

export default SettingsOverlay;
