import { useResponsive } from "@hooks/index";
import React from "react";
import { CollapsedHeader } from "./CollapsedHeader";
import { ExpandedHeader } from "./ExpandedHeader";

interface IHeaderProps {
  toggleSider: () => void;
  isSiderCollapsed: boolean;
}

const Header: React.FC<IHeaderProps> = ({ toggleSider, isSiderCollapsed }) => {
  const { mobileOnly } = useResponsive();

  return mobileOnly ? (
    <CollapsedHeader
      isSiderCollapsed={isSiderCollapsed}
      toggleSider={toggleSider}
    />
  ) : (
    <ExpandedHeader />
  );
};

export default Header;
