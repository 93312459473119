import { AddUserToDashboardForm, Dashboard, IModalBaseProps, Organization, User, UserOption } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addUserToDashboard } from "@store/slices/DashboardContent/thunks";
import { reflectDashboardEdit } from "@store/slices/Dashboards/slice";
import {
  createAutoCompleteOptionsFromArray,
  findDashboardShareType,
} from "@utils/index";
import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IAddUserToDashboardModalProps extends IModalBaseProps {
  dashboard: Dashboard;
}

const AddUserToDashboardModal: React.FC<IAddUserToDashboardModalProps> = ({
  dashboard,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();
  const [userOptions, setUserOptions] = useState<DefaultOptionType[]>([]);

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const onSubmitHandler = (formData: AddUserToDashboardForm) => {
    formData.user = JSON.parse(formData.user as string);

    const data: AddUserToDashboardForm = {
      user: formData.user,
      dashboard: dashboard,
      editable: formData.editable,
    };

    dispatch(addUserToDashboard(data))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDashboardEdit({
            dashboard: res,
            dashboardType: findDashboardShareType(res, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.addUserToDashboard", {
            userName: (data.user as User).name,
            dashboardName: data.dashboard.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addUserToDashboard", {
            userName: (data.user as User).name,
            dashboardName: data.dashboard.name,
          }),
        });
      })
      .finally(() => form.resetFields());
  };

  useEffect(() => {
    setUserOptions(
      createAutoCompleteOptionsFromArray(
        currentOrganization.users,
        (organizationUser) => organizationUser.user.id,
        (organizationUser) => organizationUser.user.name
      )
    );
  }, [currentOrganization.users]);

  const isUserCanBeAddedToDashboard = (user: User): boolean => {
    const found: UserOption | undefined = dashboard.users.find((opt) => {
      return (opt.user as User).id === user?.id;
    });

    return found ? false : true;
  };

  const initialValues = {
    user: undefined,
    editable: false,
  };

  return (
    <Modal
      title={t("modals.addUserToDashboardModal.title", {
        dashboardName: dashboard.name,
      })}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <BaseForm
        initialValues={initialValues}
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addUserToDashboardForm"
      >
        <BaseForm.Item name="user" label={t("common.name")}>
          <Select showSearch allowClear placeholder={t("common.user")}>
            {userOptions.map((option) => (
              <Select.Option
                key={option.value}
                value={JSON.stringify(option.selectedOption.user)}
                sear
                disabled={
                  !isUserCanBeAddedToDashboard(option.selectedOption.user)
                }
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </BaseForm.Item>
        <BaseForm.Item name="editable" label={t("common.editable")}>
          <Select placeholder={t("common.editable")}>
            <Select.Option value={false}>{t("common.no")}</Select.Option>
            <Select.Option value={true}>{t("common.yes")}</Select.Option>
          </Select>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddUserToDashboardModal;
