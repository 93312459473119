import styled, { css } from "styled-components";
import ReactECharts from "echarts-for-react";
import { Row } from "antd";

interface ITextBasedChartProps {
  $fontSize?: number;
}

export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const TextBasedChart = styled.div<ITextBasedChartProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  & p {
    margin-bottom: 0;
  }

  ${(props) =>
    props.$fontSize &&
    css`
      & p {
        font-size: ${props.$fontSize.toString()}px;
      }
    `}
`;
