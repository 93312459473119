import { DownOutlined } from "@ant-design/icons";
import { IDropdownMenuItemOption } from "@common/interfaces";
import { Organization } from "@common/types";
import { DropdownMenu } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { setCurrentOrganization } from "@store/slices/OrganizationContent/slice";
import { Row } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./OrganizationSelector.style";

const OrganizationSelector: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedOrganizations = useAppSelector(
    (state) => state.organizations.ownedOrganizations.data
  );
  const sharedOrganizations = useAppSelector(
    (state) => state.organizations.sharedOrganizations.data
  );

  const createOrganizationOptions = () => {
    const ownedOptions: IDropdownMenuItemOption[] = ownedOrganizations
      .slice(0, 2)
      .map((org) => ({
        key: org.id,
        title: org.name,
        disabled: false,
        onClick: () => {
          dispatch(setCurrentOrganization(org));
          NotificationController.success({
            message: t("notifications.success.selectOrganization", {
              organizationName: org.name,
            }),
          });
        },
      }));

    const sharedOptions: IDropdownMenuItemOption[] = sharedOrganizations
      .slice(0, 2)
      .map((org) => ({
        key: org.id,
        title: org.name,
        disabled: false,
        onClick: () => {
          dispatch(setCurrentOrganization(org));
          NotificationController.success({
            message: t("notifications.success.selectOrganization", {
              organizationName: org.name,
            }),
          });
        },
      }));

    return [...ownedOptions, ...sharedOptions];
  };

  const ORGANIZATION_OPTIONS: IDropdownMenuItemOption[] = [
    ...createOrganizationOptions(),
    {
      key: "organizationMainPage",
      title: t("sider.showAllOrganizations"),
      onClick: () => {
        navigate(APPLICATION_PATHS.ORGANIZATION_PATH);
      },
    },
  ];

  const renderButtonContent = useCallback(() => {
    return (
      <S.OrganizationSelectorButton
        type="text"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Row align="middle">
          <S.TextOverflowContainer>
            <S.OrganizationText>{currentOrganization?.name}</S.OrganizationText>
          </S.TextOverflowContainer>
          <S.ArrowContainer>
            <DownOutlined />
          </S.ArrowContainer>
        </Row>
      </S.OrganizationSelectorButton>
    );
  }, [currentOrganization]);

  return (
    <DropdownMenu
      options={ORGANIZATION_OPTIONS}
      buttonContent={renderButtonContent()}
    />
  );
};

export default OrganizationSelector;
