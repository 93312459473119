import { editOrganization } from "@app/store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@app/store/slices/Organizations/slice";
import {
  BillingProfile,
  CountryDetail,
  Currency,
  EditOrganizationForm,
  Obligation,
  ObligationItem,
  Organization,
  PaymentCard as PaymentCardType,
  RequestStatus,
  SavedCardPaymentForm as SavedCardPaymentFormType,
  Settlement,
  User,
} from "@common/index";
import { BaseForm } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import {
  fetchBillingProfiles,
  fetchPaymentCards,
  payWithSavedCard,
} from "@store/slices/Payments/thunks";
import {
  convertUserOptionArray,
  findOrganizationShareType,
  generateAsterix,
  getCurrency,
  getCurrencySymbol,
} from "@utils/index";
import { Button, Col, Row, Select, SelectProps } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentCard } from "../";
import * as S from "./SavedCardPaymentForm.style";

const SavedCardPaymentForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const { obligationId: currentObligationId } = useParams();

  const userAgent: CountryDetail | null = useAppSelector(
    (state) => state.userAgent.local.data
  );

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const obligations: Obligation[] = useAppSelector(
    (state) => state.payments.obligations.data
  );

  const currentObligation: Obligation | undefined = obligations.find(
    (obl) => obl.id === currentObligationId
  );

  const paymentCards: PaymentCardType[] = useAppSelector(
    (state) => state.payments.paymentCards.data
  );

  const paymentCardsStatus: RequestStatus = useAppSelector(
    (state) => state.payments.paymentCards.status
  );

  const billingProfiles: BillingProfile[] = useAppSelector(
    (state) => state.payments.billingProfiles.data
  );

  const billingProfilesStatus: RequestStatus = useAppSelector(
    (state) => state.payments.billingProfiles.status
  );

  const settlementsStatus: RequestStatus = useAppSelector(
    (state) => state.payments.settlements.status
  );

  const cardWatcher = BaseForm.useWatch("card", form);

  useEffect(() => {
    currentOrganization && dispatch(fetchPaymentCards(currentOrganization.id));
  }, [currentOrganization]);

  useEffect(() => {
    currentOrganization &&
      dispatch(fetchBillingProfiles(currentOrganization.id));
  }, [currentOrganization]);

  const getCreditCardNumber = (card: PaymentCardType): string => {
    const firstEightDigits = card.binNumber;
    const lastTwoDigits = card.lastFourDigits.slice(-2);

    return `${firstEightDigits}${generateAsterix(6)}${lastTwoDigits}`;
  };

  const checkAndModifyOrganizationDisabledStatus = (
    newSettlement: Settlement
  ) => {
    if (!currentOrganization.disabled) {
      return;
    }

    const modifiedObligations: Obligation[] = obligations.filter(
      (obg) => obg.id !== newSettlement.obligation.id
    );

    if (modifiedObligations.length < 2) {
      const editOrganizationForm: EditOrganizationForm = {
        id: currentOrganization.id,
        name: currentOrganization.name,
        users: convertUserOptionArray(currentOrganization.users),
        disabled: false,
        uid: (currentOrganization.user as User).id,
      };

      dispatch(editOrganization(editOrganizationForm))
        .unwrap()
        .then((updatedOrg) => {
          dispatch(
            reflectOrganizationEdit({
              organization: updatedOrg,
              organizationType: findOrganizationShareType(
                updatedOrg,
                currentUser.id
              ),
            })
          );
        });
    }
  };

  const onSubmitHandler = (formData: SavedCardPaymentFormType) => {
    if (!currentObligation) {
      return;
    }

    formData.tenantId = currentOrganization.id;
    formData.obligationId = currentObligation!.id;
    formData.card = JSON.parse(formData.card as string);

    formData.items = currentObligation.items.map((obgItem) => ({
      name: obgItem.type,
      price: getAmount(obgItem),
    }));

    formData.currency = getCurrency(userAgent);
    formData.price = Number(getTotalAmount());
    formData.paidPrice = Number(getTotalAmount());
    formData.walletPrice = 0;
    formData.installment = 1;

    dispatch(payWithSavedCard(formData))
      .unwrap()
      .then((settlement: Settlement) => {
        NotificationController.success({
          message: t("notifications.success.pay"),
        });

        const successNavigationPath = APPLICATION_PATHS.PAYMENTS_PATH.replace(
          ":organizationId",
          currentOrganization.id
        );

        checkAndModifyOrganizationDisabledStatus(settlement);
        navigate(successNavigationPath);
      })
      .catch(() => {
        NotificationController.error({
          message: t("notifications.error.pay"),
        });
      });
  };

  const SAVED_CARD_OPTIONS: SelectProps["options"] = paymentCards.map(
    (card) => ({
      label: getCreditCardNumber(card),
      value: JSON.stringify(card),
    })
  );

  const BILLING_PROFILE_OPTIONS: SelectProps["options"] = billingProfiles.map(
    (profile) => ({
      label: profile.title,
      value: profile.id,
    })
  );

  const getTotalAmount = () => {
    if (!currentObligation) {
      return 0;
    }

    const totalAmount = currentObligation.items.reduce((acc, curr) => {
      let currentValueInLocalCurrency = acc;

      switch (userAgent?.currencyCode) {
        case Currency.TRY:
          return (currentValueInLocalCurrency += curr.fee.tr);
        case Currency.EUR:
          return (currentValueInLocalCurrency += curr.fee.eu);
        case Currency.USD:
        default:
          return (currentValueInLocalCurrency += curr.fee.us);
      }
    }, 0);

    return totalAmount.toFixed(2);
  };

  const getAmount = (obligationItem: ObligationItem) => {
    let amount: number = 0;

    switch (userAgent?.currencyCode) {
      case Currency.TRY:
        return (amount += obligationItem.fee.tr);
      case Currency.EUR:
        return (amount += obligationItem.fee.eu);
      case Currency.USD:
      default:
        return (amount += obligationItem.fee.us);
    }
  };

  return (
    <BaseForm form={form} onFinish={onSubmitHandler}>
      <Row gutter={[24, 24]}>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 12, order: 0 }}
          xl={{ span: 14, order: 0 }}
          xxl={{ span: 16, order: 0 }}
        >
          <BaseForm.Item
            name="billingProfileId"
            label={t("pages.organizations.billingProfile")}
          >
            <Select
              options={BILLING_PROFILE_OPTIONS}
              loading={billingProfilesStatus === RequestStatus.PENDING}
              placeholder={t("pages.organizations.billingProfile")}
            />
          </BaseForm.Item>

          <BaseForm.Item name="card" label={t("pages.organizations.cards")}>
            <Select
              options={SAVED_CARD_OPTIONS}
              loading={paymentCardsStatus === RequestStatus.PENDING}
              placeholder={t("pages.organizations.cards")}
            />
          </BaseForm.Item>

          <S.ButtonContainer>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={settlementsStatus === RequestStatus.PENDING}
            >
              {`${t("pages.organizations.pay")} ${getCurrencySymbol(
                userAgent
              )} ${getTotalAmount()}`}
            </Button>
          </S.ButtonContainer>
        </Col>

        <Col
          xs={{ span: 24, order: 0 }}
          sm={{ span: 24, order: 0 }}
          md={{ span: 24, order: 0 }}
          lg={{ span: 12, order: 1 }}
          xl={{ span: 10, order: 1 }}
          xxl={{ span: 8, order: 1 }}
        >
          <S.FullHeightRow justify="center" align="middle">
            <PaymentCard
              showcase={true}
              cardHolderName={`${generateAsterix(7)}/${generateAsterix(5)}`}
              cardNumber={
                cardWatcher
                  ? getCreditCardNumber(
                      JSON.parse(cardWatcher) as PaymentCardType
                    )
                  : generateAsterix(16)
              }
              expireDate={`${generateAsterix(2)}/${generateAsterix(2)}`}
            />
          </S.FullHeightRow>
        </Col>
      </Row>
    </BaseForm>
  );
};

export default SavedCardPaymentForm;
