import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@common/enums";
import {
  ApiResponseState,
  Dataset,
  Kpi,
  Metric,
  MetricRecord,
  MetricRecordMap,
} from "@common/types";
import {
  editDataset,
  fetchKpis,
  fetchMetrics,
  addMetric,
  deleteMetric,
  editMetric,
  addKpi,
  deleteKpi,
  editKpi,
  addUserToDataset,
  editUserFromDataset,
  deleteUserFromDataset,
  fetchMetricRecordsOfDataset,
  addMetricRecord,
  deleteMetricRecord,
  editMetricRecord,
} from "./thunks";

const metricsInitialState: ApiResponseState<Metric[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const kpisInitialState: ApiResponseState<Kpi[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const metricRecordsInitialState: ApiResponseState<MetricRecordMap> = {
  status: RequestStatus.IDLE,
  data: {},
  error: null,
};

interface IInitialState {
  currentDataset: Dataset | null;
  currentDatasetStatus: RequestStatus;
  metrics: ApiResponseState<Metric[]>;
  kpis: ApiResponseState<Kpi[]>;
  metricRecords: ApiResponseState<MetricRecordMap>;
}

const initialState: IInitialState = {
  currentDataset: null,
  currentDatasetStatus: RequestStatus.IDLE,
  metrics: metricsInitialState,
  kpis: kpisInitialState,
  metricRecords: metricRecordsInitialState,
};

export const datasetContentSlice = createSlice({
  name: "datasetContent",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setCurrentDataset(state, action) {
      state.currentDataset = action.payload;
    },
    setCurrentDatasetStatus(state, action) {
      state.currentDatasetStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMetrics.pending, (state) => {
        state.metrics.status = RequestStatus.PENDING;
      })
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.metrics.status = RequestStatus.FULFILLED;
        state.metrics.data = action.payload;
      })
      .addCase(fetchMetrics.rejected, (state) => {
        state.metrics.status = RequestStatus.REJECTED;
      })
      .addCase(fetchKpis.pending, (state) => {
        state.kpis.status = RequestStatus.PENDING;
      })
      .addCase(fetchKpis.fulfilled, (state, action) => {
        state.kpis.status = RequestStatus.FULFILLED;
        state.kpis.data = action.payload;
      })
      .addCase(fetchKpis.rejected, (state) => {
        state.kpis.status = RequestStatus.REJECTED;
      })
      .addCase(editDataset.pending, (state) => {
        state.currentDatasetStatus = RequestStatus.PENDING;
      })
      .addCase(editDataset.fulfilled, (state, action) => {
        state.currentDatasetStatus = RequestStatus.FULFILLED;
        state.currentDataset = action.payload;
      })
      .addCase(editDataset.rejected, (state) => {
        state.currentDatasetStatus = RequestStatus.REJECTED;
      })
      .addCase(addMetric.pending, (state) => {
        state.metrics.status = RequestStatus.PENDING;
      })
      .addCase(addMetric.fulfilled, (state, action) => {
        state.metrics.status = RequestStatus.FULFILLED;
        state.metrics.data.push(action.payload);
      })
      .addCase(addMetric.rejected, (state) => {
        state.metrics.status = RequestStatus.REJECTED;
      })
      .addCase(editMetric.pending, (state) => {
        state.metrics.status = RequestStatus.PENDING;
      })
      .addCase(editMetric.fulfilled, (state, action) => {
        state.metrics.status = RequestStatus.FULFILLED;
        const metricIndex = state.metrics.data.findIndex(
          (metric) => metric.id === action.payload.id
        );

        if (metricIndex >= 0) {
          state.metrics.data[metricIndex] = action.payload;
        }
      })
      .addCase(editMetric.rejected, (state) => {
        state.metrics.status = RequestStatus.REJECTED;
      })
      .addCase(deleteMetric.pending, (state) => {
        state.metrics.status = RequestStatus.PENDING;
      })
      .addCase(deleteMetric.fulfilled, (state, action) => {
        state.metrics.status = RequestStatus.FULFILLED;

        const deletedMetricId: string = action.payload.id;
        state.metrics.data = state.metrics.data.filter(
          (metric) => metric.id !== deletedMetricId
        );
      })
      .addCase(deleteMetric.rejected, (state) => {
        state.metrics.status = RequestStatus.REJECTED;
      })
      .addCase(addKpi.pending, (state) => {
        state.kpis.status = RequestStatus.PENDING;
      })
      .addCase(addKpi.fulfilled, (state, action) => {
        state.kpis.status = RequestStatus.FULFILLED;
        state.kpis.data.push(action.payload);
      })
      .addCase(addKpi.rejected, (state) => {
        state.kpis.status = RequestStatus.REJECTED;
      })
      .addCase(editKpi.pending, (state) => {
        state.kpis.status = RequestStatus.PENDING;
      })
      .addCase(editKpi.fulfilled, (state, action) => {
        state.kpis.status = RequestStatus.FULFILLED;
        const kpiIndex = state.kpis.data.findIndex(
          (kpi) => kpi.id === action.payload.id
        );

        if (kpiIndex >= 0) {
          state.kpis.data[kpiIndex] = action.payload;
        }
      })
      .addCase(editKpi.rejected, (state) => {
        state.kpis.status = RequestStatus.REJECTED;
      })
      .addCase(deleteKpi.pending, (state) => {
        state.kpis.status = RequestStatus.PENDING;
      })
      .addCase(deleteKpi.fulfilled, (state, action) => {
        state.kpis.status = RequestStatus.FULFILLED;

        const deletedKpiId: string = action.payload.id;
        state.kpis.data = state.kpis.data.filter(
          (kpi) => kpi.id !== deletedKpiId
        );
      })
      .addCase(deleteKpi.rejected, (state) => {
        state.kpis.status = RequestStatus.REJECTED;
      })
      .addCase(addUserToDataset.pending, (state) => {
        state.currentDatasetStatus = RequestStatus.PENDING;
      })
      .addCase(addUserToDataset.fulfilled, (state, action) => {
        state.currentDatasetStatus = RequestStatus.FULFILLED;
        state.currentDataset = action.payload;
      })
      .addCase(addUserToDataset.rejected, (state) => {
        state.currentDatasetStatus = RequestStatus.REJECTED;
      })
      .addCase(editUserFromDataset.pending, (state) => {
        state.currentDatasetStatus = RequestStatus.PENDING;
      })
      .addCase(editUserFromDataset.fulfilled, (state, action) => {
        state.currentDatasetStatus = RequestStatus.FULFILLED;
        state.currentDataset = action.payload;
      })
      .addCase(editUserFromDataset.rejected, (state) => {
        state.currentDatasetStatus = RequestStatus.REJECTED;
      })
      .addCase(deleteUserFromDataset.pending, (state) => {
        state.currentDatasetStatus = RequestStatus.PENDING;
      })
      .addCase(deleteUserFromDataset.fulfilled, (state, action) => {
        state.currentDatasetStatus = RequestStatus.FULFILLED;
        state.currentDataset = action.payload;
      })
      .addCase(deleteUserFromDataset.rejected, (state) => {
        state.currentDatasetStatus = RequestStatus.REJECTED;
      })
      .addCase(fetchMetricRecordsOfDataset.pending, (state) => {
        state.metricRecords.status = RequestStatus.PENDING;
      })
      .addCase(fetchMetricRecordsOfDataset.fulfilled, (state, action) => {
        state.metricRecords.status = RequestStatus.FULFILLED;
        state.metricRecords.data = action.payload;
      })
      .addCase(fetchMetricRecordsOfDataset.rejected, (state) => {
        state.metricRecords.status = RequestStatus.REJECTED;
      })
      .addCase(addMetricRecord.pending, (state) => {
        state.metricRecords.status = RequestStatus.PENDING;
      })
      .addCase(addMetricRecord.fulfilled, (state, action) => {
        state.metricRecords.status = RequestStatus.FULFILLED;
        const { metricId, id, timestamp, value } = action.payload;
        const recordAdded: MetricRecord = {
          id,
          timestamp,
          value,
        };

        state.metricRecords.data[metricId].push(recordAdded);
      })
      .addCase(addMetricRecord.rejected, (state) => {
        state.metricRecords.status = RequestStatus.REJECTED;
      })
      .addCase(editMetricRecord.pending, (state) => {
        state.metricRecords.status = RequestStatus.PENDING;
      })
      .addCase(editMetricRecord.fulfilled, (state, action) => {
        state.metricRecords.status = RequestStatus.FULFILLED;

        const { id: recordId, metricId, timestamp, value } = action.payload;

        state.metricRecords.data[metricId] = state.metricRecords.data[
          metricId
        ].map((record) => {
          if (record.id === recordId) {
            return {
              id: recordId,
              value: value,
              timestamp: timestamp,
            };
          }
          return record;
        });
      })
      .addCase(editMetricRecord.rejected, (state) => {
        state.metricRecords.status = RequestStatus.REJECTED;
      })
      .addCase(deleteMetricRecord.pending, (state) => {
        state.metricRecords.status = RequestStatus.PENDING;
      })
      .addCase(deleteMetricRecord.fulfilled, (state, action) => {
        state.metricRecords.status = RequestStatus.FULFILLED;

        const { id: recordId, metricId } = action.payload;

        state.metricRecords.data[metricId] = state.metricRecords.data[
          metricId
        ].filter((record) => record.id !== recordId);
      })
      .addCase(deleteMetricRecord.rejected, (state) => {
        state.metricRecords.status = RequestStatus.REJECTED;
      });
  },
});

export const {
  reset: resetDatasetContent,
  setCurrentDataset,
  setCurrentDatasetStatus,
} = datasetContentSlice.actions;
export default datasetContentSlice.reducer;
