import { EditableDataTable } from "@app/components/common";
import { ColumnTypes } from "@app/components/common/EditableDataTable/EditableDataTable";
import { Dates } from "@app/services";
import { themeObject } from "@app/styles/themes/ThemeVariables";
import {
  ApiRangeFilter,
  AppDate,
  Dataset,
  Metric,
  MetricRecord,
  MetricRecordDataTableRecord,
  MetricRecordDataTableValue,
  MetricRecordMap,
  MetricRecordsFilter,
  RequestStatus,
  ThemeType,
} from "@common/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { fetchMetricRecordsOfDataset } from "@store/slices/DatasetContent/thunks";
import { Space } from "antd";
import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as S from "./EditableMetricRecordsTablev2.style";

interface IEditableMetricRecordsTableProps {
  dataset: Dataset;
  filter: MetricRecordsFilter;
  setIsMetricRecordCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMetricRecordValue: MetricRecordDataTableValue | undefined;
  selectMetricRecordValue: React.Dispatch<
    React.SetStateAction<MetricRecordDataTableValue | undefined>
  >;
}

const EMPTY_BUTTON_TEXT = " ";

const EditableMetricRecordsTablev2: React.FC<
  IEditableMetricRecordsTableProps
> = ({
  dataset,
  filter: timeFilter,
  selectMetricRecordValue,
  selectedMetricRecordValue,
  setIsMetricRecordCardOpen,
}) => {
  const dispatch = useAppDispatch();

  const theme: ThemeType = useAppSelector((state) => state.theme.data);

  const currentMetrics: Metric[] = useAppSelector(
    (state) => state.datasetContent.metrics.data
  );

  const currentMetricsStatus: RequestStatus = useAppSelector(
    (state) => state.datasetContent.metricRecords.status
  );

  const currentMetricRecords: MetricRecordMap = useAppSelector(
    (state) => state.datasetContent.metricRecords.data
  );

  const currentMetricRecordsStatus: RequestStatus = useAppSelector(
    (state) => state.datasetContent.metricRecords.status
  );

  useEffect(() => {
    const rangeFilter: ApiRangeFilter = {
      filter: {
        time: {
          begin: timeFilter.startDate.toISOString(),
          end: timeFilter.endDate.toISOString(),
        },
      },
    };

    const currentMetricIds: string[] = currentMetrics.map(
      (metric) => metric.id
    );

    dispatch(
      fetchMetricRecordsOfDataset({
        metricIds: currentMetricIds,
        filter: rangeFilter,
      })
    );
  }, [timeFilter, currentMetrics]);

  const findMetricNameById = (metricId: string) => {
    const foundMetric: Metric | undefined = currentMetrics.find(
      (metric) => metric.id === metricId
    );
    return foundMetric;
  };

  const createWeeklyColumns = useCallback(() => {
    const { startDate, endDate } = timeFilter!;
    const columnDates: AppDate[] = Dates.getDaysArrayInRange(
      startDate,
      endDate
    );

    const headers: (ColumnTypes[number] & {
      editable?: boolean;
      dataIndex: string;
    })[] = columnDates.map((date) => {
      const column: ColumnTypes[number] & {
        editable?: boolean;
        dataIndex: string;
      } = {
        title: date.format("ddd, DD"),
        dataIndex: date.format("X"),
        editable: false,
        width: "11%",
        render: (value, record) => {
          const isSelected: boolean =
            value?.metric?.id === selectedMetricRecordValue?.metric?.id &&
            value?.date?.isSame(selectedMetricRecordValue?.date, "day");

          return (
            <S.RecordInspectionButton
              type={isSelected ? "primary" : "text"}
              onClick={() => {
                setIsMetricRecordCardOpen(true);
                selectMetricRecordValue(value as MetricRecordDataTableValue);
              }}>
              {value?.metricRecords?.length > 0 ? (
                <Space>
                  <S.StatusPoint $color={themeObject[theme].successColor} />
                  {value?.metricRecords.length}
                </Space>
              ) : (
                EMPTY_BUTTON_TEXT
              )}
            </S.RecordInspectionButton>
          );
        },
      };

      return column;
    });

    const metricNameHeader: ColumnTypes[number] & {
      editable?: boolean;
      dataIndex: string;
    } = {
      title: "Metrics",
      dataIndex: "metric",
      fixed: "left",
      editable: false,
      width: "23%",
    };

    return [metricNameHeader, ...headers];
  }, [timeFilter, selectedMetricRecordValue]);

  const convertWeeklyDataDictToDataArray = (dataDict: any) => {
    const data: MetricRecordDataTableRecord[] = [];

    Object.keys(dataDict).forEach((metricId) => {
      const datum: MetricRecordDataTableRecord = {
        key: metricId,
        metricId: metricId,
        metric: findMetricNameById(metricId)?.name,
        ...dataDict[metricId],
      };
      data.push(datum);
    });

    return data;
  };

  const createWeeklyData = useCallback(() => {
    const columnDates: AppDate[] = Dates.getDaysArrayInRange(
      timeFilter.startDate,
      timeFilter.endDate
    );

    const weeklyDataDict: any = {};

    Object.keys(currentMetricRecords).forEach((metricId) => {
      weeklyDataDict[metricId] = {};
    });

    Object.keys(currentMetricRecords).forEach((metricId) => {
      const records = currentMetricRecords[metricId];

      columnDates.forEach((columnDate) => {
        const matchedDayRecords: MetricRecord[] = records.filter(
          (record: MetricRecord) =>
            Dates.isSameDay(
              Dates.convertStringToDate(record.timestamp),
              columnDate
            )
        );

        if (matchedDayRecords) {
          weeklyDataDict[metricId] = {
            ...weeklyDataDict[metricId],
            [columnDate.format("X")]: {
              date: columnDate,
              metric: currentMetrics.find((m) => m.id === metricId),
              metricRecords: matchedDayRecords,
            },
          };
        }
      });
    });

    return convertWeeklyDataDictToDataArray(weeklyDataDict);
  }, [
    timeFilter,
    currentMetrics,
    currentMetricRecords,
    currentMetricRecordsStatus,
  ]);

  const tableLoadingStatus =
    currentMetricsStatus === RequestStatus.PENDING ||
    currentMetricRecordsStatus === RequestStatus.PENDING;

  return (
    <EditableDataTable
      data={createWeeklyData()}
      columns={createWeeklyColumns()}
      onSave={() => {}}
      isLoading={tableLoadingStatus}
    />
  );
};

export default EditableMetricRecordsTablev2;
