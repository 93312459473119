import { Dataset, DatasetDefinitionItem, IDropdownMenuItemOption, IUserWithRole, RequestStatus, User, UserOption, UserRole } from "@common/index";
import { DropdownMenu, SectionHeader } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import {
  DeleteDatasetModal,
  EditDatasetModal,
} from "@pages/DatasetPages/modals";
import { APPLICATION_PATHS } from "@routes/index";
import { setCurrentDataset } from "@store/slices/DatasetContent/slice";
import {
  fetchOwnedDatasets,
  fetchSharedDatasets,
} from "@store/slices/Datasets/thunks";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DatasetDefinitionSection } from "../components";
import { ManageDatasetDrawer } from "../drawers";
import * as S from "./DatasetDetailPage.style";

const DatasetDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { datasetId } = useParams();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const sharedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.sharedDatasets.data
  );

  const ownedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.ownedDatasets.status
  );

  const sharedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.sharedDatasets.status
  );

  const currentDatasetInStore: Dataset | null = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  const [isEditDatasetModalOpen, setIsEditDatasetModalOpen] =
    useState<boolean>(false);

  const [isDeleteDatasetModalOpen, setIsDeleteDatasetModalOpen] =
    useState<boolean>(false);

  const [isManageDatasetDrawerOpen, setIsManageDatasetDrawerOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (ownedDatasetsStatus === RequestStatus.IDLE) {
      currentUser.id && dispatch(fetchOwnedDatasets(currentUser.id));
    }
  }, [currentUser, ownedDatasetsStatus]);

  useEffect(() => {
    if (sharedDatasetsStatus === RequestStatus.IDLE) {
      currentUser.id && dispatch(fetchSharedDatasets(currentUser.id));
    }
  }, [currentUser, sharedDatasetsStatus]);

  const getCurrentDatasetIfNotInStore = (): Dataset | undefined => {
    let matchedDataset = ownedDatasets.find((od) => od.id === datasetId);

    matchedDataset = matchedDataset
      ? matchedDataset
      : sharedDatasets.find((od) => od.id === datasetId);

    dispatch(setCurrentDataset(matchedDataset));
    return matchedDataset;
  };

  const currentDataset: Dataset | undefined = currentDatasetInStore
    ? currentDatasetInStore
    : getCurrentDatasetIfNotInStore();

  const getCurrentUserWithRole = (): IUserWithRole | undefined => {
    if (!currentDataset) {
      return undefined;
    }

    if ((currentDataset.user as User).id === currentUser.id) {
      return {
        user: currentUser,
        role: UserRole.ADMIN,
      };
    }

    const correspondingUserOption: UserOption | undefined =
      currentDataset.users.find(
        (userOpt) => (userOpt.user as User).id === currentUser.id
      );

    return correspondingUserOption
      ? {
          user: correspondingUserOption?.user as User,
          role: correspondingUserOption?.editable
            ? UserRole.EDITOR
            : UserRole.BASIC,
        }
      : undefined;
  };

  const currentUserWithRole: IUserWithRole | undefined =
    getCurrentUserWithRole();

  const DATASET_DETAIL_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "editDataset",
      title: t("common.edit"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole?.role)
        : true,
      onClick: () => setIsEditDatasetModalOpen(true),
    },
    {
      key: "manageDataset",
      title: t("common.manage"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole?.role)
        : true,
      onClick: () => setIsManageDatasetDrawerOpen(true),
    },
    {
      key: "deleteDataset",
      title: t("common.delete"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole?.role)
        : true,
      onClick: () => setIsDeleteDatasetModalOpen(true),
    },
  ];

  return (
    <React.Fragment>
      {currentDataset && (
        <React.Fragment>
          <EditDatasetModal
            dataset={currentDataset}
            isOpen={isEditDatasetModalOpen}
            setIsOpen={setIsEditDatasetModalOpen}
          />
          <DeleteDatasetModal
            dataset={currentDataset}
            isOpen={isDeleteDatasetModalOpen}
            setIsOpen={setIsDeleteDatasetModalOpen}
          />
          <ManageDatasetDrawer
            isOpen={isManageDatasetDrawerOpen}
            setIsOpen={setIsManageDatasetDrawerOpen}
            dataset={currentDataset}
          />
        </React.Fragment>
      )}

      <SectionHeader
        customMargin="0 0 3rem 0"
        title={
          <S.SectionHeaderTitle>{currentDataset?.name}</S.SectionHeaderTitle>
        }
        subtitle={
          <S.SectionHeaderSubtitle>
            {currentDataset?.description}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={APPLICATION_PATHS.DATASET_PATH}
        backButtonVisible
      >
        <DropdownMenu options={DATASET_DETAIL_OPTIONS} />
      </SectionHeader>

      {currentDataset && (
        <Row gutter={[24, 24]} align="top">
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DatasetDefinitionSection
              dataset={currentDataset}
              title={t("common.metrics")}
              itemType={DatasetDefinitionItem.METRIC}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <DatasetDefinitionSection
              dataset={currentDataset}
              title={t("common.kpis")}
              itemType={DatasetDefinitionItem.KPI}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default DatasetDetailPage;
