import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { LAYOUT, media } from "@common/constants";

const MainHeader = styled(Layout.Header)`
  line-height: 1.5;

  @media only screen and ${media.md} {
    padding: ${LAYOUT.desktop.paddingVertical}
      ${LAYOUT.desktop.paddingHorizontal};
    height: ${LAYOUT.desktop.headerHeight};
  }
`;

export default MainHeader;
