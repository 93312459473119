import { IModalBaseProps } from "@common/index";
import { Modal, Text } from "@components/common";
import React from "react";
import { useTranslation } from "react-i18next";

interface ChangeLicenseModal extends IModalBaseProps {
  onOk: () => void;
}

const ChangeLicenseModal: React.FC<ChangeLicenseModal> = ({
  onOk,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();

  const onOkHandler = () => {
    setIsOpen(false);
    onOk();
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      title={t("modals.changeLicenseModal.title")}
    >
      <Text>{t("modals.changeLicenseModal.text")}</Text>
    </Modal>
  );
};

export default ChangeLicenseModal;
