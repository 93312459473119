import {
  changeLicenseOfOrganization,
  deleteMultipleUsersFromOrganization,
  fetchLicenseOfOrganization,
} from "@store/slices/OrganizationContent/thunks";
import { persistOrganization } from "@services/LocalStorageService/localStorage.service";

import { RequestStatus } from "@common/enums";
import { Organization } from "@common/types";
import { createSlice } from "@reduxjs/toolkit";
import {
  addUserToOrganization,
  deleteUserFromOrganization,
  editOrganization,
  editUserFromOrganization,
} from "./thunks";

const initialState = {
  currentOrganization: {
    id: undefined,
    user: undefined,
    name: undefined,
    users: undefined,
    license: undefined,
  } as unknown as Organization,
  status: RequestStatus.IDLE,
};

export const organizationContentSlice = createSlice({
  name: "organizationContent",
  initialState,
  reducers: {
    reset: () => initialState,
    setCurrentOrganization(state, action) {
      state.currentOrganization = action.payload;
      state.status = RequestStatus.FULFILLED;
      persistOrganization(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editOrganization.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(editOrganization.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.currentOrganization = action.payload;
      })
      .addCase(editOrganization.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      })
      .addCase(fetchLicenseOfOrganization.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(fetchLicenseOfOrganization.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.currentOrganization.license = action.payload;
      })
      .addCase(fetchLicenseOfOrganization.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      })
      .addCase(changeLicenseOfOrganization.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(changeLicenseOfOrganization.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.currentOrganization.license = action.payload;
      })
      .addCase(changeLicenseOfOrganization.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      })
      .addCase(addUserToOrganization.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(addUserToOrganization.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.currentOrganization = action.payload;
      })
      .addCase(addUserToOrganization.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      })
      .addCase(editUserFromOrganization.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(editUserFromOrganization.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.currentOrganization = action.payload;
      })
      .addCase(editUserFromOrganization.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      })
      .addCase(deleteUserFromOrganization.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(deleteUserFromOrganization.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.currentOrganization = action.payload;
      })
      .addCase(deleteUserFromOrganization.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      })
      .addCase(deleteMultipleUsersFromOrganization.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(
        deleteMultipleUsersFromOrganization.fulfilled,
        (state, action) => {
          state.status = RequestStatus.FULFILLED;
          state.currentOrganization = action.payload;
        }
      )
      .addCase(deleteMultipleUsersFromOrganization.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      });
  },
});

export const { reset: resetOrganizationContent, setCurrentOrganization } =
  organizationContentSlice.actions;
export default organizationContentSlice.reducer;
