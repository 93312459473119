import styled from "styled-components";
import { Typography } from "antd";
import { media } from "@common/constants";

export const Text = styled(Typography.Title)`
  &.ant-typography {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 0;

    color: var(--text-main-color);

    @media only screen and ${media.md} {
      font-size: 1.5rem;
    }

    @media only screen and ${media.xxl} {
      font-size: 2rem;
    }
  }
`;
