import { DeleteUserFromOrganizationForm, IModalBaseProps, Organization, User } from "@common/index";
import { Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { deleteUserFromOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import { findOrganizationShareType } from "@utils/index";
import React from "react";
import { useTranslation } from "react-i18next";

interface IDeleteUserFromOrganizationModalProps extends IModalBaseProps {
  user: User;
  organization: Organization;
}

const DeleteUserFromOrganizationModal: React.FC<
  IDeleteUserFromOrganizationModalProps
> = ({ user, organization, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    const form: DeleteUserFromOrganizationForm = {
      user: user.id,
      organization: organization,
    };

    dispatch(deleteUserFromOrganization(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(
              organization,
              currentUser.id
            ),
          })
        );
        NotificationController.success({
          message: t("notifications.success.deleteUserFromOrganization", {
            userName: user.name,
            organizationName: organization.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.deleteUserFromOrganization", {
            userName: user.name,
            organizationName: organization.name,
          }),
        });
      });

    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteUserFromOrganizationModal.title", {
        userName: user.name,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteUserFromOrganizationModal;
