import { FONT_SIZE } from "@common/index";
import { Row } from "antd";
import styled from "styled-components";

export const LogoutText = styled.span`
  color: var(--text-light-color);
  font-size: ${FONT_SIZE.xs};
  text-decoration: underline;
  cursor: pointer;
`;

export const FooterContainer = styled(Row)`
  width: 100%;
  margin-top: 0.625rem;
  justify-content: center;
  align-items: center;
`;
