import { DayjsDatePicker } from "@components/common";
import { InputNumber, Space } from "antd";
import styled from "styled-components";

export const CustomInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const CustomDayjsDatePicker = styled(DayjsDatePicker)`
  width: 100%;
`;

export const CustomSpacer = styled(Space)`
  width: 100%;
  display: flex;
`;
