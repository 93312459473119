import { IUserWithRole, License, Organization, UserRole } from "@common/index";
import { BaseForm, Card, H1 } from "@components/common";
import { useAppSelector } from "@hooks/index";
import { Checkbox, Form, List, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DowngradeUserDeleteCard.style";

interface IDowngradeUserDeleteCardProps {
  title: string;
  userList: IUserWithRole[];
  selectedLicense: License;
  usersToDelete: IUserWithRole[];
  onUsersToDeleteChange: (usersToDelete: IUserWithRole[]) => void;
}

const DowngradeUserDeleteCard: React.FC<IDowngradeUserDeleteCardProps> = ({
  title,
  userList,
  selectedLicense,
  usersToDelete,
  onUsersToDeleteChange,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const isErrorText =
    currentOrganization.users.length + 1 - usersToDelete.length >
    selectedLicense.userCount;

  const handleCheckboxChange = (user: IUserWithRole) => {
    const index = usersToDelete.findIndex(
      (selectedUser) => selectedUser.user.id === user.user.id
    );

    if (index === -1) {
      onUsersToDeleteChange([...usersToDelete, user]);
      setSelectedRows([...selectedRows, user.user.id]);
    } else {
      onUsersToDeleteChange(
        usersToDelete.filter(
          (selectedUser) => selectedUser.user.id !== user.user.id
        )
      );
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== user.user.id)
      );
    }
  };

  const renderItem = (userWithRole: IUserWithRole) => {
    const isCheckboxDisabled = userWithRole.role === UserRole.ADMIN;
    const checkboxTooltip = isCheckboxDisabled
      ? t("pages.downgradeLicense.disabledCheckboxTooltip")
      : "";

    return (
      <S.UserListItem>
        <S.CheckboxWrapper>
          <Tooltip title={checkboxTooltip}>
            <Checkbox
              checked={
                usersToDelete.findIndex(
                  (selectedUser) =>
                    selectedUser.user.id === userWithRole.user.id
                ) !== -1
              }
              onChange={() => handleCheckboxChange(userWithRole)}
              disabled={isCheckboxDisabled}
            />
          </Tooltip>
        </S.CheckboxWrapper>
        <List.Item.Meta
          title={
            <S.TitleWrapper>
              <S.StyledTitle>{userWithRole.user.name}</S.StyledTitle>
              <S.Description>{userWithRole.user.email}</S.Description>
            </S.TitleWrapper>
          }
        />
      </S.UserListItem>
    );
  };

  return (
    <React.Fragment>
      <Card padding={[30, 30]}>
        <S.HeaderRow>
          <H1>{title}</H1>
          
          <S.CustomText isErrorText={isErrorText}>
            {`${currentOrganization.users.length + 1 - usersToDelete.length} / ${
              selectedLicense.userCount
            }`}
          </S.CustomText>
        </S.HeaderRow>

        <S.InfoText>
          {selectedRows.length}
          {t("pages.downgradeLicense.usersSelected")}
        </S.InfoText>

        <BaseForm form={form}>
          <List dataSource={userList} renderItem={renderItem} />
        </BaseForm>
      </Card>
    </React.Fragment>
  );
};

export default DowngradeUserDeleteCard;
