import React, { useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Dropdown } from "@components/common";
import { SettingsOverlay } from "../SettingsOverlay";
import { HeaderActionWrapper } from "../../../Header.style";
import { Button } from "antd";

const SettingsDropdown: React.FC = () => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <Dropdown
      overlay={<SettingsOverlay />}
      trigger={["click"]}
      onOpenChange={setIsOpened}
    >
      <HeaderActionWrapper>
        <Button type={isOpened ? "ghost" : "text"} icon={<SettingOutlined />} />
      </HeaderActionWrapper>
    </Dropdown>
  );
};

export default SettingsDropdown;
