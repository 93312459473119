import { IDropdownMenuItemOption, IUserWithRole, License, Organization, RequestStatus, User, UserOption, UserRole } from "@common/index";
import { DropdownMenu, H1, Loading, SectionHeader } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { ManageOrganizationDrawer } from "@pages/OrganizationPages/drawers";
import {
  DeleteOrganizationModal,
  EditOrganizationModal,
} from "@pages/OrganizationPages/modals";
import { APPLICATION_PATHS } from "@routes/index";
import { fetchLicenses } from "@store/slices/Licenses/thunks";
import { fetchLicenseOfOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import { findOrganizationShareType } from "@utils/index";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CurrentLicenseInfoCard } from "../components/CurrentLicenseInfoCard";
import * as S from "./OrganizationDetailPage.style";

const OrganizationDetailPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isManageUserDrawerOpen, setIsManageUserDrawerOpen] =
    useState<boolean>(false);

  const [isEditOrganizationModalOpen, setIsEditOrganizationModalOpen] =
    useState<boolean>(false);

  const [isDeleteOrganizationModalOpen, setIsDeleteOrganizationModalOpen] =
    useState<boolean>(false);

  const licenses: License[] = useAppSelector((state) => state.licenses.data);

  const licensesStatus = useAppSelector((state) => state.licenses.status);

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const currentOrganizationStatus: RequestStatus = useAppSelector(
    (state) => state.organizationContent.status
  );

  const getCurrentLicense = (): License | undefined => {
    if (currentOrganization.license) {
      const currentLicense = licenses.find(
        (license) => license.licenseType === currentOrganization.license?.type
      );

      return currentLicense;
    }
  };

  const getOrganizationOwner = (): IUserWithRole => {
    const ownerWithRole: IUserWithRole = {
      user: currentOrganization.user as User,
      role: UserRole.ADMIN,
    };

    return ownerWithRole;
  };

  const getCurrentUserIfNotOwner = (): IUserWithRole | undefined => {
    if (currentUser.id !== (currentOrganization.user as User).id) {
      const currentUserOptions: UserOption = currentOrganization.users.find(
        (userOpt) => userOpt
      ) as UserOption;

      const currentUserWithRole = {
        user: currentUser,
        role: currentUserOptions.editable ? UserRole.EDITOR : UserRole.BASIC,
      };

      return currentUserWithRole;
    }

    return undefined;
  };

  const getCurrentUserWithRole = () => {
    const currentUserWithRole: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    if (currentUserWithRole) {
      return currentUserWithRole;
    }

    return getOrganizationOwner();
  };

  useEffect(() => {
    if (!currentOrganization.license) {
      dispatch(fetchLicenseOfOrganization(currentOrganization.id))
        .unwrap()
        .then((res) => {
          const modifiedOrganization: Organization = {
            ...currentOrganization,
            license: res,
          };
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(
              modifiedOrganization,
              currentUser.id
            ),
          });
        })
        .catch((err) => {});
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (licensesStatus === RequestStatus.IDLE) {
      dispatch(fetchLicenses());
    }
  }, [licensesStatus]);

  const renderLoading = () => {
    return (
      <S.LoadingContainer>
        <Loading size="5rem" />
      </S.LoadingContainer>
    );
  };

  const currentUserWithRole: IUserWithRole = getCurrentUserWithRole();

  const ORGANIZATION_DROPDOWN_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "manageOrganization",
      title: t("pages.organizations.manageOrganization"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => setIsManageUserDrawerOpen(true),
    },
    {
      key: "editOrganization",
      title: t("pages.organizations.editOrganization"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () => setIsEditOrganizationModalOpen(true),
    },
    {
      key: "paymentAndBillingSettings",
      title: t("pages.organizations.paymentAndBillingSettings"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
        : true,
      onClick: () =>
        navigate(
          `${APPLICATION_PATHS.ORGANIZATION_PATH}/${currentOrganization.id}/payment-and-billing`
        ),
    },
    {
      key: "deleteOrganization",
      title: t("pages.organizations.deleteOrganization"),
      disabled: currentUserWithRole
        ? ![UserRole.ADMIN].includes(currentUserWithRole.role)
        : true,
      onClick: () => setIsDeleteOrganizationModalOpen(true),
    },
  ];

  return (
    <React.Fragment>
      <ManageOrganizationDrawer
        organization={currentOrganization}
        isOpen={isManageUserDrawerOpen}
        setIsOpen={setIsManageUserDrawerOpen}
      />

      <DeleteOrganizationModal
        organization={currentOrganization}
        isOpen={isDeleteOrganizationModalOpen}
        setIsOpen={setIsDeleteOrganizationModalOpen}
      />

      <EditOrganizationModal
        organization={currentOrganization}
        isOpen={isEditOrganizationModalOpen}
        setIsOpen={setIsEditOrganizationModalOpen}
      />

      <SectionHeader
        customMargin="0 0 1rem 0"
        title={<H1>{currentOrganization.name}</H1>}
        subtitle={
          <S.SectionHeaderSubtitle>
            {t("pages.organizations.manageOrganization")}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={APPLICATION_PATHS.ORGANIZATION_PATH}
        backButtonVisible
      >
        <DropdownMenu options={ORGANIZATION_DROPDOWN_OPTIONS} />
      </SectionHeader>

      {licensesStatus === RequestStatus.PENDING ||
      currentOrganizationStatus === RequestStatus.PENDING
        ? renderLoading()
        : getCurrentLicense() && (
            <CurrentLicenseInfoCard license={getCurrentLicense()!} />
          )}
    </React.Fragment>
  );
};

export default OrganizationDetailPage;
