import { InboxOutlined } from "@ant-design/icons";
import { UploadDataForm } from "@app/common/formTypes";
import { IModalBaseProps } from "@app/common/interfaces";
import { Dataset, User } from "@app/common/types";
import { BaseForm, BaseFormItem, Modal } from "@app/components/common";
import {
  fetchMetrics,
  uploadData,
} from "@app/store/slices/DatasetContent/thunks";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import * as CommonStyles from "@styles/CommonStyles";
import type { SelectProps, UploadProps } from "antd";
import { Input, Row, Select } from "antd";
import { RcFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImportDataConfirmModal } from "../ImportDataConfirmModal";
import * as S from "./ImportDataModal.style";

const ImportDataModal: React.FC<IModalBaseProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentDataset: Dataset | null = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  const [isUploadingData, setIsUploadingData] = useState<boolean>();
  const [matchingMetrics, setMatchingMetrics] = useState<string[]>([]);
  const [metricsFromFile, setMetricsFromFile] = useState<Map<any, any> | null>(
    null
  );
  const [isImportDataMappingModalOpen, setIsImportDataMappingModalOpen] =
    useState<boolean>(false);

  const dataSourceWatch = BaseForm.useWatch("dataSource", form);

  useEffect(() => {
    form?.setFieldValue("dataSource", "fromFile");
  }, [form]);

  const getMatchingMetrics = <K, V>(map: Map<K, V>, keysToMatch: K[]): K[] => {
    const matchedKeys: K[] = [];

    for (const key of keysToMatch) {
      if (map.has(key)) {
        matchedKeys.push(key);
      }
    }

    return matchedKeys;
  };

  const onOkHandler = async () => {
    setIsOpen(false);
    setIsImportDataMappingModalOpen(true);
  };

  const onCancelHandler = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const onUploadHandler = async (fileData: ArrayBuffer, file: RcFile) => {
    setIsUploadingData(true);

    const formData: UploadDataForm = {
      file: file,
      content: fileData,
      user: currentUser,
    };

    const metrics = currentDataset
      ? await dispatch(fetchMetrics(currentDataset.id)).unwrap()
      : [];

    const metricNames = Object.values(metrics).map((item) => item.name);

    await dispatch(uploadData(formData))
      .unwrap()
      .then(async (res) => {
        const resultMap = new Map(res);
        setMetricsFromFile(resultMap);

        const matchingMetrics = getMatchingMetrics(resultMap, metricNames);
        setMatchingMetrics(matchingMetrics as string[]);
        if (matchingMetrics.length > 0) {
          NotificationController.success({
            message: t("notifications.success.uploadFile"),
          });
        } else {
          NotificationController.error({
            message: t("notifications.error.noMetric"),
          });
        }
        setIsUploadingData(false);
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.uploadFile"),
        });
        setIsUploadingData(false);
      });
  };

  const renderFileUpload = () => {
    return (
      <Dragger maxCount={1} {...props}>
        <CommonStyles.VerticalSpacer size="middle">
          <Row justify="center">
            <S.IconContainer>
              <InboxOutlined />
            </S.IconContainer>
          </Row>
          <Row justify="center">
            <S.DragTitle>{t("modals.importDataModal.dragOrClick")}</S.DragTitle>
          </Row>
        </CommonStyles.VerticalSpacer>
      </Dragger>
    );
  };

  const renderURLUpload = () => {
    return (
      <BaseForm.Item name="url" label={t("modals.importDataModal.sourceURL")}>
        <Input />
      </BaseForm.Item>
    );
  };

  const DATASOURCE_OPTIONS: SelectProps["options"] = [
    {
      label: t("modals.importDataModal.fromFile"),
      value: "fromFile",
    },
    {
      label: t("modals.importDataModal.fromURL"),
      value: "fromURL",
    },
  ];

  const props: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload(file: RcFile) {
      const isExcelOrJSONorCSV =
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/json" ||
        file.type === "text/csv";

      // could need to add other csv MIME types
      if (!isExcelOrJSONorCSV) {
        NotificationController.error({
          message: t("notifications.error.importDataFileType"),
        });
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        NotificationController.error({
          message: t("notifications.error.importDataSize"),
        });
        return false;
      }

      const reader = new FileReader();

      reader.onload = async (e: ProgressEvent<FileReader>) => {
        const fileData = e.target?.result as ArrayBuffer;
        await onUploadHandler(fileData, file);
        return false;
      };

      reader.readAsArrayBuffer(file);
      return false;
    },
  };

  return (
    <React.Fragment>
      <ImportDataConfirmModal
        isOpen={isImportDataMappingModalOpen}
        setIsOpen={setIsImportDataMappingModalOpen}
        matchingMetrics={matchingMetrics}
        metricsFromFile={metricsFromFile}
      />

      <Modal
        title={t("modals.importDataModal.title")}
        open={isOpen}
        onOk={onOkHandler}
        okButtonProps={{
          loading: isUploadingData,
        }}
        onCancel={onCancelHandler}>
        <BaseForm form={form}>
          <BaseFormItem
            name="dataSource"
            label={t("modals.importDataModal.dataSource")}>
            <Select options={DATASOURCE_OPTIONS} />
          </BaseFormItem>

          {dataSourceWatch === "fromFile" && renderFileUpload()}
          {dataSourceWatch === "fromURL" && renderURLUpload()}
        </BaseForm>
      </Modal>
    </React.Fragment>
  );
};

export default ImportDataModal;
