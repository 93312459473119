import styled from "styled-components";
import { Typography } from "antd";
import { FONT_SIZE, media } from "@common/constants";

export const Title = styled(Typography.Title)`
  min-width: 150px;
  &.ant-typography {
    font-family: Neuropolitical;
    font-weight: 600;
    font-size: ${FONT_SIZE.xl};
    margin-bottom: 0;

    color: var(--white);

    @media only screen and ${media.xl} {
      font-weight: 700;
      font-size: ${FONT_SIZE.xxl};
    }
  }
`;
