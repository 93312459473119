import { createSlice, createAction, PrepareAction } from "@reduxjs/toolkit";
import { ThemeType } from "@common/types";
import { fetchTheme, persistTheme } from "@services/LocalStorageService";

interface ThemeState {
  data: ThemeType;
}

export const defaultTheme = fetchTheme() || "light";

persistTheme(defaultTheme);

const initialState: ThemeState = {
  data: defaultTheme,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.data = action.payload;
      persistTheme(action.payload);
    },
  },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;
