import { axios } from "@api/index";
import { AddOrganizationForm, Organization } from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";


const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const ORGANIZATION_ENDPOINT = `${BASE_PATH}/tenant`;
const SHARED_ORGANIZATION_ENDPOINT = `${BASE_PATH}/shared/tenant`;
const LICENSE_ENDPOINT = `${BASE_PATH}/license`;

export const fetchOwnedOrganizationsOfUser = createAsyncThunk(
  "organizations/fetchOrganizationsOfUser",
  async (userId: string) => {
    const response = await axios.get<Organization[]>(
      `${ORGANIZATION_ENDPOINT}?uid=${userId}`
    );

    return response.data;
  }
);

export const fetchSharedOrganizationsOfUser = createAsyncThunk(
  "organizations/fetchSharedOrganizationsOfUser",
  async (userId: string) => {
    const response = await axios.get<Organization[]>(
      `${SHARED_ORGANIZATION_ENDPOINT}/${userId}`
    );
    return response.data;
  }
);

export const addOrganization = createAsyncThunk(
  "organizations/addOrganization",
  async (data: AddOrganizationForm) => {
    const { name, uid, users } = data;
    const response = await axios.post<Organization>(
      `${ORGANIZATION_ENDPOINT}`,
      { name, uid, users }
    );

    return response.data;
  }
);
