import {
  AppDate, EditPaymentCardForm, IModalBaseProps, Organization,
  PaymentCard as PaymentCardType
} from "@common/index";
import { BaseForm } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editPaymentCard } from "@store/slices/Payments/thunks";
import { Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentCard } from "../../components";
import * as S from "./EditPaymentCardModal.style";

interface IEditPaymentCardModalProps extends IModalBaseProps {
  card: PaymentCardType;
}

const EditPaymentCardModal: React.FC<IEditPaymentCardModalProps> = ({
  card,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const [fieldValues, setFieldValues] = useState<{
    cardNumber: string;
    expireDate: AppDate | undefined;
  }>();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  useEffect(() => {
    const formValues = {
      cardNumber: getCardNumber(),
      expireDate: undefined,
    };

    form?.setFieldsValue(formValues);
  }, [card]);

  const getCardNumber = (): string => {
    const firstEightDigits = card.binNumber;
    const lastTwoDigits = card.lastFourDigits.slice(-2);
    const middleDigits = "******";

    return `${firstEightDigits}${middleDigits}${lastTwoDigits}`;
  };

  const getCardHolderName = (): string => {
    return "******* *****";
  };

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
    form.setFieldValue("expireDate", null);
  };

  const onSubmitHandler = (formData: EditPaymentCardForm) => {
    formData.tenantId = currentOrganization.id;
    formData.expireMonth = (formData.expireDate.month() + 1).toString();
    formData.expireYear = formData.expireDate.year().toString();
    formData.cardToken = card.cardToken!;

    dispatch(editPaymentCard(formData))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.editPaymentCard"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editPaymentCard"),
        });
      });
  };

  const monthFormat = "MM/YY";

  const renderTempCard = () => {
    return (
      <PaymentCard
        showcase={true}
        cardNumber={getCardNumber()}
        cardHolderName={getCardHolderName()}
        expireDate={fieldValues?.expireDate?.format("MM/YY") || ""}
      />
    );
  };

  return (
    <Modal
      title={t("modals.editPaymentCardModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <S.CustomSpacer direction="vertical" size="large">
        <Row justify="center">{renderTempCard()}</Row>

        <BaseForm
          onFinish={onSubmitHandler}
          onValuesChange={() => setFieldValues(form.getFieldsValue())}
          form={form}
          layout="vertical"
          name="editPaymentCardModal"
        >
          <Row gutter={[12, 12]}>
            <Col span={16}>
              <BaseForm.Item
                name="cardNumber"
                label={t("modals.editPaymentCardModal.cardNumber")}
              >
                <Input
                  disabled
                  placeholder={t("modals.editPaymentCardModal.cardNumber")}
                />
              </BaseForm.Item>
            </Col>
            <Col span={8}>
              <BaseForm.Item
                name="expireDate"
                label={t("modals.editPaymentCardModal.expireDate")}
              >
                <S.CustomDayjsDatePicker
                  picker="month"
                  clearIcon={false}
                  format={monthFormat}
                  placeholder={t("modals.editPaymentCardModal.expireDate")}
                />
              </BaseForm.Item>
            </Col>
          </Row>
        </BaseForm>
      </S.CustomSpacer>
    </Modal>
  );
};

export default EditPaymentCardModal;
