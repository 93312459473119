import React from "react";
import { Radio, Space } from "antd";
import { ReactCountryFlag } from "react-country-flag";
import { useLanguage } from "@hooks/index";
import * as S from "./LanguagePicker.style";

const LanguagePicker: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <Radio.Group
      defaultValue={language}
      onChange={(e) => setLanguage(e.target.value)}
    >
      <Space direction="vertical">
        <S.RadioBtn value="en">
          <Space align="center">
            English
            <ReactCountryFlag svg countryCode="GB" />
          </Space>
        </S.RadioBtn>
        <S.RadioBtn value="tr">
          <Space align="center">
            Türkçe
            <ReactCountryFlag svg countryCode="TR" />
          </Space>
        </S.RadioBtn>
      </Space>
    </Radio.Group>
  );
};

export default LanguagePicker;
