import { fetchTemplatesets } from "./thunks";
import { RequestStatus } from "@common/enums";
import { ApiResponseState, Templateset } from "@common/types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: ApiResponseState<Templateset[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const templatesetsSlice = createSlice({
  name: "templatesets",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplatesets.pending, (state) => {
        state.status = RequestStatus.PENDING;
      })
      .addCase(fetchTemplatesets.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.data = action.payload;
      })
      .addCase(fetchTemplatesets.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      });
  },
});

export const { reset: resetTemplatesets } = templatesetsSlice.actions;
export default templatesetsSlice.reducer;
