import ReactECharts from "echarts-for-react";
import styled from "styled-components";

export const EChart = styled(ReactECharts)`
  height: 100% !important;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 100;
`;
