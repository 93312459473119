import {
  fetchBillingProfiles,
  fetchPaymentCards,
} from "@store/slices/Payments/thunks";
import { License, Organization, RequestStatus } from "@common/index";
import { H1, Loading, SectionHeader } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { fetchLicenses } from "@store/slices/Licenses/thunks";
import * as CommonStyles from "@styles/CommonStyles";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LicensePricingCard } from "../components/LicensePricingCard";
import * as S from "./OrganizationPlanPage.style";

const OrganizationPlanPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const licenses: License[] = useAppSelector((state) => state.licenses.data);

  const licensesStatus: RequestStatus = useAppSelector(
    (state) => state.licenses.status
  );

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const currentOrganizationStatus: RequestStatus = useAppSelector(
    (state) => state.organizationContent.status
  );

  useEffect(() => {
    if (licensesStatus === RequestStatus.IDLE) {
      dispatch(fetchLicenses());
    }
  }, [licensesStatus]);

  useEffect(() => {
    currentOrganization.id &&
      dispatch(fetchBillingProfiles(currentOrganization.id));
    currentOrganization.id &&
      dispatch(fetchPaymentCards(currentOrganization.id));
  }, [currentOrganization.id]);

  const getCurrentLicense = (): License | undefined => {
    const currentLicense: License | undefined = licenses.find(
      (l) => l.licenseType === currentOrganization?.license?.type
    );

    return currentLicense;
  };

  const currentLicense: License | undefined = getCurrentLicense();

  const licenseSortingFunction = (l1: License, l2: License) => {
    return l1.price.us - l2.price.us;
  };

  const renderLicenseCards = (): React.ReactNode => {
    const sortedLicenses: License[] = licenses
      .slice()
      .sort(licenseSortingFunction);

    return (
      currentLicense && (
        <Row gutter={[24, 24]} align="middle">
          {sortedLicenses.map((license) => {
            return (
              <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                <LicensePricingCard
                  actionable
                  license={license}
                  activeLicense={currentLicense}
                />
              </Col>
            );
          })}
        </Row>
      )
    );
  };

  const renderLoading = (): React.ReactNode => {
    return (
      <CommonStyles.LoadingContainer>
        <Loading size="4rem" />
      </CommonStyles.LoadingContainer>
    );
  };

  return (
    <React.Fragment>
      <SectionHeader
        customMargin="0 0 2rem 0"
        title={<H1>{currentOrganization.name}</H1>}
        subtitle={
          <S.SectionHeaderSubtitle>
            {t("pages.organizations.changeLicense")}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={`${APPLICATION_PATHS.ORGANIZATION_PATH}/${currentOrganization.id}`}
        backButtonVisible
      />
      {currentOrganizationStatus === RequestStatus.PENDING ||
      licensesStatus === RequestStatus.PENDING
        ? renderLoading()
        : renderLicenseCards()}
    </React.Fragment>
  );
};

export default OrganizationPlanPage;
