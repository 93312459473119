import { IModalBaseProps, InsufficientLicenseCause } from "@common/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Modal, Typography } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ILicenseUpgradeModalProps extends IModalBaseProps {
  cause: InsufficientLicenseCause;
}

const LicenseUpgradeModal: React.FC<ILicenseUpgradeModalProps> = ({
  isOpen,
  setIsOpen,
  cause,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onOkHandler = useCallback(() => {
    navigate(APPLICATION_PATHS.ORGANIZATION_PLAN_PATH);
    setIsOpen(false);
  }, []);

  const onCancelHandler = useCallback(() => {
    setIsOpen(false);
  }, []);

  const getContent = () => {
    switch (cause) {
      case InsufficientLicenseCause.DATASET_COUNT:
        return t("errors.licenseDatasetCount");
      case InsufficientLicenseCause.DASHBOARD_COUNT:
        return t("errors.licenseDashboardCount");
      case InsufficientLicenseCause.USER_COUNT:
        return t("errors.licenseUserCount");
    }
  };

  return (
    <Modal
      title={t("modals.upgradeLicenseModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      okText={t("modals.upgradeLicenseModal.upgradeNow")}
      onCancel={onCancelHandler}
    >
      <Typography.Text>{getContent()}</Typography.Text>
    </Modal>
  );
};

export default LicenseUpgradeModal;
