import { DatabaseOutlined } from "@ant-design/icons";
import { Dataset, InsufficientLicenseCause, Organization, RequestStatus, User } from "@common/index";
import { CarouselNavigationSection, H1, TabTitle } from "@components/common";
import { LicenseUpgradeModal } from "@components/common/LicenseUpgradeModal";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { setCurrentDataset } from "@store/slices/DatasetContent/slice";
import {
  fetchOwnedDatasets,
  fetchSharedDatasets,
} from "@store/slices/Datasets/thunks";
import { fetchLicenses } from "@store/slices/Licenses/thunks";
import { fetchLicenseOfOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import { fetchAllUsers } from "@store/slices/Users/thunks";
import { findOrganizationShareType } from "@utils/index";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DatasetMainPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLicenseUpgradeModalOpen, setIsLicenseUpgradeModalOpen] =
    useState<boolean>(false);

  const licenses = useAppSelector((state) => state.licenses.data);
  const licensesStatus = useAppSelector((state) => state.licenses.status);

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentUserStatus: RequestStatus = useAppSelector(
    (state) => state.userContent.status
  );

  const allUsersStatus: RequestStatus = useAppSelector(
    (state) => state.users.status
  );

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );
  const sharedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.sharedDatasets.data
  );

  const ownedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.ownedDatasets.status
  );
  const sharedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.sharedDatasets.status
  );

  const filterDatasetByOrganization = (datasets: Dataset[]) => {
    return datasets.filter((d) => d.tenant === currentOrganization.id);
  };

  useEffect(() => {
    if (allUsersStatus === RequestStatus.IDLE) {
      dispatch(fetchAllUsers());
    }
  }, [allUsersStatus]);

  useEffect(() => {
    if (currentOrganization.license === undefined) {
      dispatch(fetchLicenseOfOrganization(currentOrganization.id))
        .unwrap()
        .then((res) => {
          const modifiedOrganization: Organization = {
            ...currentOrganization,
            license: res,
          };
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(
              modifiedOrganization,
              currentUser.id
            ),
          });
        })
        .catch((err) => {});
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (licensesStatus === RequestStatus.IDLE) {
      dispatch(fetchLicenses());
    }
  }, [licensesStatus]);

  useEffect(() => {
    currentUser && dispatch(fetchOwnedDatasets(currentUser.id));
    currentUser && dispatch(fetchSharedDatasets(currentUser.id));
  }, [currentUser]);

  const carouselKeyExtractor = useCallback(
    (dataset: Dataset) => dataset.id,
    []
  );

  const carouselTitleExtractor = useCallback(
    (dataset: Dataset) => dataset.name,
    []
  );

  const carouselDescriptionExtractor = useCallback(
    (dataset: Dataset) => dataset.description,
    []
  );

  const onCreateDatasetHandler = () => {
    if (currentOrganization.license) {
      const currentLicense = licenses.find(
        (license) => license.licenseType === currentOrganization.license?.type
      );

      if (currentLicense?.datasetCount) {
        currentLicense.datasetCount <=
        filterDatasetByOrganization(ownedDatasets).length
          ? setIsLicenseUpgradeModalOpen(true)
          : navigate(APPLICATION_PATHS.DATASET_CREATE_PATH);
      }
    }
  };

  return (
    <React.Fragment>
      <TabTitle title={t("pages.datasets.tabTitle")} />
      <H1>{t("pages.datasets.pageTitle")}</H1>

      <LicenseUpgradeModal
        isOpen={isLicenseUpgradeModalOpen}
        setIsOpen={setIsLicenseUpgradeModalOpen}
        cause={InsufficientLicenseCause.DATASET_COUNT}
      />

      <CarouselNavigationSection<Dataset>
        isLoading={ownedDatasetsStatus === RequestStatus.PENDING}
        items={filterDatasetByOrganization(ownedDatasets)}
        title={t("pages.datasets.yourDatasets")}
        icon={<DatabaseOutlined />}
        onCreate={onCreateDatasetHandler}
        onItemClick={(dataset: Dataset) => {
          dispatch(setCurrentDataset(dataset));
          navigate(`${APPLICATION_PATHS.DATASET_PATH}/${dataset.id}`);
        }}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
        descriptionExtractor={carouselDescriptionExtractor}
      />

      <CarouselNavigationSection<Dataset>
        isLoading={sharedDatasetsStatus === RequestStatus.PENDING}
        items={filterDatasetByOrganization(sharedDatasets)}
        title={t("common.sharedWithYou")}
        icon={<DatabaseOutlined />}
        onItemClick={(dataset: Dataset) => {
          dispatch(setCurrentDataset(dataset));
          navigate(`${APPLICATION_PATHS.DATASET_PATH}/${dataset.id}`);
        }}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
        descriptionExtractor={carouselDescriptionExtractor}
      />
    </React.Fragment>
  );
};

export default DatasetMainPage;
