import { AddDatasetFromTemplateForm, DatasetDefinitionItem, IModalBaseProps, Kpi, Metric, Organization, RequestStatus } from "@common/index";
import { Loading, Modal, P1, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { addDatasetFromTemplate } from "@store/slices/Datasets/thunks";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./AddDatasetFromTemplateModal.style";

const AddDatasetFromTemplateModal: React.FC<IModalBaseProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentUser = useAppSelector((state) => state.userContent.data);
  const currentTemplate = useAppSelector(
    (state) => state.templatesetContent.currentTemplateset
  );
  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const currentTemplatesetMetrics: Metric[] = useAppSelector(
    (state) => state.templatesetContent.metrics.data
  );
  const currentTemplatesetMetricsStatus: RequestStatus = useAppSelector(
    (state) => state.templatesetContent.metrics.status
  );

  const currentTemplatesetKpis: Kpi[] = useAppSelector(
    (state) => state.templatesetContent.kpis.data
  );
  const currentTemplatesetKpisStatus: RequestStatus = useAppSelector(
    (state) => state.templatesetContent.kpis.status
  );

  const onOkHandler = () => {
    setIsOpen(false);

    const form: AddDatasetFromTemplateForm = {
      userId: currentUser.id,
      templatesetId: currentTemplate.id,
      tenantId: currentOrganization.id,
    };

    dispatch(addDatasetFromTemplate(form))
      .unwrap()
      .then((res) => {
        navigate(APPLICATION_PATHS.DATASET_PATH);
        NotificationController.success({
          message: t("notifications.success.addDataset", {
            datasetName: currentTemplate.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addDataset", {
            datasetName: currentTemplate.name,
          }),
        });
      });
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const renderLoading = () => {
    return (
      <S.LoadingContainer>
        <Loading size="2rem" />
      </S.LoadingContainer>
    );
  };

  const renderDefinition = (
    items: Metric[] | Kpi[],
    type: DatasetDefinitionItem
  ) => {
    let title: string;
    switch (type) {
      case DatasetDefinitionItem.METRIC:
        title = t("common.metrics");
        break;
      case DatasetDefinitionItem.KPI:
        title = t("common.kpis");
        break;
    }

    return (
      <S.Container>
        <Text>{title}</Text>
        <S.Container>
          <ul>
            {items.map((item) => (
              <li style={{ marginLeft: "14px" }}>
                <P1>{item.name}</P1>
              </li>
            ))}
          </ul>
        </S.Container>
      </S.Container>
    );
  };

  return (
    <Modal
      title={t("modals.addDatasetFromTemplateModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <Text>{t("pages.datasets.createFromTemplate")}</Text>
      <Row wrap>
        <Col span={12}>
          {currentTemplatesetMetricsStatus === RequestStatus.PENDING
            ? renderLoading()
            : renderDefinition(
                currentTemplatesetMetrics,
                DatasetDefinitionItem.METRIC
              )}
        </Col>
        <Col span={12}>
          {currentTemplatesetMetricsStatus === RequestStatus.PENDING
            ? renderLoading()
            : renderDefinition(
                currentTemplatesetKpis,
                DatasetDefinitionItem.KPI
              )}
        </Col>
      </Row>
    </Modal>
  );
};

export default AddDatasetFromTemplateModal;
