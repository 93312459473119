import { Dataset, DeleteUserFromDatasetForm, IModalBaseProps, User } from "@common/index";
import { Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { deleteUserFromDataset } from "@store/slices/DatasetContent/thunks";
import { reflectDatasetEdit } from "@store/slices/Datasets/slice";
import { findDatasetShareType } from "@utils/commonUtils";
import React from "react";
import { useTranslation } from "react-i18next";

interface IDeleteUserFromDatasetModalProps extends IModalBaseProps {
  user: User;
  dataset: Dataset;
}

const DeleteUserFromDatasetModal: React.FC<
  IDeleteUserFromDatasetModalProps
> = ({ user, dataset, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    const form: DeleteUserFromDatasetForm = {
      userId: user.id,
      dataset: dataset,
    };

    dispatch(deleteUserFromDataset(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDatasetEdit({
            dataset: res,
            datasetType: findDatasetShareType(res, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.deleteUserFromDataset", {
            userName: user.name,
            datasetName: dataset.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.deleteUserFromDataset", {
            userName: user.name,
            datasetName: dataset.name,
          }),
        });
      });

    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteUserFromOrganizationModal.title", {
        userName: user.name,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteUserFromDatasetModal;
