import { fetchAllUsers } from "./thunks";
import { ApiResponseState, User } from "@common/types";
import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@common/enums";

const initialState: ApiResponseState<User[]> = {
  data: [],
  status: RequestStatus.IDLE,
  error: null,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    reflectUserEdit: (state, action) => {
      const {user} = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.status = RequestStatus.PENDING;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.status = RequestStatus.FULFILLED;
      state.data = action.payload;
    });
    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.status = RequestStatus.REJECTED;
    });
  },
});

export const { reset: resetUsers, reflectUserEdit } = userSlice.actions;
export default userSlice.reducer;

