import { Currency, LicenseType, ShareType } from "@common/enums";
import {
  CountryDetail,
  Dashboard,
  Dataset,
  LanguageType,
  License, Organization,
  User,
  UserOption
} from "@common/types";
import enUS from "antd/lib/locale/en_US";
import trTR from "antd/lib/locale/tr_TR";

export const getLocale = (language: LanguageType) => {
  switch (language) {
    case "tr":
      return trTR;
    case "en":
    default:
      return enUS;
  }
};

export const getBrowserLanguage = (): LanguageType => {
  const language = navigator.language;
  
  switch (language) {
    case "tr":
      return "tr";
    case "en-US":
    default:
      return "en";
  }
};

export const findDatasetShareType = (
  dataset?: Dataset,
  currenUserId?: string
): ShareType => {
  if (!currenUserId || !dataset) {
    return ShareType.NONE;
  }

  const datasetOwnerId = (dataset.user as User).id;

  if (datasetOwnerId === currenUserId) {
    return ShareType.OWNED;
  } else {
    return ShareType.SHARED;
  }
};

export const findOrganizationShareType = (
  organization?: Organization,
  currenUserId?: string
): ShareType => {
  if (!currenUserId || !organization) {
    return ShareType.NONE;
  }

  const organizationOwnerId = (organization.user as User).id;

  if (organizationOwnerId === currenUserId) {
    return ShareType.OWNED;
  } else {
    return ShareType.SHARED;
  }
};

export const findDashboardShareType = (
  dashboard?: Dashboard,
  currenUserId?: string
): ShareType => {
  if (!currenUserId || !dashboard) {
    return ShareType.NONE;
  }

  const organizationOwnerId = (dashboard.user as User).id;

  if (organizationOwnerId === currenUserId) {
    return ShareType.OWNED;
  } else {
    return ShareType.SHARED;
  }
};

export const createAutoCompleteOptionsFromArray = (
  array: any[],
  valueExtractor: (e: any) => string | number,
  labelExtractor: (e: any) => string
) => {
  const options = array.map((element) => {
    return {
      value: valueExtractor(element),
      label: labelExtractor(element),
      selectedOption: element,
    };
  });

  return options;
};

export const convertUserOptionArray = (userOptions: UserOption[]) => {
  return userOptions.map((option) => convertUserToUidInUserOption(option));
};

export const convertUserToUidInUserOption = (userOption: UserOption) => {
  const converted = {
    uid: (userOption.user as User).id,
    editable: userOption.editable,
  };

  return converted;
};

export const getLicenseTitle = (licenseType: LicenseType) => {
  switch (licenseType) {
    case LicenseType.LICENSE_FREE:
      return "FREE";
    case LicenseType.LICENSE_STARTER:
      return "STARTER";
    case LicenseType.LICENSE_PRO:
      return "PRO";
    case LicenseType.LICENSE_ENTERPRISE:
      return "ENTERPRISE";
  }
};

export const generateAsterix = (count: number) => {
  let returnValue = "";

  for (let i = 0; i < count; i++) {
    returnValue += "*";
  }

  return returnValue;
};

export const getLicenseCurrencySymbolAndPrice = (
  license: License,
  countryDetail: CountryDetail | null
): { symbol: string; price: number } => {
  const currencyCode: string |undefined = countryDetail?.currencyCode;

  switch (currencyCode) {
    case "TRY":
      return { price: license.price.tr, symbol: "₺" };
    case "USD":
      return { price: license.price.tr, symbol: "$" };
    case "EU":
      return { price: license.price.tr, symbol: "€" };
    default:
      return { price: license.price.us, symbol: "$" };
  }
};

export const getCurrency = (countryDetail: CountryDetail | null) => {
  const currencyCode: string | undefined = countryDetail?.currencyCode;

  switch (currencyCode) {
    case "TRY":
      return Currency.TRY;
    case "USD":
      return Currency.USD;
    case "EU":
      return Currency.EUR;
    default:
      return Currency.USD;
  }
};

export const getCurrencySymbol = (countryDetail: CountryDetail | null) => {
  const currencyCode: string | undefined = countryDetail?.currencyCode;

  switch (currencyCode) {
    case "TRY":
      return "₺";
    case "USD":
      return "$";
    case "EU":
      return "€";
    default:
      return "";
  }
};
