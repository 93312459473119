import { BASE_COLORS, ITheme, ThemeType } from "@common/index";
import { darkColorsTheme, } from "./dark/DarkTheme";
import { lightColorsTheme } from "./light/LightTheme";
import { hexToRGB } from "@utils/index";
import { css } from "styled-components";

interface IThemeContainer {
  light: ITheme;
  dark: ITheme;
}

export const themeObject: IThemeContainer = {
  light: lightColorsTheme,
  dark: darkColorsTheme,
};

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --primary-color: ${themeObject[theme].primaryColor};
  --ant-primary: ${themeObject[theme].antPrimaryColor};
  --secondary-color: ${themeObject[theme].secondaryColor};
  --primary-color-hover: ${themeObject[theme].primaryColorHover};
  --layout-header-bg-color: ${themeObject[theme].headerBgColor};
  --layout-body-bg-color: ${themeObject[theme].bodyBgColor};
  --layout-sider-bg-color: ${themeObject[theme].siderBgColor};
  --layout-footer-bg-color: ${themeObject[theme].footerBgColor};

  --main-linear-gradient-color-1: ${themeObject[theme].mainLinearGradientColor1};
  --main-linear-gradient-color-2: ${themeObject[theme].mainLinearGradientColor2};

  --text-main-color: ${themeObject[theme].textMainColor};
  --text-secondary-color: ${themeObject[theme].textSecondaryColor};
  --text-light-color: ${themeObject[theme].textLightColor};
  --text-extra-light-color: ${themeObject[theme].textExtraLightColor};

  --text-sider-primary-color: ${themeObject[theme].textSiderPrimaryColor};
  --text-sider-secondary-color: ${themeObject[theme].textSiderSecondaryColor};

  --error-color: ${themeObject[theme].errorColor};
  --warning-color: ${themeObject[theme].warningColor};
  --success-color: ${themeObject[theme].successColor};

  --primary-rgb-color: ${hexToRGB(themeObject[theme].primaryColor)};
  --info-rgb-color: ${hexToRGB(themeObject[theme].primaryColor)};
  --secondary-rgb-color: ${hexToRGB(themeObject[theme].secondaryColor)};
  --error-rgb-color: ${hexToRGB(themeObject[theme].errorColor)};
  --warning-rgb-color: ${hexToRGB(themeObject[theme].warningColor)};
  --success-rgb-color: ${hexToRGB(themeObject[theme].successColor)};

  --notification-success-color: ${themeObject[theme].notificationSuccessColor};
  --notification-primary-color: ${themeObject[theme].notificationPrimaryColor};
  --notification-warning-color: ${themeObject[theme].notificationWarningColor};
  --notification-error-color: ${themeObject[theme].notificationErrorColor};

  --input-placeholder-color: ${themeObject[theme].inputPlaceholderColor};
  --disabled-color: ${themeObject[theme].disabledColor};
  --disabled-bg-color: ${themeObject[theme].disabledBgColor};
  --collapse-background-color: ${themeObject[theme].collapseBackgroundColor};
  --border-color: ${themeObject[theme].borderColor};
  --box-shadow: ${themeObject[theme].boxShadow};
  --box-shadow-darker: ${themeObject[theme].boxShadowDarker};
  --scroll-color: ${themeObject[theme].scrollColor};

  --card-hover-color: ${themeObject[theme].cardHoverColor};
`;

export const lightThemeVariables = css`
  ${getThemeVariables("light")}
`;

export const commonThemeVariables = css`
  color-scheme: light;
  --white: ${BASE_COLORS.white};
  --black: ${BASE_COLORS.black};
  --green: ${BASE_COLORS.green};
  --orange: ${BASE_COLORS.orange};
  --gray: ${BASE_COLORS.gray};
  --lightgrey: ${BASE_COLORS.lightgrey};
  --violet: ${BASE_COLORS.violet};
  --lightgreen: ${BASE_COLORS.lightgreen};
  --pink: ${BASE_COLORS.pink};
  --blue: ${BASE_COLORS.blue};
  --skyblue: ${BASE_COLORS.skyblue};
  --red: ${BASE_COLORS.red};
`;

export const antOverrideCssVariables = css`
  --ant-primary-1: var(--primary1-color) !important;
`;
