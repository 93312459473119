import { Button } from "antd";
import styled, { css } from "styled-components";

interface IStatusPointProps {
  $color: string;
}

export const RecordInspectionButton = styled(Button).attrs({
  size: "small",
})`
  width: 100%;
  min-width: 100%;
`;

export const StatusPoint = styled.div<IStatusPointProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  ${(props) =>
    props.$color &&
    css`
      background-color: ${props.$color};
    `}
`;
