import {
  AddMetricRecordForm,
  ApiRangeFilter,
  AppDate,
  Dataset,
  DeleteMetricRecordForm,
  EditMetricRecordForm,
  EditableTableActions,
  IUserWithRole,
  Metric,
  MetricRecord,
  MetricRecordMap,
  MetricRecordsFilter,
  RequestStatus,
  User,
  UserOption,
  UserRole,
} from "@common/index";
import { EditableDataTable } from "@components/common";
import { ColumnTypes } from "@components/common/EditableDataTable/EditableDataTable";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { Dates } from "@services/DateService";
import {
  addMetricRecord,
  deleteMetricRecord,
  editMetricRecord,
  fetchMetricRecordsOfDataset,
  fetchMetrics,
} from "@store/slices/DatasetContent/thunks";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface IEditableMetricRecordsTableProps {
  dataset: Dataset;
  filter: MetricRecordsFilter;
}

export type MetricRecordDataTableEntry = {
  key: string;
  metric: string;
  metricId: string;
  [key: string]: string;
} & {
  recordIdMap: {
    [timestamp: string]: string;
  };
};

const EditableMetricRecordsTable: React.FC<
  IEditableMetricRecordsTableProps
> = ({ dataset, filter: timeFilter }) => {
  const { t } = useTranslation();
  const { datasetId } = useParams();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentDatasetInStore = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  const currentMetrics: Metric[] = useAppSelector(
    (state) => state.datasetContent.metrics.data
  );
  const currentMetricsStatus: RequestStatus = useAppSelector(
    (state) => state.datasetContent.metrics.status
  );

  const currentMetricRecords: MetricRecordMap = useAppSelector(
    (state) => state.datasetContent.metricRecords.data
  );
  const currentMetricRecordsStatus: RequestStatus = useAppSelector(
    (state) => state.datasetContent.metricRecords.status
  );

  useEffect(() => {
    currentDatasetInStore?.id &&
      dispatch(fetchMetrics(currentDatasetInStore?.id));
  }, [currentDatasetInStore?.id]);

  useEffect(() => {
    const rangeFilter: ApiRangeFilter = {
      filter: {
        time: {
          begin: timeFilter.startDate.toISOString(),
          end: timeFilter.endDate.toISOString(),
        },
      },
    };

    const currentMetricIds: string[] = currentMetrics.map(
      (metric) => metric.id
    );
    dispatch(
      fetchMetricRecordsOfDataset({
        metricIds: currentMetricIds,
        filter: rangeFilter,
      })
    );
  }, [timeFilter, currentMetrics]);

  const getCurrentUserWithRole = (): IUserWithRole | undefined => {
    if ((dataset.user as User).id === currentUser.id) {
      return {
        user: currentUser,
        role: UserRole.ADMIN,
      };
    }

    const correspondingUserOption: UserOption | undefined = dataset.users.find(
      (userOpt) => (userOpt.user as User).id === currentUser.id
    );

    return correspondingUserOption
      ? {
          user: correspondingUserOption!.user as User,
          role: correspondingUserOption?.editable
            ? UserRole.EDITOR
            : UserRole.BASIC,
        }
      : undefined;
  };

  const currentUserWithRole: IUserWithRole | undefined =
    getCurrentUserWithRole();

  const findMetricNameById = (metricId: string) => {
    const foundMetric: Metric | undefined = currentMetrics.find(
      (metric) => metric.id === metricId
    );
    return foundMetric;
  };

  const createWeeklyColumns = useCallback(() => {
    const { startDate, endDate } = timeFilter!;
    const columnDates: AppDate[] = Dates.getDaysArrayInRange(
      startDate,
      endDate
    );

    const headers: (ColumnTypes[number] & {
      editable?: boolean;
      dataIndex: string;
    })[] = columnDates.map((date) => {
      const column: ColumnTypes[number] & {
        editable?: boolean;
        dataIndex: string;
      } = {
        title: date.format("ddd, DD"),
        dataIndex: date.format("X"),
        editable: currentUserWithRole
          ? [UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)
          : false,
        width: "11%",
      };

      return column;
    });

    const metricNameHeader: ColumnTypes[number] & {
      editable?: boolean;
      dataIndex: string;
    } = {
      title: "Metrics",
      dataIndex: "metric",
      fixed: "left",
      editable: false,
      width: "23%",
    };

    return [metricNameHeader, ...headers];
  }, [timeFilter]);

  const createWeeklyData = useCallback(() => {
    const columnDates: AppDate[] = Dates.getDaysArrayInRange(
      timeFilter.startDate,
      timeFilter.endDate
    );
    const weeklyDataDict: any = {};

    Object.keys(currentMetricRecords).forEach((metricId) => {
      weeklyDataDict[metricId] = {
        recordIdMap: {},
      };
    });

    Object.keys(currentMetricRecords).forEach((metricId) => {
      const records = currentMetricRecords[metricId];
      columnDates.forEach((columnDate) => {
        const matchedDayRecord: MetricRecord | undefined = records.find(
          (record: MetricRecord) =>
            Dates.isSameDay(
              Dates.convertStringToDate(record.timestamp),
              columnDate
            )
        );

        const timestampOfDay = columnDate.format("X");

        if (matchedDayRecord) {
          weeklyDataDict[metricId] = {
            ...weeklyDataDict[metricId],
            [timestampOfDay]: matchedDayRecord.value,
            recordIdMap: {
              ...weeklyDataDict[metricId].recordIdMap,
              [timestampOfDay]: matchedDayRecord.id,
            },
          };
        }
      });
    });

    return convertWeeklyDataDictToDataArray(weeklyDataDict);
  }, [
    timeFilter,
    currentMetrics,
    currentMetricRecords,
    currentMetricRecordsStatus,
  ]);

  const convertWeeklyDataDictToDataArray = (dataDict: any) => {
    const data: MetricRecordDataTableEntry[] = [];

    Object.keys(dataDict).forEach((metricId) => {
      const datum: MetricRecordDataTableEntry = {
        key: metricId,
        metric: findMetricNameById(metricId)?.name,
        metricId: metricId,
        ...dataDict[metricId],
      };
      data.push(datum);
    });

    return data;
  };

  const checkActionType = useCallback((args: any) => {
    const { changedColumnName, updatedRow } = args;

    const columnExistInRecordIdMap =
      updatedRow?.recordIdMap?.hasOwnProperty(changedColumnName);

    const newValueExist = updatedRow[changedColumnName]
      ? String(updatedRow[changedColumnName]).trim() !== ""
      : false;

    if (columnExistInRecordIdMap && !newValueExist) {
      return EditableTableActions.DELETE;
    } else if (columnExistInRecordIdMap && newValueExist) {
      return EditableTableActions.EDIT;
    } else if (!columnExistInRecordIdMap && !newValueExist) {
      return EditableTableActions.NONE;
    } else if (!columnExistInRecordIdMap && newValueExist) {
      return EditableTableActions.SAVE;
    }

    return EditableTableActions.NONE;
  }, []);

  const onCellSaveHandler = (row: any) => {
    const actionType = checkActionType(row);
    const { updatedRow, changedColumnName } = row;

    switch (actionType) {
      case EditableTableActions.SAVE:
        const addFormData: AddMetricRecordForm = {
          metricId: updatedRow.metricId,
          timestamp: Dates.convertUnixToDate(Number(changedColumnName))
            .hour(6)
            .toISOString(),
          value: Number(updatedRow[changedColumnName]),
        };
        dispatch(addMetricRecord(addFormData));
        break;
      case EditableTableActions.DELETE:
        const deleteFormData: DeleteMetricRecordForm = {
          metricId: updatedRow.metricId,
          recordId: updatedRow.recordIdMap[changedColumnName],
          timestamp: Dates.convertUnixToDate(changedColumnName)
            .hour(6)
            .toISOString(),
        };
        dispatch(deleteMetricRecord(deleteFormData));
        break;
      case EditableTableActions.EDIT:
        const editFormData: EditMetricRecordForm = {
          metricId: updatedRow.metricId,
          recordId: updatedRow.recordIdMap[changedColumnName],
          timestamp: Dates.convertUnixToDate(changedColumnName)
            .hour(6)
            .toISOString(),
          value: Number(updatedRow[changedColumnName]),
        };

        dispatch(editMetricRecord(editFormData));
        break;
      case EditableTableActions.NONE:
      default:
        break;
    }
  };

  const tableLoadingStatus =
    currentMetricsStatus === RequestStatus.PENDING ||
    currentMetricRecordsStatus === RequestStatus.PENDING;

  return (
    <EditableDataTable
      data={createWeeklyData()}
      columns={createWeeklyColumns()}
      onSave={onCellSaveHandler}
      isLoading={tableLoadingStatus}
    />
  );
};

export default EditableMetricRecordsTable;
