import { axios } from "@api/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const USER_ENDPOINT = `${BASE_PATH}/user`;

export const fetchAllUsers = createAsyncThunk(
  "users/fetchAllUsers",
  async () => {
    const response = await axios.get(`${USER_ENDPOINT}`);
    return response.data;
  }
);

export const fetchUserById = createAsyncThunk(
  "users/fetchUserById",
  async (userId: string) => {
    const response = await axios.get(`${USER_ENDPOINT}/${userId}`);
    return response.data;
  }
);
