import { Card as BaseCard, H1, H6 } from "@components/common";
import { Button, Row } from "antd";
import styled from "styled-components";

export const CardWrapper = styled.div`
  padding: 1rem;
`;

export const Card = styled(BaseCard)`
  box-shadow: var(--box-shadow);
  min-height: 13rem;
  min-width: 10rem;
`;

export const SectionHeader = styled(H1)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-main-color);
  }
`;

export const SectionHeaderSubtitle = styled(H6)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-light-color);
  }
`;

export const ContentRow = styled(Row)`
  margin: 2rem 0 2rem 0;
  align-items: center;
`;

export const CustomTextButton = styled(Button).attrs({
  type: "text",
  size: "small",
})`
  &,
  &:hover,
  &:active,
  &:focus {
    color: var(--primary-color);
  }
`;
