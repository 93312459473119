import { fetchDatabaseUser, signup } from "./thunks";
import { createSlice } from "@reduxjs/toolkit";
import { ApiResponseState, User } from "@common/types";
import { RequestStatus } from "@common/enums";

const initialState: ApiResponseState<User> = {
  data: {
    id: undefined,
    name: undefined,
    photoUrl: undefined,
    email: undefined,
  } as unknown as User,
  status: RequestStatus.IDLE,
  error: null,
};

export const userContentSlice = createSlice({
  name: "userContent",
  initialState,
  reducers: {
    reset: () => initialState,
    setLoginStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDatabaseUser.pending, (state) => {})
      .addCase(fetchDatabaseUser.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchDatabaseUser.rejected, (state) => {});
  },
});

export const { reset: resetUserContent, setLoginStatus } =
  userContentSlice.actions;
export default userContentSlice.reducer;
