import { Organization } from "@common/types";
import { useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { PaymentNavigationData } from "../";
import * as S from "./PaymentNavigation.style";

const PaymentNavigation: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const getIsActive = (url: string): boolean => {
    try {
      const fullPath = location.pathname;
      const subPaths = fullPath.split("/");

      const latestSubPath = subPaths[subPaths.length - 1];

      return url === latestSubPath;
    } catch (err) {
      return false;
    }
  };

  return (
    <S.Wrapper>
      {PaymentNavigationData.map((item) => (
        <S.NavigationButton
          key={item.key}
          icon={item.icon}
          type="text"
          onClick={() =>
            navigate(
              `${APPLICATION_PATHS.ORGANIZATION_PATH}/${currentOrganization.id}/payment-and-billing/${item.url}`
            )
          }
          isActive={getIsActive(item.url!)}
          color={item.color}
        >
          {t(item.title)}
        </S.NavigationButton>
      ))}
    </S.Wrapper>
  );
};

export default PaymentNavigation;
