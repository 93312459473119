import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Header, MainContent, MainHeader, MainSider } from "@components/layout";
import * as S from "./MainLayout.style";

const MainLayout: React.FC = () => {
  const [siderCollapsed, setSiderCollapsed] = useState<boolean>(true);

  const toggleSider = () => setSiderCollapsed((prev) => !prev);

  return (
    <S.LayoutMaster>
      <MainSider
        isCollapsed={siderCollapsed}
        setCollapsed={setSiderCollapsed}
      />
      <S.LayoutMain>
        <MainHeader>
          <Header isSiderCollapsed={siderCollapsed} toggleSider={toggleSider} />
        </MainHeader>
        <MainContent>
          <div>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
