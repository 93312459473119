import { H1, H6 } from "@components/common";
import { Space } from "antd";
import styled from "styled-components";

export const SectionHeaderSubtitle = styled(H6)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-light-color);
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
`;

export const CustomSpacer = styled(Space).attrs({ direction: "vertical" })`
  display: flex;
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
