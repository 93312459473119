import { CountryDetail, CountryDetails, ApiResponseState, RequestStatus } from "@common/index";
import { createSlice } from "@reduxjs/toolkit";
import { fetchUserLocationInfo } from "./thunks";


const localInitialState: ApiResponseState<CountryDetail | null> = {
  status: RequestStatus.IDLE,
  data: null,
  error: null,
}

const initialState = {
  local: localInitialState
};

const userAgentSlice = createSlice({
  name: "userAgent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserLocationInfo.pending, (state) => {
        state.local.status = RequestStatus.PENDING;
      })
      .addCase(fetchUserLocationInfo.fulfilled, (state, action) => {
        state.local.status = RequestStatus.FULFILLED;
        state.local.data = CountryDetails[action.payload.country_code];;
      })
      .addCase(fetchUserLocationInfo.rejected, (state) => {
        state.local.status = RequestStatus.REJECTED;
      });
  },
});

export default userAgentSlice.reducer;
