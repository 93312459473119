import { EditMetricForm, IModalBaseProps, Metric } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch } from "@hooks/index";
import { editMetric } from "@store/slices/DatasetContent/thunks";
import { Input } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IEditMetricModalProps extends IModalBaseProps {
  item: Metric;
}

const EditMetricModal: React.FC<IEditMetricModalProps> = ({
  item,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();

  useEffect(() => {
    const formValues = {
      name: item?.name,
      description: item?.description,
    };

    form?.setFieldsValue(formValues);
  }, [item]);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (form: EditMetricForm) => {
    form.metricId = item.id;
    form.datasetId = (item as any).datasetId;

    dispatch(editMetric(form))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.editMetric", {
            metricName: form.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editMetric", {
            metricName: form.name,
          }),
        });
      });
  };

  return (
    <Modal
      open={isOpen}
      title={"Title"}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="editMetricForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label={t("common.description")}>
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditMetricModal;
