import { CrownOutlined, HighlightOutlined } from "@ant-design/icons";
import { IDropdownMenuItemOption, IUserWithRole, Organization, UserRole } from "@common/index";
import { DropdownMenu } from "@components/common";
import { useAppSelector } from "@hooks/index";
import {
  DeleteUserFromOrganizationModal,
  EditUserFromOrganizationModal,
} from "@pages/OrganizationPages/modals";
import { Avatar, Col, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./OrganizationUserCard.style";

interface IOrganizationUserCardProps {
  userWithRole: IUserWithRole;
  currentUserWithRole: IUserWithRole;
}

const OrganizationUserCard: React.FC<IOrganizationUserCardProps> = ({
  userWithRole,
  currentUserWithRole,
}) => {
  const { t } = useTranslation();
  const [
    isDeleteUserFromOrganizationModalOpen,
    setIsDeleteUserFromOrganizationModalOpen,
  ] = useState<boolean>(false);

  const [
    isEditUserFromOrganizationModalOpen,
    setIsEditUserFromOrganizationModalOpen,
  ] = useState<boolean>(false);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ORGANIZATION_USER_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "edit",
      title: t("common.edit"),
      disabled: ![UserRole.ADMIN, UserRole.EDITOR].includes(
        currentUserWithRole.role
      ),
      onClick: () => setIsEditUserFromOrganizationModalOpen(true),
    },
    {
      key: "delete",
      title: t("common.delete"),
      disabled: ![UserRole.ADMIN, UserRole.EDITOR].includes(
        currentUserWithRole.role
      ),
      onClick: () => setIsDeleteUserFromOrganizationModalOpen(true),
    },
  ];

  const getRoleIcon = () => {
    if (userWithRole.role === UserRole.ADMIN) {
      return <CrownOutlined />;
    } else if (userWithRole.role === UserRole.EDITOR) {
      return <HighlightOutlined />;
    }
  };

  return (
    <React.Fragment>
      <EditUserFromOrganizationModal
        userWithRole={userWithRole}
        organization={currentOrganization}
        isOpen={isEditUserFromOrganizationModalOpen}
        setIsOpen={setIsEditUserFromOrganizationModalOpen}
      />

      <DeleteUserFromOrganizationModal
        user={userWithRole.user}
        organization={currentOrganization}
        isOpen={isDeleteUserFromOrganizationModalOpen}
        setIsOpen={setIsDeleteUserFromOrganizationModalOpen}
      />

      <S.Card padding={[15, 20]}>
        <Row align="middle">
          <S.AvatarWrapper>
            <Avatar />
          </S.AvatarWrapper>
          <Col flex="auto">
            <S.HeaderRow>
              <span>
                {userWithRole.user.name} {getRoleIcon()}
              </span>
              {userWithRole.role !== UserRole.ADMIN && (
                <DropdownMenu options={ORGANIZATION_USER_OPTIONS} />
              )}
            </S.HeaderRow>
            <S.BodyText>{userWithRole.user.email}</S.BodyText>
          </Col>
        </Row>
      </S.Card>
    </React.Fragment>
  );
};

export default OrganizationUserCard;
