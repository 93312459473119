import {
  ChangeLicenseOfOrganizationForm,
  Dashboard,
  Dataset,
  DeleteDashboardForm,
  DeleteDatasetForm,
  DeleteMultipleUsersFromOrganizationForm,
  IModalBaseProps, IUserWithRole,
  License,
  Organization,
  User,
} from "@common/index";
import { Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { deleteDashboard } from "@store/slices/DashboardContent/thunks";
import { reflectDashboardDelete } from "@store/slices/Dashboards/slice";
import { deleteDataset } from "@store/slices/DatasetContent/thunks";
import { reflectDatasetDelete } from "@store/slices/Datasets/slice";
import {
  changeLicenseOfOrganization,
  deleteMultipleUsersFromOrganization,
} from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import {
  findDashboardShareType,
  findDatasetShareType,
  findOrganizationShareType,
  getLicenseTitle,
} from "@utils/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IDeleteForDowngradeModalProps extends IModalBaseProps {
  usersToDelete: IUserWithRole[];
  dashboardsToDelete: Dashboard[];
  datasetsToDelete: Dataset[];
  organization: Organization;
  licenseToChange: License;
}

const DeleteForDowngradeModal: React.FC<IDeleteForDowngradeModalProps> = ({
  isOpen,
  setIsOpen,
  usersToDelete,
  dashboardsToDelete,
  datasetsToDelete,
  organization,
  licenseToChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = async () => {
    if (usersToDelete) {
      const multipleUserDeleteForm: DeleteMultipleUsersFromOrganizationForm = {
        userIds: usersToDelete.map((userWithRole) => userWithRole.user.id),
        organization,
      };

      dispatch(deleteMultipleUsersFromOrganization(multipleUserDeleteForm))
        .then((res) => {
          dispatch(
            reflectOrganizationEdit({
              organization: res,
              organizationType: findOrganizationShareType(
                organization,
                currentUser.id
              ),
            })
          );
          NotificationController.success({
            message: t("notifications.success.deleteUsersFromOrganization", {
              organizationName: organization.name,
            }),
          });
        })
        .catch((err) => {
          NotificationController.error({
            message: t("notifications.error.deleteUsersFromOrganization", {
              organizationName: organization.name,
            }),
          });
        });
    }

    if (dashboardsToDelete.length > 0) {
      const deleteDashboardForms: (DeleteDashboardForm & {
        dashboard: Dashboard;
      })[] = dashboardsToDelete.map((dashboard) => ({
        dashboard,
        dashboardId: dashboard.id,
        userId: currentUser.id,
      }));

      await Promise.allSettled(
        deleteDashboardForms.map((deletionForm) =>
          dispatch(deleteDashboard(deletionForm))
            .unwrap()
            .then((res) => {
              dispatch(
                reflectDashboardDelete({
                  dashboard: deletionForm.dashboard,
                  dashboardType: findDashboardShareType(
                    deletionForm.dashboard,
                    currentUser.id
                  ),
                })
              );
            })
        )
      )
        .then((res) => {
          NotificationController.success({
            message: t("notifications.success.deleteDashboards"),
          });
        })
        .catch(() => {
          NotificationController.error({
            message: t("notifications.error.deleteDashboards"),
          });
        });
    }

    if (datasetsToDelete.length > 0) {
      const deleteDatasetForms: (DeleteDatasetForm & { dataset: Dataset })[] =
        datasetsToDelete.map((dataset) => ({
          dataset,
          datasetId: dataset.id,
          userId: currentUser.id,
        }));

      await Promise.allSettled(
        deleteDatasetForms.map((deleteDatasetForm) =>
          dispatch(deleteDataset(deleteDatasetForm))
            .unwrap()
            .then((res) => {
              dispatch(
                reflectDatasetDelete({
                  dataset: deleteDatasetForm.dataset,
                  datasetType: findDatasetShareType(
                    deleteDatasetForm.dataset,
                    currentUser.id
                  ),
                })
              );
            })
        )
      )
        .then(() => {
          NotificationController.success({
            message: t("notifications.success.deleteDatasets"),
          });
        })
        .catch(() => {
          NotificationController.error({
            message: t("notifications.error.deleteDatasets"),
          });
        });
    }

    setIsOpen(false);
    changeLicense();
    const organizationDetailPath = `${APPLICATION_PATHS.ORGANIZATION_DETAIL_PATH.replace(
      ":organizationId",
      currentOrganization.id
    )}`;
    navigate(organizationDetailPath);
  };

  const changeLicense = () => {
    const data: ChangeLicenseOfOrganizationForm = {
      tenantId: organization.id,
      licenseType: licenseToChange.licenseType,
      duration: licenseToChange.retention,
    };

    dispatch(changeLicenseOfOrganization(data))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.changeLicense", {
            licenseType: getLicenseTitle(res.type),
          }),
        });

        const modifiedOrganization: Organization = {
          ...organization,
          license: {
            type: res.type,
            startTime: res.startTime,
            validTime: res.validTime,
          },
        };
        dispatch(
          reflectOrganizationEdit({
            organization: modifiedOrganization,
            organizationType: findOrganizationShareType(
              modifiedOrganization,
              currentUser.id
            ),
          })
        );
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.changeLicense"),
        });
      });
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteForDowngradeModal.title")}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("modals.deleteForDowngradeModal.subTitle")}</Text>
    </Modal>
  );
};

export default DeleteForDowngradeModal;
