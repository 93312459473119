import { APPLICATION_PATHS } from "@routes/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import UserProfileNavigationData from "../UserProfileNavigationData/UserProfileNavigationData";
import * as S from "./UserProfileNavigation.style";

const UserProfileNavigation: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const getIsActive = (url: string): boolean => {
    try {
      const fullPath = location.pathname;
      const subPaths = fullPath.split("/");

      const latestSubPath = subPaths[subPaths.length - 1];

      return url === latestSubPath;
    } catch (err) {
      return false;
    }
  };

  return (
    <S.Wrapper>
      {UserProfileNavigationData.map((item) => (
        <S.NavigationButton
          key={item.key}
          icon={item.icon}
          type="text"
          onClick={() =>
            navigate(`${APPLICATION_PATHS.PROFILE_PATH}/${item.url}`)
          }
          isActive={getIsActive(item.url!)}
          color={item.color}
        >
          {t(item.title)}
        </S.NavigationButton>
      ))}
    </S.Wrapper>
  );
};

export default UserProfileNavigation;
