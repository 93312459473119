import { ThemeType } from "@common/types";
import { MoonSunSwitch } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { setTheme } from "@store/slices/Theme/slice";
import React from "react";

const ThemePicker: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useAppSelector((state) => state.theme.data);

  const handleClickButton = (theme: ThemeType) => {
    dispatch(setTheme(theme));
  };

  return (
    <MoonSunSwitch
      isMoonActive={theme === "dark"}
      onClickMoon={() => handleClickButton("dark")}
      onClickSun={() => handleClickButton("light")}
    />
  );
};

export default ThemePicker;
