import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100px;
`;

export const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
`;
