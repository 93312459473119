import { editOrganization } from "@app/store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@app/store/slices/Organizations/slice";
import {
  BillingProfile,
  CountryDetail,
  Currency,
  EditOrganizationForm,
  NewCardPaymentForm as NewCardPaymentFormType,
  Obligation,
  ObligationItem,
  Organization,
  RequestStatus,
  Settlement,
  User,
} from "@common/index";
import { BaseForm } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Dates } from "@services/index";
import {
  fetchBillingProfiles,
  payWithNewCard,
} from "@store/slices/Payments/thunks";
import {
  convertUserOptionArray,
  findOrganizationShareType,
  getCurrency,
  getCurrencySymbol,
} from "@utils/index";
import { Button, Col, Input, Row, Select, SelectProps } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { PaymentCard } from "../";
import * as S from "./NewCardPaymentForm.style";

const NewCardPaymentForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { obligationId: currentObligationId } = useParams();

  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const userAgent: CountryDetail | null = useAppSelector(
    (state) => state.userAgent.local.data
  );

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const settlementStatus: RequestStatus = useAppSelector(
    (state) => state.payments.settlements.status
  );

  const obligations: Obligation[] = useAppSelector(
    (state) => state.payments.obligations.data
  );

  const currentObligation: Obligation | undefined = obligations.find(
    (obl) => obl.id === currentObligationId
  );

  const billingProfiles: BillingProfile[] = useAppSelector(
    (state) => state.payments.billingProfiles.data
  );

  const billingProfilesStatus: RequestStatus = useAppSelector(
    (state) => state.payments.billingProfiles.status
  );

  const initialFormValues: NewCardPaymentFormType = {
    tenantId: "",
    obligationId: "",
    billingProfileId: "",
    cardHolderName: "",
    cardNumber: "",
    expireDate: Dates.getToday(),
    expireMonth: "",
    expireYear: "",
    cvc: "",
    price: 0,
    paidPrice: 0,
    walletPrice: 0,
    installment: 1,
    currency: Currency.TRY,
    items: [],
  };

  const [focusedBackOfCard, setFocusedBackOfCard] = useState<boolean>();
  const [fieldValues, setFieldValues] =
    useState<NewCardPaymentFormType>(initialFormValues);

  useEffect(() => {
    currentOrganization &&
      dispatch(fetchBillingProfiles(currentOrganization.id));
  }, [currentObligation]);

  const getAmount = (obligationItem: ObligationItem) => {
    let amount: number = 0;

    switch (userAgent?.currencyCode) {
      case Currency.TRY:
        return (amount += obligationItem.fee.tr);
      case Currency.EUR:
        return (amount += obligationItem.fee.eu);
      case Currency.USD:
      default:
        return (amount += obligationItem.fee.us);
    }
  };

  const getTotalAmount = () => {
    if (!currentObligation) {
      return 0;
    }

    const totalAmount = currentObligation.items.reduce((acc, curr) => {
      let currentValueInLocalCurrency = acc;

      switch (userAgent?.currencyCode) {
        case Currency.TRY:
          return (currentValueInLocalCurrency += curr.fee.tr);
        case Currency.EUR:
          return (currentValueInLocalCurrency += curr.fee.eu);
        case Currency.USD:
        default:
          return (currentValueInLocalCurrency += curr.fee.us);
      }
    }, 0);

    return totalAmount;
  };

  const checkAndModifyOrganizationDisabledStatus = (
    newSettlement: Settlement
  ) => {
    if (!currentOrganization.disabled) {
      return;
    }

    const modifiedObligations: Obligation[] = obligations.filter(
      (obg) => obg.id !== newSettlement.obligation.id
    );

    if (modifiedObligations.length < 2) {
      const editOrganizationForm: EditOrganizationForm = {
        id: currentOrganization.id,
        name: currentOrganization.name,
        users: convertUserOptionArray(currentOrganization.users),
        disabled: false,
        uid: (currentOrganization.user as User).id,
      };

      dispatch(editOrganization(editOrganizationForm))
        .unwrap()
        .then((updatedOrg) => {
          dispatch(
            reflectOrganizationEdit({
              organization: updatedOrg,
              organizationType: findOrganizationShareType(
                updatedOrg,
                currentUser.id
              ),
            })
          );
        });
    }
  };

  const onSubmitHandler = (formData: NewCardPaymentFormType) => {
    if (!currentObligation) {
      return;
    }

    formData.tenantId = currentOrganization.id;
    formData.obligationId = currentObligation.id;
    formData.billingProfileId = formData.billingProfileId;

    formData.items = currentObligation.items.map((obgItem) => ({
      name: obgItem.type,
      price: getAmount(obgItem),
    }));

    formData.currency = getCurrency(userAgent);
    formData.price = getTotalAmount();
    formData.paidPrice = getTotalAmount();
    formData.walletPrice = 0;
    formData.installment = 1;
    formData.expireYear = formData.expireDate.year().toString();
    formData.expireMonth = (formData.expireDate.month() + 1).toString();

    dispatch(payWithNewCard(formData))
      .unwrap()
      .then((settlement: Settlement) => {
        NotificationController.success({
          message: t("notifications.success.pay"),
        });

        const successNavigationPath = APPLICATION_PATHS.PAYMENTS_PATH.replace(
          ":organizationId",
          currentOrganization.id
        );

        checkAndModifyOrganizationDisabledStatus(settlement);
        navigate(successNavigationPath);
      })
      .catch((err) => {
        NotificationController.error({ message: t("notifications.error.pay") });
      });
  };

  const customWeekFormat = "MM/YY";

  const BILLING_PROFILE_OPTIONS: SelectProps["options"] = billingProfiles.map(
    (profile) => ({
      label: profile.title,
      value: profile.id,
    })
  );

  return (
    <Row gutter={[12, 24]}>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 24, order: 1 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
        xxl={{ span: 16, order: 1 }}
      >
        <BaseForm
          form={form}
          initialValues={initialFormValues}
          onFinish={onSubmitHandler}
          onValuesChange={() => setFieldValues(form.getFieldsValue())}
        >
          <BaseForm.Item
            name="billingProfileId"
            label={t("pages.organizations.billingProfile")}
          >
            <Select
              placeholder={t("pages.organizations.billingProfile")}
              options={BILLING_PROFILE_OPTIONS}
              loading={billingProfilesStatus === RequestStatus.PENDING}
            />
          </BaseForm.Item>

          <BaseForm.Item
            name={"cardHolderName"}
            label={t("pages.organizations.cardHolderName")}
          >
            <Input placeholder={t("pages.organizations.cardHolderName")} />
          </BaseForm.Item>

          <BaseForm.Item
            name={"cardNumber"}
            label={t("pages.organizations.cardNumber")}
          >
            <S.CustomInputNumber
              controls={false}
              placeholder={t("pages.organizations.cardNumber")}
            />
          </BaseForm.Item>

          <Row gutter={[12, 12]}>
            <Col span={18}>
              <BaseForm.Item
                name={"expireDate"}
                label={t("pages.organizations.expireDate")}
              >
                <S.CustomDayjsDatePicker
                  picker="month"
                  clearIcon={false}
                  format={customWeekFormat}
                  placeholder={t("pages.organizations.expireDate")}
                />
              </BaseForm.Item>
            </Col>
            <Col span={6}>
              <BaseForm.Item name={"cvc"} label={t("pages.organizations.cvc")}>
                <S.CustomInputNumber
                  controls={false}
                  placeholder={t("pages.organizations.cvc")}
                  onClick={() => setFocusedBackOfCard(true)}
                  onBlur={() => setFocusedBackOfCard(false)}
                />
              </BaseForm.Item>
            </Col>
          </Row>

          <S.ButtonContainer>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={settlementStatus === RequestStatus.PENDING}
            >
              {`${t("pages.organizations.pay")} ${getCurrencySymbol(
                userAgent
              )} ${getTotalAmount()}`}
            </Button>
          </S.ButtonContainer>
        </BaseForm>
      </Col>
      <Col
        xs={{ span: 24, order: 0 }}
        sm={{ span: 24, order: 0 }}
        md={{ span: 24, order: 0 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
        xxl={{ span: 8, order: 1 }}
      >
        <S.FullHeightRow justify="center" align="middle">
          <PaymentCard
            showcase={true}
            cardHolderName={fieldValues.cardHolderName}
            expireDate={fieldValues.expireDate.format("MM/YY")}
            cardNumber={fieldValues.cardNumber}
            cvc={fieldValues.cvc}
            focusedBack={focusedBackOfCard}
          />
        </S.FullHeightRow>
      </Col>
    </Row>
  );
};

export default NewCardPaymentForm;
