import React from "react";
import { motion } from "framer-motion";

const pageMotion = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.2 } },
  exit: { opacity: 0, y: 10, transition: { duration: 0.2 } },
};

type ReturnType<T> = (props: T) => JSX.Element;

const withAnimation = <T extends object>(
  Component: React.ComponentType<T>
): ReturnType<T> => {
  return (props: T) => (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageMotion}
    >
      <Component {...props} />
    </motion.div>
  );
};

export default withAnimation;
