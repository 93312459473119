import {
    SecurityScanOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { INavigationItem } from "@common/interfaces";

const UserProfileNavigationData: INavigationItem[] = [
    {
        title: "pages.profile.personalInfo",
        key: "personal-info",
        url: "personal-info",
        icon: <UserOutlined />,
        color: "primary",
    },
    {
        title: "pages.profile.securitySettings",
        key: "security-settings",
        url: "security-settings",
        icon: <SecurityScanOutlined />,
        color: "warning",
    },
];

export default UserProfileNavigationData;
