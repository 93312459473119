import { LanguageType, Organization, ThemeType, User } from "@common/index";
import { getBrowserLanguage } from "@utils/index";

const DEFAULT_LANGUAGE: LanguageType = "en";
const BROWSER_LANGUAGE: LanguageType = getBrowserLanguage();

export const persistLanguage = (langugage: LanguageType): void => {
  localStorage.setItem("lng", langugage);
};

export const fetchLanguage = (): LanguageType | undefined => {
  const language = localStorage.getItem("lng");
  return language ? (language as LanguageType) : undefined;
};

export const persistTheme = (theme: ThemeType) => {
  localStorage.setItem("theme", theme);
};

export const fetchTheme = (): ThemeType | undefined => {
  const theme = localStorage.getItem("theme");
  return theme ? (theme as ThemeType) : undefined;
};

export const readUser = (): User => {
  const userStr = localStorage.getItem("user");
  return userStr ? JSON.parse(userStr) : {};
};

export const persistUser = (user: User): void => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const clearUser = (): void => {
  localStorage.removeItem("user");
};

export const readBearerToken = (): string => {
  return localStorage.getItem("bearerToken") || "";
};

export const persistBearerToken = (token: string) => {
  localStorage.setItem("bearerToken", token);
};

export const clearBearerToken = () => {
  localStorage.removeItem("bearerToken");
};

export const readKeycloakToken = (): string => {
  return localStorage.getItem("keycloakToken") || "";
};

export const persistKeycloakToken = (token: string) => {
  localStorage.setItem("keycloakToken", token);
};

export const clearKeycloakToken = () => {
  localStorage.removeItem("keycloakToken");
};

export const readOrganization = (): Organization | undefined => {
  const orgStr = localStorage.getItem("organization");

  return orgStr && orgStr !== "" ? JSON.parse(orgStr) : undefined;
};

export const persistOrganization = (organization: Organization): void => {
  localStorage.setItem(
    "organization",
    organization ? JSON.stringify(organization) : ""
  );
};

export const clearOrganization = (): void => {
  localStorage.removeItem("organization");
};

export const persistDefaultDashboard = (dashboardId: string): void => {
  localStorage.setItem("defaultDashboardId", dashboardId)
}

export const readDefaultDashboard = (): string | null => {
  return localStorage.getItem("defaultDashboardId");
}

export const clearDefaultDashboard = (): void => {
  localStorage.removeItem("defaultDashboardId");
}
