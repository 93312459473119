import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { default as ReactCreditCard } from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import styled from "styled-components";

interface ICardWrapperProps {
  $background: string;
}

export const CardWrapper = styled.div<ICardWrapperProps>`
  display: inline-block;
  position: relative;

  & > .rccs > .rccs__card--unknown > div {
    background: ${(props) => `url(${props.$background})`};
    background-size: cover;
    transition: all 0.5s ease;
  }

  & > .rccs > .rccs__card {
    & .rccs__card--front,
    & .rccs__card--back {
      box-shadow: none;
    }

    & > .rccs__card--front .rccs__issuer {
      right: unset;
      left: 10%;
      background-position: left;
    }

    & > div {
      color: var(--text-secondary-color);

      & > .rccs__chip {
        display: none;
      }

      & > div:first-of-type {
        background: ${(props) => `url(${props.$background})`};
        background-size: cover;
        transition: all 0.5s ease;
      }
    }
  }
`;

export const Card = styled(ReactCreditCard)``;

export const ActionContainer = styled.div`
  position: absolute;
  top: 27px;
  right: 20px;
`;

export const CustomDeleteIcon = styled(DeleteOutlined)`
  svg {
    transition: 0.5s;
    color: white;
    font-size: 25px;
  }

  &:hover {
    svg {
      color: var(--red);
    }
  }
`;

export const CustomEditIcon = styled(EditOutlined)`
  svg {
    transition: 0.5s;
    color: white;
    font-size: 25px;
  }

  &:hover {
    svg {
      color: var(--orange);
    }
  }
`;
