import { ThemeType } from "@common/index";
import { useAppSelector, useLanguage, useThemeWatcher } from "@hooks/index";
import { AppRouter } from "@routes/index";
import { GlobalStyle } from "@styles/GlobalStyle";
import { themeObject } from "@styles/themes/ThemeVariables";
import { getLocale } from "@utils/index";
import { ConfigProvider } from "antd";
import "typeface-lato";
import "typeface-montserrat";
import "./index.less";

const App = () => {
  const { language } = useLanguage();
  const theme: ThemeType = useAppSelector((state) => state.theme.data);

  useThemeWatcher();

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primaryColor} />
      <GlobalStyle />
      <ConfigProvider locale={getLocale(language)}>
        <AppRouter />
      </ConfigProvider>
    </>
  );
};

export default App;
