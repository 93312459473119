import { AddDatasetForm, IModalBaseProps, Organization, User } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { addDataset } from "@store/slices/Datasets/thunks";
import { Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AddDatasetModal: React.FC<IModalBaseProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);
  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };
  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (form: AddDatasetForm) => {
    form.tenantId = currentOrganization.id;
    form.userId = currentUser.id;
    form.users = [];

    dispatch(addDataset(form))
      .unwrap()
      .then((res) => {
        navigate(APPLICATION_PATHS.DATASET_PATH);
        NotificationController.success({
          message: t("notifications.success.addDataset", {
            datasetName: form.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addDataset", {
            datasetName: form.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.addDatasetModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addDatasetForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label={t("common.description")}>
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddDatasetModal;
