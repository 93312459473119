import { FONT_SIZE } from "@common/constants";
import { Menu, Row } from "antd";
import styled, { css } from "styled-components";
import { P2 } from "../Typography";

export const CardMenu = styled(Menu)`
  box-shadow: var(--box-shadow);
  z-index: 999;
`;

export const MenuItem = styled(Menu.Item)`
  font-size: ${FONT_SIZE.xs};
`;

export const HeaderRow = styled(Row)`
  align-items: center;
`;

export const OrganizationSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
  text-overflow: ellipsis !important;
  margin: 0;
`;

export const OrganizationHeaderText = styled(P2)`
  &.ant-typography {
    color: var(--text-sider-secondary-color);
  }
`;
