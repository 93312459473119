import { InfoCircleOutlined } from "@ant-design/icons";
import { AddKpiForm } from "@common/formTypes";
import {
  AutocompleteItem,
  BASE_COLORS,
  Dataset,
  IModalBaseProps,
  Metric,
} from "@common/index";
import {
  AutocompleteItemComponent,
  BaseForm,
  Loading,
  Modal,
} from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addKpi } from "@store/slices/DatasetContent/thunks";
import { Input, Row, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import { useTranslation } from "react-i18next";
import * as S from "./AddKpiModal.style";
import useAxios from "@app/hooks/useAxios";

interface IAddKpiModalProps extends IModalBaseProps {
  dataset: Dataset;
}

const AddKpiModal: React.FC<IAddKpiModalProps> = ({
  dataset,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const [functionStrings, setFunctionStrings] = useState<string[]>([]);
  const [triggerCharacters, setTriggerCharacters] = useState<string[]>([]);

  const datasetMetrics = useAppSelector(
    (state) => state.datasetContent.metrics.data
  );

  const getTriggerProps = () => {
    const triggerProps = triggerCharacters.reduce((triggers, char) => {
      (triggers as Record<string, any>)[char] = {
        dataProvider: (token: string) =>
          functionStrings
            .filter((str) => str.startsWith(char + token))
            .map((name) => ({ name: `${name}`, char: `${name}` })),
        component: AutocompleteItemComponent,
        output: (item: { name: string; char: string }, trigger: string) =>
          item.name,
      };
      return triggers;
    }, {} as Record<string, any>);

    triggerProps["("] = {
      dataProvider: (token: string) => {
        const lowerCaseToken = token.toLowerCase();
        return metricNames
          .filter((name) => name.toLowerCase().startsWith(lowerCaseToken))
          .map((name) => ({
            name,
            char: `(${name})`,
          }));
      },
      component: AutocompleteItemComponent,
      output: (item: { name: string; char: string }, trigger: string) =>
        item.char,
    };

    return triggerProps;
  };

  const textareaAutocompleteProps: any = {
    className: "ant-input ant-input-textarea",
    rows: 4,
    loadingComponent: () => <Loading size="2em" />,
    minChar: 0,
    dropdownStyle: {
      position: "absolute",
    },
    listStyle: {
      position: "absolute",
      top: 20,
      zIndex: 1000,
      backgroundColor: BASE_COLORS.white,
      border: "1px",
      overflowY: "auto",
      padding: "4px",
      borderRadius: "4px",
      boxShadow: "0 4px 8px",
      whiteSpace: "nowrap",
    },
    trigger: getTriggerProps(),
  };

  const metricNames = useMemo(() => {
    return datasetMetrics.map((metric) => metric.name);
  }, [datasetMetrics]);

  const { data, isLoading, error, triggerRequest } = useAxios<string[]>({
    method: "get",
    url: "https://server.visudat.com:5043/function",
  });

  useEffect(() => {
    triggerRequest();
  }, []);

  useEffect(() => {
    if (data) {
      setFunctionStrings(data);
      const triggers = data.map((word) => word[0]);
      setTriggerCharacters([...new Set(triggers)]);
    }
  }, [data]);

  const getMetricByName = (metricName: string): Metric | undefined => {
    return datasetMetrics.find((metric) => {
      return metric.name.toLowerCase() === metricName.toLowerCase();
    });
  };

  const parseMetricNamesInKpiExpression = (expression: string) => {
    try {
      const regexBetweenParanthesis = /\(([^()]+)\)/g;
      const match: RegExpMatchArray | null = expression.match(
        regexBetweenParanthesis
      );

      let replacedExpression = expression;
      match?.forEach((metricNameWithParanthesis: any) => {
        const metricName = metricNameWithParanthesis.substring(
          1,
          metricNameWithParanthesis.length - 1
        );
        const corrMetric = getMetricByName(metricName);

        if (corrMetric) {
          replacedExpression = replacedExpression.replace(
            metricName,
            corrMetric.id
          );
        }
      });
      return replacedExpression;
    } catch (err) {
      console.error("Parsing Error in function parseKpiExpression.");
      return undefined;
    }
  };

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: AddKpiForm) => {
    formData.datasetId = dataset.id;

    const parsedExpression: string =
      parseMetricNamesInKpiExpression(formData.expression) || "";
    formData.expression = parsedExpression;

    dispatch(addKpi(formData))
      .unwrap()
      .then(() => {
        NotificationController.success({
          message: t("notifications.success.addKPI", {
            kpiName: formData.name,
          }),
        });
        form.resetFields();
      })
      .catch(() => {
        NotificationController.error({
          message: t("notifications.error.addKPI", { kpiName: formData.name }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.addKpiModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addKpiForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label={t("common.description")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="expression" label={t("common.expression")}>
          <ReactTextareaAutocomplete<AutocompleteItem>
            {...textareaAutocompleteProps}
          />
        </BaseForm.Item>
        <Tooltip
          title={t("modals.addKpiModal.tooltip")}
          placement="top"
          overlayStyle={{
            width: "320px",
            whiteSpace: "pre-line",
          }}
        >
          <Row justify="center" align="middle">
            <S.InfoCircleContainer>
              <InfoCircleOutlined />
            </S.InfoCircleContainer>

            <S.TooltipText>
              {t("modals.addKpiModal.tooltipTitle")}
            </S.TooltipText>
          </Row>
        </Tooltip>
      </BaseForm>
    </Modal>
  );
};

export default AddKpiModal;
