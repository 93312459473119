import styled from "styled-components";
import { Card as BaseCard } from "@components/common";
import { media } from "@common/constants";

export const Card = styled(BaseCard)`
  display: flex;
  overflow: hidden;

  width: 100%;
  height: 350px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
  }

  @media only screen and ${media.xl} {
    width: 100%;
    height: 100%;
  }
`;

export const hello = styled.div``;
