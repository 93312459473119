import { Text } from "@components/common";
import { List } from "antd";
import styled from "styled-components";

export const BulletPoint = styled.span`
  margin-right: 8px;
  font-weight: bold;
`;

export const CustomListItem = styled(List.Item)`
  padding-left: 20px;
  margin: 0.01px 0;
  border: none;
  &:last-child {
    border-bottom: none;
  }
`;

export const CustomList = styled(List)`
  .ant-list-items > .ant-list-item:not(:last-child) {
    border-bottom: none;
  }
`;

export const CustomText = styled(Text)`
  display: block;
  margin-bottom: 0.5rem;
`;
