import CardTheme from "@assets/images/cardTheme5.png";
import {
  DeletePaymentCardForm, Organization,
  PaymentCard as PaymentCardType
} from "@common/index";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { deletePaymentCard } from "@store/slices/Payments/thunks";
import { Popconfirm, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { EditPaymentCardModal } from "../../modals";
import * as S from "./PaymentCard.style";

export interface PaymentCardProps {
  card: PaymentCardType;
  modifiable: boolean;
}

interface IShowcasePaymentCardProps {
  showcase: true;
  cardNumber: string | number;
  cardHolderName: string;
  expireDate: string;
  cvc?: string;
  focusedBack?: boolean;
}

interface IRealPaymentCardProps {
  showcase: false;
  card: PaymentCardType;
  modifiable: boolean;
  focusedBack?: boolean;
}

type PaymentCardPropsType = IShowcasePaymentCardProps | IRealPaymentCardProps;

const EMPTY_FIELD: string = "";

const PaymentCard: React.FC<PaymentCardPropsType> = (
  props: PaymentCardPropsType
) => {
  const showcase = props.showcase;
  const focusedBack = props.focusedBack;

  const card = !showcase && props.card;
  const modifiable = !showcase && props.modifiable;
  const cardNumber = showcase && props.cardNumber;
  const cardHolderName = showcase && props.cardHolderName;
  const expireDate = showcase && props.expireDate;
  const cvc = showcase && props.cvc;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const [isEditPaymentCardModalOpen, setIsEditPaymentCardModalOpen] =
    useState<boolean>(false);

  const showCreditCardNumber = (): string => {
    if (!card) return cardNumber?.toString() || EMPTY_FIELD;

    const firstEightDigits = card.binNumber;
    const lastTwoDigits = card.lastFourDigits.slice(-2);
    const middleDigits = "******";

    return `${firstEightDigits}${middleDigits}${lastTwoDigits}`;
  };

  const showCardHolderName = (): string => {
    if (!card) return cardHolderName || EMPTY_FIELD;

    return "******* *****";
  };

  const showCvc = (): string => {
    if (!card && cvc) return cvc;
    return "***";
  };

  const showExpireDate = (): string => {
    if (!card) return expireDate || EMPTY_FIELD;

    return "**/**";
  };

  const onDeleteClick = () => {
    if (!card) return;

    const form: DeletePaymentCardForm = {
      tenantId: currentOrganization.id,
      cardToken: card.cardToken!,
    };

    dispatch(deletePaymentCard(form))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.deletePaymentCard"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.deletePaymentCard"),
        });
      });
  };

  return (
    <React.Fragment>
      {!showcase && modifiable && card && (
        <EditPaymentCardModal
          card={card}
          isOpen={isEditPaymentCardModalOpen}
          setIsOpen={setIsEditPaymentCardModalOpen}
        />
      )}
      <S.CardWrapper $background={CardTheme}>
        <S.Card
          focused={focusedBack ? "cvc" : "name"}
          cvc={showCvc()}
          expiry={showExpireDate()}
          name={showCardHolderName()}
          number={showCreditCardNumber()}
        />

        {!showcase && modifiable && card && (
          <S.ActionContainer>
            <Space>
              <Popconfirm
                title={t("pages.organizations.deleteCard")}
                onConfirm={onDeleteClick}
                okText={t("common.delete")}
                cancelText={t("common.cancel")}
              >
                <S.CustomDeleteIcon />
              </Popconfirm>

              <S.CustomEditIcon
                onClick={() => setIsEditPaymentCardModalOpen(true)}
              />
            </Space>
          </S.ActionContainer>
        )}
      </S.CardWrapper>
    </React.Fragment>
  );
};

export default PaymentCard;
