import {
  BillingProfile,
  ChangeLicenseOfOrganizationForm,
  CountryDetail as CountryDetailType,
  Dashboard,
  Dataset,
  License,
  Organization,
  PaymentCard as PaymentCardType,
  User,
} from "@common/index";
import { H2 } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { changeLicenseOfOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import {
  findOrganizationShareType,
  getLicenseCurrencySymbolAndPrice,
  getLicenseTitle,
} from "@utils/index";
import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DowngradeLicenseModal,
  RedirectMissingPaymentInformationModal,
} from "../../modals";
import { ChangeLicenseModal } from "../../modals/ChangeLicenseModal";
import * as S from "./LicensePricingCard.style";

interface ILicensePricingCardProps {
  license: License;
  actionable: boolean;
  activeLicense: License;
}

const LicensePricingCard: React.FC<ILicensePricingCardProps> = ({
  license,
  actionable,
  activeLicense,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userAgent: CountryDetailType | null = useAppSelector(
    (state) => state.userAgent.local.data
  );

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.ownedDashboards.data
  );

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const billingProfiles: BillingProfile[] = useAppSelector(
    (state) => state.payments.billingProfiles.data
  );

  const paymentCards: PaymentCardType[] = useAppSelector(
    (state) => state.payments.paymentCards.data
  );

  const [isDowngradeLicenseModalOpen, setIsDowngradeLicenseModalOpen] =
    useState<boolean>(false);

  const [isChangeLicenseModalOpen, setIsChangeLicenseModalOpen] =
    useState<boolean>(false);

  const [
    isRedirectMissingPaymentInformationModalOpen,
    setIsRedirectMissingPaymentInformationModalOpen,
  ] = useState<boolean>(false);

  const getLicenseInfo = (license: License) => {
    const { price, symbol } = getLicenseCurrencySymbolAndPrice(
      license,
      userAgent
    );

    const licenseInfo = {
      title: getLicenseTitle(license.licenseType),
      price,
      symbol,
    };

    return licenseInfo;
  };

  const filterDashboardByOrganization = (dashboards: Dashboard[]) => {
    return dashboards.filter((d) => d.tenant === currentOrganization.id);
  };

  const filterDatasetByOrganization = (datasets: Dataset[]) => {
    return datasets.filter((d) => d.tenant === currentOrganization.id);
  };

  const changeLicense = () => {
    const data: ChangeLicenseOfOrganizationForm = {
      tenantId: currentOrganization.id,
      licenseType: license.licenseType,
      duration: 1, // Specifies the license duration in terms of month,
    };

    dispatch(changeLicenseOfOrganization(data))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.changeLicense", {
            licenseType: getLicenseTitle(res.type),
          }),
        });

        const modifiedOrganization: Organization = {
          ...currentOrganization,
          license: {
            type: res.type,
            startTime: res.startTime,
            validTime: res.validTime,
          },
        };
        dispatch(
          reflectOrganizationEdit({
            organization: modifiedOrganization,
            organizationType: findOrganizationShareType(
              modifiedOrganization,
              currentUser.id
            ),
          })
        );
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.changeLicense"),
        });
      });
  };

  const onSelectLicenseClickHandler = () => {
    const isPaymentInformationComplete =
      billingProfiles.length > 0 && paymentCards.length > 0;

    isPaymentInformationComplete
      ? setIsChangeLicenseModalOpen(true)
      : setIsRedirectMissingPaymentInformationModalOpen(true);
  };

  const onLicenseChangeConfirmHandler = () => {
    const targetLicense = license;

    const organizationUserCount = currentOrganization.users.length + 1;

    const ownedDatasetsCount: number =
      filterDatasetByOrganization(ownedDatasets).length;

    const ownedDashboardsCount: number =
      filterDashboardByOrganization(ownedDashboards).length;

    const elligibleToChangeLicense: boolean =
      targetLicense.userCount >= organizationUserCount &&
      targetLicense.datasetCount >= ownedDatasetsCount &&
      targetLicense.dashboardCount >= ownedDashboardsCount;

    elligibleToChangeLicense
      ? changeLicense()
      : setIsDowngradeLicenseModalOpen(true);
  };

  const renderBullets = () => {
    const bullets = [
      t("pages.organizations.maxUserCount", {
        maxUserCount: license.userCount,
      }),
      t("pages.organizations.maxDatasetCount", {
        maxDatasetCount: license.datasetCount,
      }),
      t("pages.organizations.maxDashboardCount", {
        maxDashboardCount: license.dashboardCount,
      }),
      t("pages.organizations.maxRetentionPeriod", {
        maxRetentionPeriod: license.retention,
      }),
    ];

    return (
      <S.BulletsContainer>
        {bullets.map((bullet) => (
          <Row justify="center">
            <S.Bullet>{bullet}</S.Bullet>
          </Row>
        ))}
      </S.BulletsContainer>
    );
  };

  return (
    <React.Fragment>
      <RedirectMissingPaymentInformationModal
        isOpen={isRedirectMissingPaymentInformationModalOpen}
        setIsOpen={setIsRedirectMissingPaymentInformationModalOpen}
      />

      <DowngradeLicenseModal
        license={license}
        isOpen={isDowngradeLicenseModalOpen}
        setIsOpen={setIsDowngradeLicenseModalOpen}
      />

      <ChangeLicenseModal
        isOpen={isChangeLicenseModalOpen}
        setIsOpen={setIsChangeLicenseModalOpen}
        onOk={onLicenseChangeConfirmHandler}
      />

      <S.Card autoHeight={false} padding={[24, 20]}>
        <Col>
          <Row justify="center">
            <H2>{getLicenseInfo(license).title}</H2>
          </Row>

          <S.PricingRow>
            <S.CurrencySymbol>
              {getLicenseInfo(license).symbol}
            </S.CurrencySymbol>
            <S.PricingTitle>{getLicenseInfo(license).price}</S.PricingTitle>
          </S.PricingRow>

          {renderBullets()}

          {actionable && (
            <Row justify="center">
              <Button
                disabled={activeLicense.licenseType === license.licenseType}
                type="primary"
                size="small"
                onClick={onSelectLicenseClickHandler}>
                {activeLicense.licenseType === license.licenseType
                  ? t("pages.organizations.currentLicense")
                  : t("pages.organizations.selectLicense")}
              </Button>
            </Row>
          )}
        </Col>
      </S.Card>
    </React.Fragment>
  );
};

export default LicensePricingCard;
