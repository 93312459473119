import { axios } from '@app/api';
import { AxiosRequestConfig } from '@app/api/Axios/axios';
import { useState } from 'react';

function useAxios<T>(axiosParams: AxiosRequestConfig): {
  data: T | undefined;
  isLoading: boolean;
  error: any;
  triggerRequest: () => void;
} {
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const makeRequest = async (params: AxiosRequestConfig) => {
    try {
      const result = await axios.request<T>(params);
      setData(result.data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const triggerRequest = () => {
    makeRequest(axiosParams);
  };

  return {
    data,
    isLoading,
    error,
    triggerRequest,
  };
}

export default useAxios;
