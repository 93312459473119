import { H1, H6 } from "@components/common";
import { Select } from "antd";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";

export const LoadingAndNoDataContainer = styled.div`
  width: 100%;
  height: 400px;
`;

export const SectionHeaderTitle = styled(H1)``;

export const SectionHeaderSubtitle = styled(H6)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-light-color);
  }
`;

export const CustomSelect = styled(Select)`
  min-width: 200px;
`;

export const ResponsiveGridLayoutContainer = styled.div`
  padding: 0;
  margin: 0 0 2rem 0;
`;

export const ResponsiveGridLayout = styled(WidthProvider(Responsive))`
  max-width: 100%;
`;

export const LoadingContainer = styled.span``;
