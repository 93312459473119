import styled from "styled-components";
import { Divider, Menu } from "antd";
import { H5 } from "@components/common";

export const MenuItem = styled(Menu.Item)`
  height: 50px;
`;

export const CustomDivider = styled(Divider)`
  margin: 0;
`;

export const Text = styled(H5)`
  min-width: 50px;

  & > a {
    display: block;
  }
`;
