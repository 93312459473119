import { FONT_SIZE } from "@common/index";
import { Card as BaseCard, H1, P1, P2 } from "@components/common";
import { Row } from "antd";
import styled from "styled-components";



export const Card = styled(BaseCard)`
  box-shadow: var(--box-shadow);
  min-height: 13rem;
  min-width: 10rem;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const CurrencySymbol = styled(P1)`
  &.ant-typography {
    font-size: ${FONT_SIZE.xl};
  }
`;

export const PricingRow = styled(Row)`
  justify-content: center;
  margin: 0.5rem 0 0.5rem 0;
`;

export const PricingTitle = styled(H1)`
  &.ant-typography {
    margin: 0 !important;
    font-size: ${FONT_SIZE.x5l};
  }
`;

export const Description = styled(P2)`
  margin: 0 0 1rem 0;
  text-align: center;

  &.ant-typography {
    color: var(--text-light-color);
  }
`;

export const BulletsContainer = styled.div`
  margin: 1rem 0 1.25rem 0;
`;

export const Bullet = styled(P2)`
  text-align: center;

  &.ant-typography {
    color: var(--text-light-color);
  }
`;
