import { Dashboard, License } from "@common/index";
import { BaseForm, Card, H1 } from "@components/common";
import { Checkbox, Form, List } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DowngradeDashboardDeleteCard.style";

interface IDowngradeDashboardDeleteCardProps {
  title: string;
  dashboardList: Dashboard[];
  selectedLicense: License;
  dashboardsToDelete: Dashboard[];
  onDashboardsToDeleteChange: (dashboardsToDelete: Dashboard[]) => void;
}

const DowngradeDashboardDeleteCard: React.FC<
  IDowngradeDashboardDeleteCardProps
> = ({
  title,
  dashboardList,
  selectedLicense,
  dashboardsToDelete,
  onDashboardsToDeleteChange,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const isErrorText =
    dashboardList.length - dashboardsToDelete.length >
    selectedLicense.dashboardCount;

  const handleCheckboxChange = (dashboard: Dashboard) => {
    const index = dashboardsToDelete.findIndex(
      (selectedDashboard) => selectedDashboard.id === dashboard.id
    );

    if (index === -1) {
      onDashboardsToDeleteChange([...dashboardsToDelete, dashboard]);
      setSelectedRows([...selectedRows, dashboard.id]);
    } else {
      onDashboardsToDeleteChange(
        dashboardsToDelete.filter(
          (selectedDashboard) => selectedDashboard.id !== dashboard.id
        )
      );
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== dashboard.id)
      );
    }
  };

  const renderItem = (dashboard: Dashboard) => {
    return (
      <S.UserListItem>
        <S.CheckboxWrapper>
          <Checkbox
            checked={
              dashboardsToDelete.findIndex(
                (selectedDashboard) => selectedDashboard.id === dashboard.id
              ) !== -1
            }
            onChange={() => handleCheckboxChange(dashboard)}
          />
        </S.CheckboxWrapper>
        <List.Item.Meta
          title={
            <S.TitleWrapper>
              <S.StyledTitle>{dashboard.name}</S.StyledTitle>
              <S.Description>{dashboard.dataset.name}</S.Description>
            </S.TitleWrapper>
          }
        />
      </S.UserListItem>
    );
  };

  return (
    <Card padding={[30, 30]}>
      <S.HeaderRow>
        <H1>{title}</H1>

        <S.CustomText isErrorText={isErrorText}>
          {dashboardList.length -
            dashboardsToDelete.length +
            " / " +
            selectedLicense.dashboardCount}
        </S.CustomText>
      </S.HeaderRow>

      <S.InfoText>
        {dashboardsToDelete.length}
        {t("pages.downgradeLicense.dashboardsSelected")}
      </S.InfoText>

      <BaseForm form={form}>
        <List dataSource={dashboardList} renderItem={renderItem} />
      </BaseForm>
    </Card>
  );
};

export default DowngradeDashboardDeleteCard;
