import { Space } from "antd";
import styled from "styled-components";

export const CustomSpacer = styled(Space).attrs({ direction: "vertical" })`
  display: flex;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
`;
