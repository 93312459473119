import { useAppDispatch } from "@app/hooks";
import { Dates } from "@app/services";
import { addMetricRecord } from "@app/store/slices/DatasetContent/thunks";
import {
  AddMetricRecordForm,
  AppDate,
  IModalBaseProps,
  Metric,
} from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./AddMetricRecordModal.style";

interface IAddMetricRecordModalProps extends IModalBaseProps {
  date: AppDate;
  metric: Metric;
}

const AddMetricRecordModal: React.FC<IAddMetricRecordModalProps> = ({
  date,
  metric,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();

  const onOkHandler = () => {
    setIsOpen(false);
    form.submit();
  };

  const onCancelHandler = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const onAddMetricRecordHandler = (formData: AddMetricRecordForm) => {
    formData.metricId = metric.id;

    const modifiedTimestamp = date
      .set("hour", (formData.timestamp as AppDate).hour())
      .set("minute", (formData.timestamp as AppDate).minute());

    formData.timestamp = modifiedTimestamp.toISOString();

    dispatch(addMetricRecord(formData));
  };

  const initialFormValues = {
    timestamp: Dates.getToday(),
  };

  return (
    <Modal
      title={t("modals.addMetricRecordModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <BaseForm
        form={form}
        initialValues={initialFormValues}
        onFinish={onAddMetricRecordHandler}
      >
        <Row gutter={[12, 12]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <BaseForm.Item
              name="timestamp"
              label={t("modals.addMetricRecordModal.time")}
            >
              <S.CustomDatePicker />
            </BaseForm.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <BaseForm.Item
              name="value"
              label={t("modals.addMetricRecordModal.value")}
            >
              <S.CustomInputNumber
                placeholder={t("modals.addMetricRecordModal.value")}
              />
            </BaseForm.Item>
          </Col>
        </Row>
      </BaseForm>
    </Modal>
  );
};

export default AddMetricRecordModal;
