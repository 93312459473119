import { Dashboard, DeleteDashboardForm, IModalBaseProps, User } from "@common/index";
import { Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { deleteDashboard } from "@store/slices/DashboardContent/thunks";
import { reflectDashboardDelete } from "@store/slices/Dashboards/slice";
import { findDashboardShareType } from "@utils/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IDeleteDashboardModalProps extends IModalBaseProps {
  dashboard: Dashboard;
}

const DeleteDashboardModal: React.FC<IDeleteDashboardModalProps> = ({
  dashboard,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    setIsOpen(false);

    const form: DeleteDashboardForm = {
      dashboardId: dashboard.id,
      userId: currentUser.id,
    };

    dispatch(deleteDashboard(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDashboardDelete({
            dashboard: dashboard,
            dashboardType: findDashboardShareType(dashboard, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.deleteDashboard", {
            dashboardName: dashboard.name,
          }),
        });
        navigate(APPLICATION_PATHS.DASHBOARD_PATH);
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.deleteDashboard", {
            dashboardName: dashboard.name,
          }),
        });
      });
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteDashboardModal.title", {
        dashboardName: dashboard?.name,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteDashboardModal;
