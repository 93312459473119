import { UserForgotPasswordForm } from "@common/index";
import { BaseForm } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { sendPasswordResetEmail } from "@store/slices/UserContent/thunks";
import { Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as AuthStyles from "../../AuthPages.style";

const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const ANTD_REQUIRE_RULE = {
    required: true,
    message: t("errors.requiredField"),
  };

  const ANTD_EMAIL_RULE = {
    required: true,
    type: "email",
    message: t("errors.invalidEmail"),
  };

  const onSubmitHandler = (formData: UserForgotPasswordForm) => {
    setIsLoading(true);
    dispatch(sendPasswordResetEmail(formData.email))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.passwordForgot"),
        });
        navigate(APPLICATION_PATHS.LOGIN_PATH);
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.passwordForgot"),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <BaseForm onFinish={onSubmitHandler}>
      <AuthStyles.FormItem
        label={t("pages.auth.email")}
        name="email"
        rules={[ANTD_REQUIRE_RULE, ANTD_EMAIL_RULE]}
      >
        <Input placeholder={t("pages.auth.email")} />
      </AuthStyles.FormItem>

      <AuthStyles.SubmitButton
        loading={isLoading}
        type="primary"
        htmlType="submit"
      >
        {t("pages.auth.recoverPassword")}
      </AuthStyles.SubmitButton>

      <AuthStyles.FooterWrapper>
        <AuthStyles.Text>
          <Link to={APPLICATION_PATHS.LOGIN_PATH}>
            <AuthStyles.LinkText>
              {t("pages.auth.backToLogin")}
            </AuthStyles.LinkText>
          </Link>
        </AuthStyles.Text>
      </AuthStyles.FooterWrapper>
    </BaseForm>
  );
};

export default ForgotPasswordForm;
