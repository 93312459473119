import { PlusOutlined } from "@ant-design/icons";
import { BillingProfile, Organization, RequestStatus } from "@common/index";
import { Card, H2, Loading, NoData, SectionHeader } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { fetchBillingProfiles } from "@store/slices/Payments/thunks";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BillingProfileCard } from "../";
import { AddBillingProfileModal } from "../../modals";
import * as S from "./BillingProfilesSection.style";

const BillingInfoSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const billingProfiles: BillingProfile[] = useAppSelector(
    (state) => state.payments.billingProfiles.data
  );

  const billingProfilesStatus: RequestStatus = useAppSelector(
    (state) => state.payments.billingProfiles.status
  );

  const [isAddBillingProfileModalOpen, setIsAddBillingProfileModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    currentOrganization &&
      dispatch(fetchBillingProfiles(currentOrganization.id));
  }, [currentOrganization]);

  const renderLoading = () => {
    return (
      <S.LoadingAndNoDataContainer>
        <Loading size="4rem" />
      </S.LoadingAndNoDataContainer>
    );
  };

  const renderNoData = () => {
    return (
      <S.LoadingAndNoDataContainer>
        <NoData />
      </S.LoadingAndNoDataContainer>
    );
  };

  const renderBillingProfiles = () => {
    return (
      <S.CustomSpacer direction="vertical">
        {billingProfiles.map((profile) => (
          <BillingProfileCard key={profile.id} billingProfile={profile} />
        ))}
      </S.CustomSpacer>
    );
  };

  const renderItems = () => {
    return billingProfilesStatus === RequestStatus.PENDING
      ? renderLoading()
      : billingProfiles.length === 0
      ? renderNoData()
      : renderBillingProfiles();
  };

  return (
    <React.Fragment>
      <AddBillingProfileModal
        isOpen={isAddBillingProfileModalOpen}
        setIsOpen={setIsAddBillingProfileModalOpen}
      />

      <Card padding={[25, 25]}>
        <SectionHeader
          title={<H2>{t("pages.organizations.billingProfiles")}</H2>}
          customMargin="0 0 1.75rem 0"
        >
          <S.BtnContainer
            type="text"
            size="small"
            onClick={() => setIsAddBillingProfileModalOpen(true)}
          >
            <PlusOutlined />
          </S.BtnContainer>
        </SectionHeader>
        {renderItems()}
      </Card>
    </React.Fragment>
  );
};

export default BillingInfoSection;
