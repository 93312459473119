import { H2 } from "@components/common";
import { Button } from "antd";
import styled from "styled-components";

export const LoadingContainer = styled.div`
  width: 100%;
  height: 300px;
`;

export const AddButton = styled(Button)`
  width: 290px;
  height: 182.86px;
  font-size: 2rem;
  border-radius: 14.5px;
  border-style: dashed;
`;

export const ButtonText = styled(H2)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-light-color);
  }
`;
