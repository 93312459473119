import { Button, Col, Row } from "antd";
import styled from "styled-components";

export const SectionWrapper = styled(Col)`
  margin: 1.625rem 0;
  min-height: 300px;
`;

export const LoadingAndNoDataContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BtnContainer = styled(Button)`
  color: var(--text-extra-light-color);
`;

export const HeaderRow = styled(Row)`
  margin-bottom: 2rem;
`;

export const CardWrapper = styled.div`
  padding: 0.625rem;
`;
