import { Text } from "@components/common";
import { Button, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 500px;
`;

export const TextContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 800px;
`;

export const InfoText = styled(Text)`
  &.ant-typography {
    color: var(--text-light-color);
  }
`;

export const CustomTextButton = styled(Button).attrs({ type: "text" })`
  & {
    color: var(--primary-color) !important;
  }

  &:active {
    color: var(--primary-color) !important;
  }

  &:hover {
    color: var(--primary-color) !important;
  }
`;
