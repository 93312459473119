import { Empty } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { P1 } from "../Typography";
import * as S from "./NoData.style";

interface INoDataProps {
  description?: string;
}

const NoData: React.FC<INoDataProps> = ({ description }) => {
  const { t } = useTranslation();
  const defaultNoDataMessage = t("common.noData");

  return (
    <S.Wrapper>
      <Empty
        description={
          description ? (
            <span>
              <P1>{description}</P1>
            </span>
          ) : (
            <span>
              <P1>{defaultNoDataMessage}</P1>
            </span>
          )
        }
      />
    </S.Wrapper>
  );
};

export default NoData;
