import {
  AnalyticData,
  AnalyticMeasurementData,
  Visual,
  VisualType,
} from "@common/index";
import {
  BarChartWrapper,
  HeatmapChartWrapper,
  LineChartWrapper,
  NoData,
  Timer,
} from "@components/common";
import { Dates } from "@services/index";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./VisualChart.style";

interface IVisualChartProps {
  visual: Visual;
  data: AnalyticData[];
}

const VisualChart: React.FC<IVisualChartProps> = ({ data, visual }) => {
  const VALUE_CHART_FONT_SIZE_COEFFICIENT = 0.6;
  const DATE_CHART_FONT_SIZE_COEFFICIENT = 0.2;

  const { t } = useTranslation();

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const [containerSize, setContainerSize] = useState<{
    height: number;
    width: number;
  }>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const clientW = containerRef?.clientWidth || 0;
      const clientH = containerRef?.clientHeight || 0;

      const sizeChanged: boolean =
        clientW !== containerSize.width || clientH !== containerSize.height;

      if (sizeChanged) {
        setContainerSize({ width: clientW, height: clientH });
      }
    });

    if (containerRef) {
      resizeObserver.observe(containerRef);
    }

    () => resizeObserver.disconnect();
  }, [containerSize, containerRef]);

  const extractAnalyticData = (): AnalyticData | undefined => {
    return data.find(
      (analyticData) =>
        analyticData.visualType === visual.visualType &&
        analyticData.datasetId === visual.datasetId &&
        analyticData.kpiId === visual.kpiId &&
        analyticData.data.length === visual.historyDepth
    );
  };

  const extractMeasurementData = useCallback(() => {
    switch (visual.visualType) {
      case VisualType.BAR:
        const analyticBarData: AnalyticData | undefined = extractAnalyticData();

        const barData =
          analyticBarData?.data && analyticBarData.data.length > 0
            ? analyticBarData.data
            : undefined;

        return barData;
      case VisualType.LINE:
        const analyticLineData: AnalyticData | undefined =
          extractAnalyticData();

        const lineData =
          analyticLineData?.data && analyticLineData.data.length > 0
            ? analyticLineData.data
            : undefined;

        return lineData;
      case VisualType.HEATMAP:
        const analyticHeatmapData: AnalyticMeasurementData[] | undefined =
          extractAnalyticData()?.data;
        return analyticHeatmapData;
      case VisualType.DATE:
        return;
      case VisualType.TEXT:
        return visual.params.value || undefined;
      case VisualType.VALUE:
        const analyticValueData: AnalyticData | undefined =
          extractAnalyticData();
        const valueData =
          analyticValueData?.data && analyticValueData?.data.length > 0
            ? analyticValueData.data[0]
            : undefined;

        return valueData ? valueData : undefined;
      default:
        return;
    }
  }, [visual, data]);

  const extractPredictData = useCallback(() => {
    switch (visual.visualType) {
      case VisualType.BAR:
        const analyticBarData: AnalyticData | undefined = extractAnalyticData();

        const barData =
          analyticBarData?.predict && analyticBarData.predict.length > 0
            ? analyticBarData.predict
            : [];

        return barData;
      case VisualType.LINE:
        const analyticLineData: AnalyticData | undefined =
          extractAnalyticData();

        const lineData =
          analyticLineData?.predict && analyticLineData.predict.length > 0
            ? analyticLineData.predict
            : [];

        return lineData;
      case VisualType.HEATMAP:
      case VisualType.DATE:
      case VisualType.TEXT:
      case VisualType.VALUE:
      default:
        return [];
    }
  }, [visual, data]);

  const renderChartByVisualType = useCallback(() => {
    const measurementData = extractMeasurementData();
    const predictData = extractPredictData();

    switch (visual.visualType) {
      case VisualType.BAR:
        const xAxisExtractorBarChart = (
          data: AnalyticMeasurementData[]
        ): string[] =>
          data.map((datum) =>
            Dates.convertStringToDate(datum._time).format("DD/MM")
          );

        const yAxisExtractorBarChart = (
          data: AnalyticMeasurementData[]
        ): number[] => data.map((datum) => datum._value);

        return (
          <BarChartWrapper
            data={measurementData}
            predictMode={visual.params.predict}
            predictData={predictData}
            comparisonMode={visual.params.dataComparison}
            xAxisType="category"
            yAxisType="value"
            xAxisExtractor={xAxisExtractorBarChart}
            yAxisExtractor={yAxisExtractorBarChart}
            threshold={visual.params.thresholdEnabled ?visual.params.threshold: undefined}
            thresholdReversed={visual.params.thresholdReversed}
          />
        );
      case VisualType.LINE:
        const xAxisExtractorLineChart = (
          data: AnalyticMeasurementData[]
        ): string[] =>
          data.map((datum) =>
            Dates.convertStringToDate(datum._time).format("DD/MM")
          );

        const yAxisExtractorLineChart = (
          data: AnalyticMeasurementData[]
        ): number[] => data.map((datum) => datum._value);

        return (
          <LineChartWrapper
            data={measurementData}
            predictMode={visual.params.predict}
            predictData={predictData}
            comparisonMode={visual.params.dataComparison}
            xAxisType="category"
            yAxisType="value"
            xAxisExtractor={xAxisExtractorLineChart}
            yAxisExtractor={yAxisExtractorLineChart}
            threshold={visual.params.thresholdEnabled ?visual.params.threshold: undefined}
            thresholdReversed={visual.params.thresholdReversed}
          />
        );
      case VisualType.HEATMAP:
        return (
          <HeatmapChartWrapper
            data={measurementData}
            historyDepth={visual.historyDepth}
          />
        );
      case VisualType.DATE:
        return (
          <S.TextBasedChart
            $fontSize={
              Math.min(containerSize.width, containerSize.height) *
              DATE_CHART_FONT_SIZE_COEFFICIENT
            }
          >
            <Timer />
          </S.TextBasedChart>
        );
      case VisualType.TEXT:
        return (
          <S.TextBasedChart
            $fontSize={
              Math.min(containerSize.width, containerSize.height) *
              DATE_CHART_FONT_SIZE_COEFFICIENT
            }
          >
            {measurementData ? <p>{measurementData}</p> : <NoData />}
          </S.TextBasedChart>
        );
      case VisualType.VALUE:
        return (
          <S.TextBasedChart
            $fontSize={
              Math.min(containerSize.width, containerSize.height) *
              VALUE_CHART_FONT_SIZE_COEFFICIENT
            }
          >
            {measurementData ? <p>{measurementData._value}</p> : <NoData />}
          </S.TextBasedChart>
        );
      default:
        return <NoData />;
    }
  }, [visual, data, containerSize]);

  return (
    <React.Fragment>
      <S.InnerContainer ref={(newRef) => setContainerRef(newRef)}>
        {renderChartByVisualType()}
      </S.InnerContainer>
    </React.Fragment>
  );
};

export default VisualChart;
