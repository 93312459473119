import { Dataset, FileType } from "@app/common";
import { IModalBaseProps } from "@app/common/interfaces";
import { H4, Modal } from "@app/components/common";
import CSVIcon from "@assets/icons/CSV.png";
import ExcelIcon from "@assets/icons/Excel.png";
import JSONIcon from "@assets/icons/JSON.png";
import { Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./TemplatesModal.style";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import {
  fetchMetrics,
  getTemplateFile,
} from "@app/store/slices/DatasetContent/thunks";

const TemplatesModal: React.FC<IModalBaseProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedFileType, setSelectedFileType] = useState<FileType>(
    FileType.EXCEL
  );

  const currentUser = useAppSelector((state) => state.userContent.data);

  const currentDataset: Dataset | null = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  const onOkHandler = async () => {
    const metrics = currentDataset
      ? await dispatch(fetchMetrics(currentDataset.id)).unwrap()
      : [];

    const metricNames = Object.values(metrics).map((item) => item.name);

    await dispatch(
      getTemplateFile({
        metricNames: metricNames,
        fileType: selectedFileType,
        user: currentUser,
      })
    )
      .unwrap()
      .then((res) => {
        const firebaseDownloadLink = res;
        window.open(firebaseDownloadLink, "_blank");
        setIsOpen(false);
      })
      .catch((error) => {});
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onFileTypeClickHandler = (fileType: FileType) => {
    setSelectedFileType(fileType);
  };

  const renderOptionCard = (fileType: FileType, icon: string) => {
    return (
      <S.OptionCard
        padding={[20, 20]}
        onClick={() => onFileTypeClickHandler(fileType)}
        selected={fileType === selectedFileType}
      >
        <S.OptionIcon src={icon} />
        <Row justify={"center"}>
          <H4>{fileType}</H4>
        </Row>
      </S.OptionCard>
    );
  };

  return (
    <React.Fragment>
      <Modal
        title={t("modals.templatesModal.title")}
        open={isOpen}
        onOk={onOkHandler}
        okText={t("common.download")}
        onCancel={onCancelHandler}
      >
        <S.Container justify="space-evenly" align="middle" gutter={[15, 15]}>
          {renderOptionCard(FileType.EXCEL, ExcelIcon)}
          {renderOptionCard(FileType.CSV, CSVIcon)}
          {renderOptionCard(FileType.JSON, JSONIcon)}
        </S.Container>
      </Modal>
    </React.Fragment>
  );
};

export default TemplatesModal;
