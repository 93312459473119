import { Organization } from "@common/index";
import {
  H1,
  SectionHeader,
  withAnimation,
  withLoading,
} from "@components/common";
import { useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import * as S from "./PaymentBillingLayout.style";
import { PaymentNavigatorCard } from "./components";

const PaymentBillingLayout: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  useEffect(() => {
    const path = window.location.pathname;
    const splittedPath = path.split("/");
    const latestSubdomain = splittedPath[splittedPath.length - 1];

    latestSubdomain === "/payment-and-billing"
      ? navigate(
          APPLICATION_PATHS.PAYMENTS_PATH.replace(
            ":organizationId",
            currentOrganization.id
          )
        )
      : navigate(path);
  }, []);

  return (
    <React.Fragment>
      <SectionHeader
        customMargin="0 0 2rem 0"
        title={<H1>{currentOrganization.name}</H1>}
        subtitle={
          <S.SectionHeaderSubtitle>
            {t("pages.organizations.paymentAndBillingSettings")}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={`${APPLICATION_PATHS.ORGANIZATION_PATH}/${currentOrganization.id}`}
        backButtonVisible
      />

      <Row gutter={[30, 30]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <PaymentNavigatorCard />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
          <Outlet />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withAnimation(withLoading(PaymentBillingLayout));
