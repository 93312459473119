import { useResponsive } from "@hooks/index";
import { AnalyticMeasurementData, AppDate } from "@common/types";
import { Dates } from "@services/DateService";
import React, { useCallback } from "react";
import { NoData } from "../NoData";
import * as S from "./HeatmapChartWrapper.style";

interface IHeatmapChartWrapperProps {
  data: AnalyticMeasurementData[];
  historyDepth: number;
  // xAxisType: string;
  // yAxisType: string;
}

const HeatmapChartWrapper: React.FC<IHeatmapChartWrapperProps> = ({
  data: dataup,
  historyDepth,
}) => {
  if (!dataup) {
    return <NoData />;
  }

  const { isDesktop } = useResponsive();

  const getFirstAndLastDate = useCallback(() => {
    const dayToAdd: number = -1 * historyDepth;

    const end: AppDate = Dates.getToday();
    const start: AppDate = end.add(dayToAdd, "days");

    const startOfWeek: AppDate = Dates.getFirstWeekDay(start);
    const endOfWeek: AppDate = Dates.getLastWeekDay(end);

    return {
      firstDay: startOfWeek,
      lastDay: endOfWeek,
    };
  }, [historyDepth]);

  const getWeeks = () => {
    const { firstDay: startOfWeek, lastDay: endOfWeek } = getFirstAndLastDate();

    const dateRange: AppDate[] = Dates.getDaysArrayInRange(
      startOfWeek,
      endOfWeek
    );

    const weeks: { startDate: AppDate; endDate: AppDate; label: string }[] = [];

    for (let index = 0; index < dateRange.length; index += 7) {
      weeks.push({
        startDate: dateRange[index],
        endDate: dateRange[index + 6],
        label:
          dateRange[index].format("DD/MM") +
          "-" +
          dateRange[index + 6].format("DD/MM"),
      });
    }

    return weeks;
  };

  const getDayNames = (): string[] => {
    return Dates.getDayNames();
  };

  const getData = () => {
    // Should return data in format of [[yAxis, xAxis, value], [yAxis, xAxis, value],[yAxis, xAxis, value],[yAxis, xAxis, value]]

    const weeks = getWeeks();

    const heatmapData = dataup.map((measurementData) => {
      const dateOfData = Dates.convertStringToDate(measurementData._time);
      const valueOfData = measurementData._value;

      let xAxis: number = -1;
      let yAxis: number = -1;

      weeks.forEach((week, weekIndex) => {
        const startOfWeek = week.startDate;
        const endOfWeek = week.endDate;

        if (Dates.isBetween(dateOfData, startOfWeek, endOfWeek)) {
          xAxis = weekIndex;

          Dates.getDaysArrayInRange(startOfWeek, endOfWeek).forEach(
            (day, dayIndex) => {
              if (Dates.isSameDay(day, dateOfData)) {
                yAxis = dayIndex;
              }
            }
          );
        }
      });

      return [xAxis, yAxis, valueOfData === 0 ? "-" : valueOfData];
    });

    return heatmapData;
  };

  const getMaxDataValue = (): number => {
    return Math.max(...dataup.map((datum) => datum._value));
  };

  const getMinDataValue = (): number => {
    return Math.min(...dataup.map((datum) => datum._value));
  };

  const getGrid = () => {
    if (isDesktop) {
      return {
        x: "8%",
        y: "8%",
        x2: "4%",
        y2: "30%",
      };
    } else {
      return { x: "18%", y: "10%", x2: "4%", y2: "30%" };
    }
  };

  const getVisualMapMargin = () => {
    if (isDesktop) {
      return "10%";
    } else {
      return "0%";
    }
  };

  const option = {
    tooltip: {
      position: "top",
      formatter: function (p: any) {
        const xAxis = p.data[0];
        const yAxis = p.data[1];
        const value = p.data[2];

        const correspondingWeek = getWeeks()[xAxis];
        const correspondingDay = correspondingWeek.startDate.add(yAxis, "day");

        return `${correspondingDay.format("DD/MM/YY")}: ${value}`;
      },
    },
    grid: getGrid(),
    xAxis: {
      type: "category",
      data: getWeeks().map((value) => value.label),
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      data: getDayNames(),
      splitArea: {
        show: true,
      },
    },
    visualMap: {
      min: getMinDataValue(),
      max: getMaxDataValue(),
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: getVisualMapMargin(),
    },
    series: [
      {
        name: "Punch Card",
        type: "heatmap",
        data: getData(),
        label: {
          show: true,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <S.EChartContainer
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <S.EChart option={option} />
    </S.EChartContainer>
  );
};

export default HeatmapChartWrapper;
