import { ChangePasswordForm, RequestStatus, User } from "@common/index";
import { BaseForm, H1, SectionHeader } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { changePassword } from "@store/slices/UserContent/thunks";
import { Button, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./PasswordChangeForm.style";

const PasswordChangeForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();

  const currentUser: User = useAppSelector((state) => state.userContent.data);
  const currentUserStatus: RequestStatus = useAppSelector(
    (state) => state.userContent.status
  );

  const onSubmitHandler = (formData: ChangePasswordForm) => {
    const data: ChangePasswordForm = {
      email: currentUser.email,
      password: formData.password,
      newPassword: formData.newPassword,
      newPasswordAgain: formData.newPasswordAgain,
    };

    dispatch(changePassword(data))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.updatePassword"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.updatePassword"),
        });
      })
      .finally(() => {
        form.resetFields();
      });
  };

  return (
    <React.Fragment>
      <SectionHeader
        customMargin="0 0 1.5rem 0"
        title={<H1>{t("pages.profile.securitySettings")}</H1>}
      />
      <BaseForm form={form} layout="vertical" onFinish={onSubmitHandler}>
        <BaseForm.Item
          label={t("pages.profile.currentPassword")}
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password
            placeholder={t("pages.profile.currentPassword")}
          />
        </BaseForm.Item>

        <BaseForm.Item
          label={t("pages.profile.newPassword")}
          name="newPassword"
          rules={[{ required: true }]}
        >
          <Input.Password
            placeholder={t("pages.profile.newPassword")}
          />
        </BaseForm.Item>

        <BaseForm.Item
          label={t("pages.profile.passwordConfirm")}
          name="newPasswordAgain"
          dependencies={["newPassword"]}
          rules={[
            { required: true },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t("pages.profile.passwordMatchRule"))
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t("pages.profile.passwordConfirm")}
          />
        </BaseForm.Item>

        <S.ButtonContainer>
          <Button
            loading={currentUserStatus === RequestStatus.PENDING}
            block
            type="primary"
            htmlType="submit"
          >
            {t("common.confirm")}
          </Button>
        </S.ButtonContainer>
      </BaseForm>
    </React.Fragment>
  );
};

export default PasswordChangeForm;
