import { RequestStatus } from "@common/enums";
import { FetchBillingDetailForm } from "@common/formTypes";
import { BillingDetail, BillingProfile, Organization } from "@common/types";
import {
  Card,
  H2,
  H3,
  Loading,
  SectionHeader,
  withAnimation,
  withLoading,
} from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { Dates } from "@services/index";
import {
  fetchBillingDetails,
  fetchBillingProfiles,
} from "@store/slices/Payments/thunks";
import { generateAsterix } from "@utils/index";
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { PaymentCard } from "../";
import * as S from "./BillingDetailsSection.style";

const EMPTY_VALUE = "";

const BillingDetailsSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { settlementId } = useParams();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const billingProfiles: BillingProfile[] = useAppSelector(
    (state) => state.payments.billingProfiles.data
  );

  const billingDetails: BillingDetail = useAppSelector(
    (state) => state.payments.billingDetails.data
  );

  const billingDetailsStatus: RequestStatus = useAppSelector(
    (state) => state.payments.billingDetails.status
  );

  const getBillingProfileByBillId = (billingProfileId: string) => {
    return billingProfiles.find((profile) => profile.id === billingProfileId);
  };

  const INFORMATION_ROWS: { title: string; value: string | number }[] = [
    {
      title: t("pages.organizations.paymentId"),
      value: billingDetails?.details?.id
        ? billingDetails.details.id
        : EMPTY_VALUE,
    },
    {
      title: t("pages.organizations.paymentAmount"),
      value: billingDetails?.details?.price
        ? billingDetails.details.price
        : EMPTY_VALUE,
    },
    {
      title: t("pages.organizations.currency"),
      value: billingDetails?.details?.currency
        ? billingDetails.details.currency
        : EMPTY_VALUE,
    },
    {
      title: t("pages.organizations.obligationStartDate"),
      value: billingDetails?.settlement?.obligation?.datetime
        ? Dates.convertStringToDate(
            billingDetails.settlement.obligation.datetime
          ).format("LL")
        : EMPTY_VALUE,
    },
    {
      title: t("pages.organizations.paymentDate"),
      value: billingDetails?.details?.createdDate
        ? Dates.convertStringToDate(billingDetails.details.createdDate).format(
            "LL"
          )
        : EMPTY_VALUE,
    },
    {
      title: t("pages.organizations.cardIssuerBank"),
      value: billingDetails?.details?.cardIssuerBankName
        ? billingDetails.details.cardIssuerBankName
        : EMPTY_VALUE,
    },

    {
      title: t("pages.organizations.processingPos"),
      value: billingDetails?.details?.pos?.name
        ? billingDetails.details.pos?.name
        : EMPTY_VALUE,
    },
    {
      title: t("pages.organizations.billingProfile"),
      value: billingDetails?.settlement?.billingProfileId
        ? getBillingProfileByBillId(
            billingDetails?.settlement?.billingProfileId
          )?.title
        : EMPTY_VALUE,
    },
  ];

  useEffect(() => {
    if (currentOrganization.id && settlementId) {
      const form: FetchBillingDetailForm = {
        tenantId: currentOrganization.id,
        settlementId: settlementId,
      };

      dispatch(fetchBillingDetails(form));
    }
  }, [currentOrganization.id, settlementId]);

  useEffect(() => {
    currentOrganization &&
      dispatch(fetchBillingProfiles(currentOrganization.id));
  }, [currentOrganization.id, settlementId]);

  const getCardNumber = (binNumber: string, lastFourDigits: string): string => {
    const middleDigits = generateAsterix(6);

    return `${binNumber}${middleDigits}${lastFourDigits.slice(-2)}`;
  };

  const renderInformationRow = (
    title: string,
    value: string | number
  ): React.ReactNode => {
    return (
      <Row wrap justify="space-between" align="middle">
        <Col>
          <S.Title>{title}</S.Title>
        </Col>
        <Col>
          <H3>{value}</H3>
        </Col>
      </Row>
    );
  };

  const renderLoading = () => (
    <S.LoadingContainer>
      <Loading size="4rem" />
    </S.LoadingContainer>
  );

  const renderDetails = () => {
    return (
      <S.Container>
        <Row gutter={[0, 36]}>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 24, order: 1 }}
            xl={{ span: 12, order: 0 }}
            xxl={{ span: 12, order: 0 }}
          >
            <S.CustomSpacer direction="vertical">
              {INFORMATION_ROWS.map((info) =>
                renderInformationRow(info.title, info.value)
              )}
            </S.CustomSpacer>
          </Col>
          <Col
            xs={{ span: 24, order: 0 }}
            sm={{ span: 24, order: 0 }}
            md={{ span: 24, order: 0 }}
            lg={{ span: 24, order: 0 }}
            xl={{ span: 12, order: 1 }}
            xxl={{ span: 12, order: 1 }}
          >
            <Row justify="center">
              <PaymentCard
                showcase={true}
                cardNumber={
                  billingDetails.details.binNumber &&
                  billingDetails.details.lastFourDigits
                    ? getCardNumber(
                        billingDetails.details.binNumber,
                        billingDetails.details.lastFourDigits
                      )
                    : generateAsterix(16)
                }
                cardHolderName={
                  billingDetails.details.cardHolderName
                    ? billingDetails.details.cardHolderName
                    : `${generateAsterix(7)} ${generateAsterix(5)}`
                }
                expireDate={`${generateAsterix(2)}/${generateAsterix(2)}`}
              />
            </Row>
          </Col>
        </Row>
      </S.Container>
    );
  };

  const renderAll = () => {
    return billingDetailsStatus === RequestStatus.PENDING ||
      billingDetailsStatus === RequestStatus.IDLE
      ? renderLoading()
      : renderDetails();
  };

  return (
    <Card padding={[25, 25]}>
      <SectionHeader
        title={<H2>{t("pages.organizations.billingDetails")}</H2>}
        customMargin="0 0 2rem 0"
        backButtonVisible
      />
      {renderAll()}
    </Card>
  );
};

export default withAnimation(withLoading(BillingDetailsSection));
