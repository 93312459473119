import { DayjsDatePicker } from "@components/common";
import { InputNumber } from "antd";
import styled from "styled-components";

export const CustomInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const CustomDatePicker = styled(DayjsDatePicker).attrs({picker: "time", format: "HH:mm"})`
  width: 100%;
`;
