import styled, { css } from "styled-components";
import { Col } from "antd";
import { BurgerIcon } from "@components/common/BurgerIcon";

export const BurgerCol = styled(Col)`
  z-index: 99;
  display: flex;
`;

export const MobileBurger = styled(BurgerIcon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: -0.5rem;
  color: var(--text-main-color);

  ${(props) =>
    props.isCross &&
    css`
      color: var(--text-secondary-color);
    `};
`;
