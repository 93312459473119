import { useAppSelector } from "@app/hooks";
import { themeObject } from "@app/styles/themes/ThemeVariables";
import { AnalyticMeasurementData, BASE_COLORS, ThemeType } from "@common/index";
import { NoData } from "@components/common";
import React from "react";
import * as S from "./LineChartWrapper.style";
import { useTranslation } from "react-i18next";
import {Dates} from "@app/services";

interface ILineChartWrapperProps {
  data: AnalyticMeasurementData[];
  comparisonMode?: boolean;
  predictMode?: boolean;
  predictData?: AnalyticMeasurementData[];
  xAxisType: string;
  yAxisType: string;
  xAxisExtractor: (data: AnalyticMeasurementData[]) => string[];
  yAxisExtractor: (data: AnalyticMeasurementData[]) => number[];
  threshold?: number;
  thresholdReversed?: boolean;
}

/* TO DO: Change ._value property access to Y axis Extractor */
const LineChartWrapper: React.FC<ILineChartWrapperProps> = ({
  data,
  comparisonMode,
  predictMode,
  predictData,
  xAxisType,
  yAxisType,
  xAxisExtractor,
  yAxisExtractor,
  threshold,
  thresholdReversed,
}) => {
  if (!data) {
    return <NoData />;
  }

  const { t } = useTranslation();

  const theme: ThemeType = useAppSelector((state) => state.theme.data);

  let comparisonData: AnalyticMeasurementData[] | undefined;

  if (comparisonMode) {
    comparisonData = data.slice(0, data.length / 2);
    data = data.slice(data.length / 2);
  }

  const dataWithPrediction = predictData ? [...data, ...predictData] : data;

  function getChartItemColor(item: AnalyticMeasurementData, comparisonMode?: boolean) {
    if (item.isPrediction) return themeObject[theme].secondaryColor;
    if (comparisonMode) return BASE_COLORS.skyblue;

    if (threshold !== undefined) {
      return thresholdReversed ?
          item._value >= threshold
              ? themeObject[theme].errorColor
              : themeObject[theme].primaryColor :
          item._value < threshold
              ? themeObject[theme].errorColor
              : themeObject[theme].primaryColor;
    }
    return themeObject[theme].primaryColor;
  }

  function mapDataToChartItems(analyticMeasurementData: AnalyticMeasurementData[], comparisonMode?: boolean) {
    return analyticMeasurementData.map((item) => ({
      value: parseFloat(item._value.toFixed(2)),
      itemStyle: {
        color: getChartItemColor(item, comparisonMode)
      },
    }));
  }

  let option = {
    legend: {
      show: true,
      bottom: 0,
    },
    color: [themeObject[theme].primaryColor, BASE_COLORS.skyblue],
    xAxis: {
      type: xAxisType,
      data: xAxisExtractor(predictMode ? dataWithPrediction : data),
      axisTick: {
        alignWithLabel: true,
      },
    },
    yAxis: {
      type: yAxisType,
    },
    series: [
      {
        name: t("charts.data"),
        type: "line",
        lineStyle: {},
        smooth: true,
        data: mapDataToChartItems(predictMode ? dataWithPrediction : data),
        ...(threshold !== undefined && {
          markLine: {
            silent: true,
            symbol: "none",
            data: [
              {
                yAxis: threshold,
                lineStyle: {
                  width: 2,
                  color: 'red',
                  type: 'solid',
                },
                label: {
                  show: false,
                },
              },
            ],
          },
        }),
      },
      comparisonMode && comparisonData && {
        name: t("charts.pastData"),
        type: "line",
        lineStyle: {color: BASE_COLORS.skyblue},
        smooth: true,
        data: mapDataToChartItems(comparisonData, true),
      },
    ],
    grid: {
      x: 40,
      y: 20,
      x2: 15,
      y2: 50,
    },
    visualMap: {
      show: false,
      dimension: 0,
      pieces: [
        {
          lt: data.length - 1,
          color: themeObject[theme].primaryColor,
        },
        {
          gt: data.length - 1,
          lte: dataWithPrediction.length - 1,
          color: themeObject[theme].secondaryColor,
        },
      ],
    },
    tooltip: {
      trigger: "axis",
    },
  };

  return <S.EChart option={option} />;
};

export default LineChartWrapper;
