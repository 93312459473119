import { P2 } from "@components/common";
import styled from "styled-components";

export const TooltipText = styled(P2)`
  &.ant-typography {
    color: var(--text-light-color);
  }
`;

export const InfoCircleContainer = styled.div`
  margin-right: 0.5rem;
  color: var(--text-light-color);
`;
