import { RightOutlined } from "@ant-design/icons";
import { AppLogo, AppLogoTitle } from "@components/common";
import { useResponsive } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Col } from "antd";
import React from "react";
import { OrganizationSelector } from "../OrganizationSelector";
import * as S from "./SiderHeader.style";

interface ISiderHeaderProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}

const SiderHeader: React.FC<ISiderHeaderProps> = ({
  isSiderCollapsed,
  toggleSider,
}) => {
  const { mobileOnly } = useResponsive();

  return (
    <S.SiderHeaderContainer>
      <S.SiderHeaderLink to={APPLICATION_PATHS.DASHBOARD_PATH}>
        <AppLogo />
        <Col>
          <AppLogoTitle />
          <div onClick={(e) => e.stopPropagation()}>
            <OrganizationSelector />
          </div>
        </Col>
      </S.SiderHeaderLink>
      {!mobileOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={() => toggleSider()}
        />
      )}
    </S.SiderHeaderContainer>
  );
};

export default SiderHeader;
