import React from "react";
import { HProps } from "../interfaces";
import * as S from "./H6.style";

const H6: React.FC<HProps> = (props) => (
  <S.Text {...props} level={5}>
    {props.children}
  </S.Text>
);

export default H6;
