import { axios } from "@api/index";
import {
  AddUserToDashboardForm,
  AddVisualToDashboardForm,
  AnalyticData,
  AnalyticFilterForm,
  ApiRangeFilter,
  AppDate,
  DeleteDashboardForm,
  DeleteUserFromDashboardForm,
  DeleteVisualFromDashboardForm,
  EditDashboardForm,
  EditUserFromDashboardForm,
  EditVisualFromDashboardForm,
  User,
  Visual,
  VisualType,
} from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dates } from "@services/index";
import { convertUserOptionArray } from "@utils/index";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const DASHBOARD_ENDPOINT = `${BASE_PATH}/dashboard`;
const KPI_ENDPOINT = `${BASE_PATH}/kpi`;
const VISUAL_ENDPOINT = `${BASE_PATH}/visual`;
const ANALYTICS_ENDPOINT = `${BASE_PATH}/analytics`;

export const deleteDashboard = createAsyncThunk(
  "dashboardContent/deleteDashboard",
  async (data: DeleteDashboardForm) => {
    const { userId, dashboardId } = data;
    const response = await axios.delete(
      `${DASHBOARD_ENDPOINT}/${userId}/${dashboardId}`
    );

    return response.data;
  }
);

export const editDashboard = createAsyncThunk(
  "dashboardContent/editDashboard",
  async (data: EditDashboardForm) => {
    const response = await axios.put(
      `${DASHBOARD_ENDPOINT}/${data.userId}/${data.dashboardId}`,
      {
        tenant: data.tenantId,
        name: data.name,
        description: data.description,
        datasetId: data.datasetId,
        layouts: data.layouts,
        uid: data.userId,
        users: data.users,
      }
    );

    return response.data;
  }
);

export const addUserToDashboard = createAsyncThunk(
  "dashboardContent/addUserToDashboard",
  async (data: AddUserToDashboardForm) => {
    const userOptionToAdd = {
      uid: (data.user as User).id,
      editable: data.editable,
    };

    const modifiedDashboard = {
      ...data.dashboard,
      uid: (data.dashboard.user as User).id,
      users: [...convertUserOptionArray(data.dashboard.users), userOptionToAdd],
    };

    const response = await axios.put(
      `${DASHBOARD_ENDPOINT}/${modifiedDashboard.uid}/${modifiedDashboard.id}`,
      {
        tenant: modifiedDashboard.tenant,
        name: modifiedDashboard.name,
        description: modifiedDashboard.description,
        datasetId: modifiedDashboard.dataset.id,
        layouts: modifiedDashboard.layouts,
        users: modifiedDashboard.users,
      }
    );

    return response.data;
  }
);

export const editUserFromDashboard = createAsyncThunk(
  "dashboardContent/editUserFromDashboard",
  async (data: EditUserFromDashboardForm) => {
    const editedUser = {
      uid: (data.user as User).id,
      editable: data.editable,
    };

    let editedUsers = convertUserOptionArray(data.dashboard.users);
    editedUsers = editedUsers.map((userOption) => {
      if (userOption.uid === editedUser.uid) {
        return editedUser;
      }

      return userOption;
    });

    const modifiedDashboard = {
      ...data.dashboard,
      uid: (data.dashboard.user as User).id,
      users: [...editedUsers],
    };

    const response = await axios.put(
      `${DASHBOARD_ENDPOINT}/${modifiedDashboard.uid}/${modifiedDashboard.id}`,
      {
        tenant: modifiedDashboard.tenant,
        name: modifiedDashboard.name,
        description: modifiedDashboard.description,
        datasetId: modifiedDashboard.dataset.id,
        layouts: modifiedDashboard.layouts,
        users: modifiedDashboard.users,
      }
    );

    return response.data;
  }
);

export const deleteUserFromDashboard = createAsyncThunk(
  "dashboardContent/deleteUserFromDashboard",
  async (data: DeleteUserFromDashboardForm) => {
    const editedUsers = convertUserOptionArray(data.dashboard.users);

    const modifiedDashboard = {
      ...data.dashboard,
      uid: (data.dashboard.user as User).id,
      users: [
        ...editedUsers.filter((userOption) => userOption.uid !== data.userId),
      ],
    };

    const response = await axios.put(
      `${DASHBOARD_ENDPOINT}/${modifiedDashboard.uid}/${modifiedDashboard.id}`,
      {
        tenant: modifiedDashboard.tenant,
        name: modifiedDashboard.name,
        description: modifiedDashboard.description,
        datasetId: modifiedDashboard.dataset.id,
        layouts: modifiedDashboard.layouts,
        users: modifiedDashboard.users,
      }
    );

    return response.data;
  }
);

export const fetchKpis = createAsyncThunk(
  "dashboardContent/fetchKpis",
  async (datasetId: string) => {
    const response = await axios.get(`${KPI_ENDPOINT}/${datasetId}`);
    return response.data;
  }
);

export const addVisualToDashboard = createAsyncThunk(
  "dashboardContent/addVisualToDashboard",
  async (data: AddVisualToDashboardForm) => {
    const {
      dashboardId,
      datasetId,
      kpiId,
      name,
      type: visualType,
      historyDepth,
      params,
    } = data;

    const response = await axios.post<Visual>(
      `${VISUAL_ENDPOINT}/${dashboardId}`,
      {
        name,
        datasetId,
        kpiId,
        visualType,
        historyDepth,
        params: params ? params : {},
      }
    );

    return response.data;
  }
);

export const editVisualFromDashboard = createAsyncThunk(
  "dashboardContent/",
  async (data: EditVisualFromDashboardForm) => {
    const {
      dashboardId,
      datasetId,
      visualId,
      kpiId,
      name,
      type: visualType,
      historyDepth,
      params,
    } = data;

    const response = await axios.put(
      `${VISUAL_ENDPOINT}/${dashboardId}/${visualId}`,
      {
        name,
        datasetId,
        kpiId,
        visualType,
        historyDepth,
        params: params ? params : {},
      }
    );
    return response.data;
  }
);

export const deleteVisualFromDashboard = createAsyncThunk(
  "dashboardContent/deleteVisualFromDashboard",
  async (data: DeleteVisualFromDashboardForm) => {
    const { dashboardId, visualId } = data;
    const response = await axios.delete<{
      dashboardId: string;
      id: string;
      deleted: boolean;
    }>(`${VISUAL_ENDPOINT}/${dashboardId}/${visualId}`);

    return response.data.id;
  }
);

export const fetchVisualsOfDashboard = createAsyncThunk(
  "dashboardContent/fetchVisualsOfDashboard",
  async (dashboardId: string) => {
    const response = await axios.get<Visual[]>(
      `${VISUAL_ENDPOINT}/${dashboardId}`
    );

    return response.data;
  }
);

export const fetchAllVisualRecords = createAsyncThunk(
  "dashboardContent/fetchAllVisualsRecords",
  async (data: { visuals: Visual[]; predict?: boolean }) => {
    const requestConfigs: {
      path: string;
      form: AnalyticFilterForm;
      visualType: VisualType;
    }[] = createVisualsFetchConfigs(data.visuals, data.predict);

    // TO DO: Convert it to Promise.allSettled. It wont throw error when one of requests failed.
    const responses = await Promise.all(
      requestConfigs.map((config) =>
        axios.post<AnalyticData>(config.path, config.form)
      )
    );

    const result = responses.map((res, index) => {
      const analyticDataWithVisualType = {
        ...res.data,
        visualType: requestConfigs[index].visualType,
      };

      analyticDataWithVisualType.predict?.forEach((d) => {
        d.isPrediction = true;
      });

      return analyticDataWithVisualType;
    });

    return result;
  }
);

const createVisualsFetchConfigs = (
  visuals: Visual[],
  predict?: boolean
): {
  path: string;
  form: AnalyticFilterForm;
  visualType: VisualType;
}[] => {
  const configs: {
    path: string;
    form: AnalyticFilterForm;
    visualType: VisualType;
  }[] = visuals.map((visual) => {
    const form: AnalyticFilterForm = {
      datasetId: visual.datasetId,
      kpiId: visual.kpiId,
      visualId: visual.id,
      filter: createVisualFilter(visual).filter,
    };

    let path;
    switch (visual.visualType) {
      case VisualType.BAR:
      case VisualType.HEATMAP:
      case VisualType.LINE:
        path = `${ANALYTICS_ENDPOINT}/histogram/${form.datasetId}/${
          form.kpiId
        }${predict ? "?predict=true" : ""}`;
        break;
      case VisualType.VALUE:
        path = `${ANALYTICS_ENDPOINT}/value/${form.datasetId}/${form.kpiId}${
          predict ? "?predict=true" : ""
        }`;
        break;
      case VisualType.DATE:
      case VisualType.TEXT:
      default:
        path = "";
        break;
    }

    return {
      path: path,
      form: form,
      visualType: visual.visualType,
    };
  });

  return configs.filter((config) => config?.path !== "");
};

const createVisualFilter = (visual: Visual): ApiRangeFilter => {
  const historyDepth: number = -1 * Number(visual.historyDepth) + 1;
  const lastDateOfVisual: AppDate = Dates.getEndOfDay(Dates.getUTCTime());
  const startDateOfVisual: AppDate = Dates.getStartOfDay(
    lastDateOfVisual.add(historyDepth, "day")
  );

  const rangeFilter: ApiRangeFilter = {
    filter: {
      time: {
        begin: startDateOfVisual.toISOString(),
        end: lastDateOfVisual.toISOString(),
      },
    },
  };

  return rangeFilter;
};
