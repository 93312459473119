import { useTime } from "@hooks/index";
import React from "react";

const Timer: React.FC = () => {
  const currentTime = useTime();
  
  return <p>{currentTime?.format("dddd, DD/MM/YYYY, HH:mm:ss")}</p>;
};

export default Timer;
