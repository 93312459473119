import { PlusOutlined } from "@ant-design/icons";
import {
  DatasetDefinitionItem as DatasetDefinitionItemType,
  RequestStatus,
  UserRole,
} from "@common/enums";
import { Dataset, IUserWithRole, Kpi, Metric, User, UserOption } from "@common/index";
import { H2, Loading, NoData, SectionHeader } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import {
  fetchKpis,
  fetchMetrics,
} from "@store/slices/DatasetContent/thunks";
import * as CommonStyles from "@styles/CommonStyles";
import React, { useEffect, useState } from "react";
import { AddKpiModal, AddMetricModal } from "../../modals";
import { DatasetDefinitionItem } from "../DatasetDefinitionItem";
import * as S from "./DatasetDefinitionSection.style";

interface IDatasetDefinitionListSectionProps {
  dataset: Dataset;
  title: string;
  itemType: DatasetDefinitionItemType;
}

const DatasetDefinitionSection: React.FC<
  IDatasetDefinitionListSectionProps
> = ({ dataset, title, itemType }) => {
  const dispatch = useAppDispatch();
  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const items: Kpi[] | Metric[] = useAppSelector((state) => {
    switch (itemType) {
      case DatasetDefinitionItemType.METRIC:
        return state.datasetContent.metrics.data;
      case DatasetDefinitionItemType.KPI:
        return state.datasetContent.kpis.data;
      default:
        return [];
    }
  });

  const status: RequestStatus = useAppSelector((state) => {
    switch (itemType) {
      case DatasetDefinitionItemType.METRIC:
        return state.datasetContent.metrics.status;
      case DatasetDefinitionItemType.KPI:
        return state.datasetContent.kpis.status;
      default:
        return RequestStatus.IDLE;
    }
  });

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  useEffect(() => {
    switch (itemType) {
      case DatasetDefinitionItemType.METRIC:
        dispatch(fetchMetrics(dataset.id));
        break;
      case DatasetDefinitionItemType.KPI:
        dispatch(fetchKpis(dataset.id));
        break;
    }
  }, [dataset.id]);

  const getCurrentUserWithRole = (): IUserWithRole | undefined => {
    if (!dataset) {
      return undefined;
    }
    if ((dataset.user as User).id === currentUser.id) {
      return {
        user: currentUser,
        role: UserRole.ADMIN,
      };
    }

    const correspondingUserOption: UserOption | undefined = dataset.users.find(
      (userOpt) => (userOpt.user as User).id === currentUser.id
    );

    return correspondingUserOption
      ? {
          user: correspondingUserOption?.user as User,
          role: correspondingUserOption?.editable
            ? UserRole.EDITOR
            : UserRole.BASIC,
        }
      : undefined;
  };

  const currentUserWithRole: IUserWithRole | undefined =
    getCurrentUserWithRole();

  const onAddDatasetDefinitionClick = () => {
    setIsAddModalOpen(true);
  };

  const renderAddDatasetDefinitionButton = () => {
    return currentUserWithRole ? (
      [UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role) ? (
        <S.BtnContainer
          type="text"
          size="small"
          onClick={onAddDatasetDefinitionClick}
        >
          <PlusOutlined />
        </S.BtnContainer>
      ) : null
    ) : null;
  };

  const renderDatasetDefinitionItems = () => {
    if (!currentUserWithRole) {
      return null;
    }

    return (
      <React.Fragment>
        {items.map((definitionItem) => (
          <DatasetDefinitionItem
            key={definitionItem.id}
            item={definitionItem}
            itemType={itemType}
            currentUserWithRole={currentUserWithRole}
          />
        ))}
      </React.Fragment>
    );
  };

  const renderLoading = () => {
    return (
      <CommonStyles.LoadingContainer>
        <Loading size="4rem" />
      </CommonStyles.LoadingContainer>
    );
  };

  const renderAddModal = () => {
    switch (itemType) {
      case DatasetDefinitionItemType.METRIC:
        return (
          <AddMetricModal
            dataset={dataset}
            isOpen={isAddModalOpen}
            setIsOpen={setIsAddModalOpen}
          />
        );
      case DatasetDefinitionItemType.KPI:
        return (
          <AddKpiModal
            dataset={dataset}
            isOpen={isAddModalOpen}
            setIsOpen={setIsAddModalOpen}
          />
        );
    }
  };

  const renderNoData = () => {
    return (
      <CommonStyles.LoadingContainer>
        <NoData />
      </CommonStyles.LoadingContainer>
    );
  };

  return (
    <React.Fragment>
      {renderAddModal()}
      <SectionHeader title={<H2>{title}</H2>}>
        {renderAddDatasetDefinitionButton()}
      </SectionHeader>

      <CommonStyles.VerticalSpacer>
        {status === RequestStatus.PENDING
          ? renderLoading()
          : items.length > 0
          ? renderDatasetDefinitionItems()
          : renderNoData()}
      </CommonStyles.VerticalSpacer>
    </React.Fragment>
  );
};

export default DatasetDefinitionSection;
