import { IModalBaseProps, Organization } from "@common/index";
import { BaseForm, Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { deleteOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationDelete } from "@store/slices/Organizations/slice";
import { findOrganizationShareType } from "@utils/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IDeleteDatasetDefinitionItemModalProps extends IModalBaseProps {
  organization: Organization;
}

const DeleteOrganizationModal: React.FC<
  IDeleteDatasetDefinitionItemModalProps
> = ({ organization, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    dispatch(deleteOrganization(organization.id))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectOrganizationDelete({
            organization: res,
            organizationType: findOrganizationShareType(
              organization,
              currentUser.id
            ),
          })
        );
        NotificationController.success({
          message: t("notifications.success.deleteOrganization", {
            organization: organization.name,
          }),
        });
        navigate(APPLICATION_PATHS.ORGANIZATION_PATH);
      })
      .catch((err) => {
        NotificationController.success({
          message: t("notifications.error.deleteOrganization", {
            organization: organization.name,
          }),
        });
      });
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteOrganizationModal.title", {
        organizationName: organization.name,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteOrganizationModal;
