import { Card, H3 } from "@app/components/common";
import { Button } from "antd";
import styled from "styled-components";

export const UploadCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const UploadButton = styled(Button)`
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  width: 100%;
`;

export const IconContainer = styled.div`
  .anticon {
    font-size: 2.75rem;
    color: var(--primary-color);
  }
`;

export const DragTitle = styled(H3)`
  &.ant-typography {
    color: var(--primary-color);
  }
`;
