import { RequestStatus } from "@common/enums";
import {
  ApiResponseState,
  BillingProfile,
  Obligation,
  PaymentCard,
  Settlement,
} from "@common/types";
import { createSlice } from "@reduxjs/toolkit";
import {
  addBillingProfile,
  addPaymentCard,
  deleteBillingProfile,
  deletePaymentCard,
  editBillingProfile,
  editPaymentCard,
  fetchBillingDetails,
  fetchBillingProfiles,
  fetchObligations,
  fetchPaymentCards,
  fetchSettlements,
  payWithNewCard,
  payWithSavedCard,
} from "./thunks";

const obligationsInitialState: ApiResponseState<Obligation[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: undefined,
};

const paymentCardsInitialState: ApiResponseState<PaymentCard[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: undefined,
};

const settlementsInitialState: ApiResponseState<Settlement[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: undefined,
};

const settlementDetailsInitialState: ApiResponseState<any> = {
  status: RequestStatus.IDLE,
  data: undefined,
  error: undefined,
};

const billingProfilesInitialState: ApiResponseState<BillingProfile[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: undefined,
};

const initialState = {
  paymentCards: paymentCardsInitialState,
  obligations: obligationsInitialState,
  settlements: settlementsInitialState,
  billingDetails: settlementDetailsInitialState,
  billingProfiles: billingProfilesInitialState,
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchObligations.pending, (state) => {
        state.obligations.status = RequestStatus.PENDING;
      })
      .addCase(fetchObligations.fulfilled, (state, action) => {
        state.obligations.status = RequestStatus.FULFILLED;
        state.obligations.data = action.payload;
      })
      .addCase(fetchObligations.rejected, (state) => {
        state.obligations.status = RequestStatus.REJECTED;
      })
      .addCase(fetchPaymentCards.pending, (state) => {
        state.paymentCards.status = RequestStatus.PENDING;
      })
      .addCase(fetchPaymentCards.fulfilled, (state, action) => {
        state.paymentCards.status = RequestStatus.FULFILLED;
        state.paymentCards.data = action.payload;
      })
      .addCase(fetchPaymentCards.rejected, (state) => {
        state.paymentCards.status = RequestStatus.REJECTED;
      })
      .addCase(addPaymentCard.pending, (state) => {
        state.paymentCards.status = RequestStatus.PENDING;
      })
      .addCase(addPaymentCard.fulfilled, (state, action) => {
        state.paymentCards.status = RequestStatus.FULFILLED;
        state.paymentCards.data.push(action.payload);
      })
      .addCase(addPaymentCard.rejected, (state) => {
        state.paymentCards.status = RequestStatus.REJECTED;
      })
      .addCase(editPaymentCard.pending, (state) => {
        state.paymentCards.status = RequestStatus.PENDING;
      })
      .addCase(editPaymentCard.fulfilled, (state, action) => {
        state.paymentCards.status = RequestStatus.FULFILLED;
        state.paymentCards.data = state.paymentCards.data.map((card) => {
          if (
            card.cardToken === action.payload.cardToken &&
            card.cardUserKey === action.payload.cardUserKey
          ) {
            return { card, ...action.payload };
          }
          return card;
        });
      })
      .addCase(editPaymentCard.rejected, (state) => {
        state.paymentCards.status = RequestStatus.REJECTED;
      })
      .addCase(deletePaymentCard.pending, (state) => {
        state.paymentCards.status = RequestStatus.PENDING;
      })
      .addCase(deletePaymentCard.fulfilled, (state, action) => {
        state.paymentCards.status = RequestStatus.FULFILLED;
        action.payload.deleted &&
          (state.paymentCards.data = state.paymentCards.data.filter(
            (card) => card.cardToken !== action.payload.cardToken
          ));
      })
      .addCase(deletePaymentCard.rejected, (state) => {
        state.paymentCards.status = RequestStatus.REJECTED;
      })
      .addCase(payWithNewCard.pending, (state) => {
        state.settlements.status = RequestStatus.PENDING;
      })
      .addCase(payWithNewCard.fulfilled, (state, action) => {
        state.settlements.status = RequestStatus.FULFILLED;
        state.settlements.data.push(action.payload);
        state.obligations.data = state.obligations.data.filter(
          (ob) => ob.id !== action.payload.obligation.id
        );
      })
      .addCase(payWithNewCard.rejected, (state) => {
        state.settlements.status = RequestStatus.REJECTED;
      })
      .addCase(payWithSavedCard.pending, (state) => {
        state.settlements.status = RequestStatus.PENDING;
      })
      .addCase(payWithSavedCard.fulfilled, (state, action) => {
        state.settlements.status = RequestStatus.FULFILLED;
        state.settlements.data.push(action.payload);
        state.obligations.data = state.obligations.data.filter(
          (obg) => obg.id !== action.payload.obligation.id
        );
      })
      .addCase(payWithSavedCard.rejected, (state) => {
        state.settlements.status = RequestStatus.REJECTED;
      })
      .addCase(fetchSettlements.pending, (state) => {
        state.settlements.status = RequestStatus.PENDING;
      })
      .addCase(fetchSettlements.fulfilled, (state, action) => {
        state.settlements.status = RequestStatus.FULFILLED;
        state.settlements.data = action.payload;
      })
      .addCase(fetchSettlements.rejected, (state) => {
        state.settlements.status = RequestStatus.REJECTED;
      })
      .addCase(fetchBillingProfiles.pending, (state) => {
        state.billingProfiles.status = RequestStatus.PENDING;
      })
      .addCase(fetchBillingProfiles.fulfilled, (state, action) => {
        state.billingProfiles.status = RequestStatus.FULFILLED;
        state.billingProfiles.data = action.payload;
      })
      .addCase(fetchBillingProfiles.rejected, (state) => {
        state.billingProfiles.status = RequestStatus.REJECTED;
      })
      .addCase(fetchBillingDetails.pending, (state) => {
        state.billingDetails.status = RequestStatus.PENDING;
      })
      .addCase(fetchBillingDetails.fulfilled, (state, action) => {
        state.billingDetails.status = RequestStatus.FULFILLED;
        state.billingDetails.data = action.payload;
      })
      .addCase(fetchBillingDetails.rejected, (state) => {
        state.billingDetails.status = RequestStatus.REJECTED;
      })
      .addCase(addBillingProfile.pending, (state) => {
        state.billingProfiles.status = RequestStatus.PENDING;
      })
      .addCase(addBillingProfile.fulfilled, (state, action) => {
        state.billingProfiles.status = RequestStatus.FULFILLED;
        state.billingProfiles.data.push(action.payload);
      })
      .addCase(addBillingProfile.rejected, (state) => {
        state.billingProfiles.status = RequestStatus.REJECTED;
      })
      .addCase(editBillingProfile.pending, (state) => {
        state.billingProfiles.status = RequestStatus.PENDING;
      })
      .addCase(editBillingProfile.fulfilled, (state, action) => {
        state.billingProfiles.status = RequestStatus.FULFILLED;
        state.billingProfiles.data = state.billingProfiles.data.map(
          (profile) => {
            if (profile.id === action.payload.id) {
              return action.payload;
            }
            return profile;
          }
        );
      })
      .addCase(editBillingProfile.rejected, (state) => {
        state.billingProfiles.status = RequestStatus.REJECTED;
      })
      .addCase(deleteBillingProfile.pending, (state) => {
        state.billingProfiles.status = RequestStatus.PENDING;
      })
      .addCase(deleteBillingProfile.fulfilled, (state, action) => {
        state.billingProfiles.status = RequestStatus.FULFILLED;
        state.billingProfiles.data = state.billingProfiles.data.filter(
          (profile) => profile.id !== action.payload.id
        );
      })
      .addCase(deleteBillingProfile.rejected, (state) => {
        state.billingProfiles.status = RequestStatus.REJECTED;
      });
  },
});

export default paymentsSlice.reducer;
