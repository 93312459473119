import EditableDataTableCell from "@components/common/EditableDataTable/EditableDataTableCell";
import EditableDataTableRow from "@components/common/EditableDataTable/EditableDataTableRow";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { Loading } from "../Loading";
import "./EditableDataTable.style.css";
import * as S from './EditabledataTable.style';

interface IEditableDataTable<T> {
  data: T & { key: string }[];
  columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];
  onSave: (row: any) => void;
  isLoading?: boolean;
}

export type EditableTableProps = Parameters<typeof Table>[0];
export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const EditableDataTable = <T extends Readonly<unknown>>(
  props: IEditableDataTable<T>
) => {
  const { data, columns, isLoading, onSave } = props;
  const [dataSource, setDataSource] = useState<T & { key: string }[]>(data);

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  const handleSave = (args: any) => {
    onSave(args);
  };

  const components = {
    body: {
      row: EditableDataTableRow,
      cell: EditableDataTableCell,
    },
  };

  const columnsModified = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: T) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <S.BaseTable
      className="editable-data-table"
      components={components}
      rowClassName={() => "editable-row"}
      bordered
      dataSource={dataSource}
      columns={columnsModified as ColumnTypes}
      loading={{ indicator: <Loading size="4rem" />, spinning: isLoading }}
      pagination={false}
      scroll={{ x: 1000 }}
    />
  );
};

export default EditableDataTable;
