import { Dashboard, Dataset, DeleteDashboardForm, DeleteDatasetForm, IModalBaseProps, Organization, User } from "@common/index";
import { Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { deleteDashboard } from "@store/slices/DashboardContent/thunks";
import { reflectDashboardDelete } from "@store/slices/Dashboards/slice";
import { fetchOwnedDashboards } from "@store/slices/Dashboards/thunks";
import { deleteDataset } from "@store/slices/DatasetContent/thunks";
import { reflectDatasetDelete } from "@store/slices/Datasets/slice";
import { findDashboardShareType, findDatasetShareType } from "@utils/index";
import { List } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as S from "./DeleteDatasetModal.style";

interface IDeleteDatasetModalProps extends IModalBaseProps {
  dataset: Dataset;
}

const DeleteDatasetModal: React.FC<IDeleteDatasetModalProps> = ({
  dataset,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [dashboardsWithMatchingDataset, setDashboardsWithMatchingDataset] =
    useState<Dashboard[]>([]);

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.ownedDashboards.data
  );

  useEffect(() => {
    dispatch(fetchOwnedDashboards(currentUser.id));
  }, [currentUser.id]);

  useEffect(() => {
    const matchingDashboards = filterDashboardByOrganization(
      ownedDashboards
    ).filter((dashboard) => dashboard.dataset.id === dataset.id);

    setDashboardsWithMatchingDataset(matchingDashboards);
  }, [dataset, ownedDashboards]);

  const filterDashboardByOrganization = (dashboards: Dashboard[]) => {
    return dashboards.filter((d) => d.tenant === currentOrganization.id);
  };

  const deleteMatchingDashboards = async () => {
    const dashboardDeleteForms: (DeleteDashboardForm & {
      dashboard: Dashboard;
    })[] = dashboardsWithMatchingDataset.map(
      (dashboardToDelete: Dashboard) => ({
        dashboard: dashboardToDelete,
        dashboardId: dashboardToDelete.id,
        userId: currentUser.id,
      })
    );

    await Promise.allSettled(
      dashboardDeleteForms.map((deletionForm) =>
        dispatch(deleteDashboard(deletionForm))
          .unwrap()
          .then(() => {
            dispatch(
              reflectDashboardDelete({
                dashboard: deletionForm.dashboard,
                dashboardType: findDashboardShareType(
                  deletionForm.dashboard,
                  currentUser.id
                ),
              })
            );
          })
      )
    )
      .then(() =>
        NotificationController.success({
          message: t("notifications.success.deleteDashboards"),
        })
      )
      .catch(() => {
        NotificationController.error({
          message: t("notifications.error.deleteDashboards"),
        });
      });
  };

  const onOkHandler = () => {
    setIsOpen(false);

    const form: DeleteDatasetForm = {
      datasetId: dataset.id,
      userId: currentUser.id,
    };

    dispatch(deleteDataset(form))
      .unwrap()
      .then(async () => {
        navigate(APPLICATION_PATHS.DATASET_PATH);

        NotificationController.success({
          message: t("notifications.success.deleteDataset", {
            datasetName: dataset.name,
          }),
        });

        dispatch(
          reflectDatasetDelete({
            dataset: dataset,
            datasetType: findDatasetShareType(dataset, currentUser.id),
          })
        );

        await deleteMatchingDashboards();
      })
      .catch((err) =>
        NotificationController.error({
          message: t("notifications.error.deleteDataset"),
        })
      );
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteDatasetModal.title", {
        datasetName: dataset.name,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <S.CustomText>{t("common.canNotBeUndone")}</S.CustomText>

      {dashboardsWithMatchingDataset.length > 0 && (
        <React.Fragment>
          <S.CustomText>
            {t("modals.deleteDatasetModal.deleteDashboards")}
          </S.CustomText>
          <List
            itemLayout="vertical"
            dataSource={dashboardsWithMatchingDataset}
            renderItem={(dashboard: Dashboard) => (
              <S.CustomListItem>
                <S.BulletPoint>&bull;</S.BulletPoint>
                {dashboard.name}
              </S.CustomListItem>
            )}
            split={false}
          />
        </React.Fragment>
      )}
    </Modal>
  );
};

export default DeleteDatasetModal;
