import { RequestStatus } from "@common/index";
import { AppLogo, Loading, TabTitle } from "@components/common";
import { useAppSelector, useFirebaseAuth } from "@hooks/index";
import React from "react";
import { useTranslation } from "react-i18next";
import * as AuthStyles from "../AuthPages.style";
import { LoginForm } from "../components";
import useKeycloakAuth from "@app/hooks/useKeycloakAuth";

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const authType = process.env.REACT_APP_AUTH;

  const { isLoading: isUserLoading } = authType === "keycloak" ? useKeycloakAuth() : useFirebaseAuth();

  const currentUserStatus: RequestStatus = useAppSelector(
    (state) => state.userContent.status
  );
  const ownedOrganizastionsStatus: RequestStatus = useAppSelector(
    (state) => state.organizations.ownedOrganizations.status
  );
  const sharedOrganizastionsStatus: RequestStatus = useAppSelector(
    (state) => state.organizations.sharedOrganizations.status
  );

  const isLoading: boolean =
    isUserLoading ||
    currentUserStatus === RequestStatus.PENDING ||
    ownedOrganizastionsStatus === RequestStatus.PENDING ||
    sharedOrganizastionsStatus === RequestStatus.PENDING;

  return (
    <AuthStyles.AuthWrapper>
      <TabTitle title={t("pages.auth.login")} />

      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <AuthStyles.LogoContainer>
            <AppLogo margin="0" height="100px" width="100px" />
          </AuthStyles.LogoContainer>
          <LoginForm />
        </React.Fragment>
      )}
    </AuthStyles.AuthWrapper>
  );
};

export default LoginPage;
