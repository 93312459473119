import { ITheme } from "@common/interfaces";

export const darkColorsTheme: ITheme = {
  primaryColor: "#01509A",
  antPrimaryColor: "#f5f5f5",
  primaryColorHover: "#FEAF65",
  secondaryColor: "#FEAF65",
  headerBgColor: "#FFFFFF",
  bodyBgColor: "#f8fbff",
  siderBgColor: "linear-gradient(261.31deg, #006ccf -29.57%, #00509a 121.11%)",
  footerBgColor: "#F8FBFB",

  mainLinearGradientColor1: "#006ccf",
  mainLinearGradientColor2: "#00509a",

  textMainColor: "#404040",
  textSecondaryColor: "#ffffff",
  textLightColor: "#9A9B9F",
  textExtraLightColor: "#BEC0C6",

  textSiderPrimaryColor: "#FFB765",
  textSiderSecondaryColor: "#FFFFFF",

  errorColor: "#FF5252",
  warningColor: "#FFB155",
  successColor: "#30AF5B",

  notificationPrimaryColor: "#D7EBFF",
  notificationSuccessColor: "#EFFFF4",
  notificationWarningColor: "#FFF4E7",
  notificationErrorColor: "#FFE2E2",

  disabledColor: "rgba(0, 0, 0, 0.25)",
  disabledBgColor: "#c5d3e0",
  collapseBackgroundColor: "rgb(0, 108, 207)",
  boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.07)",
  boxShadowDarker: "0 2px 8px 0 rgba(0, 0, 0, 1)",
  borderColor: "#cce1f4",
  scrollColor: "#c5d3e0",
  spinnerBaseColor: "#01509A",
  inputPlaceholderColor: "#404040",

  cardHoverColor: "#f2f6fa",
};
