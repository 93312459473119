import {
  DRAWER_SIZES,
  IDrawerBaseProps, IUserWithRole,
  InsufficientLicenseCause,
  License, Organization,
  RequestStatus,
  User, UserOption,
  UserRole,
} from "@common/index";
import { H2, LicenseUpgradeModal, Loading, SectionHeader } from "@components/common";
import { useAppSelector, useResponsive } from "@hooks/index";
import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationUserCard } from "../../components";
import { AddUserToOrganizationModal } from "../../modals";
import * as S from "./ManageOrganizationDrawer.style";

interface IManageOrganizationDrawerProps extends IDrawerBaseProps {
  organization: Organization;
}

const ManageOrganizationDrawer: React.FC<IManageOrganizationDrawerProps> = ({
  organization,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useResponsive();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const licenses: License[] = useAppSelector((state) => state.licenses.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const currentOrganizationStatus: RequestStatus = useAppSelector(
    (state) => state.organizationContent.status
  );

  const [
    isAddUserToOrganizationModalOpen,
    setIsAddUserToOrganizationModalOpen,
  ] = useState<boolean>(false);

  const [isLicenseUpgradeModalOpen, setIsLicenseUpgradeModalOpen] =
    useState<boolean>(false);

  const getOrganizationOwner = (): IUserWithRole => {
    const ownerWithRole: IUserWithRole = {
      user: currentOrganization.user as User,
      role: UserRole.ADMIN,
    };

    return ownerWithRole;
  };

  const getCurrentUserIfNotOwner = (): IUserWithRole | undefined => {
    if (currentUser.id !== (currentOrganization.user as User).id) {
      const currentUserOptions: UserOption = currentOrganization.users.find(
        (userOpt) => userOpt
      ) as UserOption;

      const currentUserWithRole = {
        user: currentUser,
        role: currentUserOptions.editable ? UserRole.EDITOR : UserRole.BASIC,
      };

      return currentUserWithRole;
    }

    return undefined;
  };

  const getCurrentUserWithRole = () => {
    const currentUserWithRole: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    if (currentUserWithRole) {
      return currentUserWithRole;
    }

    return getOrganizationOwner();
  };

  const getOtherOrganizationMembers = (): IUserWithRole[] => {
    const organizationMembersWithRole: IUserWithRole[] =
      currentOrganization.users.map((userOpt) => {
        const userWithRole: IUserWithRole = {
          user: userOpt.user as User,
          role: userOpt.editable ? UserRole.EDITOR : UserRole.BASIC,
        };

        return userWithRole;
      });

    return organizationMembersWithRole.filter(
      (userWithRole) => userWithRole.user.id !== currentUser.id
    );
  };

  const getAllUsersWithRole = (): IUserWithRole[] => {
    const currentUserIfNotOwner: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    return currentUserIfNotOwner
      ? [
          getOrganizationOwner(),
          currentUserIfNotOwner,
          ...getOtherOrganizationMembers(),
        ]
      : [getOrganizationOwner(), ...getOtherOrganizationMembers()];
  };

  const getCurrentLicense = (): License | undefined => {
    if (currentOrganization.license) {
      const currentLicense = licenses.find(
        (license) => license.licenseType === currentOrganization.license?.type
      );

      return currentLicense;
    }
  };

  const handleAddUser = () => {
    const currentLicense = getCurrentLicense();

    if (currentLicense?.userCount) {
      // 1 is added to users because owner user is counted as member, too.
      currentLicense.userCount <= currentOrganization.users.length + 1
        ? setIsLicenseUpgradeModalOpen(true)
        : setIsAddUserToOrganizationModalOpen(true);
    }
  };

  const renderLoading = () => {
    return (
      <S.LoadingContainer>
        <Loading size="5rem" />
      </S.LoadingContainer>
    );
  };

  const renderDrawerButtons = () => {
    const currentUserIfNotOwner: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    const currentUserWithRole: IUserWithRole = currentUserIfNotOwner
      ? currentUserIfNotOwner
      : getOrganizationOwner();

    if ([UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)) {
      return (
        <Space>
          <Button size="small" type="primary" onClick={handleAddUser}>
            {t("pages.organizations.addUser")}
          </Button>
        </Space>
      );
    }
  };

  const renderUserList = () => {
    return (
      <S.CustomSpacer>
        {getAllUsersWithRole().map((userWithRole) => (
          <OrganizationUserCard
            userWithRole={userWithRole}
            currentUserWithRole={getCurrentUserWithRole()}
          />
        ))}
      </S.CustomSpacer>
    );
  };

  const renderAll = () => {
    return currentOrganizationStatus === RequestStatus.PENDING
      ? renderLoading()
      : renderUserList();
  };

  return (
    <React.Fragment>
      <AddUserToOrganizationModal
        organization={currentOrganization}
        isOpen={isAddUserToOrganizationModalOpen}
        setIsOpen={setIsAddUserToOrganizationModalOpen}
      />

      <LicenseUpgradeModal
        cause={InsufficientLicenseCause.USER_COUNT}
        isOpen={isLicenseUpgradeModalOpen}
        setIsOpen={setIsLicenseUpgradeModalOpen}
      />

      <Drawer
        open={isOpen}
        title={organization.name}
        width={isTablet ? DRAWER_SIZES.large : DRAWER_SIZES.small}
        placement="right"
        extra={renderDrawerButtons()}
        onClose={() => setIsOpen(false)}
      >
        <SectionHeader title={<H2>{t("common.users")}</H2>} />
        {renderAll()}
      </Drawer>
    </React.Fragment>
  );
};

export default ManageOrganizationDrawer;
