import { Dataset, EditDatasetForm, IModalBaseProps, Organization, User } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editDataset } from "@store/slices/DatasetContent/thunks";
import { reflectDatasetEdit } from "@store/slices/Datasets/slice";
import { convertUserOptionArray, findDatasetShareType } from "@utils/index";
import { Input } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IEditDatasetModalProps extends IModalBaseProps {
  dataset: Dataset;
}

const EditDatasetModal: React.FC<IEditDatasetModalProps> = ({
  dataset,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  useEffect(() => {
    const formValues = {
      name: dataset.name,
      description: dataset.description,
    };

    form.setFieldsValue(formValues);
  }, [dataset]);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: EditDatasetForm) => {
    const form: EditDatasetForm = {
      datasetId: dataset.id,
      userId: currentUser.id,
      users: convertUserOptionArray(dataset.users),
      tenantId: currentOrganization.id,
      name: formData.name,
      description: formData.description,
    };

    dispatch(editDataset(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDatasetEdit({
            dataset: res,
            datasetType: findDatasetShareType(dataset, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.editDataset"),
        });
      })
      .catch((err) => {
        NotificationController.success({
          message: t("notifications.error.editDataset"),
        });
      });
  };

  return (
    <Modal
      title={t("modals.editDatasetModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        form={form}
        onFinish={onSubmitHandler}
        layout="vertical"
        name="editDatasetForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
        <BaseForm.Item name="description" label={t("common.description")}>
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditDatasetModal;
