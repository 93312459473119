import React from "react";
import { useAppSelector } from "@hooks/index";
import visudatLogo from "@assets/images/visudatLogo.svg";
import visudatLogoDark from "@assets/images/visudatLogoDark.svg";
import * as S from "./AppLogo.style";

interface IAppLogoProps {
  margin?: string;
  height?: string;
  width?: string;
}

const AppLogo: React.FC<IAppLogoProps> = ({ margin, height, width }) => {
  const theme = useAppSelector((state) => state.theme.data);

  const getLogoByTheme = () => {
    switch (theme) {
      case "dark":
        return visudatLogoDark;
      case "light":
      default:
        return visudatLogo;
    }
  };

  return (
    <S.AppLogoContainer margin={margin}>
      <S.AppLogoImage height={height} width={width} src={getLogoByTheme()} />
    </S.AppLogoContainer>
  );
};

export default AppLogo;
