import {
  Dashboard,
  DeleteVisualFromDashboardForm,
  EditDashboardForm,
  IModalBaseProps,
  User, Visual,
} from "@common/index";
import { Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { setCurrentDashboard } from "@store/slices/DashboardContent/slice";
import {
  deleteVisualFromDashboard,
  editDashboard,
} from "@store/slices/DashboardContent/thunks";
import { reflectDashboardEdit } from "@store/slices/Dashboards/slice";
import {
  convertUserOptionArray,
  findDashboardShareType,
} from "@utils/index";
import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface IDeleteVisualFromDashboardModalProps extends IModalBaseProps {
  visual: Visual;
  dashboard: Dashboard;
}

const DeleteVisualFromDashboardModal: React.FC<
  IDeleteVisualFromDashboardModalProps
> = ({ visual, dashboard, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    setIsOpen(false);

    const form: DeleteVisualFromDashboardForm = {
      dashboardId: dashboard.id,
      visualId: visual.id,
    };

    dispatch(deleteVisualFromDashboard(form))
      .unwrap()
      .then((res) => {
        const modifiedDashboard: Dashboard = {
          ...dashboard,
          layouts: dashboard.layouts.filter(
            (layout) => layout.i !== form.visualId
          ),
        };

        const editDashboardData: EditDashboardForm = {
          dashboardId: modifiedDashboard.id,
          name: modifiedDashboard.name,
          description: modifiedDashboard.description,
          datasetId: modifiedDashboard.dataset.id,
          userId: (modifiedDashboard.user as User).id,
          users: convertUserOptionArray(modifiedDashboard.users),
          layouts: modifiedDashboard.layouts,
          tenantId: dashboard.tenant,
        };

        dispatch(editDashboard(editDashboardData))
          .unwrap()
          .then((res) => {
            dispatch(setCurrentDashboard(modifiedDashboard));
            dispatch(
              reflectDashboardEdit({
                dashboard: modifiedDashboard,
                dashboardType: findDashboardShareType(
                  modifiedDashboard,
                  currentUser.id
                ),
              })
            );
          });

        NotificationController.success({
          message: t("notifications.success.deleteVisualFromDashboard", {
            visualName: visual.name,
            dashboardName: dashboard.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.deleteVisualFromDashboard", {
            visualName: visual.name,
            dashboardName: dashboard.name,
          }),
        });
      });
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteVisualFromDashboard.title", {
        visualName: visual.name,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteVisualFromDashboardModal;
