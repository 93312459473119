import { AddUserToDatasetForm, Dataset, IModalBaseProps, User, UserOption } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addUserToDataset } from "@store/slices/DatasetContent/thunks";
import { reflectDatasetEdit } from "@store/slices/Datasets/slice";
import {
  createAutoCompleteOptionsFromArray,
  findDatasetShareType,
} from "@utils/index";
import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IAddUserToDatasetModal extends IModalBaseProps {
  user: User;
  dataset: Dataset;
}

const AddUserToDatasetModal: React.FC<IAddUserToDatasetModal> = ({
  user,
  dataset,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  const currentUser = useAppSelector((state) => state.userContent.data);

  const currentOrganization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const initialValues = {
    user: undefined,
    editable: false,
  };

  useEffect(() => {
    setOptions(
      createAutoCompleteOptionsFromArray(
        currentOrganization.users,
        (organizationUser) => organizationUser.user.id,
        (organizationUser) => organizationUser.user.name
      )
    );
  }, [currentOrganization.users]);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
    form.resetFields();
  };

  const onSubmitHandler = (formData: AddUserToDatasetForm) => {
    formData.user = JSON.parse(formData.user as string);

    const data: AddUserToDatasetForm = {
      user: formData.user,
      dataset: dataset,
      editable: formData.editable,
    };

    const isUserAlreadyInDataset = dataset.users.some(
      (userOpt) => (userOpt.user as User).id === (formData.user as User).id
    );

    form.resetFields();

    if (isUserAlreadyInDataset) {
      NotificationController.error({
        message: t("notifications.error.userAlreadyInDataset"),
      });
      return;
    }

    dispatch(addUserToDataset(data))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDatasetEdit({
            dataset: res,
            datasetType: findDatasetShareType(res, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.addUserToDataset", {
            userName: (data.user as User).name,
            datasetName: dataset.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.addUserToDataset", {
            userName: (data.user as User).name,
            datasetName: dataset.name,
          }),
        });
      });
  };

  const isUserCanBeAddedToDataset = (user: User): boolean => {
    const found: UserOption | undefined = dataset.users.find((opt) => {
      return (opt.user as User).id === user?.id;
    });

    return found ? false : true;
  };

  return (
    <Modal
      title={t("modals.addUserToDatasetModal.title", {
        datasetName: dataset.name,
      })}
      onOk={onOkHandler}
      open={isOpen}
      onCancel={onCancelHandler}
    >
      <BaseForm
        initialValues={initialValues}
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addUserToDatasetForm"
      >
        <BaseForm.Item name="user" label={t("common.name")}>
          <Select showSearch allowClear placeholder={t("common.user")}>
            {options.map((option) => (
              <Select.Option
                key={option.value}
                value={JSON.stringify(option.selectedOption.user)}
                disabled={
                  !isUserCanBeAddedToDataset(option.selectedOption.user)
                }
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </BaseForm.Item>
        <BaseForm.Item name="editable" label={t("common.editable")}>
          <Select placeholder={t("common.editable")}>
            <Select.Option value={false}>{t("common.no")}</Select.Option>
            <Select.Option value={true}>{t("common.yes")}</Select.Option>
          </Select>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddUserToDatasetModal;
