import { FONT_WEIGHT } from "@app/common";
import {
  Card as BaseCard,
  BaseForm,
  DayjsDatePicker,
  P1,
} from "@components/common";
import { Button, InputNumber } from "antd";
import styled from "styled-components";

export const CardContainer = styled.div``;

export const Card = styled(BaseCard)`
  box-shadow: var(--box-shadow-darker);
  transition: all 0.5s ease;

  &:hover {
    background-color: var(--card-hover-color);
  }
`;

export const CustomButton = styled(Button)`
  color: var(--text-extra-light-color);
`;

export const CustomBaseFormItem = styled(BaseForm.Item)`
  margin: 0;
  padding: 0;
  min-height: 0 !important;
  width: 100%;
`;

export const CustomDatePicker = styled(DayjsDatePicker).attrs({
  picker: "time",
  format: "HH:mm",
  size: "small",
  changeOnBlur: true,
})`
  width: 100%;
`;

export const ValueText = styled(P1)`
  &.ant-typography {
    margin: 0;
    padding: 0;
    text-align: center;

    font-weight: ${FONT_WEIGHT.semibold};
  }
`;

export const ValueInput = styled(InputNumber).attrs({ size: "small" })`
  width: 100%;
`;
