import styled from "styled-components";
import { Card as BaseCard } from "@components/common/Card";
import { BORDER_RADIUS, media } from "@common/constants";


export const Card = styled(BaseCard)`
  box-shadow: var(--box-shadow);
  min-height: 13rem;
  min-width: 10rem;
  transition: all 0.5s ease;

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const AINavigationIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  min-height: 5rem;
  padding: 0.5rem;

  .anticon {
    font-size: 30px;
  }

  @media only screen and ${media.md} {
    .anticon {
      font-size: 35px !important;
    }
  }
`;

export const AINavigationCardInfo = styled.div`
  text-align: center;
`;
