import { Button, Space } from "antd";
import styled from "styled-components";

export const BtnContainer = styled(Button)`
  color: var(--text-extra-light-color);
`;

export const LoadingAndNoDataContainer = styled.div`
  width: 100%;
  height: 300px;
`;

export const CustomSpacer = styled(Space)`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
`;
