import { Space } from "antd";
import styled, { css } from "styled-components";

interface ILoadingContainerProps {
    width?: string;
    height?: string;
}

export const VerticalSpacer = styled(Space).attrs({ direction: "vertical" })`
  width: 100%;
`;

export const LoadingContainer = styled.div<ILoadingContainerProps>`
  ${(props) =>
    props.width
        ? css`
          width: ${props.width};
        `
        : css`
          width: 100%;
        `}

  ${(props) =>
    props.height
        ? css`
          height: ${props.height};
        `
        : css`
          height: 400px;
        `}
              
  display: flex;
  justify-content: center;
  align-items: center;
`;