import { createSlice } from "@reduxjs/toolkit";
import { RequestStatus } from "@common/enums";
import { ApiResponseState, License } from "@common/types";
import { fetchLicenses } from "@store/slices/Licenses/thunks";

const initialState: ApiResponseState<License[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: undefined,
};

const licensesSlice = createSlice({
  name: "licenses",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicenses.pending, (state) => {
        state.status = RequestStatus.IDLE;
      })
      .addCase(fetchLicenses.fulfilled, (state, action) => {
        state.status = RequestStatus.FULFILLED;
        state.data = action.payload;
      })
      .addCase(fetchLicenses.rejected, (state) => {
        state.status = RequestStatus.REJECTED;
      });
  },
});

export const { reset: resetLicenses } = licensesSlice.actions;
export default licensesSlice.reducer;
