import React from "react";
import { Avatar, Col, Row } from "antd";
import { useResponsive, useAppSelector } from "@hooks/index";
import { Dropdown, H6 } from "@components/common";

import { ProfileOverlay } from "../ProfileOverlay";
import * as S from "./ProfileDropdown.style";

const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const currentUser = useAppSelector((state) => state.userContent.data);

  return currentUser ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={["click"]}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          {currentUser.photoUrl ? (
            <Avatar src={currentUser.photoUrl} />
          ) : (
            <S.CustomAvatar
              $photoExist={currentUser.photoUrl !== null}
              alt="User"
              shape="circle"
              size={40}
            >
              {currentUser.name && currentUser.name[0]}
            </S.CustomAvatar>
          )}
        </Col>
        {isTablet && (
          <Col>
            <H6>{currentUser.name}</H6>
          </Col>
        )}
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
};

export default ProfileDropdown;
