import { DRAWER_SIZES, Dashboard, IDrawerBaseProps, IUserWithRole, User, UserOption, UserRole } from "@common/index";
import { H2, SectionHeader } from "@components/common";
import { useAppSelector, useResponsive } from "@hooks/index";
import { DashboardUserCard } from "@pages/DashboardPages/components";
import { AddUserToDashboardModal } from "@pages/DashboardPages/modals";
import * as CommonStyles from "@styles/CommonStyles";
import { Button, Drawer, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface IManageDashboardDrawerProps extends IDrawerBaseProps {
  dashboard: Dashboard;
}

const ManageDashboardDrawer: React.FC<IManageDashboardDrawerProps> = ({
  dashboard,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const { isTablet } = useResponsive();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const [isAddUserToDashboardModalOpen, setIsAddUserToDashboardModalOpen] =
    useState<boolean>(false);

  const getCurrentUserWithRole = (): IUserWithRole | undefined => {
    if ((dashboard.user as User).id === currentUser.id) {
      return {
        user: currentUser,
        role: UserRole.ADMIN,
      };
    }

    const correspondingUserOption: UserOption | undefined =
      dashboard.users.find(
        (userOpt) => (userOpt.user as User).id === currentUser.id
      );

    return correspondingUserOption
      ? {
          user: correspondingUserOption!.user as User,
          role: correspondingUserOption?.editable
            ? UserRole.EDITOR
            : UserRole.BASIC,
        }
      : undefined;
  };

  const getDashboardOwner = (): IUserWithRole => {
    const ownerWithRole: IUserWithRole = {
      user: dashboard.user as User,
      role: UserRole.ADMIN,
    };

    return ownerWithRole;
  };

  const getAllUsersFromDashboard = (): IUserWithRole[] => {
    const allDashboardUsersWithRole: IUserWithRole[] = [];

    if (checkIfCurrentUserIsDashboardOwner()) {
      allDashboardUsersWithRole.push(getDashboardOwner());
    } else {
      allDashboardUsersWithRole.push(getDashboardOwner());
      currentUserWithRole &&
        allDashboardUsersWithRole.push(currentUserWithRole);
    }

    const dashboardMembersWithRole: IUserWithRole[] = dashboard.users.map(
      (userOpt) => {
        const userWithRole: IUserWithRole = {
          user: userOpt.user as User,
          role: userOpt.editable ? UserRole.EDITOR : UserRole.BASIC,
        };

        return userWithRole;
      }
    );

    return allDashboardUsersWithRole.concat(
      dashboardMembersWithRole.filter(
        (userWithRole) => userWithRole.user.id !== currentUser.id
      )
    );
  };

  const checkIfCurrentUserIsDashboardOwner = (): boolean => {
    const ownerWithRole: IUserWithRole = getDashboardOwner();

    return currentUserWithRole
      ? (currentUserWithRole.user as User).id ===
          (ownerWithRole.user as User).id
      : false;
  };

  const currentUserWithRole: IUserWithRole | undefined =
    getCurrentUserWithRole();

  const renderDrawerButtons = () => {
    if (!currentUserWithRole) {
      return null;
    }

    if ([UserRole.ADMIN, UserRole.EDITOR].includes(currentUserWithRole.role)) {
      return (
        <Space>
          <Button
            size="small"
            type="primary"
            onClick={() => setIsAddUserToDashboardModalOpen(true)}
          >
            {t("pages.dashboards.addUser")}
          </Button>
        </Space>
      );
    }
  };

  return (
    <React.Fragment>
      <AddUserToDashboardModal
        dashboard={dashboard}
        isOpen={isAddUserToDashboardModalOpen}
        setIsOpen={setIsAddUserToDashboardModalOpen}
      />

      <Drawer
        open={isOpen}
        title={dashboard.name}
        width={isTablet ? DRAWER_SIZES.large : DRAWER_SIZES.small}
        placement="right"
        extra={renderDrawerButtons()}
        onClose={() => setIsOpen(false)}
      >
        <SectionHeader title={<H2>{t("common.users")}</H2>} />
        <CommonStyles.VerticalSpacer>
          {getAllUsersFromDashboard().map((userWithRole) => (
              <DashboardUserCard userWithRole={userWithRole} dashboardId={dashboard.id}/>
          ))}
        </CommonStyles.VerticalSpacer>
      </Drawer>
    </React.Fragment>
  );
};

export default ManageDashboardDrawer;
