import { P1 } from "@components/common";
import { Button } from "antd";
import styled from "styled-components";

export const TextOverflowContainer = styled.div`
  overflow: hidden;
  max-width: 95px;

  text-align: start;
`;

export const OrganizationText = styled(P1)`
  &.ant-typography {
    color: Var(--text-secondary-color);
  }
`;

export const OrganizationSelectorButton = styled(Button)`
  margin: 0;
  padding: 0;
  color: var(--text-secondary-color);
`;

export const ArrowContainer = styled.span`
  color: white;
  font-size: 15px;
  margin-left: 0.5rem;
`;
