import React, { useRef } from "react";
import { Carousel, Col, Row } from "antd";
import {
  AppstoreOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  H2,
  H3,
  Loading,
  NavigationCard,
  NoData,
  SectionHeader,
} from "@components/common";
import { BREAKPOINTS } from "@common/constants";
import * as S from "./CarouselNavigationSection.style";
import _ from "underscore";

interface ICarouselNavigationSectionProps<T> {
  title: string;
  items: T[];
  isLoading: boolean;
  icon: React.ReactElement;
  onCreate?: () => void;
  onItemClick?: (item: T) => void;
  keyExtractor?: (item: T) => string;
  titleExtractor?: (item: T) => string;
  descriptionExtractor?: (item: T) => string;
  pathExtractor?: (item: T) => string;
}

const CarouselNavigationSection = <T extends object>(
  props: ICarouselNavigationSectionProps<T>
) => {
  const {
    isLoading,
    icon,
    title,
    items,
    onCreate,
    onItemClick,
    keyExtractor,
    titleExtractor,
    descriptionExtractor,
  } = props;
  const sliderRef = useRef<any>();

  const RESPONSIVE_SLIDE_COUNTS = [
    {
      breakpoint: BREAKPOINTS.xxl,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: BREAKPOINTS.xl,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: BREAKPOINTS.lg,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: BREAKPOINTS.md,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: BREAKPOINTS.sm,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: BREAKPOINTS.xs,
      settings: {
        slidesToShow: 1,
      },
    },
  ];

  const CAROUSEL_BUTTONS = [
    {
      key: "create",
      icon: <PlusOutlined />,
      onClick: onCreate,
      visible: !_.isUndefined(onCreate),
    },
    {
      key: "prev",
      icon: <LeftOutlined />,
      onClick: () => sliderRef?.current?.prev(),
      visible: true,
    },
    {
      key: "next",
      icon: <RightOutlined />,
      onClick: () => sliderRef?.current?.next(),
      visible: true,
    },
  ];

  const generateCarouselButtons = () => {
    return (
      <React.Fragment>
        {CAROUSEL_BUTTONS.filter((item) => item.visible).map((item) => {
          return (
            <Col key={item.key}>
              <S.BtnContainer type="text" size="small" onClick={item.onClick}>
                {item.icon as React.ReactNode}
              </S.BtnContainer>
            </Col>
          );
        })}
      </React.Fragment>
    );
  };

  const generateNavigationCards = (items: T[]) => {
    return items.map((item: T, index) => {
      return (
        <S.CardWrapper key={keyExtractor ? keyExtractor(item) : index}>
          <NavigationCard
            icon={icon}
            onClick={() => {
              onItemClick ? onItemClick(item) : null;
            }}
            title={titleExtractor ? titleExtractor(item) : ""}
            description={descriptionExtractor ? descriptionExtractor(item) : ""}
          />
        </S.CardWrapper>
      );
    });
  };

  const renderLoading = () => {
    return (
      <S.LoadingAndNoDataContainer>
        <Loading size="5rem" />
      </S.LoadingAndNoDataContainer>
    );
  };

  const NAVIGATION_CARDS = generateNavigationCards(items);

  return (
    <S.SectionWrapper>
      <SectionHeader title={<H2>{title}</H2>}>
        <Row align={"middle"}>{generateCarouselButtons()}</Row>
      </SectionHeader>

      {isLoading ? (
        renderLoading()
      ) : (
        <Col>
          {NAVIGATION_CARDS.length === 0 ? (
            <S.LoadingAndNoDataContainer>
              <NoData />
            </S.LoadingAndNoDataContainer>
          ) : (
            <Carousel
              ref={sliderRef}
              draggable
              dots={false}
              infinite={false}
              slidesToShow={4}
              slidesToScroll={1}
              responsive={RESPONSIVE_SLIDE_COUNTS}
            >
              {NAVIGATION_CARDS}
            </Carousel>
          )}
        </Col>
      )}
    </S.SectionWrapper>
  );
};

export default CarouselNavigationSection;
