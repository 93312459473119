import { H1, H6 } from "@components/common";
import styled from "styled-components";

export const SectionHeaderSubtitle = styled(H6)`
  &.ant-typography {
    margin-top: 0 !important;
    color: var(--text-light-color);
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
`;

