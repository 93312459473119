import { IModalBaseProps, License } from "@common/index";
import { Modal, Text } from "@components/common";
import { APPLICATION_PATHS } from "@routes/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IDowngradeLicenseModalProps extends IModalBaseProps {
  license: License;
}

const DowngradeLicenseModal: React.FC<IDowngradeLicenseModalProps> = ({
  isOpen,
  setIsOpen,
  license,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onOkHandler = () => {
    navigate(APPLICATION_PATHS.DOWNGRADE_LICENSE_PATH, {state: {license}});
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.downgradeLicenseModal.title")}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("modals.downgradeLicenseModal.text")}</Text>
    </Modal>
  );
};

export default DowngradeLicenseModal;
