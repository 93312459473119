import { Obligation, PaymentStorageMethod } from "@common/index";
import {
  BaseForm,
  Card,
  H2,
  SectionHeader,
  withAnimation,
  withLoading,
} from "@components/common";
import { useAppSelector } from "@hooks/index";
import { Row, Select, SelectProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  LicenseUsageDetailCard,
  NewCardPaymentForm,
  SavedCardPaymentForm,
} from "../";
import * as S from "./PaymentAreaSection.style";

const PaymentAreaSection: React.FC = () => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const { obligationId } = useParams();

  const selectedObligation: Obligation | undefined = useAppSelector(
    (state) => state.payments.obligations.data
  ).find((obg) => obg.id === obligationId);

  const selectedPaymentStorageMethod: PaymentStorageMethod = BaseForm.useWatch(
    "paymentStorageMethod",
    form
  );

  const renderForm = () => {
    switch (selectedPaymentStorageMethod) {
      case PaymentStorageMethod.SAVED_METHOD:
        return <SavedCardPaymentForm />;
      case PaymentStorageMethod.NEW_METHOD:
      default:
        return <NewCardPaymentForm />;
    }
  };

  const PAYMENT_STORAGE_METHOD_OPTIONS: SelectProps["options"] = [
    {
      label: t("pages.organizations.savedMethod"),
      value: PaymentStorageMethod.SAVED_METHOD,
    },
    {
      label: t("pages.organizations.newMethod"),
      value: PaymentStorageMethod.NEW_METHOD,
    },
  ];

  const initialValues = {
    paymentStorageMethod: PaymentStorageMethod.SAVED_METHOD,
  };

  return (
    <React.Fragment>
      <Card padding={[25, 25]}>
        <SectionHeader
          title={<H2>{t("pages.organizations.paymentArea")}</H2>}
          customMargin="0 0 2rem 0"
          backButtonVisible
        />

        <Row wrap>
          <S.CustomSpacer direction="vertical">
            {selectedObligation && (
              <LicenseUsageDetailCard obligation={selectedObligation} />
            )}
            <BaseForm
              form={form}
              initialValues={initialValues}
              style={{ width: "100%" }}
            >
              <BaseForm.Item
                name="paymentStorageMethod"
                label={t("pages.organizations.cardOptions")}
              >
                <Select options={PAYMENT_STORAGE_METHOD_OPTIONS} />
              </BaseForm.Item>
            </BaseForm>
            {renderForm()}
          </S.CustomSpacer>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default withAnimation(withLoading(PaymentAreaSection));
