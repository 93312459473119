import dashboardContentReducer from "@store/slices/DashboardContent/slice";
import dashboardsReducer from "@store/slices/Dashboards/slice";
import datasetContentReducer from "@store/slices/DatasetContent/slice";
import datasetsReducer from "@store/slices/Datasets/slice";
import licenseReducer from "@store/slices/Licenses/slice";
import organizationContentReducer from "@store/slices/OrganizationContent/slice";
import organizationReducer from "@store/slices/Organizations/slice";
import templatesetContent from "@store/slices/TemplatesetContent/slice";
import templatesetsReducer from "@store/slices/Templatesets/slice";
import themeReducer from "@store/slices/Theme/slice";
import userAgentReducer from "@store/slices/UserAgent/slice";
import userContentReducer from "@store/slices/UserContent/slice";
import usersReducer from "@store/slices/Users/slice";
import paymentsReducer from "@store/slices/Payments/slice";

const rootReducer = {
  dashboards: dashboardsReducer,
  dashboardContent: dashboardContentReducer,
  datasets: datasetsReducer,
  datasetContent: datasetContentReducer,
  theme: themeReducer,
  licenses: licenseReducer,
  users: usersReducer,
  userContent: userContentReducer,
  userAgent: userAgentReducer,
  organizations: organizationReducer,
  organizationContent: organizationContentReducer,
  payments: paymentsReducer,
  templatesets: templatesetsReducer,
  templatesetContent: templatesetContent,
};

export default rootReducer;
