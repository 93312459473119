import { PaymentStatus } from "@common/enums";
import { Tag } from "antd";
import styled, { css } from "styled-components";

interface IPaymentTagProps {
  $paymentStatus: PaymentStatus;
}

export const LicenseTypeTag = styled(Tag)`
  margin: 0;
`;

export const PaymentTag = styled(Tag)<IPaymentTagProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;

  ${(props) =>
    props.$paymentStatus === PaymentStatus.UNPAID &&
    css`
      color: var(--error-color);
      border-color: var(--error-color);
      background-color: var(--notification-error-color);
    `}

  ${(props) =>
    props.$paymentStatus === PaymentStatus.PAID &&
    css`
      color: var(--success-color);
      border-color: var(--success-color);
      background-color: var(--notification-success-color);
    `}
`;
