import { IModalBaseProps, Organization } from "@app/common";
import { useAppSelector } from "@app/hooks";
import { APPLICATION_PATHS } from "@app/routes";
import { Modal, Text } from "@components/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const RedirectMissingPaymentInformationModal: React.FC<IModalBaseProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onOkHandler = () => {
    navigate(
      APPLICATION_PATHS.PAYMENT_METHODS_PATH.replace(
        ":organizationId",
        currentOrganization.id
      )
    );
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={t("modals.redirectMissingPaymentInformationModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      okText={t("modals.redirectMissingPaymentInformationModal.goToPayments")}
      onCancel={onCancelHandler}
      getContainer={false}>
      <Text>{t("modals.redirectMissingPaymentInformationModal.text")}</Text>
    </Modal>
  );
};

export default RedirectMissingPaymentInformationModal;
