import React from "react";
import { useTranslation } from "react-i18next";
import { AppLogo, TabTitle } from "@components/common";
import { ForgotPasswordForm } from "../components";
import * as AuthStyles from "../AuthPages.style";

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthStyles.AuthWrapper>
      <TabTitle title={t("pages.auth.forgotPassword")} />
      <AuthStyles.LogoContainer>
        <AppLogo margin="0" height="100px" width="100px" />
      </AuthStyles.LogoContainer>

      <ForgotPasswordForm />
    </AuthStyles.AuthWrapper>
  );
};

export default ForgotPasswordPage;
