import DisabledImage from "@assets/images/disabled.svg";
import { Text } from "@components/common";
import { useAppSelector, useResponsive } from "@hooks/index";
import * as CommonStyles from "@styles/CommonStyles";
import { Button, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./OrganizationDisabledPage.style";
import { Organization } from "@app/common";
import { useNavigate } from "react-router-dom";
import { APPLICATION_PATHS } from "@app/routes";
import { ArrowRightOutlined, RightOutlined } from "@ant-design/icons";

const OrganizationDisabledPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mobileOnly } = useResponsive();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const navigateToPaymentScreen = () => {
    navigate(
      APPLICATION_PATHS.PAYMENTS_PATH.replace(
        ":organizationId",
        currentOrganization.id
      )
    );
  };

  return (
    <S.Container>
      <CommonStyles.VerticalSpacer size="middle">
        <Row justify="center">
          <img
            src={DisabledImage}
            width={mobileOnly ? 300 : 400}
            height="100%"
          />
        </Row>

        <Row justify={"center"}>
          <S.TextContainer>
            <S.InfoText>
              {t("pages.organizations.organizationDisabledInfo")}
            </S.InfoText>
          </S.TextContainer>
        </Row>
        <Row justify="center">
          <S.CustomTextButton
            icon={<ArrowRightOutlined />}
            onClick={navigateToPaymentScreen}
          >
            {t("pages.organizations.goToPayments")}
          </S.CustomTextButton>
        </Row>
      </CommonStyles.VerticalSpacer>
    </S.Container>
  );
};

export default OrganizationDisabledPage;
