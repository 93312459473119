import { useEffect, useState } from "react";
import { AppDate } from "@common/types";
import { Dates } from "@services/DateService";

const useTime = (refreshCycle: number = 1000) => {
  const [currentTime, setCurrentTime] = useState<AppDate>();

  useEffect(() => {
    const interval = setInterval(
      () => setCurrentTime(Dates.getToday()),
      refreshCycle
    );

    () => clearInterval(interval);
  }, [refreshCycle, Dates.getToday]);

  return currentTime;
};

export default useTime;
