import {
  AppstoreOutlined,
  DatabaseOutlined,
  ReconciliationOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { INavigationItem, Organization } from "@common/index";
import { useAppSelector, useResponsive } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as S from "./SiderMenu.style";

interface ISiderMenuProps {
  isCollapsed: boolean;
  toggleSider: () => void;
}

const SiderMenu: React.FC<ISiderMenuProps> = ({ toggleSider, isCollapsed }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { desktopOnly } = useResponsive();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onItemClick = () => {
    desktopOnly ? null : isCollapsed ? null : toggleSider();
  };

  const SiderNavigationData: INavigationItem[] = [
    {
      title: "sider.dashboard",
      key: "dashboard",
      url: APPLICATION_PATHS.DASHBOARD_PATH,
      icon: <AppstoreOutlined />,
    },
    {
      title: "sider.datasets",
      key: "datasets",
      url: APPLICATION_PATHS.DATASET_PATH,
      icon: <DatabaseOutlined />,
    },
    {
      title: "sider.measurements",
      key: "measurements",
      url: APPLICATION_PATHS.MEASUREMENT_PATH,
      icon: <ReconciliationOutlined />,
    },
    {
      title: "sider.organization",
      key: "organization",
      icon: <TeamOutlined />,
      onClick: () => {
        const organizationDetailPath = `${APPLICATION_PATHS.ORGANIZATION_DETAIL_PATH.replace(
          ":organizationId",
          currentOrganization.id
        )}`;
        navigate(organizationDetailPath);
      },
    },
  ];

  return (
    <S.Menu mode="inline" onClick={onItemClick}>
      {SiderNavigationData.map((item) => (
        <Menu.Item
          key={item.key}
          icon={item.icon}
          onClick={!item.url ? item.onClick : undefined}
        >
          <Link to={item.url || ""}>{t(item.title)}</Link>
        </Menu.Item>
      ))}
    </S.Menu>
  );
};

export default SiderMenu;
