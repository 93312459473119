import { ApiResponseState, Dataset, RequestStatus, ShareType } from "@common/index";
import { createSlice } from "@reduxjs/toolkit";
import {
  addDataset,
  addDatasetFromAITemplate,
  addDatasetFromTemplate,
} from "@store/slices/Datasets/thunks";
import { fetchOwnedDatasets, fetchSharedDatasets } from "./thunks";

const datasetsInitialState: ApiResponseState<Dataset[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const sharedDatasetsInitialState: ApiResponseState<Dataset[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const initialState = {
  ownedDatasets: datasetsInitialState,
  sharedDatasets: sharedDatasetsInitialState,
};

export const datasetsSlice = createSlice({
  name: "datasets",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    reflectDatasetDelete: (state, action) => {
      const { dataset, datasetType } = action.payload;
      const deletedDatasetId = dataset.id;

      switch (datasetType) {
        case ShareType.SHARED:
          state.sharedDatasets.data = state.sharedDatasets.data.filter(
            (sharedDataset) => sharedDataset.id !== deletedDatasetId
          );
          break;
        case ShareType.OWNED:
          state.ownedDatasets.data = state.ownedDatasets.data.filter(
            (ownedDataset) => ownedDataset.id !== deletedDatasetId
          );
          break;
        default:
          break;
      }
    },

    reflectDatasetEdit: (state, action) => {
      const { dataset, datasetType } = action.payload;
      const datasetIndex = getDatasetIndex(state, datasetType, dataset);

      switch (datasetType) {
        case ShareType.SHARED:
          if (datasetIndex >= 0) {
            state.sharedDatasets.data[datasetIndex] = dataset;
          }
          break;
        case ShareType.OWNED:
          if (datasetIndex >= 0) {
            state.ownedDatasets.data[datasetIndex] = dataset;
          }
          break;
        default:
          break;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOwnedDatasets.pending, (state) => {
        state.ownedDatasets.status = RequestStatus.PENDING;
      })
      .addCase(fetchOwnedDatasets.fulfilled, (state, action) => {
        state.ownedDatasets.status = RequestStatus.FULFILLED;
        state.ownedDatasets.data = action.payload;
      })
      .addCase(fetchOwnedDatasets.rejected, (state) => {
        state.ownedDatasets.status = RequestStatus.REJECTED;
      })
      .addCase(fetchSharedDatasets.pending, (state) => {
        state.sharedDatasets.status = RequestStatus.PENDING;
      })
      .addCase(fetchSharedDatasets.fulfilled, (state, action) => {
        state.sharedDatasets.status = RequestStatus.FULFILLED;
        state.sharedDatasets.data = action.payload;
      })
      .addCase(fetchSharedDatasets.rejected, (state) => {
        state.sharedDatasets.status = RequestStatus.REJECTED;
      })
      .addCase(addDataset.pending, (state) => {
        state.ownedDatasets.status = RequestStatus.PENDING;
      })
      .addCase(addDataset.fulfilled, (state, action) => {
        state.ownedDatasets.status = RequestStatus.FULFILLED;
        state.ownedDatasets.data.push(action.payload);
      })
      .addCase(addDataset.rejected, (state) => {
        state.ownedDatasets.status = RequestStatus.REJECTED;
      })
      .addCase(addDatasetFromTemplate.pending, (state) => {
        state.ownedDatasets.status = RequestStatus.PENDING;
      })
      .addCase(addDatasetFromTemplate.fulfilled, (state, action) => {
        state.ownedDatasets.status = RequestStatus.FULFILLED;
        state.ownedDatasets.data.push(action.payload);
      })
      .addCase(addDatasetFromTemplate.rejected, (state) => {
        state.ownedDatasets.status = RequestStatus.REJECTED;
      })
      .addCase(addDatasetFromAITemplate.pending, (state) => {
        state.ownedDatasets.status = RequestStatus.PENDING;
      })
      .addCase(addDatasetFromAITemplate.fulfilled, (state, action) => {
        state.ownedDatasets.status = RequestStatus.FULFILLED;
        state.ownedDatasets.data.push(action.payload);
      })
      .addCase(addDatasetFromAITemplate.rejected, (state) => {
        state.ownedDatasets.status = RequestStatus.REJECTED;
      });
  },
});

const getDatasetIndex = (
  state: typeof initialState,
  datasetType: ShareType,
  dataset: Dataset
) => {
  let datasetIndex = -1;

  switch (datasetType) {
    case ShareType.SHARED:
      datasetIndex = state.sharedDatasets.data.findIndex(
        (sharedDataset) => sharedDataset.id === dataset.id
      );
      break;
    case ShareType.OWNED:
      datasetIndex = state.ownedDatasets.data.findIndex(
        (ownedDataset) => ownedDataset.id === dataset.id
      );
      break;
    default:
      break;
  }

  return datasetIndex;
};

export const {
  reset: resetDatasets,
  reflectDatasetDelete,
  reflectDatasetEdit,
} = datasetsSlice.actions;
export default datasetsSlice.reducer;
