import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { MetricRecord, MetricRecordDataTableValue } from "@common/index";
import { Card, H2, SectionHeader } from "@components/common";
import * as CommonStyles from "@styles/CommonStyles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AddMetricRecordModal } from "../../modals";
import { MetricRecordCard } from "../MetricRecordCard";
import * as S from "./MetricRecordInformationsCard.style";

interface IMetricRecordInformationCardProps {
  metricRecordValue: MetricRecordDataTableValue;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectMetricRecordValue: React.Dispatch<
    React.SetStateAction<MetricRecordDataTableValue | undefined>
  >;
}

const MetricRecordInformationCard: React.FC<IMetricRecordInformationCardProps> = ({
  metricRecordValue,
  setIsOpen,
  selectMetricRecordValue,
}) => {
  const date = metricRecordValue.date;
  const metric = metricRecordValue.metric;
  const metricRecords = metricRecordValue.metricRecords;

  const { t } = useTranslation();

  const [isAddMetricRecordModalOpen, setIsAddMetricRecordModalOpen] =
    useState<boolean>(false);

  const recordSorter = (r1: MetricRecord, r2: MetricRecord) => {
    return r1.timestamp > r2.timestamp ? 1 : -1;
  };

  return (
    <React.Fragment>
      <AddMetricRecordModal
        date={date}
        metric={metric}
        isOpen={isAddMetricRecordModalOpen}
        setIsOpen={setIsAddMetricRecordModalOpen}
      />

      <Card padding={[25, 25]}>
        <SectionHeader
          title={<H2>{metric.name}</H2>}
          subtitle={<S.Subtitle>{date.format("LL")}</S.Subtitle>}
          customMargin="0 0 1.625rem 0"
        >
          <S.CustomButton
            icon={<CloseOutlined />}
            type="text"
            size="small"
            onClick={() => {
              setIsOpen(false);
              selectMetricRecordValue(undefined);
            }}
          />
        </SectionHeader>

        <CommonStyles.VerticalSpacer size="middle">
          {metricRecords.sort(recordSorter).map((rec) => (
            <MetricRecordCard metric={metric} record={rec} />
          ))}

          <S.CustomButton
            type="text"
            size="small"
            icon={<PlusOutlined />}
            block
            onClick={() => setIsAddMetricRecordModalOpen(true)}
          >
            {t("pages.measurements.addRecord")}
          </S.CustomButton>
        </CommonStyles.VerticalSpacer>
      </Card>
    </React.Fragment>
  );
};

export default MetricRecordInformationCard;
