import { EditUserFromOrganizationForm, IModalBaseProps, IUserWithRole, Organization, User, UserRole } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editUserFromOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import { findOrganizationShareType } from "@utils/index";
import { Input, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IEditUserFromOrganizationModalProps extends IModalBaseProps {
  userWithRole: IUserWithRole;
  organization: Organization;
}

const EditUserFromOrganizationModal: React.FC<
  IEditUserFromOrganizationModalProps
> = ({ organization, userWithRole, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  useEffect(() => {
    const formValues = {
      name: (userWithRole.user as User).name,
      editable: [UserRole.ADMIN, UserRole.EDITOR].includes(userWithRole.role),
    };

    form?.setFieldsValue(formValues);
  }, [organization]);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: EditUserFromOrganizationForm) => {
    const form: EditUserFromOrganizationForm = {
      user: userWithRole.user,
      organization: organization,
      editable: formData.editable,
    };

    dispatch(editUserFromOrganization(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(res, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.editUserFromOrganization", {
            userName: userWithRole.user.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.success({
          message: t("notifications.error.editUserFromOrganization", {
            userName: userWithRole.user.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.editOrganizationUserModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="editOrganizationUserForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input disabled={true} />
        </BaseForm.Item>
        <BaseForm.Item name="editable" label={t("common.editable")}>
          <Select placeholder={t("common.editable")}>
            <Select.Option value={false}>{t("common.no")}</Select.Option>
            <Select.Option value={true}>{t("common.yes")}</Select.Option>
          </Select>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditUserFromOrganizationModal;
