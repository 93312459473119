import React from "react";
import { Helmet } from "react-helmet-async";

interface ITabTitleProps {
  title: string;
}

const TabTitle: React.FC<ITabTitleProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{title} | Visudat</title>
    </Helmet>
  );
};

export default TabTitle;
