import { axios } from "@api/index";
import { AddDashboardForm, Dashboard, Dataset } from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const DASHBOARD_ENDPOINT = `${BASE_PATH}/dashboard`;
const SHARED_DASHBOARD_ENDPOINT = `${BASE_PATH}/shared/dashboard`;

export const fetchOwnedDashboards = createAsyncThunk(
  "dashboards/fetchOwnedDashboards",
  async (userId: string) => {
    const response = await axios.get<Dashboard[]>(
      `${DASHBOARD_ENDPOINT}/${userId}`
    );

    return response.data;
  }
);

export const fetchSharedDashboards = createAsyncThunk(
  "dashboards/fetchSharedDashboards",
  async (userId: string) => {
    const response = await axios.get<Dashboard[]>(
      `${SHARED_DASHBOARD_ENDPOINT}/${userId}`
    );
    return response.data;
  }
);

export const addDashboard = createAsyncThunk(
  "dashboards/addDashboard",
  async (data: AddDashboardForm) => {
    const { uid, dataset, name, tenant, layouts, users, description } = data;
    const datasetId = (dataset as Dataset).id;

    const response = await axios.post<Dashboard>(
      `${DASHBOARD_ENDPOINT}/${uid}`,
      { tenant, name, datasetId, layouts, users, description }
    );
    return response.data;
  }
);
