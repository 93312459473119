import { FONT_SIZE } from "@app/common";
import { Card } from "@app/components/common";
import { Row } from "antd";
import styled, { css } from "styled-components";

interface IOptionCardProps {
  selected?: boolean;
}

export const Container = styled(Row)`
  margin: 1rem;
`;

export const OptionCard = styled(Card)<IOptionCardProps>`
  box-shadow: var(--box-shadow-darker);

  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      outline: 1px solid var(--success-color);
    `}

  .anticon {
    font-size: ${FONT_SIZE.x4l};
  }
`;

export const OptionIcon = styled.img`
  display: inline;
  width: 80px;
  height: 80px;
  margin: 0.625rem;
`;
