import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserProfileCard } from "./components";
import { APPLICATION_PATHS } from "@routes/index";

const ProfileLayout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`${APPLICATION_PATHS.PERSONAL_INFO_PATH}`);
  }, []);

  return (
    <React.Fragment>
      <Row gutter={[30, 30]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <UserProfileCard />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
          <Outlet />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ProfileLayout;
