import { EditOrganizationForm, IModalBaseProps, Organization, User } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import {
  convertUserOptionArray,
  findOrganizationShareType,
} from "@utils/index";
import { Input } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IEditOrganizationModalProps extends IModalBaseProps {
  organization: Organization;
}

const EditOrganizationModal: React.FC<IEditOrganizationModalProps> = ({
  organization,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const formValues = {
      name: organization?.name,
    };

    form?.setFieldsValue(formValues);
  }, [organization]);

  const currentUser: User = useAppSelector((state) => state.userContent.data);
  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: EditOrganizationForm) => {
    const form: EditOrganizationForm = {
      id: currentOrganization.id,
      name: formData.name,
      uid: (currentOrganization.user as User).id,
      users: convertUserOptionArray(currentOrganization.users),
      disabled: currentOrganization.disabled
    };

    dispatch(editOrganization(form))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(
              organization,
              currentUser.id
            ),
          })
        );
        NotificationController.success({
          message: t("notifications.success.editOrganization", {
            organizationName: res.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editOrganization", {
            organizationName: organization.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.editOrganizationModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        name="editOrganizationForm"
        form={form}
        layout="vertical"
        onFinish={onSubmitHandler}
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditOrganizationModal;
