import { TeamOutlined } from "@ant-design/icons";
import { Organization, RequestStatus } from "@common/index";
import { CarouselNavigationSection, H1, TabTitle } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { AddOrganizationModal } from "@pages/OrganizationPages/modals";
import { APPLICATION_PATHS } from "@routes/index";
import { fetchLicenses } from "@store/slices/Licenses/thunks";
import { setCurrentOrganization } from "@store/slices/OrganizationContent/slice";
import { fetchLicenseOfOrganization } from "@store/slices/OrganizationContent/thunks";
import { reflectOrganizationEdit } from "@store/slices/Organizations/slice";
import { fetchAllUsers } from "@store/slices/Users/thunks";
import { findOrganizationShareType } from "@utils/index";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const OrganizationMainPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isAddOrganizationModalOpen, setIsAddOrganizationModalOpen] =
    useState<boolean>(false);

  const currentUser: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const allUsersStatus: RequestStatus = useAppSelector(
    (state) => state.users.status
  );

  const licensesStatus: RequestStatus = useAppSelector(
    (state) => state.licenses.status
  );

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  useEffect(() => {
    if (allUsersStatus === RequestStatus.IDLE) {
      dispatch(fetchAllUsers());
    }
  }, [allUsersStatus]);

  useEffect(() => {
    if (!currentOrganization.license) {
      dispatch(fetchLicenseOfOrganization(currentOrganization.id))
        .unwrap()
        .then((res) => {
          const modifiedOrganization: Organization = {
            ...currentOrganization,
            license: res,
          };
          reflectOrganizationEdit({
            organization: res,
            organizationType: findOrganizationShareType(
              modifiedOrganization,
              currentUser.id
            ),
          });
        })
        .catch((err) => {});
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (licensesStatus === RequestStatus.IDLE) {
      dispatch(fetchLicenses());
    }
  }, [licensesStatus]);

  const ownedOrganizations: Organization[] = useAppSelector(
    (state) => state.organizations.ownedOrganizations.data
  );
  const ownedOrganizationsStatus: RequestStatus = useAppSelector(
    (state) => state.organizations.ownedOrganizations.status
  );

  const sharedOrganizations: Organization[] = useAppSelector(
    (state) => state.organizations.sharedOrganizations.data
  );
  const sharedOrganizationsStatus: RequestStatus = useAppSelector(
    (state) => state.organizations.sharedOrganizations.status
  );

  const carouselKeyExtractor = useCallback(
    (organization: Organization) => organization.id,
    []
  );

  const carouselTitleExtractor = useCallback(
    (organization: Organization) => organization.name,
    []
  );

  const onCreateOrganizationHandler = () => {
    setIsAddOrganizationModalOpen(true);
  };

  return (
    <React.Fragment>
      <TabTitle title={t("pages.organizations.tabTitle")} />
      <H1>{t("pages.organizations.pageTitle")}</H1>

      <AddOrganizationModal
        isOpen={isAddOrganizationModalOpen}
        setIsOpen={setIsAddOrganizationModalOpen}
      />

      <CarouselNavigationSection<Organization>
        isLoading={ownedOrganizationsStatus === RequestStatus.PENDING}
        items={ownedOrganizations}
        title={t("pages.organizations.yourOrganizations")}
        icon={<TeamOutlined />}
        onCreate={onCreateOrganizationHandler}
        onItemClick={(organization: Organization) => {
          dispatch(setCurrentOrganization(organization));
          NotificationController.success({
            message: t("notifications.success.selectOrganization", {
              organizationName: organization.name,
            }),
          });
          navigate(`${APPLICATION_PATHS.ORGANIZATION_PATH}/${organization.id}`);
        }}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
      />

      <CarouselNavigationSection<Organization>
        isLoading={sharedOrganizationsStatus === RequestStatus.PENDING}
        items={sharedOrganizations}
        title={t("common.sharedWithYou")}
        icon={<TeamOutlined />}
        onItemClick={(organization: Organization) => {
          dispatch(setCurrentOrganization(organization));
          NotificationController.success({
            message: t("notifications.success.selectOrganization", {
              organizationName: organization.name,
            }),
          });
          navigate(`${APPLICATION_PATHS.ORGANIZATION_PATH}/${organization.id}`);
        }}
        keyExtractor={carouselKeyExtractor}
        titleExtractor={carouselTitleExtractor}
      />
    </React.Fragment>
  );
};

export default OrganizationMainPage;
