import { RequestStatus } from "@common/index";
import { Loading } from "@components/common";
import NoOrganizationSVG from "@assets/images/noOrganization.svg";
import { useAppSelector } from "@hooks/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { NoOrganizationForm } from "../components";
import * as S from "./NoOrganizationPage.style";

const NoOrganizationPage: React.FC = () => {
  const { t } = useTranslation();

  const currentUserStatus: RequestStatus = useAppSelector(
    (state) => state.userContent.status
  );

  const ownedOrganizationStatus: RequestStatus = useAppSelector(
    (state) => state.organizations.ownedOrganizations.status
  );

  const sharedOrganizationStatus: RequestStatus = useAppSelector(
    (state) => state.organizations.sharedOrganizations.status
  );

  const renderForm = () => {
    if (
      ownedOrganizationStatus === RequestStatus.PENDING ||
      sharedOrganizationStatus === RequestStatus.PENDING ||
      currentUserStatus === RequestStatus.PENDING
    ) {
      return <Loading size="5rem" />;
    }

    return (
      <React.Fragment>
        <S.SVGWrapper>
          <S.NoOrganizationSVG src={NoOrganizationSVG} />
        </S.SVGWrapper>

        <S.NoOrganizationTitleContainer>
          <S.NoOrganizationTitle>
            {t("pages.noOrganization.header")}
          </S.NoOrganizationTitle>
        </S.NoOrganizationTitleContainer>

        <NoOrganizationForm />
        <S.OceanEffect>
          <S.Wave />
          <S.Wave />
          <S.Wave />
        </S.OceanEffect>
      </React.Fragment>
    );
  };

  return (
    <S.PageWrapper>
      <S.ContentWrapper>{renderForm()}</S.ContentWrapper>
    </S.PageWrapper>
  );
};

export default NoOrganizationPage;
