import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { AppDate, Dataset, MetricRecordsFilter, RequestStatus, TimeDirection, TimeOption, User } from "@common/index";
import { DayjsDatePicker, SectionHeader } from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { Dates } from "@services/index";
import {
  setCurrentDataset,
  setCurrentDatasetStatus,
} from "@store/slices/DatasetContent/slice";
import {
  fetchOwnedDatasets,
  fetchSharedDatasets,
} from "@store/slices/Datasets/thunks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EditableMetricRecordsTable } from "../components";
import * as S from "./MeasurementDetailPage.style";

const MeasurementDetailPage: React.FC = () => {
  const weekFormat = "DD.MM";
  const { datasetId } = useParams();
  const dispatch = useAppDispatch();

  const initialTimeRangeFilter: MetricRecordsFilter = {
    startDate: Dates.getFirstWeekDay(Dates.getToday()),
    endDate: Dates.getLastWeekDay(Dates.getToday()),
    timeOption: TimeOption.WEEKLY,
  };

  const [timeRangeFilter, setTimeRangeFilter] = useState<MetricRecordsFilter>(
    initialTimeRangeFilter
  );

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const sharedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.sharedDatasets.data
  );

  const ownedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.ownedDatasets.status
  );

  const sharedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.sharedDatasets.status
  );

  const currentDatasetInStore: Dataset | null = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  useEffect(() => {
    if (ownedDatasetsStatus === RequestStatus.IDLE) {
      currentUser.id && dispatch(fetchOwnedDatasets(currentUser.id));
    }
  }, [currentUser.id, ownedDatasetsStatus]);

  useEffect(() => {
    if (sharedDatasetsStatus === RequestStatus.IDLE) {
      currentUser.id && dispatch(fetchSharedDatasets(currentUser.id));
    }
  }, [currentUser.id, sharedDatasetsStatus]);

  const changeTimeFilterWithButton = (timeDirection: TimeDirection) => {
    let daysToAdd;
    switch (timeDirection) {
      case TimeDirection.NEXT:
        daysToAdd = 7;
        break;
      case TimeDirection.PREVIOUS:
        daysToAdd = -7;
        break;
    }

    const customizedStartDate = timeRangeFilter.startDate.add(
      daysToAdd,
      "days"
    );

    onWeekFilterChange(customizedStartDate);
  };

  const onWeekFilterChange = (choosenWeek: AppDate | null) => {
    const firstDayOfWeek = Dates.getFirstWeekDay(choosenWeek as AppDate);
    const lastDayOfWeek = Dates.getLastWeekDay(choosenWeek as AppDate);

    const filter: MetricRecordsFilter = {
      startDate: firstDayOfWeek,
      endDate: lastDayOfWeek,
      timeOption: TimeOption.WEEKLY,
    };

    setTimeRangeFilter(filter);
  };

  const getCurrentDatasetIfNotInStore = (): Dataset | undefined => {
    let matchedDataset = ownedDatasets.find((od) => od.id === datasetId);

    matchedDataset = matchedDataset
      ? matchedDataset
      : sharedDatasets.find((od) => od.id === datasetId);

    dispatch(setCurrentDataset(matchedDataset));
    dispatch(setCurrentDatasetStatus(RequestStatus.FULFILLED));
    return matchedDataset;
  };

  const currentDataset: Dataset | undefined = currentDatasetInStore
    ? currentDatasetInStore
    : getCurrentDatasetIfNotInStore();

  const customWeekStartEndFormat = (date: AppDate) =>
    `${Dates.getFirstWeekDay(date).format(weekFormat)} - ${Dates.getLastWeekDay(
      date
    ).format(weekFormat)}`;

  return (
    <React.Fragment>
      <SectionHeader
        customMargin="0 0 3rem 0"
        title={
          <S.SectionHeaderTitle>{currentDataset?.name}</S.SectionHeaderTitle>
        }
        subtitle={
          <S.SectionHeaderSubtitle>
            {currentDataset?.description}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={APPLICATION_PATHS.MEASUREMENT_PATH}
        backButtonVisible
      >
        <S.BtnContainer
          icon={<LeftOutlined />}
          type="text"
          size="small"
          onClick={() => changeTimeFilterWithButton(TimeDirection.PREVIOUS)}
        />
        <DayjsDatePicker
          picker="week"
          clearIcon={false}
          format={customWeekStartEndFormat}
          defaultValue={Dates.getClearDate()}
          onChange={onWeekFilterChange}
          value={timeRangeFilter.startDate}
        />
        <S.BtnContainer
          icon={<RightOutlined />}
          type="text"
          size="small"
          onClick={() => changeTimeFilterWithButton(TimeDirection.NEXT)}
        />
      </SectionHeader>
      {currentDataset && (
        <EditableMetricRecordsTable
          dataset={currentDataset}
          filter={timeRangeFilter}
        />
      )}
    </React.Fragment>
  );
};

export default MeasurementDetailPage;
