import { Card as BaseCard } from "@components/common";
import styled from "styled-components";

export const CardWrapper = styled.div`
  margin-bottom: 1.625rem;
`;

export const Card = styled(BaseCard)`
  box-shadow: var(--box-shadow-darker);
`;
