import React from "react";
import { H3, P2 } from "../../../../components/common/Typography";
import * as S from "./AINavigationCard.style";

interface IAINavigationCardProps {
  icon: React.ReactElement;
  title: string | undefined;
  description?: string;
  onClick: () => void;
}

const AINavigationCard: React.FC<IAINavigationCardProps> = ({
  icon,
  title,
  description,
  onClick,
}) => {
  return (
    <S.Card onClick={onClick} autoHeight={false} padding={[24, 20]}>
      <S.AINavigationIconWrapper>{icon}</S.AINavigationIconWrapper>
      <S.AINavigationCardInfo>
        <H3>{title}</H3>
        <P2>{description}</P2>
      </S.AINavigationCardInfo>
    </S.Card>
  );
};

export default AINavigationCard;
