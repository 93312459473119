import { MODAL_SIZES } from "@common/index";
import { Modal as AntdModal, ModalProps as AntModalProps } from "antd";
import React from "react";
import * as S from "./Modal.styles";

export const {
  info: InfoModal,
  success: SuccessModal,
  warning: WarningModal,
  error: ErrorModal,
} = AntdModal;

interface ModalProps extends AntModalProps {
  size?: "small" | "medium" | "large";
}

const Modal: React.FC<ModalProps> = ({
  size = "medium",
  children,
  ...props
}) => {
  const modalSize = Object.entries(MODAL_SIZES).find(
    (sz) => sz[0] === size
  )?.[1];

  return (
    <S.Modal width={modalSize} {...props}>
      {children}
    </S.Modal>
  );
};

export default Modal;
