import { Organization, PaymentCard as PaymentCardType, RequestStatus } from "@common/index";
import {
  Card,
  H2,
  Loading,
  SectionHeader,
  withAnimation,
  withLoading,
} from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { fetchPaymentCards } from "@store/slices/Payments/thunks";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddPaymentCardModal } from "../../modals";
import { PaymentCard } from "../PaymentCard";
import * as S from "./PaymentMethods.style";

const MAX_PAYMENT_METHOD_COUNT = 4;

const PaymentMethodsSection: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const paymentCards: PaymentCardType[] = useAppSelector(
    (state) => state.payments.paymentCards.data
  );
  const paymentCardsStatus: RequestStatus = useAppSelector(
    (state) => state.payments.paymentCards.status
  );

  const [isAddPaymentCardModalOpen, setIsAddPaymentCardModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    currentOrganization && dispatch(fetchPaymentCards(currentOrganization.id));
  }, [currentOrganization.id]);

  const renderPaymentMethodsAndAddButton = () => {
    const paymentMethodCount: number = paymentCards.length;
    const isAddButtonVisible: boolean =
      MAX_PAYMENT_METHOD_COUNT - paymentMethodCount > 0;

    return (
      <Row justify="center" gutter={[24, 24]}>
        {paymentCards.map((card) => (
          <Col>
            <PaymentCard card={card} showcase={false} modifiable />
          </Col>
        ))}

        {isAddButtonVisible && (
          <Col>
            <S.AddButton onClick={() => setIsAddPaymentCardModalOpen(true)}>
              <S.ButtonText>
                {t("modals.addPaymentCardModal.addPaymentMethod")}
              </S.ButtonText>
            </S.AddButton>
          </Col>
        )}
      </Row>
    );
  };

  const renderLoading = () => {
    return (
      <S.LoadingContainer>
        <Loading size="5rem" />
      </S.LoadingContainer>
    );
  };

  const renderAll = () => {
    return paymentCardsStatus === RequestStatus.PENDING
      ? renderLoading()
      : renderPaymentMethodsAndAddButton();
  };

  return (
    <React.Fragment>
      <AddPaymentCardModal
        isOpen={isAddPaymentCardModalOpen}
        setIsOpen={setIsAddPaymentCardModalOpen}
      />
      <Card padding={[25, 25]}>
        <SectionHeader
          title={<H2>{t("pages.organizations.paymentMethods")}</H2>}
          customMargin="0 0 2rem 0"
        />
        {renderAll()}
      </Card>
    </React.Fragment>
  );
};

export default withAnimation(withLoading(PaymentMethodsSection));
