export const APPLICATION_PATHS = {
  ROOT_PATH: "/",

  APPLICATION_AUTH_PATH: "/auth",
  LOGIN_PATH: "/auth/login",
  SIGN_UP_PATH: "/auth/sign-up",
  FORGOT_PASSWORD_PATH: "/auth/forgot-password",

  NO_ORGANIZATION_PATH: "/no-organization",

  APPLICATION_ROOT_PATH: "/app",
  DASHBOARD_PATH: "/app/dashboard",
  DASHBOARD_DETAIL_PATH: "/app/dashboard/:dashboardId",
  DATASET_PATH: "/app/dataset",
  DATASET_DETAIL_PATH: "/app/dataset/:datasetId",
  DATASET_CREATE_PATH: "/app/dataset/create",
  MEASUREMENT_PATH: "/app/measurement",
  MEASUREMENT_DETAIL_PATH: "/app/measurement/:datasetId",
  ORGANIZATION_PATH: "/app/organization",
  ORGANIZATION_DISABLED_PATH: "/app/organization/disabled",
  ORGANIZATION_DETAIL_PATH: "/app/organization/:organizationId",

  PAYMENT_BILLING_PATH: "/app/organization/:organizationId/payment-and-billing",
  PAYMENTS_PATH:
    "/app/organization/:organizationId/payment-and-billing/payments",
  PAYMENT_AREA_PATH:
    "/app/organization/:organizationId/payment-and-billing/payments/:obligationId",
  BILLING_DETAIL_PATH:
    "/app/organization/:organizationId/payment-and-billing/payments/bill/:settlementId",
  PAYMENT_METHODS_PATH:
    "/app/organization/:organizationId/payment-and-billing/payment-methods",
  BILLING_PROFILES_PATH:
    "/app/organization/:organizationId/payment-and-billing/billing-profiles",
  
  PROFILE_PATH: "/app/profile",
  PERSONAL_INFO_PATH: "/app/profile/personal-info",
  SECURITY_SETTINGS_PATH: "/app/profile/security-settings",
  ORGANIZATION_PLAN_PATH: "/app/organization-plan",
  DOWNGRADE_LICENSE_PATH: "/app/downgrade-license",

  SUBSCRIPTION_AGREEMENT_PATH:
    "https://visudat.com/contracts/subscription-agreement",
  PRIVACY_POLICY_PATH: "https://visudat.com/contracts/privacy-policy",
  LANDING_PAGE_PATH: "https://www.visudat.com",
};
