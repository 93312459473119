import { FONT_SIZE, FONT_WEIGHT } from "@common/index";
import { Card as BaseCard } from "@components/common";
import { Avatar, Row } from "antd";
import styled, { css } from "styled-components";

interface ICustomAvatarProps {
  $photoExist: boolean;
}

export const Card = styled(BaseCard)`
  height: unset;
  display: flex;
`;

export const Wrapper = styled(Row)`
  flex-direction: column;
  align-items: center;
`;

export const FullName = styled.p`
  font-size: ${FONT_SIZE.xxl};
  font-weight: ${FONT_WEIGHT.bold};
  margin: 0;
`;

export const NavigationWrapper = styled.div`
  padding: 0 1.625rem 1.625rem 1rem;
`;

export const CustomAvatar = styled(Avatar)<ICustomAvatarProps>`
  ${(props) =>
    !props.$photoExist
      ? css`
          color: var(--white);
          background-color: var(--primary-color);
        `
      : null}
`;
