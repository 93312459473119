import { AppDate } from "@common/types";
import generatePicker from "antd/es/date-picker/generatePicker";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import React from "react";

const Picker = generatePicker<AppDate>(dayjsGenerateConfig);

type DatePickerProps = PickerProps<AppDate>;

const DatePicker = React.forwardRef<
  React.Component<DatePickerProps>,
  DatePickerProps
>(({ className, ...props }, ref) => (
  <Picker ref={ref} className={className} {...props} />
));

export default DatePicker;
