import { EditUserInfoForm } from "@common/index";
import {
  BaseButtonsForm,
  H1,
  SectionHeader,
  withAnimation,
  withLoading,
} from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editUserInfo } from "@store/slices/UserContent/thunks";
import { reflectUserEdit } from "@store/slices/Users/slice";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PersonalInfoForm: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = BaseButtonsForm.useForm();

  const currentUser = useAppSelector((state) => state.userContent.data);

  const [isFieldsChanged, setIsFieldsChanged] = useState<boolean>(false);

  const onSubmitHandler = (formData: EditUserInfoForm) => {
    const data: EditUserInfoForm = {
      name: formData.name,
      email: currentUser.email, // change here with email change update
      user: currentUser,
    };

    dispatch(editUserInfo(data))
      .unwrap()
      .then((res) => {
        setIsFieldsChanged(false);
        dispatch(
          reflectUserEdit({
            user: res,
          })
        );
        NotificationController.success({
          message: t("notifications.success.editUser"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editUser"),
        });
      });
  };

  const initialValues = {
    name: currentUser.name,
    email: currentUser.email,
  };

  useEffect(() => {
    form?.setFieldsValue(initialValues);
  }, [currentUser]);

  return (
    <React.Fragment>
      <SectionHeader
        customMargin="0 0 1.5rem 0"
        title={<H1>{t("pages.profile.personalInfo")}</H1>}
      />

      <BaseButtonsForm
        form={form}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setIsFieldsChanged}
        onFieldsChange={() => setIsFieldsChanged(true)}
        layout="vertical"
        onFinish={onSubmitHandler}
        initialValues={initialValues}
      >
        <BaseButtonsForm.Item
          label={t("pages.profile.fullName")}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </BaseButtonsForm.Item>

        <BaseButtonsForm.Item
          label={t("pages.profile.emailAddress")}
          name="email"
          // rules={[{ required: true, type: "email" }]} uncomment with emailchange update
        >
          <Input disabled />
        </BaseButtonsForm.Item>
      </BaseButtonsForm>
    </React.Fragment>
  );
};

export default withLoading(withAnimation(PersonalInfoForm));
