import React from "react";
import * as S from "./AppLogoTitle.style";

interface IAppLogoTitleProps {
  title?: string;
}

const AppLogoTitle: React.FC<IAppLogoTitleProps> = ({ title }) => {
  const titleToDisplay: string = title || "Visudat";

  return <S.Title>{titleToDisplay}</S.Title>;
};

export default AppLogoTitle;
