import { EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { RequestStatus, UploadAvatarForm, User } from "@common/index";
import { Loading } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { uploadAvatar } from "@store/slices/UserContent/thunks";
import * as CommonStyles from "@styles/CommonStyles";
import { Row } from "antd";
import type { RcFile } from "antd/es/upload/interface";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./AvatarUpload.style";

const AvatarUpload: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const currentUser: User = useAppSelector((state) => state.userContent.data);
  const currentUserStatus: RequestStatus = useAppSelector(
    (state) => state.userContent.status
  );

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <Row justify="center" align="middle">
        {t("common.upload")}
      </Row>
    </div>
  );

  const onBeforeUploadHandler = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      NotificationController.error({
        message: t("notifications.error.uploadAvatarFileType"),
      });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      NotificationController.error({
        message: t("notifications.error.uploadAvatarSize"),
      });
      return false;
    }

    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const resizedImage = await resizeImage(
        e.target?.result as ArrayBuffer,
        file
      );
      await onUploadHandler(resizedImage, file);
      return false;
    };

    reader.readAsArrayBuffer(file);
  };

  const resizeImage = (data: ArrayBuffer, file: RcFile) => {
    return new Promise<ArrayBuffer>((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const MAX_WIDTH = 120;
        const MAX_HEIGHT = 120;
        let width = img.width;
        let height = img.height;

        if (width > height && width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (reader.result instanceof ArrayBuffer) {
              resolve(reader.result);
            }
          };
          reader.readAsArrayBuffer(blob!);
        }, file.type);
      };

      const dataUrl = URL.createObjectURL(new Blob([data]));
      img.src = dataUrl;
    });
  };

  const onUploadHandler = async (resizedImage: ArrayBuffer, file: RcFile) => {
    const formData: UploadAvatarForm = {
      file: file,
      content: resizedImage,
      user: currentUser,
    };

    dispatch(uploadAvatar(formData))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.uploadAvatar"),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.uploadAvatar"),
        });
      });
  };

  const renderLoading = () => {
    return (
      <CommonStyles.LoadingContainer height="120px">
        <Loading size="4rem" />
      </CommonStyles.LoadingContainer>
    );
  };

  const renderAvatar = () => {
    return !currentUser.photoUrl ? (
      <S.CustomUpload
        name="avatar"
        listType="picture-card"
        style={{ width: "fit-content" }}
        showUploadList={false}
        beforeUpload={onBeforeUploadHandler}
        customRequest={() => {}}
      >
        {uploadButton}
      </S.CustomUpload>
    ) : (
      <S.CustomUpload
        style={{ width: "fit-content" }}
        showUploadList={false}
        beforeUpload={onBeforeUploadHandler}
        customRequest={() => {}}
      >
        <S.ProfileImageContainer>
          <S.EditButton icon={<EditOutlined />} />
          <S.ProfileImage src={currentUser.photoUrl || ""} alt="avatar" />
        </S.ProfileImageContainer>
      </S.CustomUpload>
    );
  };

  return (
    <React.Fragment>
      {currentUserStatus === RequestStatus.PENDING
        ? renderLoading()
        : renderAvatar()}
    </React.Fragment>
  );
};

export default AvatarUpload;
