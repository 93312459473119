import {
  Dashboard,
  Dataset, IUserWithRole, Organization,
  User,
  UserOption, UserRole
} from "@common/index";
import { H1, SectionHeader, TabTitle } from "@components/common";
import { useAppSelector } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import { getLicenseTitle } from "@utils/index";
import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  DowngradeDatasetDeleteCard,
  DowngradeUserDeleteCard,
} from "../components";
import { DowngradeDashboardDeleteCard } from "../components/DowngradeDashboardDeleteCard";
import { DeleteForDowngradeModal } from "../modals";
import * as S from "./DowngradeLicensePage.style";

const DowngradeLicensePage: React.FC = () => {
  const { t } = useTranslation();
  const state = useLocation();

  const [dashboardsToDelete, setDashboardsToDelete] = useState<Dashboard[]>([]);
  const [datasetsToDelete, setDatasetsToDelete] = useState<Dataset[]>([]);
  const [usersToDelete, setUsersToDelete] = useState<IUserWithRole[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const [isDeleteForDowngradeModalOpen, setIsDeleteForDowngradeModalOpen] =
    useState<boolean>(false);

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const currentOrganization: Organization = useAppSelector(
    (state) => state.organizationContent.currentOrganization
  );

  const ownedDashboards: Dashboard[] = useAppSelector(
    (state) => state.dashboards.ownedDashboards.data
  );

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const dashboardsWithMatchingDataset: Dashboard[] = [];

  useEffect(() => {
    const ownedDatasetsInOrganization =
      filterDatasetByOrganization(ownedDatasets);

    const ownedDashboardsInOrganization =
      filterDashboardByOrganization(ownedDashboards);

    if (
      ownedDatasetsInOrganization.length - datasetsToDelete.length <=
        state.state.license.datasetCount &&
      ownedDashboardsInOrganization.length - dashboardsToDelete.length <=
        state.state.license.dashboardCount &&
      currentOrganization.users.length + 1 - usersToDelete.length <=
        state.state.license.userCount
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [
    ownedDatasets,
    datasetsToDelete,
    ownedDashboards,
    dashboardsToDelete,
    currentOrganization.users,
    usersToDelete,
    state.state.license,
  ]);

  const filterDashboardByOrganization = (dashboards: Dashboard[]) => {
    return dashboards.filter((d) => d.tenant === currentOrganization.id);
  };

  const filterDatasetByOrganization = (datasets: Dataset[]) => {
    return datasets.filter((d) => d.tenant === currentOrganization.id);
  };

  const getOtherOrganizationMembers = (): IUserWithRole[] => {
    const organizationMembersWithRole: IUserWithRole[] =
      currentOrganization.users.map((userOpt) => {
        const userWithRole: IUserWithRole = {
          user: userOpt.user as User,
          role: userOpt.editable ? UserRole.EDITOR : UserRole.BASIC,
        };

        return userWithRole;
      });

    return organizationMembersWithRole.filter(
      (userWithRole) => userWithRole.user.id !== currentUser.id
    );
  };

  const getCurrentUserIfNotOwner = (): IUserWithRole | undefined => {
    if (currentUser.id !== (currentOrganization.user as User).id) {
      const currentUserOptions: UserOption = currentOrganization.users.find(
        (userOpt) => userOpt
      ) as UserOption;

      const currentUserWithRole = {
        user: currentUser,
        role: currentUserOptions.editable ? UserRole.EDITOR : UserRole.BASIC,
      };

      return currentUserWithRole;
    }

    return undefined;
  };

  const getOrganizationOwner = (): IUserWithRole => {
    const ownerWithRole: IUserWithRole = {
      user: currentOrganization.user as User,
      role: UserRole.ADMIN,
    };

    return ownerWithRole;
  };

  const getAllUsersWithRole = (): IUserWithRole[] => {
    const currentUserIfNotOwner: IUserWithRole | undefined =
      getCurrentUserIfNotOwner();

    return currentUserIfNotOwner
      ? [
          getOrganizationOwner(),
          currentUserIfNotOwner,
          ...getOtherOrganizationMembers(),
        ]
      : [getOrganizationOwner(), ...getOtherOrganizationMembers()];
  };

  const handleDashboardsToDeleteChange = (dashboardsToDelete: Dashboard[]) => {
    setDashboardsToDelete(dashboardsToDelete);
  };

  const handleDatasetsToDeleteChange = (datasetsToDelete: Dataset[]) => {
    setDatasetsToDelete(datasetsToDelete);
    filterDashboardByOrganization(ownedDashboards).forEach((dashboard) => {
      datasetsToDelete.forEach((dataset) => {
        if (dashboard.dataset.id === dataset.id) {
          dashboardsWithMatchingDataset.push(dashboard);
        }
      });
    });
    handleDashboardsToDeleteChange(dashboardsWithMatchingDataset);
  };

  const handleUsersToDeleteChange = (usersToDelete: IUserWithRole[]) => {
    setUsersToDelete(usersToDelete);
  };

  const handleFinish = () => {
    setIsDeleteForDowngradeModalOpen(true);
  };

  return (
    <React.Fragment>
      <TabTitle title={t("pages.downgradeLicense.tabTitle")} />

      <SectionHeader
        customMargin="0 0 2rem 0"
        title={<H1>{t("pages.downgradeLicense.pageTitle")}</H1>}
        subtitle={
          <S.SectionHeaderSubtitle>
            {t("pages.downgradeLicense.pageSubtitle", {
              targetLicense: getLicenseTitle(state.state.license.licenseType),
            })}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={`${APPLICATION_PATHS.ORGANIZATION_PLAN_PATH}`}
        backButtonVisible
      >
        <Button
          type="primary"
          onClick={handleFinish}
          disabled={isButtonDisabled}
        >
          {t("common.confirm")}
        </Button>
      </SectionHeader>

      <Row gutter={[12, 12]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <DowngradeUserDeleteCard
            title={t("common.users")}
            userList={getAllUsersWithRole()}
            selectedLicense={state.state.license}
            usersToDelete={usersToDelete}
            onUsersToDeleteChange={handleUsersToDeleteChange}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <DowngradeDatasetDeleteCard
            title={t("pages.downgradeLicense.datasets")}
            datasetList={filterDatasetByOrganization(ownedDatasets)}
            selectedLicense={state.state.license}
            datasetsToDelete={datasetsToDelete}
            onDatasetsToDeleteChange={handleDatasetsToDeleteChange}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
          <DowngradeDashboardDeleteCard
            title={t("pages.downgradeLicense.dashboards")}
            dashboardList={filterDashboardByOrganization(ownedDashboards)}
            selectedLicense={state.state.license}
            dashboardsToDelete={dashboardsToDelete}
            onDashboardsToDeleteChange={handleDashboardsToDeleteChange}
          />
        </Col>
      </Row>

      <DeleteForDowngradeModal
        isOpen={isDeleteForDowngradeModalOpen}
        setIsOpen={setIsDeleteForDowngradeModalOpen}
        usersToDelete={usersToDelete}
        dashboardsToDelete={dashboardsToDelete}
        datasetsToDelete={datasetsToDelete}
        organization={currentOrganization}
        licenseToChange={state.state.license}
      />
    </React.Fragment>
  );
};

export default DowngradeLicensePage;
