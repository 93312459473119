import React from "react";
import { HProps } from "../interfaces";
import * as S from "./H4.style";

const H4: React.FC<HProps> = (props) => (
  <S.Text  level={4}>
    {props.children}
  </S.Text>
);

export default H4;
