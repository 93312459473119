import { axios } from "@api/index";
import {
  AddBillingProfileForm,
  AddPaymentCardForm,
  BillingDetail,
  BillingProfile,
  DeleteBillingProfileForm,
  DeletePaymentCardForm,
  EditBillingProfileForm,
  EditPaymentCardForm,
  FetchBillingDetailForm,
  NewCardPaymentForm,
  Obligation,
  PaymentCard,
  SavedCardPaymentForm,
  Settlement,
} from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";

const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const PAYMENT_ENDPOINT = `${BASE_PATH}/payment`;
const PAYMENT_CARDS_ENDPOINT = `${BASE_PATH}/payment/card`;
const OBLIGATION_ENDPOINT = `${BASE_PATH}/obligation`;
const SETTLEMENT_ENDPOINT = `${BASE_PATH}/settlement`;
const BILLING_PROFILE_ENDPOINT = `${BASE_PATH}/billingprofile`;

export const fetchObligations = createAsyncThunk(
  "payments/fetchObligations",
  async (tenantId: string) => {
    const response = await axios.get<Obligation[]>(
      `${OBLIGATION_ENDPOINT}/${tenantId}`
    );
    return response.data;
  }
);

export const fetchPaymentCards = createAsyncThunk(
  "payments/fetchPaymentCards",
  async (tenantId: string) => {
    const response = await axios.get<PaymentCard[]>(
      `${PAYMENT_CARDS_ENDPOINT}/${tenantId}`
    );

    return response.data;
  }
);

export const addPaymentCard = createAsyncThunk(
  "payments/addPaymentCard",
  async (data: AddPaymentCardForm) => {
    const response = await axios.post<PaymentCard>(
      `${PAYMENT_CARDS_ENDPOINT}/${data.tenantId}`,
      {
        card: {
          cardHolderName: data.cardHolderName,
          cardNumber: data.cardNumber,
          expireYear: data.expireYear,
          expireMonth: data.expireMonth,
        },
      }
    );
    return response.data;
  }
);

export const editPaymentCard = createAsyncThunk(
  "payments/editPaymentCard",
  async (data: EditPaymentCardForm) => {
    const response = await axios.put<PaymentCard>(
      `${PAYMENT_CARDS_ENDPOINT}/${data.tenantId}/${data.cardToken}`,
      {
        cardToken: data.cardToken,
        expireYear: data.expireYear,
        expireMonth: data.expireMonth,
      }
    );

    return response.data;
  }
);

export const deletePaymentCard = createAsyncThunk(
  "payments/deletePaymentCard",
  async (data: DeletePaymentCardForm) => {
    const response = await axios.delete<{
      cardToken: string;
      deleted: boolean;
    }>(`${PAYMENT_CARDS_ENDPOINT}/${data.tenantId}/${data.cardToken}`);

    return response.data;
  }
);

export const payWithSavedCard = createAsyncThunk(
  "payments/payWithSavedCard",
  async (data: SavedCardPaymentForm) => {
    const response = await axios.post<Settlement>(
      `${PAYMENT_ENDPOINT}/${data.tenantId}/${data.obligationId}/${
        (data.card as PaymentCard).cardToken
      }`,
      {
        billingProfileId: data.billingProfileId,
        price: data.price,
        paidPrice: data.paidPrice,
        walletPrice: 0,
        installment: 1,
        currency: data.currency,
        items: data.items,
      }
    );

    return response.data;
  }
);

export const payWithNewCard = createAsyncThunk(
  "payments/payWithNewCard",
  async (data: NewCardPaymentForm) => {
    const requestBody = {
      billingProfileId: data.billingProfileId,
      card: {
        cardHolderName: data.cardHolderName,
        cardNumber: data.cardNumber,
        expireYear: data.expireYear,
        expireMonth: data.expireMonth,
        cvc: data.cvc,
      },
      price: data.price,
      paidPrice: data.paidPrice,
      walletPrice: data.walletPrice,
      installment: data.installment,
      currency: data.currency,
      items: data.items,
    };

    const response = await axios.post<Settlement>(
      `${PAYMENT_ENDPOINT}/${data.tenantId}/${data.obligationId}`,
      requestBody
    );

    return response.data;
  }
);

export const fetchSettlements = createAsyncThunk(
  "payments/fetchSettlements",
  async (tenantId: string) => {
    const response = await axios.get<Settlement[]>(
      `${SETTLEMENT_ENDPOINT}/${tenantId}`
    );

    return response.data;
  }
);

export const fetchBillingProfiles = createAsyncThunk(
  "billings/fetchBillingProfiles",
  async (tenantId: string) => {
    const response = await axios.get<BillingProfile[]>(
      `${BILLING_PROFILE_ENDPOINT}/${tenantId}`
    );
    return response.data;
  }
);

export const addBillingProfile = createAsyncThunk(
  "billings/addBillingProfile",
  async (data: AddBillingProfileForm) => {
    const response = await axios.post<BillingProfile>(
      `${BILLING_PROFILE_ENDPOINT}/${data.tenantId}`,
      data
    );
    return response.data;
  }
);

export const editBillingProfile = createAsyncThunk(
  "billings/editBillingProfile",
  async (data: EditBillingProfileForm) => {
    const response = await axios.put<BillingProfile>(
      `${BILLING_PROFILE_ENDPOINT}/${data.tenantId}/${data.id}`,
      data
    );

    return response.data;
  }
);

export const deleteBillingProfile = createAsyncThunk(
  "billings/deleteBillingProfile",
  async (data: DeleteBillingProfileForm) => {
    const response = await axios.delete<{ tenantId: string; id: string }>(
      `${BILLING_PROFILE_ENDPOINT}/${data.tenantId}/${data.id}`
    );
    return response.data;
  }
);

export const fetchBillingDetails = createAsyncThunk(
  "payments/fetchBillingDetails",
  async (data: FetchBillingDetailForm) => {
    const response = await axios<BillingDetail>(
      `${SETTLEMENT_ENDPOINT}/${data.tenantId}/${data.settlementId}`
    );

    return response.data;
  }
);
