import { AutocompleteItem } from "@app/common";
import { ItemComponentProps } from "@webscopeio/react-textarea-autocomplete";

const AutocompleteItemComponent: React.FC<
  ItemComponentProps<AutocompleteItem>
> = ({ entity: { name, char }, ...restProps }) => (
  <div {...restProps}>{`${name}: ${char}`}</div>
);

export default AutocompleteItemComponent;
