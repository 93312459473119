import { useAppDispatch } from "@hooks/index";
import { APPLICATION_PATHS } from "@routes/index";
import {
  clearBearerToken
} from "@services/index";
import { logout } from "@store/slices/UserContent/thunks";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DropdownMenu } from "../../../Header.style";
import * as S from "./ProfileOverlay.style";

const ProfileOverlay: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const MENU_ITEMS = [
    // {
    //   key: "profile",
    //   link: APPLICATION_PATHS.PROFILE_PATH,
    //   title: t("header.profile.title"),
    //   onClick: () => {},
    // },
    {
      key: "logout",
      link: APPLICATION_PATHS.LOGIN_PATH,
      title: t("header.profile.logout"),
      onClick: () =>
        dispatch(logout())
          .unwrap()
          .then((res) => {
            clearBearerToken();
          })
          .catch((err) => {}),
    },
  ];

  const generateMenuItems = () => {
    return (
      <React.Fragment>
        {MENU_ITEMS.map((item, idx) => (
          <React.Fragment key={item.key}>
            <S.MenuItem key={item.key}>
              <S.Text>
                <Link onClick={item.onClick} to={item.link}>
                  {item.title}
                </Link>
              </S.Text>
            </S.MenuItem>
            {idx !== MENU_ITEMS.length - 1 && <S.CustomDivider />}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  };

  return <DropdownMenu selectable={false}>{generateMenuItems()}</DropdownMenu>;
};

export default ProfileOverlay;
