import { FONT_SIZE } from "@app/common";
import { Card, DayjsDatePicker } from "@app/components/common";
import { Radio, Row } from "antd";
import styled, { css } from "styled-components";

interface IOptionCardProps {
  selected?: boolean;
}

export const CenteredRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export const CustomDayjsDatePicker = styled(DayjsDatePicker)`
  width: 100%;
`;

export const Container = styled(Row)`
  margin: 1rem 0 0 0;
`;

export const OptionCard = styled(Card)<IOptionCardProps>`
  box-shadow: var(--box-shadow-darker);

  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      outline: 1px solid var(--success-color);
    `}

  .anticon {
    font-size: ${FONT_SIZE.x4l};
  }
`;

export const OptionIcon = styled.img`
  display: inline;
  width: 80px;
  height: 80px;
  margin: 0.625rem;
`;
