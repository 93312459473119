import { axios, storage } from "@api/index";
import {
  AddKpiForm,
  AddMetricForm,
  AddMetricRecordForm,
  AddUserToDatasetForm,
  ApiRangeFilter,
  DeleteDatasetForm,
  DeleteDefinitionForm,
  DeleteMetricRecordForm,
  DeleteUserFromDatasetForm,
  EditDatasetForm,
  EditKpiForm,
  EditMetricForm,
  EditMetricRecordForm,
  EditUserFromDatasetForm,
  Kpi,
  Metric,
  MetricRecord,
  MetricRecordMap,
  UploadDataForm,
  User,
} from "@common/index";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { convertUserOptionArray } from "@utils/index";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Dates } from "@services/index";
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const DATASET_ENDPOINT = `${BASE_PATH}/dataset`;
const METRIC_ENDPOINT = `${BASE_PATH}/metric`;
const KPI_ENDPOINT = `${BASE_PATH}/kpi`;
const METRIC_RECORD_ENDPOINT = `${BASE_PATH}/record`;
const METRIC_DATA_ENDPOINT = `${BASE_PATH}/data`;
const DEFAULT_LOCATION = "30,30";

const FLOW_PATH = process.env.REACT_APP_FLOW_PATH;
const DATAFILE_STORAGE = "dataFile";
const MEASUREMENT_ENDPOINT = `${FLOW_PATH}/measurement`;

export const editDataset = createAsyncThunk(
  "datasets/edit",
  async (data: EditDatasetForm) => {
    const { datasetId, userId, users, name, tenantId, description } = data;

    const response = await axios.put(
      `${DATASET_ENDPOINT}/${userId}/${datasetId}`,
      {
        name,
        users,
        tenant: tenantId,
        description,
      }
    );

    return response.data;
  }
);

export const deleteDataset = createAsyncThunk(
  "datasets/deleteDataset",
  async (data: DeleteDatasetForm) => {
    const { userId, datasetId } = data;
    const response = await axios.delete(
      `${DATASET_ENDPOINT}/${userId}/${datasetId}`
    );

    return {
      datasetId: datasetId,
      response: response.data,
    };
  }
);

export const fetchMetrics = createAsyncThunk(
  "datasetContent/fetchMetrics",
  async (datasetId: string) => {
    const response = await axios.get<Metric[]>(
      `${METRIC_ENDPOINT}/${datasetId}`
    );
    return response.data;
  }
);

export const fetchKpis = createAsyncThunk(
  "datasetContent/fetchKpis",
  async (datasetId: string) => {
    const response = await axios.get<Kpi[]>(`${KPI_ENDPOINT}/${datasetId}`);

    return response.data;
  }
);

export const addMetric = createAsyncThunk(
  "datasetContent/addMetric",
  async (data: AddMetricForm) => {
    const { datasetId, name, description } = data;
    const response = await axios.post(`${METRIC_ENDPOINT}/${datasetId}`, {
      name,
      description,
    });

    return response.data;
  }
);

export const editMetric = createAsyncThunk(
  "datasetContent/editMetric",
  async (data: EditMetricForm) => {
    const { datasetId, metricId, name, description } = data;
    const response = await axios.put(
      `${METRIC_ENDPOINT}/${datasetId}/${metricId}`,
      { name, description }
    );

    return response.data;
  }
);

export const deleteMetric = createAsyncThunk(
  "datasetContent/deleteMetric",
  async (data: DeleteDefinitionForm) => {
    const { datasetId, elementId } = data;
    const response = await axios.delete(
      `${METRIC_ENDPOINT}/${datasetId}/${elementId}`
    );

    return response.data;
  }
);

export const addKpi = createAsyncThunk(
  "datasetContent/addKpi",
  async (data: AddKpiForm) => {
    const { datasetId, name, description, expression } = data;
    const response = await axios.post(`${KPI_ENDPOINT}/${datasetId}`, {
      name,
      description,
      expression,
    });

    return response.data;
  }
);

export const editKpi = createAsyncThunk(
  "datasetContent/editKpi",
  async (data: EditKpiForm) => {
    const { datasetId, kpiId, name, description, expression } = data;

    const response = await axios.put(`${KPI_ENDPOINT}/${datasetId}/${kpiId}`, {
      name,
      description,
      expression,
    });

    return response.data;
  }
);

export const deleteKpi = createAsyncThunk(
  "datasetContent/deleteKpi",
  async (data: DeleteDefinitionForm) => {
    const { datasetId, elementId } = data;

    const response = await axios.delete(
      `${KPI_ENDPOINT}/${datasetId}/${elementId}`
    );
    return response.data;
  }
);

export const addUserToDataset = createAsyncThunk(
  "datasetContent/addUserToDataset",
  async (data: AddUserToDatasetForm) => {
    const userOptionToAdd = {
      uid: (data.user as User).id,
      editable: data.editable,
    };

    const modifiedDataset = {
      ...data.dataset,
      uid: (data.dataset.user as User).id,
      users: [...convertUserOptionArray(data.dataset.users), userOptionToAdd],
    };

    const response = await axios.put(
      `${DATASET_ENDPOINT}/${modifiedDataset.uid}/${modifiedDataset.id}`,
      modifiedDataset
    );

    return response.data;
  }
);

export const editUserFromDataset = createAsyncThunk(
  "datasetContent/editUserFromDataset",
  async (data: EditUserFromDatasetForm) => {
    const editedUser = {
      uid: (data.user as User).id,
      editable: data.editable,
    };

    let editedUsers = convertUserOptionArray(data.dataset.users);
    editedUsers = editedUsers.map((userOption) => {
      if (userOption.uid === editedUser.uid) {
        return editedUser;
      }

      return userOption;
    });

    const datasetToSend = {
      ...data.dataset,
      uid: (data.dataset.user as User).id,
      users: editedUsers,
    };

    const response = await axios.put(
      `${DATASET_ENDPOINT}/${datasetToSend.uid}/${datasetToSend.id}`,
      datasetToSend
    );
    return response.data;
  }
);

export const deleteUserFromDataset = createAsyncThunk(
  "datasetContent/deleteUserFromDataset",
  async (data: DeleteUserFromDatasetForm) => {
    const editedUsers = convertUserOptionArray(data.dataset.users);

    const datasetToSend = {
      ...data.dataset,
      uid: (data.dataset.user as User).id,
      users: [
        ...editedUsers.filter((userOption) => userOption.uid !== data.userId),
      ],
    };

    const response = await axios.put(
      `${DATASET_ENDPOINT}/${datasetToSend.uid}/${datasetToSend.id}`,
      datasetToSend
    );
    return response.data;
  }
);

export const fetchMetricRecordsOfDataset = createAsyncThunk(
  "datasetContent/fetchMetricRecords",
  async (
    data: { metricIds: string[]; filter: ApiRangeFilter },
    { rejectWithValue }
  ) => {
    const { metricIds, filter } = data;

    const recordMap: MetricRecordMap = {};
    const urlsToFetchData: string[] = metricIds.map((id) => {
      const endpoint = `${METRIC_RECORD_ENDPOINT}/${id}`;
      return endpoint;
    });

    await Promise.all(urlsToFetchData.map((url) => axios.post(url, filter)))
      .then((responses) => {
        responses.forEach((response, index) => {
          const records = extractMetricRecordsFromResponse(response.data);
          recordMap[metricIds[index]] = records;
        });
      })
      .catch((err) => rejectWithValue(err));

    return recordMap;
  }
);

export const fetchMetricRecordsOfDatasetInRange = createAsyncThunk(
  "datasetContent/fetchMetricRecordsOfDatasetInRange",
  async (
    data: { metricIds: string[]; filter: ApiRangeFilter },
    { rejectWithValue }
  ) => {
    const { metricIds, filter } = data;

    const recordMap: MetricRecordMap = {};
    const urlsToFetchData: string[] = metricIds.map((id) => {
      const endpoint = `${METRIC_RECORD_ENDPOINT}/${id}`;
      return endpoint;
    });

    await Promise.all(urlsToFetchData.map((url) => axios.post(url, filter)))
      .then((responses) => {
        responses.forEach((response, index) => {
          const records = extractMetricRecordsFromResponse(response.data);
          recordMap[metricIds[index]] = records;
        });
      })
      .catch((err) => rejectWithValue(err));

    return recordMap;
  }
);

export const fetchMetricRecordByMetricIdAndTimestamp = createAsyncThunk(
  "datasetContent/fetchMetricRecordByMetricIdAndTimestamp",
  async (data: { metricId: string; filter: ApiRangeFilter }) => {
    const response = await axios.post(
      `${METRIC_RECORD_ENDPOINT}/${data.metricId}`,
      data.filter
    );
    return response.data.length > 0 ? response.data[0] : undefined;
  }
);

export const addMetricRecord = createAsyncThunk(
  "datasetContent/addMetricRecord",
  async (data: AddMetricRecordForm) => {
    const { metricId, timestamp, value } = data;
    const response = await axios.post(`${METRIC_DATA_ENDPOINT}/${metricId}`, {
      timestamp,
      location: DEFAULT_LOCATION,
      value,
    });

    return extractMetricRecord(response.data);
  }
);

export const editMetricRecord = createAsyncThunk(
  "datasetContent/editMetricRecord",
  async (data: EditMetricRecordForm) => {
    const { metricId, recordId, timestamp, value } = data;

    const response = await axios.post(
      `${METRIC_RECORD_ENDPOINT}/${metricId}/${recordId}`,
      {
        timestamp,
        location: DEFAULT_LOCATION,
        value,
      }
    );

    return extractMetricRecord(response.data);
  }
);

export const deleteMetricRecord = createAsyncThunk(
  "datasetContent/deleteMetricRecord",
  async (data: DeleteMetricRecordForm) => {
    const { recordId, metricId, timestamp } = data;
    const response = await axios.delete(
      `${METRIC_RECORD_ENDPOINT}/${metricId}/${timestamp}/${recordId}`
    );

    return response.data;
  }
);

export const uploadData = createAsyncThunk(
  "MeasurementContent/uploadData",
  async (data: UploadDataForm) => {
    const { file, content, user } = data;

    const metadata = {
      cacheControl: "public,max-age=300",
      contentType: file.type,
    };

    const dataFileRef = ref(
      storage,
      `${DATAFILE_STORAGE}/${(user as User).id}`
    );
    const res = await uploadBytes(dataFileRef, content, metadata);

    const downloadUrl = await getDownloadURL(dataFileRef);

    const response = await axios.post(
      `${MEASUREMENT_ENDPOINT}/${data.user.id}`,
      {
        dataFileUrl: downloadUrl,
        fileType: file.type,
      }
    );

    return response.data;
  }
);

export const getDataFile = createAsyncThunk(
  "MeasurementContent/getDataFile",
  async (data: {
    metricRecordMap: MetricRecordMap;
    fileType: string;
    user: User;
  }) => {
    const timezoneString: string = Dates.getTimeZone();

    const queryParams = {
      metricRecordMap: JSON.stringify(data.metricRecordMap),
      fileType: data.fileType,
      timezone: timezoneString,
    };

    const response = await axios.get(
      `${MEASUREMENT_ENDPOINT}/${data.user.id}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  }
);

export const getTemplateFile = createAsyncThunk(
  "MeasurementContent/getTemplateFile",
  async (data: { metricNames: string[]; fileType: string; user: User }) => {
    const queryParams = {
      metricNames: data.metricNames,
      fileType: data.fileType,
    };

    const response = await axios.get(
      `${MEASUREMENT_ENDPOINT}/${data.user.id}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  }
);

// This method is used to extract data from get request of all Metric Records.
// Method should be modified according to Elastic Search or Influx DB changes.
const extractMetricRecordsFromResponse = (
  rawRecords: any[]
): MetricRecord[] => {
  return rawRecords.map((record: any) => {
    const extractedMetricRecord: MetricRecord = {
      id: record.id,
      timestamp: record._time,
      value: Number(record._value),
    };

    return extractedMetricRecord;
  });
};

// This method is used to extract data from post and edit request of Metric Records.
const extractMetricRecord = (
  rawRecord: any
): MetricRecord & { metricId: string } => {
  const metricId = Object.keys(rawRecord.fields)[0];
  const recordId = rawRecord.tags.id;
  const value = Number(rawRecord.fields[metricId]);
  const timestamp = rawRecord.time;

  return {
    id: recordId,
    timestamp: timestamp,
    value: value,
    metricId: metricId,
  };
};
