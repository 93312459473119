import { Dataset, EditUserFromDatasetForm, IModalBaseProps, IUserWithRole, User, UserRole } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { editUserFromDataset } from "@store/slices/DatasetContent/thunks";
import { reflectDatasetEdit } from "@store/slices/Datasets/slice";
import { findDatasetShareType } from "@utils/index";
import { Input, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

interface IEditUserFromDatasetModalProps extends IModalBaseProps {
  userWithRole: IUserWithRole;
  dataset: Dataset;
}

const EditUserFromDatasetModal: React.FC<IEditUserFromDatasetModalProps> = ({
  userWithRole,
  dataset,
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const initialValues = {
    name: userWithRole.user.name,
    editable: [UserRole.ADMIN, UserRole.EDITOR].includes(userWithRole.role),
  };

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: EditUserFromDatasetForm) => {
    const data: EditUserFromDatasetForm = {
      dataset: dataset,
      user: userWithRole.user,
      editable: formData.editable,
    };

    dispatch(editUserFromDataset(data))
      .unwrap()
      .then((res) => {
        dispatch(
          reflectDatasetEdit({
            dataset: res,
            datasetType: findDatasetShareType(dataset, currentUser.id),
          })
        );
        NotificationController.success({
          message: t("notifications.success.editUserFromDataset", {
            userName: userWithRole.user.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.editUserFromDataset", {
            userName: userWithRole.user.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.editUserFromDatasetModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        form={form}
        initialValues={initialValues}
        onFinish={onSubmitHandler}
        layout="vertical"
        name="editUserFromDatasetForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input disabled={true} />
        </BaseForm.Item>
        <BaseForm.Item name="editable" label={t("common.editable")}>
          <Select placeholder={t("common.editable")}>
            <Select.Option value={false}>{t("common.no")}</Select.Option>
            <Select.Option value={true}>{t("common.yes")}</Select.Option>
          </Select>
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default EditUserFromDatasetModal;
