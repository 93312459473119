import { MoreOutlined } from "@ant-design/icons";
import { IDropdownMenuItemOption } from "@common/interfaces";
import { Dropdown, P2 } from "@components/common";
import { Button, Row } from "antd";
import React from "react";
import ItemOptionsPopover from "./ItemOptionsPopover";
import * as S from "./DropdownMenu.style";

interface IDropdownMenuProps {
  icon?: React.ReactNode;
  buttonContent?: React.ReactNode;
  options: IDropdownMenuItemOption[];
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  icon,
  buttonContent,
  options,
}) => {
  const renderDropdownContent = () => {
    return buttonContent ? (
      buttonContent
    ) : (
      <Button
        type="text"
        size="small"
        icon={icon ? icon : <MoreOutlined />}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      />
    );
  };

  return (
    <Dropdown
      overlay={<ItemOptionsPopover options={options} />}
      placement="bottomRight"
      trigger={["click"]}
      getPopupContainer={() => document.body}
    >
      {renderDropdownContent()}
    </Dropdown>
  );
};

export default DropdownMenu;
