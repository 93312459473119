import { DayjsDatePicker } from "@components/common";
import { InputNumber, Row, Space } from "antd";
import styled from "styled-components";

export const CustomDayjsDatePicker = styled(DayjsDatePicker)`
  width: 100%;
`;

export const CustomSpacer = styled(Space)`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
`;

export const CustomInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const FullHeightRow = styled(Row)`
  height: 100%;
`;

export const ButtonContainer = styled.div`
  margin: 1rem 0;
`;
