import { RequestStatus } from "@common/enums";
import { ApiResponseState, Kpi, Metric, Templateset } from "@common/types";
import { createSlice } from "@reduxjs/toolkit";
import { fetchTemplatesetKpis, fetchTemplatesetMetrics } from "./thunks";

const metricsInitialState: ApiResponseState<Metric[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const kpisInitialState: ApiResponseState<Kpi[]> = {
  status: RequestStatus.IDLE,
  data: [],
  error: null,
};

const initialState = {
  currentTemplateset: {} as Templateset,
  metrics: metricsInitialState,
  kpis: kpisInitialState,
};

export const templateContentSlice = createSlice({
  name: "templatesetContent",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setCurrentTemplateset(state, action) {
      state.currentTemplateset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplatesetMetrics.pending, (state) => {
        state.metrics.status = RequestStatus.PENDING;
      })
      .addCase(fetchTemplatesetMetrics.fulfilled, (state, action) => {
        state.metrics.status = RequestStatus.FULFILLED;
        state.metrics.data = action.payload;
      })
      .addCase(fetchTemplatesetMetrics.rejected, (state) => {
        state.metrics.status = RequestStatus.REJECTED;
      })
      .addCase(fetchTemplatesetKpis.pending, (state) => {
        state.kpis.status = RequestStatus.PENDING;
      })
      .addCase(fetchTemplatesetKpis.fulfilled, (state, action) => {
        state.kpis.status = RequestStatus.FULFILLED;
        state.kpis.data = action.payload;
      })
      .addCase(fetchTemplatesetKpis.rejected, (state) => {
        state.kpis.status = RequestStatus.REJECTED;
      });
  },
});

export const { reset: resetTemplasetContent, setCurrentTemplateset } =
  templateContentSlice.actions;
export default templateContentSlice.reducer;
