import { Card as BaseCard, H6, P1 } from "@components/common";
import { Row } from "antd";
import styled from "styled-components";

export const Card = styled(BaseCard)`
  box-shadow: var(--box-shadow);
  transition: all 0.5s ease;

  &:hover {
    background-color: var(--card-hover-color);
  }
`;

export const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
`;

export const HeaderText = styled(H6)`
  &.ant-typography {
    font-weight: 600;
  }
`;

export const BodyText = styled(P1)`
  &.ant-typography {
    color: var(--text-light-color);
  }
`;
