import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { APPLICATION_PATHS } from "@app/routes";
import { fetchMetrics } from "@app/store/slices/DatasetContent/thunks";
import {
  AppDate,
  Dataset,
  IDropdownMenuItemOption,
  MetricRecordDataTableValue,
  MetricRecordMap,
  MetricRecordsFilter,
  RequestStatus,
  TimeDirection,
  TimeOption,
  User,
} from "@common/index";
import {
  DayjsDatePicker,
  DropdownMenu,
  H1,
  SectionHeader,
} from "@components/common";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { Dates } from "@services/index";
import {
  setCurrentDataset,
  setCurrentDatasetStatus,
} from "@store/slices/DatasetContent/slice";
import {
  fetchOwnedDatasets,
  fetchSharedDatasets,
} from "@store/slices/Datasets/thunks";
import * as CommonStyles from "@styles/CommonStyles";
import { Button, Col, Row, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  EditableMetricRecordsTablev2,
  MetricRecordInformationsCard,
} from "../components";
import { ExportDataModal, ImportDataModal } from "../modals";
import * as S from "./MeasurementDetailPagev2.style";
import { TemplatesModal } from "../modals/TemplatesModal";

const MeasurementDetailPagev2: React.FC = () => {
  const weekFormat = "DD.MM";
  const { t } = useTranslation();
  const { datasetId } = useParams();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const ownedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.ownedDatasets.data
  );

  const sharedDatasets: Dataset[] = useAppSelector(
    (state) => state.datasets.sharedDatasets.data
  );

  const ownedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.ownedDatasets.status
  );

  const sharedDatasetsStatus: RequestStatus = useAppSelector(
    (state) => state.datasets.sharedDatasets.status
  );

  const currentDatasetInStore: Dataset | null = useAppSelector(
    (state) => state.datasetContent.currentDataset
  );

  const currentMetricRecordMap: MetricRecordMap = useAppSelector(
    (state) => state.datasetContent.metricRecords.data
  );

  const [isMetricRecordCardOpen, setIsMetricRecordCardOpen] =
    useState<boolean>(false);

  const [currentMetricRecordValue, setCurrentMetricRecordValue] =
    useState<MetricRecordDataTableValue>();

  const [isImportDataModalOpen, setIsImportDataModalOpen] =
    useState<boolean>(false);

  const [isExportDataModalOpen, setIsExportDataModalOpen] =
    useState<boolean>(false);

  const [isTemplatesModalOpen, setIsTemplatesModalOpen] =
    useState<boolean>(false);

  const initialTimeRangeFilter: MetricRecordsFilter = {
    startDate: Dates.getFirstWeekDay(Dates.getToday()),
    endDate: Dates.getLastWeekDay(Dates.getToday()),
    timeOption: TimeOption.WEEKLY,
  };

  const [timeRangeFilter, setTimeRangeFilter] = useState<MetricRecordsFilter>(
    initialTimeRangeFilter
  );

  const getCurrentDatasetIfNotInStore = (): Dataset | undefined => {
    let matchedDataset = ownedDatasets.find((od) => od.id === datasetId);

    matchedDataset = matchedDataset
      ? matchedDataset
      : sharedDatasets.find((od) => od.id === datasetId);

    dispatch(setCurrentDataset(matchedDataset));
    dispatch(setCurrentDatasetStatus(RequestStatus.FULFILLED));
    return matchedDataset;
  };

  const currentDataset: Dataset | undefined = currentDatasetInStore
    ? currentDatasetInStore
    : getCurrentDatasetIfNotInStore();

  useEffect(() => {
    if (ownedDatasetsStatus === RequestStatus.IDLE) {
      currentUser.id && dispatch(fetchOwnedDatasets(currentUser.id));
    }
  }, [currentUser.id, ownedDatasetsStatus]);

  useEffect(() => {
    if (sharedDatasetsStatus === RequestStatus.IDLE) {
      currentUser.id && dispatch(fetchSharedDatasets(currentUser.id));
    }
  }, [currentUser.id, sharedDatasetsStatus]);

  useEffect(() => {
    currentDatasetInStore?.id &&
      dispatch(fetchMetrics(currentDatasetInStore.id));
  }, [currentDatasetInStore]);

  useEffect(() => {
    Object.keys(currentMetricRecordMap).map((metricId) => {
      if (metricId === currentMetricRecordValue?.metric.id) {
        setCurrentMetricRecordValue({
          ...currentMetricRecordValue,
          metricRecords: currentMetricRecordMap[metricId].filter((rec) =>
            Dates.convertStringToDate(rec.timestamp).isSame(
              currentMetricRecordValue.date,
              "day"
            )
          ),
        });
      }
    });
  }, [currentMetricRecordMap]);

  const onWeekFilterChange = (choosenWeek: AppDate | null) => {
    const firstDayOfWeek = Dates.getFirstWeekDay(choosenWeek as AppDate);
    const lastDayOfWeek = Dates.getLastWeekDay(choosenWeek as AppDate);

    const filter: MetricRecordsFilter = {
      startDate: firstDayOfWeek,
      endDate: lastDayOfWeek,
      timeOption: TimeOption.WEEKLY,
    };

    setTimeRangeFilter(filter);
  };

  const changeTimeFilterWithButton = (timeDirection: TimeDirection) => {
    let daysToAdd;
    switch (timeDirection) {
      case TimeDirection.NEXT:
        daysToAdd = 7;
        break;
      case TimeDirection.PREVIOUS:
        daysToAdd = -7;
        break;
    }

    const customizedStartDate = timeRangeFilter.startDate.add(
      daysToAdd,
      "days"
    );

    onWeekFilterChange(customizedStartDate);
  };

  const customWeekStartEndFormat = (date: AppDate) =>
    `${Dates.getFirstWeekDay(date).format(weekFormat)} - ${Dates.getLastWeekDay(
      date
    ).format(weekFormat)}`;

  const onImportClickHandler = () => {
    setIsImportDataModalOpen(true);
  };

  const onExportClickHandler = () => {
    setIsExportDataModalOpen(true);
  };

  const onTemplatesClickHandler = () => {
    setIsTemplatesModalOpen(true);
  };

  const MEASUREMENT_DETAIL_OPTIONS: IDropdownMenuItemOption[] = [
    {
      key: "import",
      title: t("pages.measurements.import"),
      onClick: onImportClickHandler,
    },
    {
      key: "export",
      title: t("pages.measurements.export"),
      onClick: onExportClickHandler,
    },
    {
      key: "templates",
      title: t("pages.measurements.templates"),
      onClick: onTemplatesClickHandler,
    },
  ];

  return (
    <React.Fragment>
      <ImportDataModal
        isOpen={isImportDataModalOpen}
        setIsOpen={setIsImportDataModalOpen}
      />
      <ExportDataModal
        isOpen={isExportDataModalOpen}
        setIsOpen={setIsExportDataModalOpen}
      />
      <TemplatesModal
        isOpen={isTemplatesModalOpen}
        setIsOpen={setIsTemplatesModalOpen}
      />

      <SectionHeader
        customMargin="0 0 3rem 0"
        title={<H1>{currentDataset?.name}</H1>}
        subtitle={
          <S.SectionHeaderSubtitle>
            {currentDataset?.description}
          </S.SectionHeaderSubtitle>
        }
        backButtonPath={APPLICATION_PATHS.MEASUREMENT_PATH}
        backButtonVisible>
        <Row align="middle">
          <S.CustomButton
            icon={<LeftOutlined />}
            type="text"
            size="small"
            onClick={() => {
              changeTimeFilterWithButton(TimeDirection.PREVIOUS);
            }}
          />
          <DayjsDatePicker
            picker="week"
            clearIcon={false}
            format={customWeekStartEndFormat}
            defaultValue={Dates.getClearDate()}
            onChange={onWeekFilterChange}
            value={timeRangeFilter.startDate}
          />
          <S.CustomButton
            icon={<RightOutlined />}
            type="text"
            size="small"
            onClick={() => changeTimeFilterWithButton(TimeDirection.NEXT)}
          />
          {/* <DropdownMenu options={MEASUREMENT_DETAIL_OPTIONS} /> */}
        </Row>
      </SectionHeader>

      <CommonStyles.VerticalSpacer size="large">
        <Row gutter={[24, 12]} align="top">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={isMetricRecordCardOpen ? 16 : 24}
            xxl={isMetricRecordCardOpen ? 16 : 24}>
            {currentDataset && (
              <EditableMetricRecordsTablev2
                filter={timeRangeFilter}
                dataset={currentDataset}
                setIsMetricRecordCardOpen={setIsMetricRecordCardOpen}
                selectedMetricRecordValue={currentMetricRecordValue}
                selectMetricRecordValue={setCurrentMetricRecordValue}
              />
            )}
          </Col>

          {isMetricRecordCardOpen && currentMetricRecordValue && (
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
              <MetricRecordInformationsCard
                metricRecordValue={currentMetricRecordValue}
                setIsOpen={setIsMetricRecordCardOpen}
                selectMetricRecordValue={setCurrentMetricRecordValue}
              />
            </Col>
          )}
        </Row>
      </CommonStyles.VerticalSpacer>
    </React.Fragment>
  );
};

export default MeasurementDetailPagev2;
