import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as S from "./SectionHeader.style";

interface ISectionHeaderProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  backButtonVisible?: boolean;
  backButtonPath?: string;
  customMargin?: string;
  style?: React.CSSProperties;
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({
  title,
  children,
  subtitle,
  backButtonVisible,
  backButtonPath,
  customMargin,
  style,
}) => {
  const navigate = useNavigate();

  const onBackButtonClickHandler = () => {
    backButtonPath ? navigate(backButtonPath) : navigate(-1);
  };

  return (
    <S.WrapperRow
      $customMargin={customMargin}
      justify="space-between"
      style={style}
    >
      <Row align="middle">
        <Space>
          {backButtonVisible && (
            <Col>
              <Button
                type="text"
                size="small"
                onClick={onBackButtonClickHandler}
              >
                <LeftOutlined style={{ fontSize: "20px" }} />
              </Button>
            </Col>
          )}
          <Col>
            {title}
            {subtitle}
          </Col>
        </Space>
      </Row>

      {children && <Col>{children}</Col>}
    </S.WrapperRow>
  );
};

export default SectionHeader;
