import { AddOrganizationForm, IModalBaseProps, User } from "@common/index";
import { BaseForm, Modal } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import { addOrganization } from "@store/slices/Organizations/thunks";
import { Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const AddOrganizationModal: React.FC<IModalBaseProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();
  const dispatch = useAppDispatch();

  const currentUser: User = useAppSelector((state) => state.userContent.data);

  const onOkHandler = () => {
    form.submit();
    setIsOpen(false);
  };

  const onCancelHandler = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const onSubmitHandler = (formData: AddOrganizationForm) => {
    const form: AddOrganizationForm = {
      name: formData.name,
      uid: currentUser.id,
      users: [],
    };

    dispatch(addOrganization(form))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.addOrganization", {
            organizationName: form.name,
          }),
        });
      })
      .catch((err) => {
        NotificationController.success({
          message: t("notifications.error.addOrganization", {
            organizationName: form.name,
          }),
        });
      });
  };

  return (
    <Modal
      title={t("modals.addOrganizationModal.title")}
      open={isOpen}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      getContainer={false}
    >
      <BaseForm
        onFinish={onSubmitHandler}
        form={form}
        layout="vertical"
        name="addOrganizationForm"
      >
        <BaseForm.Item name="name" label={t("common.name")}>
          <Input placeholder={t("common.name")} />
        </BaseForm.Item>
      </BaseForm>
    </Modal>
  );
};

export default AddOrganizationModal;
