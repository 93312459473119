import { Col, Row } from "antd";
import React from "react";
import * as S from "./PaymentNavigatorCard.style";
import { PaymentNavigation } from "..";

const PaymentNavigatorCard: React.FC = () => {
  return (
    <React.Fragment>
      <S.Card padding={[25, 25]}>
        <PaymentNavigation />
      </S.Card>
    </React.Fragment>
  );
};

export default PaymentNavigatorCard;
