import React from "react";
import { H3, P2 } from "../Typography";
import * as S from "./NavigationCard.style";

interface INavigationCardProps {
  icon: React.ReactElement;
  title: string | undefined;
  description?: string;
  onClick: () => void;
}

const NavigationCard: React.FC<INavigationCardProps> = ({
  icon,
  title,
  description,
  onClick,
}) => {
  return (
    <S.Card onClick={onClick} autoHeight={false} padding={[24, 20]}>
      <S.NavigationIconWrapper>{icon}</S.NavigationIconWrapper>
      <S.NavigationCardInfo>
        <H3>{title}</H3>
        <P2>{description}</P2>
      </S.NavigationCardInfo>
    </S.Card>
  );
};

export default NavigationCard;
// const navigate = useNavigate();
