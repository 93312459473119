import { BillingProfile, DeleteBillingProfileForm, IModalBaseProps } from "@common/index";
import { Modal, Text } from "@components/common";
import { NotificationController } from "@controllers/index";
import { useAppDispatch } from "@hooks/index";
import { deleteBillingProfile } from "@store/slices/Payments/thunks";
import React from "react";
import { useTranslation } from "react-i18next";

interface IDeleteBillingProfileModalProps extends IModalBaseProps {
  billingProfile: BillingProfile;
}

const DeleteBillingProfileModal: React.FC<IDeleteBillingProfileModalProps> = ({
  isOpen,
  setIsOpen,
  billingProfile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onOkHandler = () => {
    const data: DeleteBillingProfileForm = {
      id: billingProfile.id,
      tenantId: billingProfile.tenantId,
    };

    setIsOpen(false);
    dispatch(deleteBillingProfile(data))
      .unwrap()
      .then((res) => {
        NotificationController.success({
          message: t("notifications.success.deleteBillingProfile", {
            billingProfile: billingProfile.title,
          }),
        });
      })
      .catch((err) => {
        NotificationController.error({
          message: t("notifications.error.deleteBillingProfile", {
            billingProfile: billingProfile.title,
          }),
        });
      });
  };

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      title={t("modals.deleteBillingProfileModal.title", {
        billingProfile: billingProfile.title,
      })}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
    >
      <Text>{t("common.canNotBeUndone")}</Text>
    </Modal>
  );
};

export default DeleteBillingProfileModal;
