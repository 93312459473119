import React from "react";
import { IDropdownMenuItemOption } from "@common/interfaces";
import * as S from "./DropdownMenu.style";

interface IItemOptionsPopoverProps {
  options: IDropdownMenuItemOption[];
}

const ItemOptionsPopover: React.FC<IItemOptionsPopoverProps> = ({
  options,
}) => {
  return (
    <S.CardMenu selectable={false}>
      {options.map((option) => (
        <S.MenuItem
          key={option.key}
          onClick={() => (option.onClick ? option.onClick() : null)}
          onMouseDown={(e) => e.stopPropagation()}
          disabled={option.disabled ? option.disabled : false}
        >
          {option.title}
        </S.MenuItem>
      ))}
    </S.CardMenu>
  );
};

export default ItemOptionsPopover;
