import styled, { css } from "styled-components";
import { media } from "@common/constants";
import { HeaderActionWrapper } from "../../../Header.style";
import { Avatar } from "antd";

interface ICustomAvatarProps {
  $photoExist: boolean;
}

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`;

export const CustomAvatar = styled(Avatar)<ICustomAvatarProps>`
  ${(props) =>
    !props.$photoExist
      ? css`
          color: var(--white);
          background-color: var(--primary-color);
        `
      : null}
`;
