import { media } from "@common/constants";
import { BaseFormTitle, H1, H3, Text } from "@components/common";
import { Row } from "antd";
import styled from "styled-components";

export const PageWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  min-width: 280px;

  @media screen and only ${media.md} {
    min-width: 350px;
  }
`;

export const SVGWrapper = styled(Row)`
  justify-content: center;
  align-items: center;
`;

export const NoOrganizationSVG = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;

  @media only screen and ${media.md} {
    max-width: 300px;
  }

  @media screen and only ${media.lg} {
    max-width: 500px;
  }
`;

export const NoOrganizationTitleContainer = styled.div`
  max-width: 280px;
  text-align: center;
  margin-bottom: 1.125rem;
`;

export const NoOrganizationTitle = styled(BaseFormTitle)`
  &.ant-typography {
    color: var(--primary-color);
  }
`;

export const OceanEffect = styled.div`
  height: 80px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Wave = styled.div`
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23003F7C'/%3E%3C/svg%3E");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;

  &:nth-of-type(2) {
    bottom: 0;
    animation: wave 18s linear reverse infinite;
    opacity: 0.5;
  }

  &:nth-of-type(3) {
    bottom: 0;
    animation: wave 20s -1s linear infinite;
    opacity: 0.5;
  }

  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-25%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
`;
